import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ProcessStatus } from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';
import { fetchConfig } from '../../../actions/projects';

@withTranslation(['project', 'general'], { wait: true })
class ProjectConfig extends Component {
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {
    this.props.fetchConfig(this.props.projectId);
    console.log('fetchingConfig');
  }
  // UNSAFE_componentWillReceiveProps (nextProps) {}

  render() {
    const { config, fetchingConfig, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    console.log('config', config);
    return (
      <div className={'column'}>
        {config === null && (
          <LoadingBar
            show='true'
            wrapperClassname={'loadingBarWrapper'}
            message={t(`${generalLNS}:updating_project`)}
          />
        )}
        {/*
        <textarea rows="30" cols="50">
          {JSON.stringify(config, null, 2)}
        </textarea>
        */}
        {config !== null && (
          <pre style={{ color: 'white' }}>
            <code
              style={{
                backgroundColor: '#282828',
                padding: '10px',
                color: 'white',
                display: 'inline-block',
                fontSize: '12px'
              }}
            >
              {JSON.stringify(config, null, 2)}
            </code>
          </pre>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchingConfig: state.getIn(['projects', 'fetchingConfig']),
    config: state.getIn(['projects', 'config'])
  };
}

export default connect(mapStateToProps, { fetchConfig })(ProjectConfig);
