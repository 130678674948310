import { Rx } from 'rxjs/Rx';
import { ajax } from 'rxjs/observable/dom/ajax';

import { UsersAction } from '../utils/ActionTypes';
import {
  fetchUsersFulfilled,
  fetchUsersRejected,
  fetchUserRejected,
  fetchUserFulfilled,
  addUserFulfilled,
  addUserRejected,
  deleteUserFulfilled,
  deleteUserRejected,
  editUserFulfilled,
  editUserRejected,
  updateConnectedPropertySuccess,
  linkUserToProjectFulfilled,
  linkUserToProjectRejected,
  linkUserToTranslationFulfilled,
  linkUserToTranslationRejected,
  linkUserToClientFulfilled,
  linkUserToClientRejected,
  inviteUserFulfilled,
  inviteUserRejected
} from '../actions/users';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchUsersEpic = (action$, store) => {
  return action$
    .ofType(UsersAction.FETCH_USERS)
    .debounceTime(250)
    .mergeMap((action) => {
      let param = action.skinny ? '?skinny=true' : '';
      let url = baseApiUrl + 'users' + param;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((res) => res.response.data)
        .map(fetchUsersFulfilled)
        .catch(fetchUsersRejected);
    });
};

const fetchUserEpic = (action$, store) => {
  return action$
    .ofType(UsersAction.FETCH_USER)
    .debounceTime(500)
    .mergeMap((action) => {
      // let url = `${baseApiUrl}user/${action.userId}`;
      let url = baseApiUrl + 'users/' + action.userId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(fetchUserFulfilled)
        .catch(fetchUserRejected);
    });
};

const addUserEpic = (action$, store) => {
  return action$
    .ofType(UsersAction.ADD_USER)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'users';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers, body: action.user })
        .map((res) => res.response.data)
        .map(addUserFulfilled)
        .catch(addUserRejected);
    });
};

const editUserEpic = (action$, store) => {
  return (
    action$
      .ofType(UsersAction.EDIT_USER)
      // .debounceTime(500)
      .mergeMap((action) => {
        // let url = `${baseApiUrl}user/${action.user.id}`;
        let url = baseApiUrl + 'users/' + action.user.id;
        let headers = getHttpAuthHeaders(store, url);
        // return ajax({url, method: 'PUT', headers: headers, body: action.user.toObject()})
        return ajax({ url, method: 'PUT', headers: headers, body: action.user })
          .map((res) => res.response.data)
          .map(editUserFulfilled)
          .catch(editUserRejected);
      })
  );
};

const deleteUserEpic = (action$, store) => {
  return action$
    .ofType(UsersAction.DELETE_USER)
    .debounceTime(500)
    .mergeMap((action) => {
      // let url = `${baseApiUrl}user/${action.userId}`;
      let url = baseApiUrl + 'users/' + action.userId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'DELETE', headers: headers })
        .map((res) => res.data)
        .map(() => deleteUserFulfilled(action.userId))
        .catch(deleteUserRejected);
    });
};

const linkUserToProjectEpic = (action$, store) => {
  return action$
    .ofType(UsersAction.LINK_USER_TO_PROJECT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'users/' + action.data.user_id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.data })
        .map((res) => res.response.projects)
        .map(linkUserToProjectFulfilled)
        .catch(linkUserToProjectRejected);
    });
};

const linkUserToTranslationEpic = (action$, store) => {
  return action$
    .ofType(UsersAction.LINK_USER_TO_TRANSLATION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'users/' + action.data.user_id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.data })
        .map((res) => res.response.translations)
        .map(linkUserToTranslationFulfilled)
        .catch(linkUserToTranslationRejected);
    });
};

const linkUserToClientEpic = (action$, store) => {
  return action$
    .ofType(UsersAction.LINK_USER_TO_CLIENT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'users/' + action.data.user_id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.data })
        .map((res) => res.response.clients)
        .map(linkUserToClientFulfilled)
        .catch(linkUserToClientRejected);
    });
};

const inviteUserEpic = (action$, store) => {
  return action$
    .ofType(UsersAction.INVITE_USER)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'users/' + action.userId + '/invite';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({
        url,
        method: 'POST',
        headers: headers,
        body: { new_password: action.password }
      })
        .map((res) => res.response.data)
        .map(inviteUserFulfilled)
        .catch(inviteUserRejected);
    });
};

const updateConnectedPropertyEpic = (action$, store) => {
  return action$
    .ofType(
      UsersAction.ADD_USER_TO_PROJECT,
      UsersAction.REMOVE_USER_FROM_PROJECT,
      UsersAction.ADD_USER_TO_TRANSLATION,
      UsersAction.REMOVE_USER_FROM_TRANSLATION,
      UsersAction.CHANGE_USER_ROLE_FOR_TRANSLATION
    )
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'users/' + action.userId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.body })
        .map((res) => res.response)
        .map(updateConnectedPropertySuccess)
        .catch((error) =>
          Rx.Observable.of({
            type: UsersAction.UPDATE_CONNECTED_PROPERTY_ERROR,
            payload: error.xhr.response,
            error: true
          })
        );
    });
};

export {
  fetchUsersEpic,
  fetchUserEpic,
  addUserEpic,
  editUserEpic,
  deleteUserEpic,
  linkUserToProjectEpic,
  linkUserToTranslationEpic,
  linkUserToClientEpic,
  updateConnectedPropertyEpic,
  inviteUserEpic
};
