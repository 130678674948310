import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-md';
import styles from '../../styles/translationTool/translationTool.scss';
import Comment from './Comment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  saveTextComment,
  deleteTextComment,
  setCommentsCount as setCommentsCountReduxState
} from '../../actions/translationTool';
import { aclFilter } from '../../utils/ACL';
import { aclTranslationTool } from '../../utils/Permissions';
import { Scope } from '../../utils/constants/ACL';
import { ProcessStatus } from '../../utils/Constants';

@withTranslation('translation_navigation', { wait: true })
class CommentsDrawer extends Component {
  constructor(props) {
    super(props);
    this.commentWrapper = React.createRef();
    this.scrollBox = React.createRef();
    this.commentTextarea = React.createRef();
    this.state = {
      newCommentTextareaShown: false,
      comments: [],
      waitingForNew: false,
      waitingForDeleted: false,
      autoHeight: false
    };
  }

  componentDidMount() {
    const { comments } = this.props;
    if (comments) {
      if (comments.length === 0) {
        this.showTextArea();
      } else {
        this.props.setCommentsCount(comments.length);
        this.setState({
          comments: this.sortComments(comments)
        });
      }
    }
  }

  sortComments = (list) => {
    return list.sort(function (a, b) {
      return a.id - b.id; // ältestes zuerst, unten ist neuestes
    });
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      savingComment,
      show,
      savedComment,
      deletingComment,
      deletedCommentId,
      setCommentsCountReduxState,
      commentsCount
    } = this.props;

    const { waitingForNew, waitingForDeleted, comments } = this.state;

    if (!prevProps.show && show && this.commentWrapper.current) {
      this.commentWrapper.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
      this.scrollToBottom();
    }

    // new comment successfully saved => add to comments list
    if (
      waitingForNew &&
      prevProps.savingComment !== savingComment &&
      savingComment === ProcessStatus.FINISHED &&
      savedComment
    ) {
      let newComments = this.state.comments;
      // newComments.splice(0, 0, savedComment); // am anfang einfügen
      newComments.push(savedComment); // am ende einfügen
      this.setState(
        {
          comments: newComments,
          waitingForNew: false
        },
        () => {
          this.scrollToBottom();
          setCommentsCountReduxState(commentsCount + 1);
          this.props.setCommentsCount(newComments.length);
        }
      );
    }

    // comment deleted
    if (
      waitingForDeleted &&
      prevProps.deletingComment !== deletingComment &&
      deletingComment === ProcessStatus.FINISHED &&
      deletedCommentId !== null
    ) {
      let newComments2 = [];
      for (let d = 0; d < comments.length; d++) {
        if (comments[d].id !== deletedCommentId) {
          newComments2.push(comments[d]);
        }
      }
      this.setState(
        {
          comments: newComments2,
          waitingForDeleted: false
        },
        () => {
          this.scrollToBottom();
          this.props.setCommentsCount(newComments2.length);
        }
      );
    }
  }

  showTextArea = () => {
    this.setState(
      {
        newCommentTextareaShown: true
      },
      () => {
        this.commentTextarea.current.focus();
        setTimeout(() => {
          this.scrollToBottom();
        }, 500);
      }
    );
  };

  closeTextArea = () => {
    this.commentTextarea.current.value = '';
    this.setState({
      newCommentTextareaShown: false
    });
  };

  sendComment = () => {
    const { collectionId, textId, translationId } = this.props;
    let val = this.commentTextarea.current.value;
    if (val === '') {
      return;
    }

    const sendObj = {
      username: this.props.user.username,
      text: val,
      collectionId: collectionId,
      textId: textId,
      translationId: translationId
    };

    this.commentTextarea.current.value = '';
    this.props.saveTextComment(sendObj);

    this.setState(
      {
        waitingForNew: true
      },
      () => {
        setTimeout(() => {
          this.closeTextArea();
        }, 500);
      }
    );
  };

  deleteComment = (id) => {
    this.setState(
      {
        waitingForDeleted: true
      },
      () => {
        this.props.deleteTextComment(id);
      }
    );
  };

  scrollToBottom = () => {
    this.scrollBox.current.scrollTop = this.scrollBox.current.scrollHeight;
  };

  toggleHeight = () => {
    this.setState({
      autoHeight: !this.state.autoHeight
    });
  };

  render() {
    const { show, toggle, collectionId, textId, savingComment } = this.props;
    const { newCommentTextareaShown, comments, autoHeight } = this.state;

    return (
      <div
        ref={this.commentWrapper}
        className={classNames(styles.commentsWrapper, {
          extended: show,
          'no-scroll': autoHeight
        })}
      >
        <div className={styles.commentButtons}>
          <Button icon title='Close Comments' onClick={toggle}>
            vertical_align_top
          </Button>
          <Button icon title='toggle height' onClick={this.toggleHeight}>
            {autoHeight ? 'align_vertical_bottom' : 'height'}
          </Button>
        </div>
        <div className={styles.commentColumn}>
          <div className={styles.comments} ref={this.scrollBox}>
            {savingComment === ProcessStatus.STARTED && (
              <span>saving comment....</span>
            )}
            {comments.length === 0 && <span>no comments here so far</span>}
            {comments.length > 0 &&
              comments.map((item) => (
                <Comment
                  key={collectionId + '_' + textId + '_comment_' + item.id}
                  id={item.id}
                  name={item.user.name}
                  commentUserId={item.user.id}
                  text={item.text}
                  createdAt={item.created_at}
                  updatedAt={item.updated_at}
                  done={item.done}
                  currentUserId={this.props.user.id}
                  deleteTextComment={this.deleteComment}
                />
              ))}

            {aclFilter(
              <div
                className={classNames('newComment', {
                  show: newCommentTextareaShown
                })}
              >
                <textarea
                  rows={5}
                  placeholder='New comment'
                  ref={this.commentTextarea}
                />
                <div className={styles.buttonsColumn}>
                  <Button
                    icon
                    title='close new comment area'
                    onClick={this.closeTextArea}
                  >
                    close
                  </Button>
                  <Button
                    icon
                    title='send'
                    className='sendCommentButton'
                    onClick={this.sendComment}
                  >
                    send
                  </Button>
                </div>
              </div>
            )([], [aclTranslationTool.comments_add], Scope.TRANSLATION)}

            {aclFilter(
              <div>
                {!newCommentTextareaShown && (
                  <Button
                    iconChildren='add_comment'
                    style={{ marginBottom: '10px', borderRadius: '5px' }}
                    title='add comment'
                    onClick={this.showTextArea}
                  >
                    add comment
                  </Button>
                )}
              </div>
            )([], [aclTranslationTool.comments_add], Scope.TRANSLATION)}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.get('auth').get('user') || null,
    savingComment: state.getIn(['translationTool', 'savingComment']),
    savedComment: state.getIn(['translationTool', 'savedComment']),
    deletingComment: state.getIn(['translationTool', 'deletingComment']),
    deletedCommentId: state.getIn(['translationTool', 'deletedCommentId']),
    commentsCount: state.getIn(['translationTool', 'commentsCount'])
  };
}

export default connect(mapStateToProps, {
  saveTextComment,
  deleteTextComment,
  setCommentsCountReduxState
})(CommentsDrawer);
