import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import VirtualizedSelect from 'react-select';
import styles from '../../../styles/projects/translationoverview/translation-select.scss';

class TranslationSelect extends Component {
  render() {
    const {
      cardTitle,
      isLoadingExternally,
      translationsForSelect,
      handleSelectChange
    } = this.props;

    return (
      /*
       *  API: https://deploy-preview-2289--react-select.netlify.com/props
       * */
      <VirtualizedSelect
        // menuIsOpen={true} // for styling
        name='select-language'
        simpleValue={false}
        className={styles.reactLanguageSelect}
        theme={
          // theme => {
          () => {
            // console.log('theme', theme); // => zum debuggen
            return {
              spacing: {
                baseUnit: 4,
                controlHeight: 65,
                menuGutter: 8
              },
              borderRadius: 0,
              colors: {
                primary25: '#ff5900', // hover
                primary: '#ff8b4c', // selected
                primary50: '#ff5900', // clicked
                primary75: '#4C9AFF',
                danger: '#DE350B',
                dangerLight: '#FFBDAD',
                neutral0: 'transparent', // HINTERGRUND
                neutral5: 'hsl(0, 0%, 95%)',
                neutral10: 'hsl(0, 0%, 90%)',
                neutral20: 'hsl(0, 0%, 80%)',
                neutral30: 'hsl(0, 0%, 70%)',
                neutral40: 'hsl(0, 0%, 60%)',
                neutral50: 'hsl(0, 0%, 50%)',
                neutral60: 'hsl(0, 0%, 40%)',
                neutral70: 'hsl(0, 0%, 30%)',
                neutral80: 'hsl(0, 0%, 20%)',
                neutral90: 'hsl(0, 0%, 10%)'
              }
            };
          }
        }
        classNamePrefix={'language-select-'}
        value={cardTitle}
        setValue={cardTitle}
        placeholder={cardTitle}
        clearable={false}
        searchable
        hideSelectedOptions={false}
        onChange={handleSelectChange}
        isDisabled={isLoadingExternally}
        options={translationsForSelect}
        isOptionSelected={(translationsForSelect) =>
          translationsForSelect.selected
        }
        isOptionDisabled={(translationsForSelect) =>
          translationsForSelect.selected
        }
        blurInputOnSelect
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchingTranslationStatistics: state.getIn([
      'projects',
      'fetchingTranslationStatistics'
    ]),
    fetchingTranslation: state.getIn(['projects', 'fetchingTranslation']),
    translation: state.getIn(['projects', 'translation'])
  };
}

export default withRouter(connect(mapStateToProps, {})(TranslationSelect));
