import React, { Component } from 'react';

import { connect } from 'react-redux';

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  // ToDo: für jeden Benutzer ein enstprechendes Dashboard bauen
  dashboardContent() {
    return <span>{this.props.role.name}</span>;
  }

  render() {
    return (
      <div>
        <h1>Dashboard</h1>
        {this.dashboardContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let user = state.get('auth').get('user');
  return {
    user: user,
    role: user.roles[0]
  };
}

export default connect(mapStateToProps)(Dashboard);
