import { fromJS } from 'immutable';

import { ProjectsFilterAction } from '../../utils/ActionTypes';

import { FilterType, ProjectSortProp } from '../../utils/Constants';

const initialState = fromJS({
  filterType: FilterType.ALL,
  sortProp: ProjectSortProp.NONE,
  searchTerm: ''
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ProjectsFilterAction.FILTER_BY_TYPE:
      return state.set('filterType', action.filterType);
    case ProjectsFilterAction.RESET_FILTER:
      return state.set('filterType', FilterType.ALL);
    case ProjectsFilterAction.SORT_BY_PROP:
      return state.set('sortProp', action.sortProp);
    case ProjectsFilterAction.RESET_SORT:
      return state.set('sortProp', ProjectSortProp.NONE);
    case ProjectsFilterAction.SEARCH_BY_TERM:
      return state.set('searchTerm', action.searchTerm);
    case ProjectsFilterAction.RESET_SEARCH:
      return state.set('searchTerm', '');
    default:
      return state;
  }
};

export default reducer;
