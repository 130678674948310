import { AdditionalContentsAction } from '../utils/ActionTypes';
import { List, fromJS } from 'immutable';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  additionalContents: List(),
  fetchingAdditionalContents: ProcessStatus.INITIAL,
  pdfFiles: null,
  fetchingPdfFiles: ProcessStatus.INITIAL,
  voFiles: null,
  fetchingVoFiles: ProcessStatus.INITIAL,
  addingAdditionalContents: ProcessStatus.INITIAL,
  updatingAdditionalContents: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AdditionalContentsAction.FETCH_ADDITIONAL_CONTENTS:
      return state.set('fetchingAdditionalContents', ProcessStatus.STARTED);
    case AdditionalContentsAction.FETCH_ADDITIONAL_CONTENTS_FULFILLED:
      return state
        .set('fetchingAdditionalContents', ProcessStatus.FINISHED)
        .set('additionalContents', List(action.data));
    case AdditionalContentsAction.FETCH_ADDITIONAL_CONTENTS_REJECTED:
      return state.set('fetchingAdditionalContents', ProcessStatus.FAILED);

    case AdditionalContentsAction.FETCH_PDF_FILES:
      return state.set('fetchingPdfFiles', ProcessStatus.STARTED);
    case AdditionalContentsAction.FETCH_PDF_FILES_FULFILLED:
      return state
        .set('fetchingPdfFiles', ProcessStatus.FINISHED)
        .set('pdfFiles', action.data.data);
    case AdditionalContentsAction.FETCH_PDF_FILES_REJECTED:
      return state.set('fetchingPdfFiles', ProcessStatus.FAILED);

    case AdditionalContentsAction.FETCH_VO_FILES:
      return state.set('fetchingVoFiles', ProcessStatus.STARTED);
    case AdditionalContentsAction.FETCH_VO_FILES_FULFILLED:
      return state
        .set('fetchingVoFiles', ProcessStatus.FINISHED)
        .set('voFiles', action.data.data);
    case AdditionalContentsAction.FETCH_VO_FILES_REJECTED:
      return state.set('fetchingVoFiles', ProcessStatus.FAILED);

    case AdditionalContentsAction.ADD_ADDITIONAL_CONTENTS:
      return state.set('addingAdditionalContents', ProcessStatus.STARTED);
    case AdditionalContentsAction.ADD_ADDITIONAL_CONTENTS_FULFILLED:
      return state.set('addingAdditionalContents', ProcessStatus.FINISHED);
    // .set('additionalContents', Map(action.data));
    case AdditionalContentsAction.ADD_ADDITIONAL_CONTENTS_REJECTED:
      return state.set('addingAdditionalContents', ProcessStatus.FAILED);

    case AdditionalContentsAction.UPDATE_ADDITIONAL_CONTENTS:
      return state.set('updatingAdditionalContents', ProcessStatus.STARTED);
    case AdditionalContentsAction.UPDATE_ADDITIONAL_CONTENTS_FULFILLED:
      return state
        .set('updatingAdditionalContents', ProcessStatus.FINISHED)
        .set('additionalContents', List(action.data.additional_contents));
    case AdditionalContentsAction.UPDATE_ADDITIONAL_CONTENTS_REJECTED:
      return state.set('updatingAdditionalContents', ProcessStatus.FAILED);
    default:
      return state;
  }
};

export { initialState, reducer as additionalContents };
