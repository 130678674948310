import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
// import notify from 'redux-notify';
// import notifyEvents from './NotifyEvents';
import invalidUrlMiddleware from '../middleware/invalidUrl';
import translationToolMiddleware from '../middleware/translationTool';
import { combinedReducers } from './Reducers';
import epics from './Epics';
import { responsiveStoreEnhancer } from 'redux-responsive';

// const initialEnhancers = [responsiveStoreEnhancer];

let loggerOptions = {
  collapsed: true, // default: false
  timestamp: false, // default: true

  colors: {
    title: () => '#bbbbbb', // default: "title: () => 'inherit'"
    prevState: () => '#9E9E9E',
    action: () => '#03A9F4',
    nextState: () => '#4CAF50',
    error: () => '#F20404'
  }
};

let composeEnhancers = null;
let store = null;
const epicMiddleware = createEpicMiddleware();
let middleware = [
  invalidUrlMiddleware,
  translationToolMiddleware,
  epicMiddleware
];
// let enhancers = {};

const initialMiddleware = [createLogger(loggerOptions)];

if (process.env.NODE_ENV !== 'production') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // ersetzt "compose" in createStore
  store = createStore(
    combinedReducers,
    composeEnhancers(
      applyMiddleware(
        ...initialMiddleware,
        ...middleware /* , notify(notifyEvents) */
      ),
      responsiveStoreEnhancer // ...initialEnhancers
      // ,
      // ...enhancers
    )
  );
} else {
  store = createStore(
    combinedReducers,
    compose(
      applyMiddleware(...middleware),
      responsiveStoreEnhancer // ...initialEnhancers
    )
  );
}

epicMiddleware.run(epics);
export default store;
