// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".object-autocomplete ul li {\n  /* fixes bug, that clicking a disabled link let the menu still open */\n}\n\n.object-autocomplete ul li div[disabled] {\n  pointer-events: none;\n  cursor: default;\n}\n", ""]);
// Exports
exports.locals = {
	"object-autocomplete": "object-autocomplete",
	"objectAutocomplete": "object-autocomplete"
};
module.exports = exports;
