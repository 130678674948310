import React, { Component } from 'react';

import { connect } from 'react-redux';

import { NotificationAnimation } from '../../utils/Constants';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import notificationsStyles from '../../styles/notification.scss';

import Notification from './Notification';

class NotificationSystem extends Component {
  renderNotification(notification) {
    return (
      <Notification key={notification.identifier} notification={notification} />
    );
  }

  render() {
    return (
      <div className={notificationsStyles.notificationWrapper}>
        <ReactCSSTransitionGroup
          component='div'
          transitionName={notificationsStyles}
          transitionAppear={true}
          transitionAppearTimeout={NotificationAnimation.APPEAR}
          transitionLeaveTimeout={NotificationAnimation.LEAVE}
          transitionEnterTimeout={NotificationAnimation.ENTER}
        >
          {this.props.notifications.map((notification) =>
            this.renderNotification(notification)
          )}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.get('notifications').get('notifications')
  };
}

export default connect(mapStateToProps, {})(NotificationSystem);
