import { List, fromJS } from 'immutable';

import { RolesAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';
import { generateGuid } from '../utils/HelperFunctions';

const initialState = fromJS({
  pristine: true,
  roles: List(),
  permissions: List(),
  fetchingRoles: ProcessStatus.INITIAL,
  fetchingRolePermissions: ProcessStatus.INITIAL,
  changingRolePermissions: ProcessStatus.INITIAL,
  selectedRole: null,
  addingRole: ProcessStatus.INITIAL,
  fetchingRole: ProcessStatus.INITIAL,
  editingRole: ProcessStatus.INITIAL,
  deletingRole: ProcessStatus.INITIAL,
  updateToken: '',
  error: null
});
let index = 0;
let role = null;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RolesAction.FETCH_ROLES:
      return state.set('fetchingRoles', ProcessStatus.STARTED);
    case RolesAction.FETCH_ROLES_FULFILLED:
      return state
        .set('fetchingRoles', ProcessStatus.FINISHED)
        .set('roles', List(action.roles));
    case RolesAction.FETCH_ROLES_REJECTED:
      return state
        .set('fetchingRoles', ProcessStatus.FAILED)
        .set('error', action.payload);
    case RolesAction.ADD_ROLE:
      return state.set('addingRole', ProcessStatus.STARTED);
    case RolesAction.ADD_ROLE_FULFILLED:
      if (state.get('roles').size !== 0) {
        return state
          .set('addingRole', ProcessStatus.FINISHED)
          .set('roles', state.get('roles').push(action.role));
      }
      return state.set('addingRole', ProcessStatus.FINISHED);
    case RolesAction.ADD_ROLE_REJECTED:
      return state
        .set('addingRole', ProcessStatus.FAILED)
        .set('error', action.payload);
    case RolesAction.DELETE_ROLE:
      return state.set('deletingRole', ProcessStatus.STARTED);
    case RolesAction.DELETE_ROLE_FULFILLED:
      state = state.set('deletingRole', ProcessStatus.FINISHED);
      index = state.get('roles').findIndex(function (obj) {
        return obj.id === action.roleId;
      });
      if (index > -1) {
        state = state.set('roles', state.get('roles').delete(index));
        if (
          state.get('selectedRole') !== null &&
          state.get('selectedRole').id === action.roleId
        ) {
          state = state.set('selectedRole', null);
        }
      }
      return state;
    case RolesAction.DELETE_ROLE_REJECTED:
      return state
        .set('deletingRole', ProcessStatus.FAILED)
        .set('error', action.payload);
    case RolesAction.EDIT_ROLE:
      return state.set('editingRole', ProcessStatus.STARTED);
    case RolesAction.EDIT_ROLE_FULFILLED:
      index = state.get('roles').findIndex(function (role) {
        return role.id === action.role.id;
      });

      role = action.role;

      if (role.active) {
        role.active = +role.active; // TODO: true false to 1 0, why do we have to do this?
      }
      if (index > -1) {
        return state
          .set('editingRole', ProcessStatus.FINISHED)
          .set('selectedRole', update(state.get('selectedRole'), role))
          .set('roles', state.get('roles').set(index, action.role))
          .set('updateToken', generateGuid());
      }

      // TODO: Ist das richtig? Wenn es den Role nicht gibt, sollte dann nicht einfach nichts gemacht werden?
      return state
        .set('editingRole', ProcessStatus.FINISHED)
        .set('selectedRole', update(state.get('selectedRole'), action.role))
        .set('updateToken', generateGuid());
    case RolesAction.EDIT_ROLE_REJECTED:
      return state
        .set('editingRole', ProcessStatus.FAILED)
        .set('error', action.payload);

    case RolesAction.FETCH_ROLE_PERMISSIONS:
      return state.set('fetchingRolePermissions', ProcessStatus.STARTED);
    case RolesAction.FETCH_ROLE_PERMISSIONS_FULFILLED:
      return state
        .set('fetchingRolePermissions', ProcessStatus.FINISHED)
        .set('permissions', List(action.permissions));
    case RolesAction.FETCH_ROLE_PERMISSIONS_REJECTED:
      return state.set('fetchingRolePermissions', ProcessStatus.FAILED);
    case RolesAction.CHANGE_ROLE_PERMISSIONS:
      return state.set('changingRolePermissions', ProcessStatus.STARTED);
    case RolesAction.CHANGE_ROLE_PERMISSIONS_FULFILLED:
      if (typeof action.allow !== 'undefined') {
        if (action.allow) {
          state = state.set(
            'permissions',
            state.get('permissions').push({ id: action.permissionId })
          );
        } else {
          let index = state.get('permissions').findIndex(function (obj) {
            return obj.id === action.permissionId;
          });
          if (index > -1) {
            state = state.set(
              'permissions',
              state.get('permissions').delete(index)
            );
          }
        }
      }
      return state.set('changingRolePermissions', ProcessStatus.FINISHED);
    case RolesAction.CHANGE_ROLE_PERMISSIONS_REJECTED:
      return state.set('changingRolePermissions', ProcessStatus.FAILED);
    case RolesAction.RESET_ROLE_PERMISSIONS:
      return state.set('permissions', List());

    default:
      return state;
  }
};

function update(current, coming) {
  if (!current) return coming;
  if (!coming) return current;
  for (let property in coming) {
    if (current.hasOwnProperty(property)) {
      switch (typeof coming[property]) {
        case 'string':
        case 'number':
          if (coming[property] !== current[property]) {
            current[property] = coming[property];
          }
          break;
        default:
          break;
      }
    } else {
      current[property] = coming[property];
    }
  }
  return current;
}

export { initialState, reducer as roles };
