import * as Rx from 'rxjs';
import { StructureAction } from '../utils/ActionTypes';

const fetchStructure = (projectId, translationId) => {
  return {
    type: StructureAction.FETCH_STRUCTURE,
    projectId: projectId,
    translationId: translationId
  };
};

const fetchStructureFulfilled = (data) => {
  return {
    type: StructureAction.FETCH_STRUCTURE_FULFILLED,
    data: data
  };
};

const fetchStructureRejected = (error) => {
  return Rx.Observable.of({
    type: StructureAction.FETCH_STRUCTURE_REJECTED,
    payload: error.xhr ? error.xhr.response : error
  });
};

const filterStructure = (texts, filterText) => {
  return {
    type: StructureAction.FILTER_STRUCTURE,
    texts: texts,
    filterText: filterText
  };
};

const selectItem = (projectId, translationId, itemId) => {
  return {
    type: StructureAction.SELECT_ITEM,
    projectId: projectId,
    translationId: translationId,
    itemId: itemId
  };
};

const selectItemFulfilled = (data) => {
  return {
    type: StructureAction.SELECT_ITEM_FULFILLED,
    data: data
  };
};

const selectItemRejected = (error) => {
  return Rx.Observable.of({
    type: StructureAction.SELECT_ITEM_REJECTED,
    payload: error.xhr ? error.xhr.response : error
  });
};

const setItemStatus = (data) => {
  return {
    type: StructureAction.SET_ITEM_STATUS,
    projectId: data.projectId,
    translationId: data.translationId,
    itemId: data.itemId,
    status: data.status
  };
};

const setItemStatusFulfilled = (data) => {
  return {
    type: StructureAction.SET_ITEM_STATUS_FULFILLED,
    data: data
  };
};

const setItemStatusRejected = (error) => {
  return Rx.Observable.of({
    type: StructureAction.SET_ITEM_STATUS_REJECTED,
    payload: error.xhr ? error.xhr.response : error
  });
};

export {
  fetchStructure,
  fetchStructureFulfilled,
  fetchStructureRejected,
  filterStructure,
  selectItem,
  selectItemFulfilled,
  selectItemRejected,
  setItemStatus,
  setItemStatusFulfilled,
  setItemStatusRejected
};
