import * as Rx from 'rxjs';
import { ProjectsAction } from '../utils/ActionTypes';
import { changeOccurringNullIntoEmptyString } from '../utils/HelperFunctions';

const fetchProjects = () => {
  return {
    type: ProjectsAction.FETCH_PROJECTS
  };
};

const fetchProjectsFulfilled = (data) => {
  return {
    type: ProjectsAction.FETCH_PROJECTS_FULFILLED,
    projects: data
  };
};

const fetchProjectsRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.FETCH_PROJECTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchProject = (projectId) => {
  return {
    type: ProjectsAction.FETCH_PROJECT,
    projectId: projectId
  };
};

const fetchProjectFulfilled = (data) => {
  return {
    type: ProjectsAction.FETCH_PROJECT_FULFILLED,
    project: data
  };
};

const fetchProjectRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.FETCH_PROJECT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchProjectAdditionalContentCollections = (data = {}) => {
  // console.log('ACCESSED fetchProjectAdditionalContentCollections', data);
  return {
    type: ProjectsAction.FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS,
    data
  };
};

const fetchProjectAdditionalContentCollectionsFulfilled = (collections) => {
  // console.log('GOT AC-DATA', collections);
  return {
    type: ProjectsAction.FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS_FULFILLED,
    existingAdditionalContent: collections.data
  };
};

const fetchProjectAdditionalContentCollectionsRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const importProject = (data) => {
  return {
    type: ProjectsAction.IMPORT_PROJECT,
    project: data
  };
};

const importProjectFulfilled = (data) => {
  return {
    type: ProjectsAction.IMPORT_PROJECT_FULFILLED,
    data: data
  };
};

const importProjectRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.IMPORT_PROJECT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const editProject = (project) => {
  return {
    type: ProjectsAction.EDIT_PROJECT,
    project: project
  };
};

const editProjectFulfilled = (project) => {
  return {
    type: ProjectsAction.EDIT_PROJECT_FULFILLED,
    project: project
  };
};

const editProjectRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.EDIT_PROJECT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const deleteProject = (projectId) => {
  return {
    type: ProjectsAction.DELETE_PROJECT,
    projectId: projectId
  };
};

const deleteProjectFulfilled = (data) => {
  return {
    type: ProjectsAction.DELETE_PROJECT_FULFILLED,
    data: data
  };
};

const deleteProjectRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.DELETE_PROJECT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const validateProject = (data) => {
  return {
    type: ProjectsAction.VALIDATE_PROJECT,
    data: data
  };
};

const validateProjectFulfilled = (data) => {
  return {
    type: ProjectsAction.VALIDATE_PROJECT_FULFILLED,
    data: data
  };
};

const validateProjectRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.VALIDATE_PROJECT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const validateUpdate = (data) => {
  return {
    type: ProjectsAction.VALIDATE_UPDATE,
    data: data
  };
};

const validateUpdateFulfilled = (data) => {
  return {
    type: ProjectsAction.VALIDATE_UPDATE_FULFILLED,
    data: data
  };
};

const validateUpdateRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.VALIDATE_UPDATE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const importUpdate = (data) => {
  return {
    type: ProjectsAction.IMPORT_UPDATE,
    data: data
  };
};

const importUpdateFulfilled = (data) => {
  return {
    type: ProjectsAction.IMPORT_UPDATE_FULFILLED,
    data: data
  };
};

const importUpdateRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.IMPORT_UPDATE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const resetCollectedInfos = (data) => {
  return {
    type: ProjectsAction.RESET_COLLECTED_INFOS,
    data: data
  };
};

const resetDownloadPath = (data) => {
  return {
    type: ProjectsAction.RESET_DOWNLOAD_PATH,
    data: data
  };
};

const fetchAvailableExports = (projectId) => {
  return {
    type: ProjectsAction.FETCH_AVAILABLE_EXPORTS,
    projectId: projectId
  };
};

const fetchAvailableExportsFulfilled = (data) => {
  return {
    type: ProjectsAction.FETCH_AVAILABLE_EXPORTS_FULFILLED,
    availableExports: data
  };
};

const fetchAvailableExportsRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.FETCH_AVAILABLE_EXPORTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchTranslation = (projectId, translationId) => {
  return {
    type: ProjectsAction.FETCH_TRANSLATION,
    projectId: projectId,
    translationId: translationId
  };
};

const fetchTranslationFulfilled = (data) => {
  return {
    type: ProjectsAction.FETCH_TRANSLATION_FULFILLED,
    translation: data
  };
};

const fetchTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.FETCH_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const editTranslation = (projectId, translationId, translation) => {
  let t = translation;
  t = changeOccurringNullIntoEmptyString(t, 'parent_id');
  t = changeOccurringNullIntoEmptyString(t, 'contact_id');
  t = changeOccurringNullIntoEmptyString(t, 'contact_id_external');
  t = changeOccurringNullIntoEmptyString(t, 'imported_at');

  return {
    type: ProjectsAction.EDIT_TRANSLATION,
    projectId: projectId,
    translationId: translationId,
    translation: t
  };
};

const editTranslationFulfilled = (translation) => {
  return {
    type: ProjectsAction.EDIT_TRANSLATION_FULFILLED,
    translation: translation
  };
};

const editTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.EDIT_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchTranslationStatistics = (projectId, translationId) => {
  return {
    type: ProjectsAction.FETCH_TRANSLATION_STATISTICS,
    projectId: projectId,
    translationId: translationId
  };
};

const fetchTranslationStatisticsFulfilled = (data) => {
  return {
    type: ProjectsAction.FETCH_TRANSLATION_STATISTICS_FULFILLED,
    statistics: data.statistics
  };
};

const fetchTranslationStatisticsRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.FETCH_TRANSLATION_STATISTICS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const exportTranslation = (
  projectId,
  exportId,
  translationId,
  additionalContentId = null,
  collectionId = '',
  sourceSnapshotId = '',
  translationSnapshotId = ''
) => {
  return {
    type: ProjectsAction.EXPORT_TRANSLATION,
    projectId: projectId,
    exportId: exportId,
    translationId: translationId,
    additionalContentId: additionalContentId,
    collectionId: collectionId,
    sourceSnapshotId: sourceSnapshotId,
    translationSnapshotId: translationSnapshotId
  };
};

const exportTranslationFulfilled = (data) => {
  return {
    type: ProjectsAction.EXPORT_TRANSLATION_FULFILLED,
    data: data
  };
};

const exportTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.EXPORT_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const addTranslation = (projectId, data) => {
  return {
    type: ProjectsAction.ADD_TRANSLATION,
    projectId: projectId,
    data: data
  };
};

const addTranslationFulfilled = (data) => {
  return {
    type: ProjectsAction.ADD_TRANSLATION_FULFILLED,
    data: data
  };
};

const addTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.ADD_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const importTranslation = (data) => {
  return {
    type: ProjectsAction.IMPORT_TRANSLATION,
    translation: data
  };
};

const importTranslationFulfilled = (data) => {
  return {
    type: ProjectsAction.IMPORT_TRANSLATION_FULFILLED,
    data: data
  };
};

const importTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.IMPORT_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const importTranslationReset = () => {
  return {
    type: ProjectsAction.IMPORT_TRANSLATION_RESET
  };
};

//
// Wird nicht mehr verwendet
//
const importPDFTranslation = (data) => {
  return {
    type: ProjectsAction.IMPORT_PDF_TRANSLATION,
    translation: data
  };
};

//
// Wird nicht mehr verwendet
//
const importPDFTranslationFulfilled = (data) => {
  return {
    type: ProjectsAction.IMPORT_PDF_TRANSLATION_FULFILLED,
    data: data
  };
};

//
// Wird nicht mehr verwendet
//
const importPDFTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.IMPORT_PDF_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const validateTranslation = (data) => {
  return {
    type: ProjectsAction.VALIDATE_TRANSLATION,
    data: data
  };
};

const validateTranslationFulfilled = (data) => {
  return {
    type: ProjectsAction.VALIDATE_TRANSLATION_FULFILLED,
    data: data
  };
};

const validateTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.VALIDATE_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const validatePDFTranslation = (data) => {
  return {
    type: ProjectsAction.VALIDATE_PDF_TRANSLATION,
    data: data
  };
};

const validatePDFTranslationFulfilled = (data) => {
  return {
    type: ProjectsAction.VALIDATE_PDF_TRANSLATION_FULFILLED,
    data: data
  };
};

const validatePDFTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.VALIDATE_PDF_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const deleteTranslation = (data) => {
  return {
    type: ProjectsAction.DELETE_TRANSLATION,
    projectId: data.projectId,
    translationId: data.translationId
  };
};

const deleteTranslationFulfilled = (data) => {
  return {
    type: ProjectsAction.DELETE_TRANSLATION_FULFILLED,
    data: data
  };
};

const deleteTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.DELETE_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const linkProjectToUser = (data) => {
  return {
    type: ProjectsAction.LINK_PROJECT_TO_USER,
    data: data
  };
};

const linkProjectToUserFulfilled = (data) => {
  return {
    type: ProjectsAction.LINK_PROJECT_TO_USER_FULFILLED,
    data: data
  };
};

const linkProjectToUserRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.LINK_PROJECT_TO_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const linkTranslationToUser = (data) => {
  return {
    type: ProjectsAction.LINK_TRANSLATION_TO_USER,
    data: data
  };
};

const linkTranslationToUserFulfilled = (data) => {
  return {
    type: ProjectsAction.LINK_TRANSLATION_TO_USER_FULFILLED,
    data: data
  };
};

const linkTranslationToUserRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.LINK_TRANSLATION_TO_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const linkProjectToExportType = (data) => {
  return {
    type: ProjectsAction.LINK_PROJECT_TO_EXPORT_TYPE,
    data: data
  };
};

const linkProjectToExportTypeFulfilled = (data) => {
  return {
    type: ProjectsAction.LINK_PROJECT_TO_EXPORT_TYPE_FULFILLED,
    data: data
  };
};

const linkProjectToExportTypeRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.LINK_PROJECT_TO_EXPORT_TYPE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const setForceApproval = (data) => {
  return {
    type: ProjectsAction.SET_FORCE_APPROVAL,
    projectId: data.projectId,
    translationId: data.translationId,
    status: data.status
  };
};

const setForceApprovalFulfilled = (data) => {
  return {
    type: ProjectsAction.SET_FORCE_APPROVAL_FULFILLED,
    data: data
  };
};

const setForceApprovalRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.SET_FORCE_APPROVAL_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchConfig = (projectId) => {
  return {
    type: ProjectsAction.FETCH_CONFIG,
    projectId: projectId
  };
};

const fetchConfigFulfilled = (data) => {
  return {
    type: ProjectsAction.FETCH_CONFIG_FULFILLED,
    projects: data
  };
};

const fetchConfigRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.FETCH_CONFIG_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchAllExistingProjects = () => {
  return {
    type: ProjectsAction.FETCH_EXISTING_PROJECT_FOLDERS
  };
};

const fetchAllExistingProjectsFulfilled = (data) => {
  return {
    type: ProjectsAction.FETCH_EXISTING_PROJECT_FOLDERS_FULFILLED,
    projects: data
  };
};

const fetchAllExistingProjectsRejected = (error) => {
  return Rx.Observable.of({
    type: ProjectsAction.FETCH_EXISTING_PROJECT_FOLDERS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

export {
  fetchProjects,
  fetchProjectsFulfilled,
  fetchProjectsRejected,
  fetchProjectAdditionalContentCollections,
  fetchProjectAdditionalContentCollectionsFulfilled,
  fetchProjectAdditionalContentCollectionsRejected,
  fetchProject,
  fetchProjectFulfilled,
  fetchProjectRejected,
  importProject,
  importProjectFulfilled,
  importProjectRejected,
  editProject,
  editProjectFulfilled,
  editProjectRejected,
  deleteProject,
  deleteProjectFulfilled,
  deleteProjectRejected,
  validateProject,
  validateProjectFulfilled,
  validateProjectRejected,
  validateUpdate,
  validateUpdateFulfilled,
  validateUpdateRejected,
  importUpdate,
  importUpdateFulfilled,
  importUpdateRejected,
  resetCollectedInfos,
  resetDownloadPath,
  fetchAvailableExports,
  fetchAvailableExportsFulfilled,
  fetchAvailableExportsRejected,
  exportTranslation,
  exportTranslationFulfilled,
  exportTranslationRejected,
  fetchTranslation,
  fetchTranslationFulfilled,
  fetchTranslationRejected,
  editTranslation,
  editTranslationFulfilled,
  editTranslationRejected,
  fetchTranslationStatistics,
  fetchTranslationStatisticsFulfilled,
  fetchTranslationStatisticsRejected,
  addTranslation,
  addTranslationFulfilled,
  addTranslationRejected,
  importTranslation,
  importTranslationFulfilled,
  importTranslationRejected,
  importTranslationReset,
  importPDFTranslation,
  importPDFTranslationFulfilled,
  importPDFTranslationRejected,
  validateTranslation,
  validateTranslationFulfilled,
  validateTranslationRejected,
  validatePDFTranslation,
  validatePDFTranslationFulfilled,
  validatePDFTranslationRejected,
  deleteTranslation,
  deleteTranslationFulfilled,
  deleteTranslationRejected,
  linkProjectToUser,
  linkProjectToUserFulfilled,
  linkProjectToUserRejected,
  linkTranslationToUser,
  linkTranslationToUserFulfilled,
  linkTranslationToUserRejected,
  linkProjectToExportType,
  linkProjectToExportTypeFulfilled,
  linkProjectToExportTypeRejected,
  setForceApproval,
  setForceApprovalFulfilled,
  setForceApprovalRejected,
  fetchConfig,
  fetchConfigFulfilled,
  fetchConfigRejected,
  fetchAllExistingProjects,
  fetchAllExistingProjectsFulfilled,
  fetchAllExistingProjectsRejected
};
