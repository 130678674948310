import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { ProcessStatus } from '../../../utils/Constants';
import ClientPrime from './ClientPrime';
import { addClient, fetchClients } from '../../../actions/clients';
import styles from '../../../styles/project_detail.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
import ActionResponseButton from '../../general/ActionResponseButton';

@withTranslation(['clients', 'general'], { wait: true })
class AddClient extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const { fetchingClients, clients } = this.props;
    if (fetchingClients != ProcessStatus.STARTED && clients.size == 0)
      this.props.fetchClients();
  }

  handleSubmit = (data) => {
    console.log('handleSubmit, data = ', data);
    data.active = +data.active; // true false to 0 1
    console.log('handleSubmit, data = ', data);
    this.props.addClient(data);
    this.props.closeDrawer();
  };

  render() {
    const { t, fetchingClients, clients } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={stylesDrawer.drawer + ' editDrawer'}>
        <Form
          onSubmit={this.handleSubmit}
          initialValues={{ active: false }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={'body paddingLeftRight marginTop'}>
                <FormSpy
                  subscription={{ values: true }}
                  no
                  component={ClientPrime}
                />
                <div className={styles.buttonsRightWrapper}>
                  {/*
                  <Button children={ t(`${generalLNS}:submit`) } type="submit" flat primary swapTheming/>*/}
                  <ActionResponseButton
                    title={''}
                    defaulticon=''
                    type='submit'
                    loading={fetchingClients === ProcessStatus.STARTED}
                    finished={fetchingClients === ProcessStatus.FINISHED}
                    text={t(`${generalLNS}:submit`)}
                    deactivated={false}
                    onClick={null}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clients: state.getIn(['clients', 'clients']),
    fetchingClients: state.getIn(['clients', 'fetchingClients'])
  };
}

export default connect(mapStateToProps, { addClient, fetchClients })(AddClient);
