import React, { Component } from 'react';
import { Switch } from 'react-md';

import styles from '../../styles/app.scss';

class AsyncSwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: false,
      value: false
    };
  }

  UNSAFE_componentWillMount() {
    this.update(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.update(nextProps);
  }

  update = (props) => {
    const { value } = props;
    this.setState({ pending: false, value: value });
  };

  handleChange = (value, event) => {
    this.setState({ pending: true, value: !this.state.value });
    this.props.onChange(value, event);
  };

  render() {
    const { name, label } = this.props;
    const { pending, value } = this.state;

    let className = styles.mangooluSwitch + ' ' + this.props.className;

    if (pending) {
      className += ' ' + styles.mangooluSwitchPending;
    }

    return (
      <Switch
        {...this.props}
        id={name}
        name={name}
        label={label}
        checked={value}
        disabled={pending}
        value={value}
        onChange={this.handleChange}
        className={className}
      />
    );
  }
}

export default AsyncSwitch;
