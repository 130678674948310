import { Map, List, fromJS } from 'immutable';

import { StatusAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  status: Map(),
  formattedStatus: List([]),
  timestamp: 0,
  selectedStatusId: null,
  fetchingStatus: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case StatusAction.FETCH_STATUS:
      return state.set('fetchingStatus', ProcessStatus.STARTED);
    case StatusAction.FETCH_STATUS_FULFILLED:
      return state
        .set('fetchingStatus', ProcessStatus.FINISHED)
        .set('timestamp', Date.now())
        .set('status', Map(action.status))
        .set(
          'formattedStatus',
          Map(action.status).map((key, value) => {
            return { value: key, label: value };
          })
        );
    case StatusAction.FETCH_STATUS_REJECTED:
      return state
        .set('fetchingStatus', ProcessStatus.FAILED)
        .set('error', action.payload);
    default:
      return state;
  }
};

export { initialState, reducer as status };
