import { SnapshotsAction } from '../utils/ActionTypes';
import * as Rx from 'rxjs';
import { changeOccurringNullIntoEmptyString } from '../utils/HelperFunctions';

const fetchSnapshots = (projectId, translationId, snapshotId = -1) => {
  return {
    type: SnapshotsAction.FETCH_SNAPSHOTS,
    projectId: projectId,
    translationId: translationId,
    snapshotId: snapshotId
  };
};

const fetchSnapshotsFulfilled = (data) => {
  return {
    type: SnapshotsAction.FETCH_SNAPSHOTS_FULFILLED,
    snapshots: data
  };
};

const fetchSnapshotRejected = (error) => {
  return Rx.Observable.of({
    type: SnapshotsAction.FETCH_SNAPSHOTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const addSnapshot = (
  projectId,
  translationId,
  version,
  name,
  description = ''
) => {
  return {
    type: SnapshotsAction.ADD_SNAPSHOT,
    projectId: projectId,
    translationId: translationId,
    payload: {
      version: version,
      name: name,
      description: description
    }
  };
};

const addSnapshotFulfilled = (data) => {
  return {
    type: SnapshotsAction.ADD_SNAPSHOT_FULFILLED,
    snapshot: data
  };
};

const addSnapshotRejected = (error) => {
  return Rx.Observable.of({
    type: SnapshotsAction.ADD_SNAPSHOT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const editSnapshot = (projectId, translationId, snapshot) => {
  let s = snapshot;
  s = changeOccurringNullIntoEmptyString(s, 'created_at');
  s = changeOccurringNullIntoEmptyString(s, 'description');

  return {
    type: SnapshotsAction.EDIT_SNAPSHOT,
    projectId: projectId,
    translationId: translationId,
    snapshot: s
  };
};

const editSnapshotFulfilled = (snapshot) => {
  return {
    type: SnapshotsAction.EDIT_SNAPSHOT_FULFILLED,
    snapshot: snapshot
  };
};

const editSnapshotRejected = (error) => {
  return Rx.Observable.of({
    type: SnapshotsAction.EDIT_SNAPSHOT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const deleteSnapshot = (projectId, translationId, snapshotId) => {
  return {
    type: SnapshotsAction.DELETE_SNAPSHOT,
    projectId: projectId,
    translationId: translationId,
    snapshotId: snapshotId
  };
};

const deleteSnapshotFulfilled = (snapshotId) => {
  return {
    type: SnapshotsAction.DELETE_SNAPSHOT_FULFILLED,
    snapshotId: snapshotId
  };
};

const deleteSnapshotRejected = (error) => {
  return Rx.Observable.of({
    type: SnapshotsAction.DELETE_SNAPSHOT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  fetchSnapshots,
  fetchSnapshotsFulfilled,
  fetchSnapshotRejected,
  addSnapshot,
  addSnapshotFulfilled,
  addSnapshotRejected,
  editSnapshot,
  editSnapshotFulfilled,
  editSnapshotRejected,
  deleteSnapshot,
  deleteSnapshotFulfilled,
  deleteSnapshotRejected
};
