import { ajax } from 'rxjs/observable/dom/ajax';
import { SourceSnapshotsAction } from '../utils/ActionTypes';

import {
  fetchSourceSnapshotsFulfilled,
  fetchSourceSnapshotsRejected
} from '../actions/sourceSnapshots';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchSourceSnapshotsEpic = (action$, store) => {
  return action$
    .ofType(SourceSnapshotsAction.FETCH_SOURCE_SNAPSHOTS)
    .debounceTime(850)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.translationId +
        '/snapshots';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(fetchSourceSnapshotsFulfilled)
        .catch(fetchSourceSnapshotsRejected);
    });
};

export { fetchSourceSnapshotsEpic };
