import { List, fromJS } from 'immutable';

import { ErrorDialogAction, AuthAction } from '../utils/ActionTypes';

import { createErrorDialog } from '../utils/HelperFunctions';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  dialogs: List(),
  removingDialog: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  let dialog = null;
  let updatedList = null;
  let index = null;
  let dialogs = null;

  if (action.error && action.status === 401) {
    // dialog = createErrorDialog(action.type, action.payload.notify.error[0], action.status);
    dialogs = state.get('dialogs');
    let has401 = dialogs.findIndex((dialog) => dialog.status === 401) >= 0;
    if (!has401) {
      dialog = createErrorDialog(action.type, null, action.status);
      updatedList = state.get('dialogs').push(dialog);
      return state.set('dialogs', updatedList);
    }
  }

  switch (action.type) {
    case ErrorDialogAction.REMOVE_ERROR_DIALOG:
      index = state.get('dialogs').findIndex(function (obj) {
        return obj.identifier === action.identifier;
      });
      if (index > -1) {
        state = state.set('dialogs', state.get('dialogs').delete(index));
      }
      return state;

    case AuthAction.LOGOUT:
      return state.set('dialogs', List());

    default:
      return state;
  }
};

export { initialState, reducer as errorDialog };
