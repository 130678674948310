// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".searchBox {\n  position: relative;\n  padding: 0;\n  line-height: 22px;\n  border: 0;\n  width: auto;\n  height: 100%;\n  max-height: 100px;\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: flex-end;\n}\n\n.searchBox > div {\n  border: 0;\n  height: 33px;\n  width: 100%;\n  flex-grow: 1;\n  flex-shrink: 1;\n  flex-basis: auto;\n}\n\n.searchBox * {\n  display: inline-block;\n  vertical-align: top;\n}\n\n.searchBox input {\n  height: 33px !important;\n  font-size: 18px !important;\n  padding: 0 !important;\n  line-height: 33px !important;\n  margin-top: 8px;\n}\n\n.searchBox [type=\"button\"] {\n  padding: 0;\n  width: 33px;\n  height: 33px;\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n}\n\n.searchBox .searchLabel {\n  display: none;\n  position: absolute;\n  top: -7px;\n  left: 0;\n  font-size: 12px;\n}\n\n.searchBox hr {\n  display: none !important;\n}\n", ""]);
// Exports
exports.locals = {
	"searchBox": "searchBox",
	"searchLabel": "searchLabel"
};
module.exports = exports;
