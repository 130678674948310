const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
const isEnumerable = Function.bind.call(
  Function.call,
  Object.prototype.propertyIsEnumerable
);
const concat = Function.bind.call(Function.call, Array.prototype.concat);
const keys = Reflect.ownKeys;

if (!Object.values) {
  Object.values = function values(O) {
    return reduce(
      keys(O),
      (v, k) =>
        concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []),
      []
    );
  };
}

if (!Object.entries) {
  Object.entries = function entries(O) {
    return reduce(
      keys(O),
      (e, k) =>
        concat(
          e,
          typeof k === 'string' && isEnumerable(O, k) ? [[k, O[k]]] : []
        ),
      []
    );
  };
}

import * as React from 'react';
import { hot } from 'react-hot-loader';
import store from '../utils/store';
import { Provider } from 'react-redux';
import i18n from '../utils/i18n';
import { I18nextProvider } from 'react-i18next';
import { createRoutes } from '../utils/Routes';
import { BrowserRouter } from 'react-router-dom';
// import NotificationSystem from '../components/general/NotificationSystem';

// import stylesb from '../styles/app.scss';
// import stylesa from '../styles/commons.scss';
// import stylesc from '../styles/mdl/grid.scss';

// const createSelectLocationState = () => {
//   let prevRoutingState, prevRoutingStateJS;
//   return state => {
//     const routingState = state.get('routing'); // or state.routing
//     if (typeof prevRoutingState === 'undefined' || prevRoutingState !== routingState) {
//       prevRoutingState = routingState;
//       prevRoutingStateJS = routingState.toJS();
//     }
//     return prevRoutingStateJS;
//   };
// };

/* const history = syncHistoryWithStore(browserHistory, store, {
    selectLocationState: createSelectLocationState()
}); */

const routes = createRoutes(store);

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <div>{routes}</div>
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    );
  }
}

export default hot(module)(App);
