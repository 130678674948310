import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';
import TranslationPrime from './TranslationPrime';
import { addTranslation } from '../../../actions/projects';
import { fetchLanguages } from '../../../actions/languages';
import styles from '../../../styles/project_detail.scss';
import { ProcessStatus } from '../../../utils/Constants';
import stylesDrawer from '../../../styles/drawer_inner.scss';

@withTranslation(['project', 'general'], { wait: true })
class AddTranslation extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const { fetchingLanguages, languages } = this.props;
    if (fetchingLanguages !== ProcessStatus.STARTED && languages.size === 0) {
      this.props.fetchLanguages();
    }
  }

  handleSubmit = (data) => {
    const { languages, countries } = this.props;

    let language = null;
    let country = null;

    if (data.language && data.language.value && !data.language.disabled) {
      language = languages.find(
        (language) => language.id == data.language.value
      );
    }
    if (data.country && data.country.value && !data.country.disabled) {
      country = countries.find((country) => country.id == data.country.value);
    }

    if (language && country) {
      let languagecode = language.lc;

      // if country is not null and not "Intl'"
      if (country && country.id > 1) {
        languagecode = language.lc + '-' + country.cc;
      }

      data.languagecode = languagecode;

      let sourceLanguages = data.detailed_source_languages;

      if (sourceLanguages) {
        console.log('sourceLanguages = ', sourceLanguages);

        let formattedSourceLanguages = sourceLanguages.map((language) =>
          language.get('id')
        );

        console.log('formattedSourceLanguages = ', formattedSourceLanguages);
        let formattedSourceLanguagesString = formattedSourceLanguages
          .toArray()
          .toString();
        data.sources = formattedSourceLanguagesString;
        delete data.detailed_source_languages;
        delete data.language;
        delete data.country;
      }

      this.props.addTranslation(this.props.projectId, data);
      this.props.closeDrawer();
    }
  };

  render() {
    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={stylesDrawer.drawer + ' editDrawer'}>
        <Form
          mutators={{
            setFieldData,

            setCollectedInfos: (args, state) => {
              state.formState.values = {
                ...state.formState.values,
                ...args[0]
              };
            },
            changeValue: (args, state, utils) => {
              utils.changeValue(state, args[0].name, () => args[0].value);
            },
            changeValues: (args, state, utils) => {
              args.map((arg) =>
                utils.changeValue(state, arg.name, () => arg.value)
              );
            }
          }}
          onSubmit={this.handleSubmit}
          validate={(values) => {
            const errors = {};
            if (values.disabled) {
              errors.dummy = t(`${generalLNS}:error`); // Error texts are handled locally
            }
            if (
              values.detailed_source_languages &&
              values.detailed_source_languages.size == 0
            ) {
              errors.dummy = t(`${generalLNS}:error`); // Error texts are handled locally
            }
            return errors;
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={'body paddingLeftRight marginTop'}>
                <FormSpy
                  subscription={{ values: true }}
                  no
                  component={TranslationPrime}
                />
                <div className={styles.buttonsRightWrapper}>
                  <Button
                    children={t(`${generalLNS}:submit`)}
                    type='submit'
                    flat
                    primary
                    swapTheming
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    languages: state.getIn(['languages', 'languages']),
    countries: state.getIn(['languages', 'countries']),
    fetchingLanguages: state.getIn(['languages', 'fetchingLanguages'])
  };
}

export default connect(mapStateToProps, { addTranslation, fetchLanguages })(
  AddTranslation
);
