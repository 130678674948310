import { ajax } from 'rxjs/observable/dom/ajax';
import { AdditionalContentsAction } from '../utils/ActionTypes';

import {
  fetchAdditionalContentsFulfilled,
  fetchAdditionalContentsRejected,
  fetchPdfFilesFulfilled,
  fetchPdfFilesRejected,
  fetchVoFilesFulfilled,
  fetchVoFilesRejected,
  addAdditionalContentsFulfilled,
  addAdditionalContentsRejected,
  updateAdditionalContentsFulfilled,
  updateAdditionalContentsRejected
} from '../actions/additionalContents';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchAdditionalContentsEpic = (action$, store) => {
  return action$
    .ofType(AdditionalContentsAction.FETCH_ADDITIONAL_CONTENTS)
    .debounceTime(1200)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/additionalcontents/' +
        action.translationId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((res) => res.response.additional_contents)
        .map(fetchAdditionalContentsFulfilled)
        .catch(fetchAdditionalContentsRejected);
    });
};

const fetchPdfFilesEpic = (action$, store) => {
  return action$
    .ofType(AdditionalContentsAction.FETCH_PDF_FILES)
    .debounceTime(1200)
    .mergeMap((action) => {
      // let url = baseApiUrl + 'projects/' + action.projectId + '/additionalcontents/pdf/' + action.translationId;
      let url =
        baseApiUrl + 'projects/' + action.projectId + '/additionalcontents/pdf';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((res) => res.response)
        .map(fetchPdfFilesFulfilled)
        .catch(fetchPdfFilesRejected);
    });
};

const fetchVoFilesEpic = (action$, store) => {
  return action$
    .ofType(AdditionalContentsAction.FETCH_VO_FILES)
    .debounceTime(1200)
    .mergeMap((action) => {
      let url =
        baseApiUrl + 'projects/' + action.projectId + '/additionalcontents/vo';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((res) => res.response)
        .map(fetchVoFilesFulfilled)
        .catch(fetchVoFilesRejected);
    });
};

const addAdditionalContentsEpic = (action$, store) => {
  return action$
    .ofType(AdditionalContentsAction.ADD_ADDITIONAL_CONTENTS)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.project.projectId +
        '/additionalcontents/';
      let headers = getHttpAuthHeaders(store, url);
      console.log('action', action.project);
      return ajax({
        url,
        method: 'POST',
        headers: headers,
        body: action.project
      })
        .map((res) => res.response)
        .map(addAdditionalContentsFulfilled)
        .catch(addAdditionalContentsRejected);
    });
};

const updateAdditionalContentsEpic = (action$, store) => {
  return action$
    .ofType(AdditionalContentsAction.UPDATE_ADDITIONAL_CONTENTS)
    .debounceTime(500)
    .mergeMap((action) => {
      let url;

      if (action.translationId) {
        url =
          baseApiUrl +
          'projects/' +
          action.projectId +
          '/additionalcontents/' +
          action.contentId +
          '/' +
          action.translationId;
      } else {
        url =
          baseApiUrl +
          'projects/' +
          action.projectId +
          '/additionalcontents/' +
          action.contentId;
      }
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.data })
        .map((res) => res.response)
        .map(updateAdditionalContentsFulfilled)
        .catch(updateAdditionalContentsRejected);
    });
};

export {
  fetchAdditionalContentsEpic,
  fetchPdfFilesEpic,
  fetchVoFilesEpic,
  addAdditionalContentsEpic,
  updateAdditionalContentsEpic
};
