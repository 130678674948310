import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';

import {
  renderTextField /* ,renderDatePicker*/
} from '../../general/BasicFormComponents';

import styles from '../../../styles/project_detail.scss';
// import {normalizeDatePickerDate} from '../../../utils/HelperFunctions';

@withTranslation(['clients', 'general'], { wait: true })
class ClientTimestamps extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { editMode, t } = this.props;
    const generalLNS = 'general'; // clientsLanguageNamespaceSource

    return (
      <div className={'column'} editmode={editMode ? 'true' : 'false'}>
        <div className={styles.header}>
          <h2 className={'headName'}>{t(`${generalLNS}:timestamps`)}</h2>
        </div>

        <div className='editableElements'>
          <Field
            id='expires_at'
            name='expires_at'
            label={t(`${generalLNS}:expires_at`)}
            type='text'
            // helpText={ t('not_editable') }
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
        </div>

        <div className='notEditableElements'>
          <Field
            id='created_at'
            name='created_at'
            label={t(`${generalLNS}:created_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
          <Field
            id='updated_at'
            name='updated_at'
            label={t(`${generalLNS}:updated_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

export default connect()(ClientTimestamps);
