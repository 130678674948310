import * as Rx from 'rxjs';

import {
  // SnapshotsAction,
  TranslationToolAction
} from '../utils/ActionTypes';

const fetchTranslations = (data = {}) => {
  return {
    type: TranslationToolAction.FETCH_TRANSLATIONS,
    data
  };
};

const fetchTranslationsFulfilled = (translations) => {
  return {
    type: TranslationToolAction.FETCH_TRANSLATIONS_FULFILLED,
    translations
  };
};

const fetchTranslationsRejected = (error) => {
  return Rx.Observable.of({
    type: TranslationToolAction.FETCH_TRANSLATIONS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const fetchAdditionalContentTranslations = (data = {}) => {
  return {
    type: TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS,
    data
  };
};

const fetchAdditionalContentTranslationsFulfilled = (translations) => {
  return {
    type: TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_FULFILLED,
    translations
  };
};

const fetchAdditionalContentTranslationsRejected = (error) => {
  return Rx.Observable.of({
    type: TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const saveTranslation = (data = {} /* , onSuccessCallback = () => {} */) => {
  return {
    type: TranslationToolAction.SAVE_TRANSLATION,
    data: data /* ,
    // onSuccessCallback: onSuccessCallback */
  };
};

const saveTranslationSuccess = (translation) => {
  return {
    type: TranslationToolAction.SAVE_TRANSLATION_SUCCESS,
    translation
  };
};

const saveTranslationError = (error) => {
  return Rx.Observable.of({
    type: TranslationToolAction.SAVE_TRANSLATION_ERROR,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const updateStorage = (data) => {
  return {
    type: TranslationToolAction.UPDATE_STORAGE,
    payload: data
  };
};

const setEditingInitialStatus = () => {
  return {
    type: TranslationToolAction.SET_EDITING_INITIAL_STATUS
  };
};

const setAreExpanded = (value) => {
  return {
    type: TranslationToolAction.SET_ARE_EXPANDED,
    value: value
  };
};

const setAreTagsShown = (value) => {
  return {
    type: TranslationToolAction.SET_ARE_TAGS_SHOWN,
    value: value
  };
};

const setAreAllTextsShown = (value) => {
  return {
    type: TranslationToolAction.SET_ARE_ALL_TEXTS_SHOWN,
    value: value
  };
};

const setOnlyWithCommentsShown = (value) => {
  return {
    type: TranslationToolAction.SET_ONLY_WITH_COMMENTS_SHOWN,
    value: value
  };
};

const setCommentsCount = (value) => {
  return {
    type: TranslationToolAction.SET_COMMENTS_COUNT,
    value: value
  };
};

const setAreOnlyDifferencesShown = (value) => {
  return {
    type: TranslationToolAction.SET_ARE_ONLY_DIFFERENCES_SHOWN,
    value: value
  };
};

const setAreDifferencesHighlighted = (value) => {
  return {
    type: TranslationToolAction.SET_ARE_DIFFERENCES_HIGHLIGHTED,
    value: value
  };
};

const setAreOnlyEmptyShown = (value) => {
  return {
    type: TranslationToolAction.SET_ARE_ONLY_EMPTY_SHOWN,
    value: value
  };
};

const saveTextComment = (data = {}) => {
  return {
    type: TranslationToolAction.SAVE_TEXT_COMMENT,
    data: data
  };
};

const saveTextCommentFullfilled = (comment) => {
  return {
    type: TranslationToolAction.SAVE_TEXT_COMMENT_FULFILLED,
    data: comment
  };
};

const saveTextCommentRejected = (error) => {
  return Rx.Observable.of({
    type: TranslationToolAction.SAVE_TEXT_COMMENT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const deleteTextComment = (commentId) => {
  return {
    type: TranslationToolAction.DELETE_TEXT_COMMENT,
    commentId: commentId
  };
};

const deleteTextCommentFullfilled = (comment) => {
  return {
    type: TranslationToolAction.DELETE_TEXT_COMMENT_FULFILLED,
    data: comment
  };
};

const deleteTextCommentRejected = (error) => {
  return Rx.Observable.of({
    type: TranslationToolAction.DELETE_TEXT_COMMENT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const setTextCommentDone = (commentId) => {
  return {
    type: TranslationToolAction.SET_TEXT_COMMENT_DONE,
    commentId: commentId
  };
};

const setTextCommentDoneFullfilled = (comment) => {
  return {
    type: TranslationToolAction.SET_TEXT_COMMENT_DONE_FULFILLED,
    data: comment
  };
};

const setTextCommentDoneRejected = (error) => {
  return Rx.Observable.of({
    type: TranslationToolAction.SET_TEXT_COMMENT_DONE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const setTextCommentUndone = (commentId) => {
  return {
    type: TranslationToolAction.SET_TEXT_COMMENT_UNDONE,
    commentId: commentId
  };
};

const setTextCommentUndoneFullfilled = (comment) => {
  return {
    type: TranslationToolAction.SET_TEXT_COMMENT_UNDONE_FULFILLED,
    data: comment
  };
};

const setTextCommentUndoneRejected = (error) => {
  return Rx.Observable.of({
    type: TranslationToolAction.SET_TEXT_COMMENT_UNDONE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const updateTextComment = (data = {}) => {
  return {
    type: TranslationToolAction.UPDATE_TEXT_COMMENT,
    data: data
  };
};

const updateTextCommentFullfilled = (comment) => {
  return {
    type: TranslationToolAction.UPDATE_TEXT_COMMENT_FULFILLED,
    data: comment
  };
};

const updateTextCommentRejected = (error) => {
  return Rx.Observable.of({
    type: TranslationToolAction.UPDATE_TEXT_COMMENT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  fetchTranslations,
  fetchTranslationsFulfilled,
  fetchTranslationsRejected,
  fetchAdditionalContentTranslations,
  fetchAdditionalContentTranslationsFulfilled,
  fetchAdditionalContentTranslationsRejected,
  saveTranslation,
  saveTranslationSuccess,
  saveTranslationError,
  updateStorage,
  setEditingInitialStatus,
  setAreExpanded,
  setAreTagsShown,
  setAreAllTextsShown,
  setAreOnlyDifferencesShown,
  setAreDifferencesHighlighted,
  setAreOnlyEmptyShown,
  setOnlyWithCommentsShown,
  setCommentsCount,
  saveTextComment,
  saveTextCommentFullfilled,
  saveTextCommentRejected,
  deleteTextComment,
  deleteTextCommentFullfilled,
  deleteTextCommentRejected,
  setTextCommentDone,
  setTextCommentDoneFullfilled,
  setTextCommentDoneRejected,
  setTextCommentUndone,
  setTextCommentUndoneFullfilled,
  setTextCommentUndoneRejected,
  updateTextComment,
  updateTextCommentFullfilled,
  updateTextCommentRejected
};
