import React, { Component } from 'react';
import { connect } from 'react-redux';

import { linkProjectToExportType } from '../../../actions/projects';
import { fetchExportTypes } from '../../../actions/exportTypes';
import RelationBase from '../../general/RelationBase';
import NamedChip from '../../general/chip/NamedChip';
import { withTranslation } from 'react-i18next';
import { List, Map } from 'immutable';

@withTranslation(['exporttypes', 'general'], { wait: true })
class ProjectExportTypes extends Component {
  linkSourceToTarget = (mode, exportTypeId, roleId) => {
    this.props.linkProjectToExportType({
      mode: mode,
      export_type_id: exportTypeId,
      project_id: this.props.project.id,
      role_id: roleId,
      property_type: 'projects_x_export_types'
    });
  };

  render() {
    const {
      project,
      exportTypes,
      fetchingExportTypes,
      linkingProjectToExportType,
      t
    } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    let sourceTargets = null;

    if (project.export_types != null) {
      sourceTargets = project.export_types.map((exportType) => {
        return { ...exportType };
      });
    }

    console.log(
      't(`${generalLNS}:export_types`)',
      t(`${generalLNS}:export_types`)
    );

    return (
      <RelationBase
        fetchTargets={this.props.fetchExportTypes}
        fetchingTargets={fetchingExportTypes}
        label={t(`${generalLNS}:export_types`)}
        source={project}
        sourceTargets={sourceTargets}
        targets={exportTypes}
        chip={NamedChip}
        linkSourceToTarget={this.linkSourceToTarget}
        linkingSourceToTargets={linkingProjectToExportType}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.getIn(['projects', 'selectedProject']),
    exportTypes: state.getIn(['exportTypes', 'exportTypes']),
    fetchingExportTypes: state.getIn(['exportTypes', 'fetchingExportTypes']),
    linkingProjectToExportType: state.getIn([
      'projects',
      'linkingProjectToExportType'
    ])
  };
}

export default connect(mapStateToProps, {
  linkProjectToExportType,
  fetchExportTypes
})(ProjectExportTypes);
