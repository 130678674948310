import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TablePagination
} from 'react-md';

import AsyncSwitch from '../general/AsyncSwitch';

import {
  EditButton,
  UploadButton,
  DownloadButton,
  DeleteButton,
  LoginAsButton,
  InviteButton
} from '../general/BasicComponents';
import classNames from 'classnames';
import ButtonMenu from '../general/ButtonMenu';

import styles from '../../styles/list.scss';
import stylesTable from '../../styles/table.scss';
import { aclFilter, matchesPermissions } from '../../utils/ACL';
import {
  aclProject,
  aclTranslation,
  aclClient,
  aclUser,
  aclPermission,
  aclRole,
  aclContact,
  aclSnapshot
} from '../../utils/Permissions';
import { hasPermission } from '../../utils/AuthHelper';
import { FontIcon } from 'react-md';
/* import {forEach} from 'react-bootstrap/es/utils/ElementChildren'; */

// const TooltipCell = Tooltip(TableCell); //TODO: Tooltip muss wieder rein

@withRouter
@withTranslation(['general', 'table'], { wait: true })
class CustomTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      sortType: 'type',
      ascending: true,
      filteredData: null,
      start: 0,
      rowsPerPage: 10,
      maxNumWithoutPagination: 20,
      pinned: false
    };
  }

  UNSAFE_componentWillMount() {
    let selected = [];
    let sortType = 'type';
    let ascending = true;
    let rowsPerPage = 10;

    if (this.props.selected == null) {
      //
    } else {
      selected = this.props.selected;
    }
    if (this.props.model.initSortType) {
      sortType = this.props.model.initSortType;
    }
    if (this.props.model.initSortOrder) {
      ascending = this.props.model.initSortOrder === 'asc';
    }
    if (this.props.model.initRowsPerPage) {
      rowsPerPage = this.props.model.initRowsPerPage;
    }

    let filteredData = this.filterData(
      this.props,
      this.props.model.initSortType,
      ascending
    );
    let slicedData = filteredData.slice(0, rowsPerPage);

    this.setState({
      filteredData,
      slicedData,
      selected,
      sortType,
      ascending,
      rowsPerPage
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hasNewItem } = nextProps;
    const propsSelected = nextProps.selected;
    const stateSelected = this.state.selected;
    const { start, rowsPerPage, ascending, sortType } = this.state;

    let selected =
      propsSelected != undefined &&
      propsSelected != null &&
      propsSelected !== this.props.selected
        ? propsSelected
        : stateSelected;

    let st = sortType;
    let asc = ascending;
    if (hasNewItem) {
      st = 'created_at';
      asc = false;
    }

    let filteredData = this.filterData(nextProps, st, asc);
    let slicedData = filteredData.slice(start, start + rowsPerPage);

    this.setState({
      filteredData: filteredData,
      slicedData: slicedData,
      selected: selected,
      sortType: st,
      ascending: asc
    });
  }

  /*
   * Filter, Search and Sort
   *
   * @param rows
   * @returns {*}
   */
  filterData = (props, sortType, ascending) => {
    const { model, rows, searchTerm, appliedFilters } = props;
    const filterFunction = model.createFilterFunction(appliedFilters);
    const searchFunction = model.createSearchFunction(
      searchTerm,
      model.defaultSearchProps
    );
    const sortFunction = model.createSortFunction(
      sortType,
      ascending ? 'asc' : 'desc'
    );

    return rows
      .filter(filterFunction)
      .filter(searchFunction)
      .sort(sortFunction);
  };

  sort = (sortType) => {
    const { start, rowsPerPage } = this.state;

    let ascending;

    if (this.state.sortType === sortType) {
      ascending = !this.state.ascending;
    } else {
      ascending = false;
    }

    let filteredData = this.filterData(this.props, sortType, ascending);
    let slicedData = filteredData.slice(start, start + rowsPerPage);

    this.setState({ ascending, sortType, filteredData, slicedData });
  };

  handleClick(event, row, cell, idxSortedData, idxSequence, custom) {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    if (this.props.onCellClick) {
      this.props.onCellClick(row, cell, idxSortedData, idxSequence, custom);
    }
  }

  handleProgressMenuClick(event, row, cell, idxSortedData, idxSequence) {
    event.stopPropagation();

    if (hasPermission(aclTranslation.update_progress)) {
      if (this.props.onCellClick) {
        this.props.onCellClick(row, cell, idxSortedData, idxSequence, {
          name: 'handleProgressMenuClick'
        });
      }
    }
  }

  fillCell = (
    row,
    cell,
    idxSortedData,
    idxSequence,
    existingAdditionalContent
  ) => {
    // console.log(
    //   'row, cell, idxSortedData, idxSequence, existingAdditionalContent',
    //   row,
    //   cell,
    //   idxSortedData,
    //   idxSequence,
    //   existingAdditionalContent
    // );
    // const {pinned} = this.state;
    const { t } = this.props;
    let content = row[cell.id];
    const key = 'key_' + idxSortedData + '_' + idxSequence;
    let element = null;
    let filter = null;
    let lastApiAction = row.last_api_action;
    let invitedAt = row.invited_at;
    let isPending = row.isPending;
    let isPropagationStopped = true;
    let isChecked = content === 1;

    let aCExists = false;
    let contentName = null;
    if (content && content.name) {
      contentName = content.name;
      contentName = contentName.replace(`_${this.props.languagecode}.pdf`, '');
      // console.log('!!! contentName: ', contentName);

      if (existingAdditionalContent) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(existingAdditionalContent)) {
          if (value === contentName) {
            aCExists = true;
          }
        }
      }
    }

    let cellAlign = cell.textAlign === 'right' ? stylesTable.cellTextRight : '';
    let className = cellAlign;

    if (isPending && cell.component != 'switch') {
      className = cellAlign + ' ' + stylesTable.pending;
    }

    if (cell.id instanceof Array) {
      cell.id.map((id) => {
        row = row[id];
      });
      content = row;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (row.hasOwnProperty('proxies')) {
      // eslint-disable-next-line no-unused-vars
      for (let proxy of row.proxies) {
        // eslint-disable-next-line no-prototype-builtins
        if (proxy.hasOwnProperty(cell.id)) {
          content = proxy[cell.id];

          // TODO: @m.o.: wieder rausnehmen, wenn Carsten die Benamung in der DB geändert hat
          if (content === 'I/VB-32') {
            content = 'AUDI';
          }
        }
      }
    }

    // TODO: @JH: 'language' wird concated aus 'languagecode' und 'name',
    // TODO:       --> aber die Sortieren-Funktion geht nicht mehr in dieser Spalte :(
    if (cell.languageSpecial) {
      content =
        row[cell.languageSpecial[0]] + ' - ' + row[cell.languageSpecial[1]];
    }

    const editButton = (
      <EditButton
        key={key + '_editButton'}
        tabIndex={'-1'}
        title={t('edit')}
        onClick={(event) => {
          this.handleClick(event, row, cell, idxSortedData, idxSequence, {
            name: 'edit'
          });
        }}
      />
    );

    const uploadButton = (
      <UploadButton
        key={key + '_uploadButton'}
        tabIndex={'-1'}
        title={t('upload')}
        onClick={(event) => {
          this.handleClick(event, row, cell, idxSortedData, idxSequence, {
            name: 'upload'
          });
        }}
      />
    );

    const downloadButton = (
      <DownloadButton
        key={key + '_uploadButton'}
        tabIndex={'-1'}
        title={t('download')}
        onClick={(event) => {
          this.handleClick(event, row, cell, idxSortedData, idxSequence, {
            name: 'download'
          });
        }}
      />
    );

    const deleteButton = (
      <DeleteButton
        key={key + '_deleteButton'}
        tabIndex={'-1'}
        title={t('delete')}
        onClick={(event) => {
          this.handleClick(event, row, cell, idxSortedData, idxSequence, {
            name: 'delete'
          });
        }}
      />
    );

    const loginAsButton = (
      <LoginAsButton
        key={key + '_loginAsButton'}
        tabIndex={'-1'}
        title={t('login_as')}
        onClick={(event) => {
          this.handleClick(event, row, cell, idxSortedData, idxSequence, {
            name: 'loginAs'
          });
        }}
      />
    );

    const switchButton = (
      <AsyncSwitch
        id={'switch_' + key}
        name={'switch_' + key}
        key={'switch_' + key}
        aria-label={'Active'}
        value={isChecked}
        tabIndex={-1}
        disabled={false}
        onChange={(value, event) => {
          this.handleClick(event, row, cell, idxSortedData, idxSequence, {
            name: 'switch'
          });
        }}
      />
    );

    const inviteButton = (
      <InviteButton
        key={key + '_inviteButton'}
        tabIndex={'-1'}
        lastApiAction={lastApiAction}
        invitedAt={invitedAt}
        textsObject={{
          lastInvitationInfoText: t('last_invitation_info'),
          inviteNewUserText: t('invite_new_user'),
          inviteExistingUserText: t('invite_existing_user'),
          renewInvitationText: t('renew_invitation')
        }}
        onClick={(event) => {
          this.handleClick(event, row, cell, idxSortedData, idxSequence, {
            name: 'invite'
          });
        }}
      />
    );

    const progressMenu = (
      <ButtonMenu
        key={key + '_progressMenu'}
        item={row}
        cell={cell}
        tabIndex={'-1'}
        onMenuClick={(event) => {
          this.handleProgressMenuClick(
            event,
            row,
            cell,
            idxSortedData,
            idxSequence
          );
        }}
      />
    );

    let getLoginAsButton = (isDisabled = false) => (
      <LoginAsButton
        key={key + '_loginAsButton'}
        tabIndex={'-1'}
        disabled={isDisabled}
        onClick={(event) => {
          this.handleClick(event, row, cell, idxSortedData, idxSequence, {
            name: 'loginAs'
          });
        }}
      />
    );

    if (cell.permissions) {
      let actions = [];

      cell.permissions.map((permission) => {
        if (hasPermission(permission)) {
          switch (permission) {
            case aclProject.activate:
            case aclTranslation.activate:
            case aclClient.activate:
            case aclUser.activate:
              actions.push(switchButton);
              break;

            case aclTranslation.delete:
            case aclClient.delete:
            case aclUser.delete:
            case aclPermission.delete:
            case aclRole.delete:
            case aclContact.delete:
            case aclSnapshot.delete:
              actions.push(deleteButton);
              break;

            case aclTranslation.update:
            case aclClient.update:
            case aclUser.update:
            case aclPermission.update:
            case aclRole.update:
            case aclContact.update:
            case aclSnapshot.update:
              actions.push(editButton);
              break;

            case aclTranslation.import:
              if (row.type === 'translation') {
                actions.push(uploadButton);
              }
              break;

            case aclTranslation.read_progress:
              actions.push(progressMenu);
              break;

            case aclUser.invite:
              actions.push(inviteButton);
              break;

            case aclUser.login_as:
              if (this.props.hasLoginButton) {
                if (this.props.userId == row.id) {
                  let blub = getLoginAsButton(true);
                  actions.push(blub);
                } else {
                  actions.push(loginAsButton);
                }
              }
              break;

            default:
              console.warn(
                'CUSTOMTABLE: "' + permission + '" is an unknown permission'
              );
          }
        } else {
          console.warn('CUSTOMTABLE: missing permission for ' + permission);
        }
      });

      if (actions.length === 0) {
        return null;
      }

      /* eslint-disable */

      let tableColumn = (
        <TableColumn
          key={key}
          onClick={
            isPending || isPropagationStopped
              ? (event) => event.stopPropagation()
              : null
          }
          className={className}
          tabIndex={'-1'}
          numeric={cell.numeric ? cell.numeric : undefined}
        >
          {actions}
        </TableColumn>
      );
      /* eslint-enable */

      return filter ? aclFilter(tableColumn)(filter) : tableColumn;
    }

    if (cell.component) {
      switch (cell.component) {
        case 'clientName':
          isPropagationStopped = false;
          element = [<h4 key='clientname'>{row.name}</h4>];
          break;

        case 'clientDescription':
          isPropagationStopped = false;
          element = [<span key='clientdescription'>{row.description}</span>];
          break;

        case 'roleName':
          isPropagationStopped = false;
          element = [
            <h4 key='rolename'>{row.display_name}</h4>,
            <span key='roledescription'>{row.description}</span>
          ];
          break;

        case 'super':
          isPropagationStopped = false;
          element = <span></span>;
          if (content === 1) {
            element = (
              <FontIcon
                style={{ marginRight: '14px' }}
                title={'Super'}
                className={'super filled'}
              >
                star
              </FontIcon>
            );
          }
          break;

        case 'snapshotActions':
          element = [downloadButton, deleteButton];
          break;

        case 'pdfActions':
          if (existingAdditionalContent) {
            element = aCExists ? (
              <span>
                {/*
              <a
                className={'openButton hover-round-button'}
                href={content.path + content.name}
                download={content.name}>
                <FontIcon>save_alt</FontIcon>
              </a>
              */}
                {/*
                  <div className={'buttonWrapper'}>
                    <a
                      className={'openLinkBtn'}
                      title={'open project\n\nor right click and open in new tab'}
                      href={content.path + content.name}>
                      open
                    </a>
                  </div>
              */}
                <div>
                  <button
                    className={'openButton hover-round-button'}
                    title={'edit PDF text'}
                    value={content.name}
                    onClick={this.handleEditPDFTextTranslationTool}
                  >
                    <FontIcon>mode_edit</FontIcon>
                  </button>
                  <button
                    className={'openButton hover-round-button'}
                    title={'export PDF text'}
                    value={content.name}
                    onClick={this.handleExportSinglePDF}
                  >
                    <FontIcon>archive</FontIcon>
                  </button>
                  <button
                    className={'openButton hover-round-button'}
                    title={'import PDF text'}
                    value={content.name}
                    onClick={this.handleImportSinglePDF}
                  >
                    <FontIcon>file_upload</FontIcon>
                  </button>
                  {/* <a
                    className={'openButton hover-round-button'}
                    title={'edit PDF text'}
                    href={content.path + content.name}
                    target={'_blank'}>
                    <FontIcon>mode_edit</FontIcon>
                  </a> */}
                </div>
              </span>
            ) : (
              <span>
                {/*
              <a
                className={'openButton hover-round-button'}
                href={content.path + content.name}
                download={content.name}>
                <FontIcon>save_alt</FontIcon>
              </a>
              */}
                {/*
              <div className={'buttonWrapper'}>
                <a
                  className={'openLinkBtn'}
                  title={'open project\n\nor right click and open in new tab'}
                  href={content.path + content.name}>
                  open
                </a>
              </div>
              */}
                <div>
                  <button
                    className={'openButton hover-round-button'}
                    title={'import PDF text'}
                    value={content.name}
                    onClick={this.handleImportSinglePDF}
                  >
                    <FontIcon>file_upload</FontIcon>
                  </button>

                  {/* <a
                    className={'openButton hover-round-button'}
                    title={'edit PDF text'}
                    href={content.path + content.name}
                    target={'_blank'}>
                    <FontIcon>mode_edit</FontIcon>
                  </a> */}
                </div>
              </span>
            );
          } else {
            element = '';
          }
          break;

        case 'voActions':
          element = (
            <span>
              <a
                className={'openButton hover-round-button'}
                href={content.path + content.name}
                target={'_blank'}
              >
                <FontIcon>open_in_new</FontIcon>
              </a>
            </span>
          );
          break;

        case 'pdfPreview':
          // eslint-disable-next-line no-case-declarations
          let toggle = (e) => {
            let p = e.target.parentNode.parentNode;
            let tds = p.parentNode.parentNode.querySelectorAll('td');
            // console.log(tds);
            if (p.classList.contains('show')) {
              p.classList.remove('show');
              for (let i = 0; i < tds.length; i++) {
                tds[i].classList.remove('pdf-open');
              }
            } else {
              p.classList.add('show');
              for (let ii = 0; ii < tds.length; ii++) {
                tds[ii].classList.add('pdf-open');
              }
            }
          };
          // eslint-disable-next-line no-case-declarations
          let size = (e) => {
            let p = e.target.parentNode.parentNode;
            if (p.classList.contains('big')) {
              p.classList.remove('big');
            } else {
              p.classList.add('big');
            }
          };
          element = (
            <div className={'pdfPreview'}>
              <embed
                src={content}
                width='500'
                height='200'
                type='application/pdf'
              />
              <div>
                <button onClick={toggle}>
                  <FontIcon className='fadeIn'>aspect_ratio</FontIcon>
                  <FontIcon className='fadeOut'>close</FontIcon>
                </button>
                <button className={'foldButton'} onClick={size}>
                  <FontIcon className='more'>zoom_in</FontIcon>
                  <FontIcon className='less'>zoom_out</FontIcon>
                </button>
              </div>
            </div>
          );
          break;

        case 'soundPlayer':
          element = (
            <audio
              controls
              controlsList='nodownload'
              style={{ position: 'relative' }}
            >
              <source src={content} type='audio/mpeg' />
              <span>Your browser does not support the audio element.</span>
            </audio>
          );
          break;

        case 'additionalName':
          element = (
            <span
              title={
                'File:\n' + content.name + '\n\nLocated in:\n' + content.path
              }
            >
              {content.name}
            </span>
          );
          break;

        case 'pinAction':
          /* eslint-disable-next-line */
          let pinned = this.props.pinnedProjects.indexOf(row.id) !== -1;
          element = (
            <button
              className={'openButton hover-round-button'}
              title={'pin or unpin project'}
              value={pinned.toString()}
              data-id={row.id.toString()}
              onClick={this.handleProjectPinning}
            >
              <FontIcon style={{ pointerEvents: 'none' }}>
                {pinned ? 'favorite' : 'favorite_border'}
              </FontIcon>
            </button>
          );
          break;

        case 'version_full':
          element = (
            <>
              <span
                title={
                  content.config
                    ? 'timestamp: ' + content.config.properties.timestamp
                    : 'no timestamp'
                }
              >
                {content.version}
              </span>
            </>
          );
          break;

        default:
          element = cell.component;
      }
    } else {
      isPropagationStopped = false;
      element =
        typeof cell.format === 'function' ? cell.format(content) : content;
    }

    /* eslint-disable */
    let tableColumn = (
      <TableColumn
        key={key}
        onClick={
          isPending || isPropagationStopped
            ? (event) => event.stopPropagation()
            : null
        }
        className={className}
        tabIndex={'-1'}
        title={cell.time ? content : ''}
        numeric={cell.numeric ? cell.numeric : undefined}
      >
        {/*{element !== 'null' ? element : ''}*/}
        {element}
      </TableColumn>
    );
    /* eslint-enable */

    return filter ? aclFilter(tableColumn)(filter) : tableColumn;
  };

  handleImportSinglePDF = (pdfName) => {
    let collection = pdfName.currentTarget.value;
    collection = collection.replace(`_${this.props.languagecode}.pdf`, '');
    this.props.history.push(
      this.props.translationId + '/import-pdf-translation/' + collection
    );
  };

  handleExportSinglePDF = (pdfName) => {
    let collection = pdfName.currentTarget.value;
    collection = collection.replace(`_${this.props.languagecode}.pdf`, '');
    this.props.history.push(
      this.props.translationId + '/export-pdf-translation/' + collection
    );
  };

  handleProjectPinning = (e) => {
    let id = parseInt(e.target.getAttribute('data-id'));
    let pinned = e.target.getAttribute('value') === 'true';
    if (pinned) {
      this.props.unpinProject(id);
    } else {
      this.props.pinProject(id);
    }
  };
  handleEditPDFTextTranslationTool = (pdfName) => {
    let collection = pdfName.currentTarget.value;
    collection = collection.replace(`_${this.props.languagecode}.pdf`, '');
    // console.log('Edit PDF by Collection:', collection);
    this.props.handleTranslationButton({ collection: collection });
  };

  onKeyDown(e) {
    // 13 = Return, 32 = Space
    if (e.keyCode === 13 || e.keyCode === 32) {
      // this.props.onRowClick(e.target.getAttribute('itemid'));
      e.target.click();
    }
  }

  renderTableHeader = () => {
    const { columns } = this.props.model;
    const { t } = this.props;

    return columns.map((column, index) => {
      if (column.permissions && !matchesPermissions(column.permissions)) {
        return null;
      }

      const sortType =
        this.state.sortType === column.id ? stylesTable.activeHead : '';
      const textAlign =
        column.textAlign === 'right' ? stylesTable.cellTextRight : '';
      let classNames = textAlign + ' ' + sortType;

      let sorted;
      let onClick;

      if (column.sortable && column.sortable == 'false') {
        sorted = undefined;
        onClick = undefined;
        classNames = classNames + ' noSort';
      } else {
        sorted =
          this.state.sortType === column.id ? this.state.ascending : true;
        onClick = () => this.sort(column.id);
      }

      let headline = t(`table:${column.translation_id}`);
      if (column.id === 'is_super') {
        headline = (
          <FontIcon
            style={{
              width: '100%',
              textAlign: 'right',
              paddingRight: '10px'
            }}
            title={'Super'}
            className={'super filled'}
          >
            star
          </FontIcon>
        );
      }
      if (column.id === 'languagecode') {
        headline = (
          <abbr title={t('general:language_code')}>
            {t(`table:${column.translation_id}`)}
          </abbr>
        );
      }

      return (
        <TableColumn
          key={'head_' + index}
          className={classNames}
          sorted={sorted}
          onClick={onClick}
          id={column.id}
        >
          {headline}
        </TableColumn>
      );
    });
  };

  renderTableRows = () => {
    const { columns } = this.props.model;
    const { slicedData, filteredData, maxNumWithoutPagination } = this.state;
    const existingAdditionalContent = this.props.existingAdditionalContent
      ? this.props.existingAdditionalContent
      : null;
    // const existingAdditionalContent = null;
    // console.log('does existingAdditionalContent exist?', this.props.existingAdditionalContent);
    let sortedData =
      filteredData.length > maxNumWithoutPagination ? slicedData : filteredData;

    let onRowClick = () => {};

    if (this.props.onRowClick) {
      onRowClick = (id) => {
        this.props.onRowClick(id);
        this.setState({ selected: [id] });
      };
    }

    return sortedData.map((item, idxSortedData) => (
      <TableRow
        key={'key_' + idxSortedData}
        tabIndex={'0'}
        selectable={false}
        // TODO: selected style only visible with checkboxes, add classname maybe?
        // selected={(this.state.selected.indexOf(item.id) !== -1)}

        className={classNames(
          {
            'is-disabled':
              'isExistingAsFolder' in item && !item.isExistingAsFolder
          },
          this.state.selected.indexOf(item.id) !== -1
            ? stylesTable.tableRowFocusEffect + ' ' + styles.rowSelected
            : stylesTable.tableRowFocusEffect
        )}
        onClick={() => onRowClick(item.id)}
        onKeyDown={this.onKeyDown}
        disabled={!item.isExistingAsFolder}
      >
        {existingAdditionalContent
          ? columns.map((cell, idxSequence) => {
              // eslint-disable-next-line max-len
              return this.fillCell(
                item,
                cell,
                idxSortedData,
                idxSequence,
                existingAdditionalContent
              );
            })
          : columns.map((cell, idxSequence) => {
              return this.fillCell(item, cell, idxSortedData, idxSequence);
            })}
      </TableRow>
    ));
  };

  handlePagination = (start, rowsPerPage) => {
    this.setState({
      start: start,
      rowsPerPage: rowsPerPage,
      slicedData: this.state.filteredData.slice(start, start + rowsPerPage)
    });
  };

  render() {
    const { filteredData, rowsPerPage, maxNumWithoutPagination } = this.state;
    const { initRowsPerPage } = this.props.model;
    const { t } = this.props;
    const rowsPerPageLabel = this.props.mobile
      ? t('table:rows')
      : t('table:rows_per_page');
    const defaultRowsPerPage = initRowsPerPage ? [initRowsPerPage] : [10];
    const standardOptions = [10, 20, 30, 40, 50, 100];
    const optionalRows = [...defaultRowsPerPage, ...standardOptions];
    optionalRows.sort((a, b) => a - b);
    return (
      <DataTable baseId='simple-pagination' className={stylesTable.table}>
        <TableHeader>
          <TableRow selectable={false}>{this.renderTableHeader()}</TableRow>
        </TableHeader>
        <TableBody>{this.renderTableRows()}</TableBody>
        {filteredData.length > maxNumWithoutPagination && (
          <TablePagination
            rows={filteredData.length}
            defaultRowsPerPage={rowsPerPage}
            rowsPerPageItems={optionalRows}
            rowsPerPageLabel={rowsPerPageLabel}
            onPagination={this.handlePagination}
          />
        )}
      </DataTable>
    );
  }
}

CustomTable.propTypes = {
  model: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  appliedFilters: PropTypes.array,
  onCellClick: PropTypes.func
};

export default CustomTable;
