import * as Rx from 'rxjs';
import { I18nAction } from '../utils/ActionTypes';

const fetchAvailableTranslations = () => {
  return {
    type: I18nAction.FETCH_AVAILABLE_TRANSLATIONS
  };
};

const fetchAvailableTranslationsFulfilled = (data) => {
  return {
    type: I18nAction.FETCH_AVAILABLE_TRANSLATIONS_FULFILLED,
    data: data
  };
};

const fetchAvailableTranslationsRejected = (error) => {
  return Rx.Observable.of({
    type: I18nAction.FETCH_AVAILABLE_TRANSLATIONS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  fetchAvailableTranslations,
  fetchAvailableTranslationsFulfilled,
  fetchAvailableTranslationsRejected
};
