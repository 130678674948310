import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

/* global ENV */

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: ENV.DEBUG,
    lng: 'en',
    fallbackLng: 'en',
    ns: ['general'],
    defaultNS: 'general',
    interpolation: {
      formatSeparator: ',',
      format: function (value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    },
    react: {
      useSuspense: false,
      wait: true
    }
  });

export default i18n;
