import React, { Component } from 'react';
import { Button } from 'react-md';

import { withTranslation } from 'react-i18next';
import SearchField from './SearchField';

@withTranslation('general', { wait: true })
class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchFieldVisible: false
    };
  }

  showSearchField = () => {
    this.setState({ isSearchFieldVisible: true });
  };

  handleSearchReset = () => {
    this.setState({ isSearchFieldVisible: false });
    this.props.handleSearchReset();
  };

  render() {
    const { searchTerm, t, isFetching } = this.props;
    const { isSearchFieldVisible } = this.state;

    return (
      <div
        title={t('search_box_hint_text')}
        className={`custom-search ${
          isSearchFieldVisible ? ' searchVisible' : ''
        }`}
      >
        <Button
          icon
          disabled={isSearchFieldVisible || isFetching}
          onClick={this.showSearchField}
        >
          search
        </Button>
        <SearchField
          placeholder={t('search')}
          customClass={'searchBox'}
          searchTerm={searchTerm}
          handleSearchInput={this.props.handleSearchInput}
          handleSearchReset={this.handleSearchReset}
          isVisible={isSearchFieldVisible}
        />
      </div>
    );
  }
}

export default Search;
