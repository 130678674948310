import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';

import { ProcessStatus } from '../../../utils/Constants';

import PermissionPrime from './PermissionPrime';

import { addPermission, fetchPermissions } from '../../../actions/permissions';

import styles from '../../../styles/project_detail.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
import ActionResponseButton from '../../general/ActionResponseButton';

@withTranslation(['permissions', 'general'], { wait: true })
class AddPermission extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const { fetchingPermissions } = this.props;
    if (fetchingPermissions === ProcessStatus.INITIAL)
      this.props.fetchPermissions();
  }

  handleSubmit = (data) => {
    console.log('handleSubmit, data = ', data);

    this.props.addPermission(data);
    this.props.closeDrawer();
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { t, fetchingPermissions } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={stylesDrawer.drawer + ' editDrawer'}>
        <Form
          onSubmit={this.handleSubmit}
          mutators={{
            setDisplayName: (args, state, utils) => {
              const { name } = state.fields;

              const currentName = name.lastFieldState.value;

              if (currentName) {
                let n = currentName.indexOf(':');
                if (n > 0) {
                  let category = currentName.substr(0, n);
                  let action = currentName.substr(n + 1, currentName.length);

                  utils.changeValue(
                    state,
                    'display_name',
                    () => this.capitalizeFirstLetter(action) + ' ' + category
                  );
                  utils.changeValue(state, 'category', () =>
                    this.capitalizeFirstLetter(category)
                  );
                }
              }
            }
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={'body paddingLeftRight marginTop'}>
                <FormSpy
                  subscription={{ values: true }}
                  no
                  component={PermissionPrime}
                />
                <div className={styles.buttonsRightWrapper}>
                  <ActionResponseButton
                    title={''}
                    defaulticon=''
                    type='submit'
                    loading={fetchingPermissions === ProcessStatus.STARTED}
                    finished={fetchingPermissions === ProcessStatus.FINISHED}
                    text={t(`${generalLNS}:submit`)}
                    deactivated={false}
                    onClick={null}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.getIn(['permissions', 'permissions']),
    fetchingPermissions: state.getIn(['permissions', 'fetchingPermissions'])
  };
}

export default connect(mapStateToProps, { addPermission, fetchPermissions })(
  AddPermission
);
