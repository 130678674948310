import { ajax } from 'rxjs/observable/dom/ajax';
import { ContactsAction } from '../utils/ActionTypes';

import {
  fetchContactsFulfilled,
  fetchContactsRejected,
  addContactFulfilled,
  addContactRejected,
  editContactFulfilled,
  editContactRejected,
  deleteContactFulfilled,
  deleteContactRejected
} from '../actions/contacts';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';
// import { deletePermissionFulfilled } from '../actions/permissions';

const fetchContactsEpic = (action$, store) => {
  return action$
    .ofType(ContactsAction.FETCH_CONTACTS)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'contacts';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((ajaxResponse) => ajaxResponse.response.data)
        .map(fetchContactsFulfilled)
        .catch(fetchContactsRejected);
    });
};

const addContactEpic = (action$, store) => {
  return action$
    .ofType(ContactsAction.ADD_CONTACT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'contacts';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({
        url,
        method: 'POST',
        headers: headers,
        body: action.contact
      })
        .map((res) => res.response.data)
        .map(addContactFulfilled)
        .catch(addContactRejected);
    });
};

const editContactEpic = (action$, store) => {
  return action$
    .ofType(ContactsAction.EDIT_CONTACT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'contacts/' + action.contact.id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({
        url,
        method: 'PUT',
        headers: headers,
        body: action.contact
      })
        .map((res) => res.response.data)
        .map(editContactFulfilled)
        .catch(editContactRejected);
    });
};

const deleteContactEpic = (action$, store) => {
  return action$
    .ofType(ContactsAction.DELETE_CONTACT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'contacts/' + action.contactId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'DELETE', headers: headers })
        .map((res) => res.response)
        .map(() => deleteContactFulfilled(action.contactId))
        .catch(deleteContactRejected);
    });
};

export {
  fetchContactsEpic,
  addContactEpic,
  editContactEpic,
  deleteContactEpic
};
