import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-md';
import ProfilePrime from './ProfilePrime';
// import { editProfile } from '../../actions/profile';
import { updateUserData } from '../../actions/auth';
import styles from '../../styles/project_detail.scss';
import stylesDrawer from '../../styles/drawer_inner.scss';
import i18n from '../../utils/i18n';
import { ProcessStatus } from '../../utils/Constants';
import ActionResponseButton from '../../components/general/ActionResponseButton';

@withTranslation(['profile', 'general'], { wait: true })
class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      themeIsDark: true
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updatingUser, user } = nextProps;
    if (
      updatingUser === ProcessStatus.FINISHED &&
      updatingUser !== this.props.updatingUser
    ) {
      i18n.changeLanguage(user.languagecode, (err) => {
        if (err) return console.log('something went wrong loading', err);
      });
    }
  }

  handleSubmit = (data) => {
    const { id, name, username, email, languagecode } = data;
    const dataOfInterest = { id, name, username, email, languagecode };
    this.props.updateUserData(dataOfInterest);
    // this.props.closeDrawer();
  };

  render() {
    const { user, t, updatingUser } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div>
        <Form
          mutators={{
            setFieldData,

            setCollectedInfos: (args, state) => {
              state.formState.values = {
                ...state.formState.values,
                ...args[0]
              };
            },
            changeValue: (args, state, utils) => {
              utils.changeValue(state, args[0].name, () => args[0].value);
            },
            changeValues: (args, state, utils) => {
              args.map((arg) =>
                utils.changeValue(state, arg.name, () => arg.value)
              );
            }
          }}
          onSubmit={this.handleSubmit}
          initialValues={user}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div
                className={
                  stylesDrawer.drawer + ' drawerInner marginTop' + ' editDrawer'
                }
              >
                <div className={styles.autoHeight}>
                  <FormSpy
                    subscription={{ values: true }}
                    component={ProfilePrime}
                  />
                </div>
                <div
                  className={styles.buttonsRightWrapper + ' paddingLeftRight'}
                  style={{ margin: '8px' }}
                >
                  <ActionResponseButton
                    title={''}
                    defaulticon=''
                    type='submit'
                    loading={updatingUser === ProcessStatus.STARTED}
                    finished={updatingUser === ProcessStatus.FINISHED}
                    text={t(`${generalLNS}:submit`)}
                    deactivated={false}
                    onClick={null}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.getIn(['auth', 'user']),
    updatingUser: state.getIn(['auth', 'updatingUser'])
  };
}

export default connect(mapStateToProps, { updateUserData })(EditProfile);
