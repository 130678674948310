import { fromJS } from 'immutable';

import { TranslationsFilterAction } from '../../utils/ActionTypes';

const initialState = fromJS({
  searchTerm: ''
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TranslationsFilterAction.SEARCH_BY_TERM:
      return state.set('searchTerm', action.searchTerm);
    case TranslationsFilterAction.RESET_SEARCH:
      return state.set('searchTerm', '');
    default:
      return state;
  }
};

export default reducer;
