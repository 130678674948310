import { List, fromJS } from 'immutable';

import { FieldLevelAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  scrollTo: { collection: '', textfield_id: '' },
  unsavedTexts: List(),
  error: null
});
let unsavedTexts;
let index;
let uta;
let unsavedText;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FieldLevelAction.SET_SCROLL_TO:
      return state.set('scrollTo', action.scrollTo);
    case FieldLevelAction.ADD_UNSAVED_TEXT:
      unsavedTexts = state.get('unsavedTexts');
      index = unsavedTexts.findIndex((text) => {
        return text.refName === action.refName;
      });
      uta = unsavedTexts.toArray();
      for (var k = 0; k < uta.length; k++) {
        let refName = uta[k].refName;
        let el = document.querySelector(
          '[field-section-refname="' + refName + '"]'
        );
        let contentChanged = 0;
        if (el.querySelector('.editor')) {
          contentChanged =
            el.querySelector('.editor').getAttribute('contentchanged') ===
            'true';
          if (!contentChanged) {
            unsavedTexts = unsavedTexts.delete(
              unsavedTexts.findIndex((text) => {
                return text.refName === refName;
              })
            );
          }
        }
      }

      unsavedText = {
        refName: action.refName,
        collection: action.collection,
        id: action.id
      };

      if (index >= 0) {
        unsavedTexts = unsavedTexts.set(index, unsavedText);
      } else {
        unsavedTexts = unsavedTexts.set(unsavedTexts.size, unsavedText);
      }
      return state.set('unsavedTexts', unsavedTexts);

    case FieldLevelAction.REMOVE_UNSAVED_TEXT:
      unsavedTexts = state.get('unsavedTexts');
      index = unsavedTexts.findIndex((text) => {
        return text.refName === action.refName;
      });
      if (index >= 0) {
        unsavedTexts = unsavedTexts.delete(index);
      }
      return state
        .set('unsavedTexts', unsavedTexts)
        .set('scrollTo', { collection: '', textfield_id: '' });
    default:
      return state;
  }
};

export { initialState, reducer as fieldLevel };
