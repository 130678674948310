import { changeDatetimeToDate } from '../utils/HelperFunctions';
import {
  defaultCreateFilterFunction,
  defaultCreateSearchFunction,
  defaultCreateSortFunction
} from './defaultFunctions';

/**
 * User Model
 * @type {*[]}
 */
const model = {
  columns: [
    {
      id: 'name',
      translation_id: 'name'
      // name: 'Name'
    },
    {
      id: 'email',
      // name: 'Email',
      translation_id: 'email',
      sortable: 'false'
    },
    {
      // id: ['role', 'display_name'],     TODO: WO IST DIE ROLLE HIN?
      // name: 'Roles'
      // }, {
      id: 'updated_at',
      translation_id: 'updated_at',
      // name: 'Updated at',
      format: changeDatetimeToDate,
      time: true
    },
    {
      id: 'created_at',
      // name: 'Created at',
      translation_id: 'created_at',
      format: changeDatetimeToDate,
      time: true
    }
  ],

  createFilterFunction: defaultCreateFilterFunction,
  createSearchFunction: defaultCreateSearchFunction,
  createSortFunction: defaultCreateSortFunction,

  defaultSearchProps: ['name']
};

export default model;
