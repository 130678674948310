import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { linkTranslationToUser } from '../../../actions/projects';
import { fetchUsers } from '../../../actions/users';
import RelationBase from '../../general/RelationBase';
import RoleAvatarChip from '../../general/chip/RoleAvatarChip';
import { hasPermission } from '../../../utils/AuthHelper';
import { aclTranslation } from '../../../utils/Permissions';
import { fetchRoles } from '../../../actions/roles';

@withTranslation(['project', 'general'], { wait: true })
class TranslationUsers extends Component {
  linkSourceToTarget = (mode, userId, roleId) => {
    this.props.linkTranslationToUser({
      mode: mode,
      project_id: this.props.translation.get('project_id'),
      translation_id: this.props.translation.get('id'),
      user_id: userId,
      role_id: roleId,
      property_type: 'translations_x_users'
    });
  };

  UNSAFE_componentWillMount() {
    const { roles } = this.props;
    if (roles.size == 0) {
      this.props.fetchRoles();
    }
  }

  render() {
    const { translation, users, fetchingUsers, linkingTranslationToUser, t } =
      this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <RelationBase
        fetchTargets={this.props.fetchUsers}
        fetchingTargets={fetchingUsers}
        label={t(`${generalLNS}:users`)}
        source={translation.toObject()}
        sourceTargets={translation.get('users')}
        targets={users}
        chip={RoleAvatarChip}
        editable={hasPermission(aclTranslation.update)}
        linkSourceToTarget={this.linkSourceToTarget}
        linkingSourceToTargets={linkingTranslationToUser}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    translation: state.getIn(['projects', 'translation']),
    users: state.getIn(['users', 'users']),
    fetchingUsers: state.getIn(['users', 'fetchingUsers']),
    linkingTranslationToUser: state.getIn([
      'projects',
      'linkingTranslationToUser'
    ]),
    updateToken: state.getIn(['projects', 'updateToken']),
    roles: state.getIn(['roles', 'roles'])
  };
}

export default connect(mapStateToProps, {
  linkTranslationToUser,
  fetchUsers,
  fetchRoles
})(TranslationUsers);
