import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontIcon } from 'react-md';
import styles from '../../../styles/help.scss';
import { Routes } from '../../../utils/Constants';
import { matchPath } from 'react-router';
import { withTranslation } from 'react-i18next';
import { editUserSettings } from '../../../actions/auth';
import helpTexts from './helpTexts';

@withTranslation('help', { wait: true })
class GlobalHelp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      isFocused: false,
      currentStep: 0,
      textScope: null,
      highLightPositions: [],
      textPositions: [],
      defaultPadding: 2
    };
    this.helpElements = [];
    this.textScopeFast = [];
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;

    document.addEventListener('keydown', (e) => {
      this.handleKeyDown(e);
    });

    this.waitingTillUpdate();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show, location } = nextProps;

    if (show !== this.state.isOpened) {
      this.update(nextProps);
    } else if (location.pathname !== this.props.location.pathname) {
      this.waitingTillUpdate();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener('keydown', (e) => {
      this.handleKeyDown(e);
    });
  }

  waitingTillUpdate = () => {
    setTimeout(() => {
      this.update(this.props);
    }, 3000);
  };

  update = (props) => {
    const { user, show } = props;
    const match = this.checkMatch(props);

    if (match) {
      if (show) {
        this.initTextsAndPositions(match);
      }
      if (user) {
        let wasShown = this.initUserSettings(user, match);

        if (!wasShown) {
          this.initTextsAndPositions(match);
          this.editUserSettings(user, match);
        }
      }
    }
  };

  checkMatch = (props) => {
    const { location } = props;

    let routeMatched = null;
    let match = null;

    helpTexts.map((helpText) => {
      match = matchPath(location.pathname, { path: helpText.route });

      if (match && match.path) {
        if (
          routeMatched == null ||
          helpText.route.length > routeMatched.length
        ) {
          routeMatched = helpText.route;
        }
      }
    });
    return routeMatched;
  };

  initUserSettings = (user, match) => {
    let helpShownFromSettings = _.get(user, 'settings.wasShown');
    let wasShown = false;
    if (helpShownFromSettings) {
      wasShown = helpShownFromSettings.includes(match);
    }
    return wasShown;
  };

  editUserSettings = (user, match) => {
    let wasShown = _.get(user, 'settings.wasShown');

    if (wasShown) {
      wasShown.push(match);
    } else {
      wasShown = [match];
    }
    this.props.editUserSettings(user.id, { wasShown: wasShown });
  };

  getTextScope = (match) => {
    let i = helpTexts.findIndex((helpText) => helpText.route === match);
    let code = helpTexts[i].textCode;
    let s = helpTexts[i].steps;
    let array = [];
    for (let ii = 0; ii < this.helpElements.length; ii++) {
      let attributeId = parseInt(
        this.helpElements[ii].getAttribute('help-tool')
      );
      for (let iii = 0; iii < s.length; iii++) {
        if (attributeId === s[iii].helpId) {
          let entry = {};
          let id = s[iii].helpId;
          entry.id = id;
          entry.headline = code + '_' + id + '_headline';
          entry.text = code + '_' + id + '_text';
          entry.text = entry.text.replace();
          // eslint-disable-next-line no-prototype-builtins
          entry.padding = s[iii].hasOwnProperty('padding')
            ? s[iii].padding
            : this.state.defaultPadding;
          array.push(entry);
          break;
        }
      }
    }
    this.textScopeFast = array;
  };

  getAllHelpElements = () => {
    let allHelpTextElements = Array.prototype.slice.call(
      document.querySelectorAll('[help-tool]')
    );

    // AUSNAHME FÜR STARTSEITE:
    if (
      this.props.location.pathname === Routes.HOME ||
      this.props.location.pathname === Routes.HOME + '/' // ausnahme für zusätzlichen Slash
    ) {
      // help-project-tool
      let allNavElements = Array.prototype.slice.call(
        document.querySelectorAll('[help-project-tool]')
      );

      for (let jj = 0; jj < allNavElements.length; jj++) {
        allNavElements[jj].setAttribute(
          'help-tool',
          allNavElements[jj].getAttribute('help-project-tool')
        );
        allNavElements[jj].removeAttribute('help-project-tool');
        allHelpTextElements.push(allNavElements[jj]);
      }
    }

    allHelpTextElements.sort(function (a, b) {
      let aa = parseInt(a.getAttribute('help-tool'));
      let bb = parseInt(b.getAttribute('help-tool'));
      if (aa < bb) {
        return -1;
      }
      if (aa > bb) {
        return 1;
      }
      return 0;
    });
    this.helpElements = allHelpTextElements;
  };

  initTextsAndPositions = (match) => {
    this.getAllHelpElements();
    this.getTextScope(match);
    if (this.textScopeFast.length !== this.helpElements.length) {
      console.warn(
        'GLOBALHELP WARNING: textScope und help-tool-elemente stimmen nicht überein',
        this.textScopeFast.length,
        this.helpElements.length
      );
      this.prepareClose();
      return false;
    }

    setTimeout(() => {
      if (this.mounted) {
        const positions = this.getPositions();

        this.setState({
          currentStep: 0,
          isOpened: true,
          highLightPositions: positions.highLightPositions,
          textPositions: positions.textPositions,
          textScope: this.textScopeFast
        });

        setTimeout(() => {
          this.setState({ isFocused: true });
        }, 600);
      }
    }, 200);
  };

  getPositions = () => {
    const { defaultPadding } = this.state;
    let highLightPositions = [];
    let textPositions = [];

    for (let j = 0; j < this.helpElements.length; j++) {
      let rect = this.helpElements[j].getBoundingClientRect();
      let helpPopupRect = this.helpPopup.getBoundingClientRect();
      let padding = defaultPadding;

      if (
        this.textScopeFast[j] &&
        this.textScopeFast[j].hasOwnProperty('padding')
      ) {
        padding = this.textScopeFast[j].padding;
      }
      let l = (rect.left / helpPopupRect.width) * 100 - padding;
      let t = (rect.top / helpPopupRect.height) * 100 - padding;
      let w = (rect.width / helpPopupRect.width) * 100 + padding * 2;
      let h = (rect.height / helpPopupRect.height) * 100 + padding * 2;

      highLightPositions.push({
        left: l + '%',
        top: t + '%',
        width: w + '%',
        height: h + '%'
      });

      let textSpaces = [
        {
          // links von Highlight, oben Rand, unten Abschluss Highlight
          id: 0,
          left: 0,
          top: 0,
          width: l,
          height: t + h,
          justifyContent: 'flex-end',
          padding: '10px 10px 0 10px',
          alignRight: true
        },
        {
          // links von Highlight, oben Anfang Highlight, unten Rand
          id: 1,
          left: 0,
          top: t > 0 ? t : '10px',
          width: l,
          height: 100 - t,
          justifyContent: 'flex-start',
          padding: '0 10px 10px 10px',
          alignRight: true
        },
        {
          // über Highlight, top 0, left == Highlight, Breite bis Rand rechts
          id: 2,
          left: l > 0 ? l : '10px',
          top: 0,
          width: 100 - l,
          height: t,
          justifyContent: 'flex-end',
          padding: '10px 20px 10px 0',
          alignRight: false
        },
        {
          // rechts von Highlight, top == Highlight, rechts und unten bis Rand
          id: 3,
          left: l + w,
          top: t > 0 ? t : '10px',
          width: 100 - l - w,
          height: 100 - t,
          justifyContent: 'flex-start',
          padding: '0 20px 10px 10px',
          alignRight: false
        },
        {
          // unter Highlight, left == Hightlight, rechts und unten bis Rand
          id: 4,
          left: l > 0 ? l : '10px',
          top: t + h,
          width: 100 - l,
          height: 100 - (t + h),
          justifyContent: 'flex-start',
          padding: '10px 20px 10px 0',
          alignRight: false
        }
      ];

      let biggestSpace = this.getBiggestSpace(textSpaces);
      if (biggestSpace.id === 4 && biggestSpace.left <= 0) {
        biggestSpace.padding = '10px 20px 10px 10px';
      }

      textPositions.push({
        left:
          typeof biggestSpace.left !== 'string'
            ? biggestSpace.left + '%'
            : biggestSpace.left,
        right:
          typeof biggestSpace.right !== 'string'
            ? biggestSpace.right + '%'
            : biggestSpace.right,
        top:
          typeof biggestSpace.top !== 'string'
            ? biggestSpace.top + '%'
            : biggestSpace.top,
        bottom:
          typeof biggestSpace.bottom !== 'string'
            ? biggestSpace.bottom + '%'
            : biggestSpace.bottom,
        width:
          typeof biggestSpace.width !== 'string'
            ? biggestSpace.width + '%'
            : biggestSpace.width,
        height:
          typeof biggestSpace.height !== 'string'
            ? biggestSpace.height + '%'
            : biggestSpace.height,
        justifyContent: biggestSpace.justifyContent,
        textAlign: biggestSpace.alignRight ? 'right' : 'left',
        padding: biggestSpace.padding
      });
    }

    return {
      highLightPositions: highLightPositions,
      textPositions: textPositions
    };
  };

  getBiggestSpace = (spaces) => {
    let biggestSpace = 0;
    let biggestArea = 0;
    for (let i = 0; i < spaces.length; i++) {
      let area = spaces[i].height * spaces[i].width;
      if (area > biggestArea) {
        biggestArea = area;
        biggestSpace = spaces[i];
      }
    }
    return biggestSpace;
  };

  handleKeyDown = (e) => {
    // if (e.ctrlKey && e.which === 27) {
    if (e.which === 27) {
      this.prepareClose();
    } else if (e.which === 39) {
      this.nextStep();
    } else if (e.which === 37) {
      this.prevStep();
    }
  };

  prepareClose = () => {
    this.setState(
      {
        isOpened: false,
        isFocused: false
      },
      () => {
        // eslint-disable-next-line no-prototype-builtins
        if (this.props.hasOwnProperty('show') && this.props.show) {
          setTimeout(() => {
            this.close();
          }, 400);
        }
      }
    );
  };

  close = () => {
    this.props.close();
  };

  nextStep = () => {
    const { textScope } = this.state;
    let step = this.state.currentStep;
    let isUpdated = false;

    if (textScope) {
      if (step === textScope.length - 1) {
        this.prepareClose();
        setTimeout(() => {
          this.setState({ currentStep: 0 });
        }, 1000);
      } else {
        if (step < textScope.length - 1) {
          step++;
          isUpdated = true;
        }
      }
      if (isUpdated) {
        this.setState({ isFocused: false });
        setTimeout(() => {
          this.setState({ currentStep: step });
          setTimeout(() => {
            this.setState({ isFocused: true });
          }, 300);
        }, 300);
      }
    }
  };

  prevStep = () => {
    let step = this.state.currentStep;
    let isUpdated = false;

    if (step > 0) {
      step--;
      isUpdated = true;
    }

    if (isUpdated) {
      this.setState({ isFocused: false });
      setTimeout(() => {
        this.setState({ currentStep: step });
        setTimeout(() => {
          this.setState({ isFocused: true });
        }, 300);
      }, 300);
    }
  };

  render() {
    const { t } = this.props;
    const {
      isOpened,
      isFocused,
      currentStep,
      textPositions,
      textScope,
      highLightPositions
    } = this.state;

    // if (textScope === null) {
    // console.warn('GLOBALHELP WARNING textScope === null');
    // this.waitingTillUpdate();
    // }

    return (
      <div
        className={styles.help}
        ref={(div) => (this.helpPopup = div)}
        is-visible={isOpened.toString()}
      >
        <div
          className={isFocused ? 'highLight focus' : 'highLight'}
          style={highLightPositions[currentStep]}
        ></div>

        {/* <div className="headbar">*/}
        {/* <div className="help-headline">Help</div>*/}
        {/* </div>*/}
        {textPositions.length > 0 && textScope && (
          <div
            className={!isFocused ? 'text-wrapper' : 'text-wrapper show'}
            has-padding={textPositions[currentStep].padding.toString()}
            is-align-right={(
              textPositions[currentStep].textAlign === 'right'
            ).toString()}
            style={textPositions[currentStep]}
          >
            <div className='headline'>
              {textScope &&
                textScope[currentStep] &&
                t(textScope[currentStep].headline) !== '--' && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(textScope[currentStep].headline)
                    }}
                  ></span>
                )}
            </div>
            {textScope &&
              textScope[currentStep] &&
              t(textScope[currentStep].text) !== '--' && (
                <div className='text'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(textScope[currentStep].text)
                    }}
                  ></div>
                </div>
              )}
            <div className='nav'>
              <div className='nav-inner'>
                {currentStep !== 0 && (
                  <button
                    className={'leftButton'}
                    title={t('previous_step')}
                    disabled={!isFocused || currentStep === 0}
                    onClick={this.prevStep}
                  >
                    <FontIcon>chevron_left</FontIcon>
                  </button>
                )}

                {textScope && textScope[currentStep] && (
                  <span className={'steps'}>
                    {currentStep + 1 + '/' + textScope.length}
                  </span>
                )}
                {textScope && currentStep < textScope.length - 1 && (
                  <button
                    className={'rightButton'}
                    title={t('next_step')}
                    disabled={!isFocused || currentStep === textScope.length}
                    onClick={this.nextStep}
                  >
                    <FontIcon>chevron_right</FontIcon>
                  </button>
                )}

                <button
                  className='closeButton'
                  title={t('close_help')}
                  onClick={this.prepareClose}
                >
                  <FontIcon>close</FontIcon>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.getIn(['auth', 'user']),
    impersonatedUser: state.getIn(['auth', 'impersonatedUser'])
  };
}

export default withRouter(
  connect(mapStateToProps, {
    editUserSettings
  })(GlobalHelp)
);
