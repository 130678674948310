import React from 'react';
import { Switch } from 'react-md';

export default (props) => {
  return (
    <Switch
      {...props}
      id={props.name}
      name={props.name}
      label={props.label}
      checked={props.value}
      value={props.value}
      onChange={props.onChange}
      className={props.className}
    />
  );
};
