import React, { Component } from 'react';
import { SelectField } from 'react-md';
import {
  fetchSnapshots as fetchTargetSnapshots,
  setSnapshotId as setTargetSnapshotId
} from '../../actions/snapshots';
import { connect } from 'react-redux';
import { ProcessStatus } from '../../utils/Constants';
import styles from '../../styles/translationTool/sourceSnapshots.scss';
import { FontIcon } from 'react-md';
import { withTranslation } from 'react-i18next';

@withTranslation('translation', { wait: true })
class TargetSnapshots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetSnapshots: [],
      targetSnapshotDescription: null
    };
  }

  UNSAFE_componentWillMount() {
    const {
      targetSnapshots,
      projectId,
      targetLanguageId,
      targetSnapshotId,
      targetSnapshotProjectId,
      targetSnapshotTranslationId,
      fetchingTargetSnapshots
    } = this.props;

    if (
      fetchingTargetSnapshots == ProcessStatus.FINISHED &&
      projectId == targetSnapshotProjectId &&
      targetLanguageId == targetSnapshotTranslationId
    ) {
      this.setState({ targetSnapshots: targetSnapshots.toArray() });
    } else {
      this.props.fetchTargetSnapshots(
        projectId,
        targetLanguageId,
        targetSnapshotId
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { targetSnapshots, fetchingTargetSnapshots, targetSnapshotId } =
      nextProps;

    if (
      fetchingTargetSnapshots == ProcessStatus.FINISHED &&
      fetchingTargetSnapshots != this.props.fetchingTargetSnapshots
    ) {
      this.setState({ targetSnapshots: targetSnapshots.toArray() });
    }

    if (targetSnapshotId != this.props.targetSnapshotId) {
      let targetSnapshot = this.state.targetSnapshots.find(
        (targetSnapshot) => targetSnapshot.id == targetSnapshotId
      );
      let description = targetSnapshot ? targetSnapshot.description : null;
      this.setState({ targetSnapshotDescription: description });
    }
  }

  formatTargetSnapshotOptions = (targetSnapshots) => {
    return targetSnapshots.map((targetSnapshot) => {
      return {
        label:
          targetSnapshot.created_at.substr(0, 10) +
          ' | ' +
          targetSnapshot.id +
          ' - ' +
          targetSnapshot.name,
        value: targetSnapshot.id
      };
    });
  };

  handleTargetSnapshotChange = (value) => {
    this.props.setTargetSnapshotId(value);
  };

  sortDescending = (a, b) => {
    if (a.value > b.value) return -1;
    if (a.value < b.value) return 1;
    return 0;
  };

  render() {
    const { targetSnapshotId, t } = this.props;
    const { targetSnapshots } = this.state;

    let formattedTargetSnapshots = [];

    if (targetSnapshots.length > 0) {
      formattedTargetSnapshots = this.formatTargetSnapshotOptions(
        targetSnapshots
      ).sort((a, b) => this.sortDescending(a, b));
      formattedTargetSnapshots.unshift({
        label: t('source_snapshots_none'),
        value: -1
      });
    }

    let snapshot = formattedTargetSnapshots.find(
      (targetSnapshot) => targetSnapshot.value === targetSnapshotId
    );

    if (snapshot == null) {
      return null;
    }

    return (
      <div className={styles.onlyOneSnapshot}>
        {snapshot.label}
        {/*
        <SelectField
          id="targetSnapshots"
          //menuItems={formattedTargetSnapshots}
          menuItems={formattedTargetSnapshots}
          value={targetSnapshotId}
          className={styles.targetSnapshots}
          //dropdownIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
          //onChange={this.handleTargetSnapshotChange}
        />
        */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchingTargetSnapshots: state.getIn(['snapshots', 'fetchingSnapshots']),
    targetSnapshots: state.getIn(['snapshots', 'snapshots']),
    targetsnapshotProjectId: state.getIn(['snapshots', 'projectId']),
    targetSnapshotTranslationId: state.getIn(['snapshots', 'translationId'])
  };
}

export default connect(mapStateToProps, {
  fetchTargetSnapshots,
  setTargetSnapshotId
})(TargetSnapshots);
