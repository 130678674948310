import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DialogContainer } from 'react-md';
import { withTranslation } from 'react-i18next';
import HighlightedProject from '../projects/HighlightedProjectCard';
import { ProjectModel } from '../../tableModels';
import FilterSearchPanel from '../general/FilterSearchPanel';
import CustomTable from '../general/CustomTable';
import { Routes, ProcessStatus, Scope } from '../../utils/Constants';
import MangooluLoading from '../general/MangooluLoading';
import ImportProject from './importprojectwizard/ImportProjectWizard';
import { arrayHelper } from '../../utils/HelperFunctions';
import { fetchClients } from '../../actions/clients';
import {
  fetchProjects,
  editProject,
  fetchAllExistingProjects
} from '../../actions/projects';
import { defaultCreateSortFunction } from '../../tableModels/defaultFunctions';
import styles from '../../styles/list.scss';
import dialogStyles from '../../styles/dialog.scss';
import ContentDrawer from '../../utils/ContentDrawer';
import LayoutContainer from '../layout/LayoutContainer';
import { hasPermission } from '../../utils/AuthHelper';
import { aclProject, aclTranslationTool } from '../../utils/Permissions';
import { editUserSettings } from '../../actions/auth';
import { aclFilter } from '../../utils/ACL';

@withTranslation(['project', 'general', 'projectselection'], { wait: true })
class ListProjects extends Component {
  state = {
    searchTerm: '',
    filters: [],
    focusOnTable: false,
    sortedProjects: [],
    highlightedProjects: [],
    pinnedProjects: [],
    pinnedFromUserSettings: false
  };

  UNSAFE_componentWillMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { projects, loggingOutAsUser, fetchingClients } = nextProps;

    if (
      fetchingClients === ProcessStatus.FAILED &&
      fetchingClients !== ProcessStatus.FINISHED
    ) {
      this.props.fetchClients();
    }

    if (
      loggingOutAsUser === ProcessStatus.FINISHED &&
      loggingOutAsUser !== this.props.loggingOutAsUser
    ) {
      this.init(nextProps);
    } else {
      if (projects.size > 0) {
        this.sortProjects(projects);
      }
    }
  }

  init = (props) => {
    const { clients, projects } = props;

    this.props.fetchAllExistingProjects();

    if (clients.size === 0) {
      this.props.fetchClients();
    }

    if (projects.size === 0) {
      this.props.fetchProjects();
    } else {
      this.sortProjects(projects);
    }

    this.setPinnedProjectsIfAvailable();
  };

  setPinnedProjectsIfAvailable = () => {
    // eslint-disable-next-line no-prototype-builtins
    if (this.props.user.settings.hasOwnProperty('pinnedProjects')) {
      if (
        JSON.stringify(this.state.pinnedProjects) !==
        JSON.stringify(this.props.user.settings.pinnedProjects)
      ) {
        this.setState({
          pinnedProjects: this.props.user.settings.pinnedProjects
        });
      }
    }
  };

  sortProjects = (projects) => {
    let sortFunction = defaultCreateSortFunction('updated_at', 'desc');
    const sortedProjects = projects.toArray().sort(sortFunction);

    if (
      this.props.existingProjectFolders &&
      this.props.existingProjectFolders.length > 0
    ) {
      sortedProjects.map((project) => {
        project.isExistingAsFolder =
          this.props.existingProjectFolders.indexOf(project.identifier) !== -1;
        return project;
      });
    }

    const highlightedProjects = sortedProjects.slice(0, 3);
    this.setState({
      sortedProjects: sortedProjects,
      highlightedProjects: highlightedProjects
    });
  };

  pinProject = (id) => {
    const { user } = this.props;
    const { pinnedProjects } = this.state;
    let pp = pinnedProjects;
    if (pp.length === 3) {
      pp.shift();
    }
    pp.push(id);
    this.setState({ pinnedProjects: pp }, () => {
      this.props.editUserSettings(user.id, { pinnedProjects: pp });
    });
  };

  unpinProject = (id) => {
    const { user } = this.props;
    const { pinnedProjects } = this.state;
    let pp = pinnedProjects;
    for (var o = 0; o < pp.length; o++) {
      if (pp[o] === id) {
        pp.splice(o, 1);
        break;
      }
    }
    this.setState({ pinnedProjects: pp }, () => {
      this.props.editUserSettings(user.id, { pinnedProjects: pp });
    });
  };

  closeForm = () => {
    this.props.history.push('/projects');
  };

  updateForm = () => {
    this.props.history.push(Routes.PROJECTS.IMPORT);
  };

  closeDrawer = () => {
    this.props.history.push('/projects');
    // TODO: IMPORT IT
    // this.props.history.push(Routes.PROJECTS.IMPORT_WARNING);
  };

  handleCellClick = (row, cell) => {
    if (cell && cell.id) {
      switch (cell.id) {
        case 'active':
          this.handleActive(row);
          break;
        default:
          break;
      }
    }
  };

  handleActive = (project) => {
    project.active = parseInt(+!project.active);
    this.props.editProject(project);
  };

  handleFilter = (type, value) => {
    const newFilters = arrayHelper(this.state.filters, type, value);
    this.setState({ filters: newFilters });
  };

  handleFilterReset = (type) => {
    console.log('handleFilterReset', type);
    // return () => this.props.filterByType(type);
  };

  handleSearchInput = (value) => {
    this.setState({ searchTerm: value });
  };

  handleSearchReset = () => {
    this.setState({ searchTerm: '' });
  };

  hitEnterToFocusOnTable = () => {
    this.setState({ focusOnTable: true });
  };

  selectProject = (id) => {
    this.props.history.push('projects/' + id);
  };

  renderHighlightedProjects() {
    const { highlightedProjects } = this.state;
    if (highlightedProjects.length !== 0) {
      return (
        <>
          <h5 className={'project-highlight-headline'}>Last Updated:</h5>
          <div className={styles.projectRowMiddle} help-tool='2'>
            {highlightedProjects.map((project, key) => (
              <HighlightedProject
                key={key}
                project={project}
                controlpanel={true}
                onClick={this.selectProject}
              />
            ))}
          </div>
        </>
      );
    }
  }

  renderPinnedProjects() {
    const { pinnedProjects } = this.state;
    const { projects } = this.props;

    if (projects.size <= 0) {
      return null;
    }

    let pinnedProjectsView = [];
    for (var o = 0; o < pinnedProjects.length; o++) {
      const project = projects.find(
        (project) => project.id === pinnedProjects[o]
      );
      pinnedProjectsView.push(project);
    }

    return (
      <>
        <h5 className={'project-highlight-headline'}>Favorites:</h5>
        <div className={styles.projectRowMiddle}>
          {pinnedProjectsView.map((project, key) => (
            <HighlightedProject
              key={key}
              project={project}
              controlpanel={true}
              onClick={this.selectProject}
              removePinnedProject={this.unpinProject}
            />
          ))}
        </div>
      </>
    );
  }

  renderFiltersAndTable = () => {
    const { clients, projects, fetchingProjects, t } = this.props;
    const projectselectionLNS = 'projectselection'; // projectselectionLanguageNamespaceSource

    if (projects.size <= 0 || fetchingProjects == ProcessStatus.STARTED) {
      return (
        <LayoutContainer>
          <MangooluLoading show={true} />
        </LayoutContainer>
      );
    }

    // TODO: Verallgemeinern...
    const projectsWithProxies = projects.map((project) => {
      project.proxies = [];
      const client = clients.find((client) => {
        return client.id === project.client_id;
      });
      project.proxies.push({ client_id: client ? client.name : null });
      // TODO: Is this the correct approach for inject texts from locales?
      project.type_label = t(`${projectselectionLNS}:${project.type}`);

      project.version_full = {
        version: project.version,
        config: project.config
      };
      return project;
    });

    const filters = ProjectModel.prepareFilters.call(
      ProjectModel, // focus
      ProjectModel.filters,
      ['client_id', 'type_label'],
      projectsWithProxies,
      { clients: clients.toArray() }
    );

    let route = hasPermission(aclProject.create)
      ? Routes.PROJECTS.IMPORT
      : null;

    return (
      <div help-tool='1'>
        <FilterSearchPanel
          filters={filters}
          appliedFilters={this.state.filters}
          handleFilter={this.handleFilter}
          handleFilterReset={this.handleFilterReset}
          searchBox={true}
          searchTerm={this.state.searchTerm}
          handleSearchInput={this.handleSearchInput}
          handleSearchReset={this.handleSearchReset}
          hitEnterToFocus={this.hitEnterToFocusOnTable}
          routeToAddNewItem={route}
        />
        <CustomTable
          model={ProjectModel}
          rows={projectsWithProxies.toArray()}
          searchTerm={this.state.searchTerm}
          appliedFilters={this.state.filters}
          focus={this.state.focusOnTable}
          onCellClick={this.handleCellClick}
          onRowClick={this.selectProject}
          pinProject={this.pinProject}
          unpinProject={this.unpinProject}
          pinnedProjects={this.state.pinnedProjects}
        />
      </div>
    );
  };

  render() {
    const { clients, projects, fetchingProjects, t } = this.props;
    const { sortedProjects, pinnedProjects } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    const dialogActions = [
      {
        onClick: this.closeForm,
        primary: true,
        children: t(`${generalLNS}:discard_from`)
      },
      {
        onClick: this.updateForm,
        primary: true,
        children: t(`${generalLNS}:go_on`)
      }
    ];

    return (
      <div className={styles.list}>
        <DialogContainer
          id='add-warning'
          className={dialogStyles.dialogContainer}
          visible={
            this.props.location.pathname == Routes.PROJECTS.IMPORT_WARNING
          }
          title={t(`${generalLNS}:warning`)}
          onHide={this.closeForm}
          aria-describedby='speed-boost-description'
          modal
          disableScrollLocking={true}
          actions={dialogActions}
        >
          <p id='speed-boost-description' className='md-color--secondary-text'>
            {t('warning_unsaved_data')}
          </p>
        </DialogContainer>

        <ContentDrawer
          key='1'
          children={<ImportProject />}
          visible={this.props.match.path == Routes.PROJECTS.IMPORT}
          closeDrawer={this.closeDrawer}
        />

        <LayoutContainer width='small'>
          <MangooluLoading
            show={
              projects.size < 0 || fetchingProjects === ProcessStatus.STARTED
            }
          />
          {sortedProjects.length == 0 &&
            fetchingProjects === ProcessStatus.FINISHED && (
              <div>No projects found.</div>
            )}
          {pinnedProjects.length > 0 && this.renderPinnedProjects()}
          {sortedProjects.length > 0 && this.renderHighlightedProjects()}
          {sortedProjects.length > 0 &&
            clients.toArray().length > 0 &&
            this.renderFiltersAndTable()}
        </LayoutContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.getIn(['auth', 'user']),
    clients: state.getIn(['clients', 'clients']),
    fetchingClients: state.getIn(['clients', 'fetchingClients']),
    fetchingProjects: state.getIn(['projects', 'fetchingProjects']),
    projects: state.getIn(['projects', 'projects']),
    existingProjectFolders: state.getIn(['projects', 'existingProjectFolders']),
    loggingOutAsUser: state.getIn(['auth', 'loggingOutAsUser'])
    // updateToken: state.getIn(['projects','updateToken'])
  };
}

export default connect(mapStateToProps, {
  fetchClients,
  fetchProjects,
  fetchAllExistingProjects,
  editProject,
  editUserSettings
})(ListProjects);
