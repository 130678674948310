import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, MenuButton, Layover } from 'react-md';
import {
  setAreTagsShown,
  setAreAllTextsShown,
  setAreOnlyEmptyShown,
  setAreOnlyDifferencesShown,
  setAreDifferencesHighlighted,
  setOnlyWithCommentsShown
} from '../../actions/translationTool';
import { withTranslation } from 'react-i18next';
import is from 'is_js';
import Select from '@material-ui/core/Select';
import styles from '../../styles/translationTool/controlMenu.scss';
import { matchesPermissions } from '../../utils/ACL';
import { aclTranslationTool } from '../../utils/Permissions';
import { Scope } from '../../utils/constants/ACL';

@withTranslation('control_menu', { wait: true })
class ControlMenu extends Component {
  state = {
    active: true
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.areFiltersActive();
    if (
      this.props.hideListItemTextOnStage &&
      this.props.areAllTextsShown !== true
    ) {
      this.toggleAreAllTextsShown();
    }
  }

  componentDidUpdate() {
    this.areFiltersActive();
  }

  toggleAreTagsShown = () => {
    this.props.setAreTagsShown(!this.props.areTagsShown);
  };

  toggleAreAllTextsShown = () => {
    this.props.setAreAllTextsShown(!this.props.areAllTextsShown);
  };

  toggleAreOnlyEmptyShown = () => {
    this.props.setAreOnlyEmptyShown(!this.props.areOnlyEmptyShown);
  };

  toggleOnlyWithCommentsShown = () => {
    this.props.setOnlyWithCommentsShown(!this.props.onlyWithCommentsShown);
  };

  toggleAreOnlyDifferencesShown = () => {
    this.props.setAreOnlyDifferencesShown(!this.props.areOnlyDifferencesShown);
  };

  toggleAreDifferencesHighlighted = () => {
    this.props.setAreDifferencesHighlighted(
      !this.props.areDifferencesHighlighted
    );
  };

  handleTargetSnapshotChange = (e) => {
    this.props.setTargetSnapshot(parseInt(e.target.value));
  };

  handleSourceSnapshotChange = (e) => {
    this.props.setSourceSnapshot(parseInt(e.target.value));
  };
  /*
    handleSearchInput = value => {
      this.setState({searchTerm: value}, () => this.updateSections(this.props));
    };

    handleSearchReset = () => {
      this.setState({searchTerm: ''});
    };

    handleSourceSnapshotClick = () => {
      this.setState({isSourceSnapshotBarVisible: !this.state.isSourceSnapshotBarVisible});
    };
  */

  areFiltersActive = () => {
    const {
      areTagsShown,
      areAllTextsShown,
      areOnlyDifferencesShown,
      areDifferencesHighlighted,
      areOnlyEmptyShown
    } = this.props;

    const active =
      areTagsShown ||
      !areAllTextsShown ||
      areOnlyDifferencesShown ||
      areDifferencesHighlighted ||
      areOnlyEmptyShown;

    if (this.state.active !== active) {
      this.setState({ active });
    }
  };

  createHeadline = (key, id) => {
    const { t, sectionCount } = this.props;
    let headline = id;
    if (id === 'filter') {
      headline = `${id} (${sectionCount} ${t('transunits')})`;
    }
    return (
      <div
        key={key + '_' + Math.random().toString().substr(2, 8)}
        id={id}
        className={styles.controlMenuHeadline}
      >
        <span>{headline}</span>
      </div>
    );
  };

  createToggleItem = (key, id, state, changeFunc) => {
    const { t, isFetching } = this.props;

    const switchStyle = {
      display: 'inline-block',
      flexGrow: '0',
      flexShrink: '0',
      flexBasis: 'auto',
      height: 'auto'
    };

    return (
      <div
        key={key + '_' + Math.random().toString().substr(2, 8)}
        id={id}
        className={styles.switchWithText}
      >
        <span>{t(id)}</span>
        <Switch
          disabled={isFetching}
          style={switchStyle}
          id={id + '_switch'}
          name={id}
          aria-label={id}
          checked={state}
          onChange={changeFunc}
        />
      </div>
    );
  };

  createListItems = () => {
    const {
      areAllTextsShown,
      areOnlyDifferencesShown,
      areOnlyEmptyShown,
      areTagsShown,
      areDifferencesHighlighted,
      onlyWithCommentsShown,
      sourceSnapshotId,
      targetSnapshotId,
      withCollection,
      hideListItemTextOnStage,
      sourceSnapshots,
      targetSnapshots,
      isFetching,
      t,
      sourceLanguageCode,
      translationLanguageCode
    } = this.props;

    let filter = this.createHeadline('hd_1', 'filter');
    let highlights = this.createHeadline('hd_2', 'highlights');
    let onStage = null;
    if (withCollection) {
      onStage = this.createToggleItem(
        'fr_1',
        'filter_by_on_stage',
        areAllTextsShown,
        this.toggleAreAllTextsShown
      );
    } else {
      onStage = this.createToggleItem(
        'fr_1',
        'filter_by_on_stage',
        !areAllTextsShown,
        this.toggleAreAllTextsShown
      );
    }

    let changed = this.createToggleItem(
      'fr_2',
      'filter_by_changed',
      areOnlyDifferencesShown,
      this.toggleAreOnlyDifferencesShown
    );

    let empty = this.createToggleItem(
      'fr_3',
      'filter_by_empty',
      areOnlyEmptyShown,
      this.toggleAreOnlyEmptyShown
    );

    let comment = this.createToggleItem(
      'fr_3',
      'filter_by_comment',
      onlyWithCommentsShown,
      this.toggleOnlyWithCommentsShown
    );

    let tags = this.createToggleItem(
      'ht_1',
      'highlight_tags',
      areTagsShown,
      this.toggleAreTagsShown
    );

    let changes = this.createToggleItem(
      'ht_2',
      'highlight_changes',
      areDifferencesHighlighted,
      this.toggleAreDifferencesHighlighted
    );

    let legend = (
      <div
        key={'changesLegend_' + Math.random().toString().substr(2, 8)}
        id=''
        className={`legend ${areDifferencesHighlighted ? ' visible' : ''}`}
      >
        {this.createHeadline('hd_3', 'legend')}
        <div className={'legendItem'}>
          <div className={'rectangle removed'} />
          <span>removed words</span>
        </div>
        <div className={'legendItem'}>
          <div className={'rectangle added'} />
          <span>added words</span>
        </div>
      </div>
    );

    let listItems = [filter];

    if (!hideListItemTextOnStage && !is.ie()) {
      // Hide on IE11
      listItems.push(onStage);
    }

    let snapshotHeadline = this.createHeadline('hd_2', 'snapshots');

    const sourceSnapshotSelect = (
      <div
        key={'sourcesnap_' + Math.random().toString().substr(2, 8)}
        className={styles.snapshotSelect}
      >
        <div className='subHeadline'>
          <span>{t('snapshot_for') + ' '}</span>
          <b>{sourceLanguageCode}</b>
        </div>
        <Select
          native
          disabled={isFetching}
          value={this.props.sourceSnapshotId}
          onChange={this.handleSourceSnapshotChange}
        >
          <option key={'00_source_snapshot'} value={-1}>
            {t('no_snapshot')}
          </option>
          {sourceSnapshots.size > 0 &&
            sourceSnapshots.map((snapshot) => {
              return (
                <option
                  key={snapshot.id + '_source_snapshot'}
                  value={snapshot.id}
                >
                  {snapshot.created_at.substr(0, 10) +
                    ' | ' +
                    snapshot.id +
                    ' - ' +
                    snapshot.name}
                </option>
              );
            })}
        </Select>
      </div>
    );

    const targetSnapshotSelect = (
      <div
        key={'targetsnap_' + Math.random().toString().substr(2, 8)}
        className={styles.snapshotSelect}
      >
        <div className='subHeadline'>
          <span>{t('snapshot_for') + ' '}</span>
          <b>{translationLanguageCode}</b>
        </div>
        <Select
          native
          disabled={isFetching}
          value={this.props.targetSnapshotId}
          onChange={this.handleTargetSnapshotChange}
        >
          <option key={'00_target_snapshot'} value={-1}>
            {t('no_snapshot')}
          </option>
          {targetSnapshots.size > 0 &&
            targetSnapshots.map((snapshot) => {
              return (
                <option
                  key={snapshot.id + '_target_snapshot'}
                  value={snapshot.id}
                >
                  {snapshot.created_at.substr(0, 10) +
                    ' | ' +
                    snapshot.id +
                    ' - ' +
                    snapshot.name}
                </option>
              );
            })}
        </Select>
      </div>
    );

    if (sourceSnapshotId > -1 || targetSnapshotId > -1) {
      listItems.push(
        changed,
        empty,
        comment,
        { divider: true },
        highlights,
        tags,
        changes,
        legend
      );
    } else {
      listItems.push(empty, comment, { divider: true }, highlights, tags);
    }

    if (
      matchesPermissions(
        [aclTranslationTool.snapshot_compare],
        Scope.TRANSLATION
      )
    ) {
      if (targetSnapshots.size > 0 || sourceSnapshots.size > 0) {
        listItems.push({ divider: true }, snapshotHeadline);
      }
      if (sourceSnapshots.size > 0) {
        listItems.push(sourceSnapshotSelect);
      }
      if (targetSnapshots.size > 0) {
        listItems.push(targetSnapshotSelect);
      }
    }

    return listItems;
  };

  render() {
    const {
      areTagsShown,
      areAllTextsShown,
      areOnlyDifferencesShown,
      areDifferencesHighlighted,
      areOnlyEmptyShown,
      isFetching,
      t
    } = this.props;

    const active =
      areTagsShown ||
      !areAllTextsShown ||
      areOnlyDifferencesShown ||
      areDifferencesHighlighted ||
      areOnlyEmptyShown;

    let listItems = this.createListItems();

    let activeFiltersText = t('title');
    if (active) {
      activeFiltersText += '\n\n' + t('title2');
      if (!areAllTextsShown) {
        activeFiltersText += '\n - ' + t('filter_by_on_stage');
      }
      if (areOnlyDifferencesShown) {
        activeFiltersText += '\n - ' + t('filter_by_changed');
      }
      if (areDifferencesHighlighted) {
        activeFiltersText += '\n - ' + t('highlights');
      }
      if (areOnlyEmptyShown) {
        activeFiltersText += '\n - ' + t('filter_by_empty');
      }
      if (areTagsShown) {
        activeFiltersText += '\n - ' + t('highlight_tags');
      }
    }

    return (
      <div
        className={
          isFetching ? styles.controlMenu + ' disabled' : styles.controlMenu
        }
      >
        <MenuButton
          id='menu-button-2'
          className={this.state.active ? styles.activeFilters : ''}
          anchor={{
            x: Layover.HorizontalAnchors.INNER_RIGHT,
            y: Layover.VerticalAnchors.BOTTOM
          }}
          position={Layover.Positions.TOP_RIGHT}
          icon
          menuItems={listItems}
          title={activeFiltersText}
        >
          tune
        </MenuButton>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    areTagsShown: state.getIn(['translationTool', 'areTagsShown']),
    areAllTextsShown: state.getIn(['translationTool', 'areAllTextsShown']),
    areOnlyDifferencesShown: state.getIn([
      'translationTool',
      'areOnlyDifferencesShown'
    ]),
    areDifferencesHighlighted: state.getIn([
      'translationTool',
      'areDifferencesHighlighted'
    ]),
    areOnlyEmptyShown: state.getIn(['translationTool', 'areOnlyEmptyShown']),
    onlyWithCommentsShown: state.getIn([
      'translationTool',
      'onlyWithCommentsShown'
    ])
  };
}

export default connect(mapStateToProps, {
  setAreTagsShown,
  setAreAllTextsShown,
  setAreOnlyEmptyShown,
  setAreOnlyDifferencesShown,
  setAreDifferencesHighlighted,
  setOnlyWithCommentsShown
})(ControlMenu);
