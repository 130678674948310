// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".display-none {\n  display: none !important;\n}\n\n.loadingContainer {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.loadingContainer [class*=\"center\"] {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.loadingContainer [class*=\"center\"] .loop-light {\n  display: block;\n}\n\n.loadingContainer [class*=\"center\"] .loop-dark {\n  display: none;\n}\n\n.loadingText {\n  font-size: 12px;\n}\n", ""]);
// Exports
exports.locals = {
	"display-none": "display-none",
	"displayNone": "display-none",
	"loadingContainer": "loadingContainer",
	"loop-light": "loop-light",
	"loopLight": "loop-light",
	"loop-dark": "loop-dark",
	"loopDark": "loop-dark",
	"loadingText": "loadingText"
};
module.exports = exports;
