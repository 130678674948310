import { List, fromJS } from 'immutable';

import { GlobalSnackbarAction } from '../utils/ActionTypes';

const initialState = fromJS({
  toasts: List()
});

const reducer = (state = initialState, action) => {
  let updatedList = null;

  switch (action.type) {
    case GlobalSnackbarAction.ADD_TOAST:
      updatedList = state.get('toasts').push(action.toast);
      return state.set('toasts', updatedList);
    case GlobalSnackbarAction.REMOVE_TOAST:
      updatedList = state.get('toasts').delete(0);
      return state.set('toasts', updatedList);
    default:
      return state;
  }
};

export { initialState, reducer as globalSnackbar };
