import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import EditTranslation from './EditTranslation';
import TranslationUsers from './TranslationUsers';
import TranslationSourceLanguages from './TranslationSourceLanguages';

import styles from '../../../styles/project_detail.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
import stylesTabs from '../../../styles/tabs.scss';

@withTranslation(['project', 'general'], { wait: true })
class EditTranslationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      tabCount: 2
    };
  }

  setTab = (tabIndex) => {
    this.setState({ tabIndex: tabIndex });
  };

  render() {
    const { project, translation, t } = this.props;
    const { tabIndex, tabCount } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    const hasParentId = translation.get('parent_id') != null;
    const subtitle = hasParentId
      ? translation.get('id')
      : translation.get('id') + ' | ' + t(`${generalLNS}:master_language`);
    const name =
      translation.get('languagecode') + ' - ' + translation.get('name');

    let transformValue = 100 * (tabIndex / tabCount) * -1;
    let tabContainerTransformValue =
      'translateX(' + transformValue + '%) translateZ(0)';
    let tabContainerWidth = 'calc(100% * ' + tabCount + ')';

    return (
      <div
        className={stylesDrawer.drawer + ' editDrawer'}
        style={{ minWidth: '80vw' }}
      >
        <div className={'head'}>
          <div className={'headlines'}>
            <h4 title={'Projectname'} style={{ marginBottom: '-1rem' }}>
              {project.name}
            </h4>
            <h2>{name}</h2>
            <h4>{subtitle}</h4>
          </div>
        </div>

        <div className={stylesTabs.tabBtnRow}>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 0).toString()}
            onClick={() => {
              this.setTab(0);
            }}
          >
            {t(`${generalLNS}:properties`)}
          </button>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 1).toString()}
            onClick={() => {
              this.setTab(1);
            }}
          >
            {t(`${generalLNS}:relations`)}
          </button>
        </div>

        <div className={stylesTabs.tabContainerRow}>
          <div
            className='tabContainerWrapper'
            style={{
              transform: tabContainerTransformValue,
              width: tabContainerWidth
            }}
          >
            <div className={'tabContainer'}>
              <EditTranslation />
            </div>
            <div className={'tabContainer'}>
              <div
                className={
                  styles.formContentWrapper + ' ' + styles.flexHorizontalWrapper
                }
              >
                <div className={'column'}>
                  <TranslationUsers />
                </div>
                {hasParentId && (
                  <div className={'column'}>
                    <TranslationSourceLanguages />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.getIn(['projects', 'selectedProject']),
    translation: state.getIn(['projects', 'translation'])
  };
}

export default connect(mapStateToProps, {})(EditTranslationContainer);
