import React, { Component } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { withTranslation } from 'react-i18next';
import { FontIcon } from 'react-md';
import { getSelectionInlineStyle } from '../helper/helperFunctions';

@withTranslation(['translation_navigation'], { wait: true })
class SoftBreakButton extends Component {
  constructor(props) {
    super(props);
  }

  onClick = () => {
    const { getEditorState, setEditorState } = this.props;
    const editorState = getEditorState();
    const selectionState = editorState.getSelection();
    // const content = editorState.getCurrentContent();
    const currentInlineStyle = getSelectionInlineStyle(editorState);

    if (selectionState.isCollapsed() && !currentInlineStyle.NOBREAK) {
      const text = '\n';
      const contentState = editorState.getCurrentContent();
      const modifiedContent = Modifier.insertText(
        contentState,
        selectionState,
        text,
        editorState.getCurrentInlineStyle(),
        null
      );
      const nextState = EditorState.push(
        editorState,
        modifiedContent,
        'insert-characters'
      );
      const selectedEditorState = EditorState.forceSelection(
        nextState,
        modifiedContent.getSelectionAfter()
      );
      setEditorState(selectedEditorState);

      /* TOGGLE STYLE: */
      // _this.props.setEditorState(_draftJs.RichUtils.toggleInlineStyle(_this.props.getEditorState(), style));
    }
  };

  cannotAct = () => {
    const { getEditorState } = this.props;
    const editorState = getEditorState();
    const selectionState = editorState.getSelection();
    const currentInlineStyle = getSelectionInlineStyle(editorState);
    return !selectionState.isCollapsed() || currentInlineStyle.NOBREAK;
  };

  render() {
    let disabled = this.cannotAct();
    return (
      <span
        className='button shy-button'
        onMouseDown={this.onClick}
        title={this.props.description}
        data-disabled={disabled.toString()}
        data-active={''}
      >
        <FontIcon>keyboard_return</FontIcon>
      </span>
    );
  }
}

export default SoftBreakButton;
