import * as Rx from 'rxjs';
import { ajax } from 'rxjs/observable/dom/ajax';

import {
  ClientsAction,
  ProjectsAction,
  TranslationToolAction
} from '../utils/ActionTypes';

import {
  fetchProjectsFulfilled,
  fetchProjectsRejected,
  fetchProjectFulfilled,
  fetchProjectRejected,
  fetchProjectAdditionalContentCollectionsFulfilled,
  fetchProjectAdditionalContentCollectionsRejected,
  importProjectFulfilled,
  importProjectRejected,
  editProjectFulfilled,
  editProjectRejected,
  deleteProjectFulfilled,
  deleteProjectRejected,
  addTranslationFulfilled,
  addTranslationRejected,
  importTranslationFulfilled,
  importTranslationRejected,
  importPDFTranslationFulfilled,
  importPDFTranslationRejected,
  validateTranslationFulfilled,
  validateTranslationRejected,
  validatePDFTranslationFulfilled,
  validatePDFTranslationRejected,
  editTranslationFulfilled,
  editTranslationRejected,
  deleteTranslationFulfilled,
  deleteTranslationRejected,
  validateProjectFulfilled,
  validateProjectRejected,
  validateUpdateFulfilled,
  validateUpdateRejected,
  importUpdateFulfilled,
  importUpdateRejected,
  fetchAvailableExportsFulfilled,
  fetchAvailableExportsRejected,
  exportTranslationFulfilled,
  exportTranslationRejected,
  fetchTranslationFulfilled,
  fetchTranslationRejected,
  fetchTranslationStatisticsFulfilled,
  fetchTranslationStatisticsRejected,
  setForceApprovalFulfilled,
  setForceApprovalRejected,
  linkProjectToUserFulfilled,
  linkProjectToUserRejected,
  linkTranslationToUserFulfilled,
  linkTranslationToUserRejected,
  linkProjectToExportTypeFulfilled,
  linkProjectToExportTypeRejected,
  fetchConfigFulfilled,
  fetchConfigRejected,
  fetchAllExistingProjectsRejected,
  fetchAllExistingProjectsFulfilled
} from '../actions/projects';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';
import {
  fetchTranslationsFulfilled,
  fetchTranslationsRejected
} from '../actions/translationTool';

const fetchProjectsEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.FETCH_PROJECTS)
    .debounceTime(600)
    .mergeMap(() => {
      let url = baseApiUrl + 'projects';
      let headers = getHttpAuthHeaders(store, url);
      return (
        ajax
          .getJSON(url, headers)
          // .do(console.log('after call of: ' + url))
          .map((res) => res.data)
          .map(fetchProjectsFulfilled)
          .catch(fetchProjectsRejected)
      );
    });
};

const fetchProjectAdditionalContentCollectionsEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'texts/additionalcontentcollections/' +
        action.data.projectId +
        '/' +
        action.data.translationId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((res) => res.response)
        .map(fetchProjectAdditionalContentCollectionsFulfilled)
        .catch(fetchProjectAdditionalContentCollectionsRejected);
    });
};

const fetchProjectEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.FETCH_PROJECT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.projectId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(fetchProjectFulfilled)
        .catch(fetchProjectRejected);
    });
};

const importProjectEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.IMPORT_PROJECT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/import';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({
        url,
        method: 'POST',
        headers: headers,
        body: action.project.toObject()
      })
        .map((res) => res.response)
        .map(importProjectFulfilled)
        .catch(importProjectRejected);
    });
};

const validateProjectEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.VALIDATE_PROJECT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/import/validate';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers, body: action.data })
        .map((res) => res.response.data)
        .map(validateProjectFulfilled)
        .catch(validateProjectRejected);
    });
};

const validateUpdateEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.VALIDATE_UPDATE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/import/validate';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers, body: action.data })
        .map((res) => res.response.data)
        .map(validateUpdateFulfilled)
        .catch(validateUpdateRejected);
    });
};

const importUpdateEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.IMPORT_UPDATE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/update';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers, body: action.data })
        .map((res) => res.response)
        .map(importUpdateFulfilled)
        .catch(importUpdateRejected);
    });
};

const editProjectEpic = (action$, store) => {
  return (
    action$
      .ofType(ProjectsAction.EDIT_PROJECT)
      // .debounceTime(500)
      .mergeMap((action) => {
        let url = baseApiUrl + 'projects/' + action.project.id;
        let headers = getHttpAuthHeaders(store, url);
        return ajax({
          url,
          method: 'PUT',
          headers: headers,
          body: action.project
        })
          .map((res) => res.response)
          .map(editProjectFulfilled)
          .catch(editProjectRejected);
      })
  );
};

const deleteProjectEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.DELETE_PROJECT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.projectId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'DELETE', headers: headers })
        .map((res) => res.response)
        .map(deleteProjectFulfilled)
        .catch(deleteProjectRejected);
    });
};

const addTranslationEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.ADD_TRANSLATION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.projectId + '/translations';
      let headers = getHttpAuthHeaders(store, url);
      let body = action.data;
      return ajax({ url, method: 'POST', headers: headers, body: body })
        .map((res) => res.response)
        .map(addTranslationFulfilled)
        .catch(addTranslationRejected);
    });
};

const importTranslationEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.IMPORT_TRANSLATION)
    .debounceTime(500)
    .mergeMap((action) => {
      let translation = action.translation.toObject();
      let url =
        baseApiUrl +
        'projects/' +
        translation.project_id +
        '/translations/import';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers, body: translation })
        .map((res) => res)
        .map(importTranslationFulfilled)
        .catch(importTranslationRejected);
    });
};

//
// Wird nicht mehr verwendet
//
const importPDFTranslationEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.IMPORT_PDF_TRANSLATION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.translation.get('project_id') +
        '/translations/import';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({
        url,
        method: 'POST',
        headers: headers,
        body: action.translation.toObject()
      })
        .map((res) => res)
        .map(importPDFTranslationFulfilled)
        .catch(importPDFTranslationRejected);
    });
};

const validateTranslationEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.VALIDATE_TRANSLATION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.data.project_id +
        '/translations/import/validate';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers, body: action.data })
        .map((res) => res.response.data)
        .map(validateTranslationFulfilled)
        .catch(validateTranslationRejected);
    });
};

const validatePDFTranslationEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.VALIDATE_PDF_TRANSLATION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.data.project_id +
        '/translations/import/validate';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers, body: action.data })
        .map((res) => res.response.data)
        .map(validatePDFTranslationFulfilled)
        .catch(validatePDFTranslationRejected);
    });
};

const editTranslationEpic = (action$, store) => {
  return (
    action$
      .ofType(ProjectsAction.EDIT_TRANSLATION)
      // .debounceTime(500)
      .mergeMap((action) => {
        let url =
          baseApiUrl +
          'projects/' +
          action.projectId +
          '/translations/' +
          action.translationId;
        let headers = getHttpAuthHeaders(store, url);
        return ajax({
          url,
          method: 'PUT',
          headers: headers,
          body: action.translation
        })
          .map((res) => res.response)
          .map(editTranslationFulfilled)
          .catch(editTranslationRejected);
      })
  );
};

const deleteTranslationEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.DELETE_TRANSLATION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.translationId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'DELETE', headers: headers })
        .map((res) => res.response)
        .map(deleteTranslationFulfilled)
        .catch(deleteTranslationRejected);
    });
};

const linkProjectToUserEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.LINK_PROJECT_TO_USER)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.data.project_id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.data })
        .map((res) => res.response.users)
        .map(linkProjectToUserFulfilled)
        .catch(linkProjectToUserRejected);
    });
};

const linkTranslationToUserEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.LINK_TRANSLATION_TO_USER)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.data.project_id +
        '/translations/' +
        action.data.translation_id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.data })
        .map((res) => res.response.users)
        .map(linkTranslationToUserFulfilled)
        .catch(linkTranslationToUserRejected);
    });
};

const linkProjectToExportTypeEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.LINK_PROJECT_TO_EXPORT_TYPE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.data.project_id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.data })
        .map((res) => res.response.exportTypes)
        .map(linkProjectToExportTypeFulfilled)
        .catch(linkProjectToExportTypeRejected);
    });
};

const fetchAvailableExportsEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.FETCH_AVAILABLE_EXPORTS)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.projectId + '/exporttypes';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(fetchAvailableExportsFulfilled)
        .catch(fetchAvailableExportsRejected);
    });
};

const exportTranslationEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.EXPORT_TRANSLATION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url;
      switch (Number(action.exportId)) {
        case 6:
        case 7:
        case 8:
        case 12:
          if (action.additionalContentId) {
            if (action.collectionId) {
              url =
                baseApiUrl +
                'projects/' +
                action.projectId +
                '/translations/' +
                action.translationId +
                '/export/' +
                action.exportId +
                '/pdf/' +
                action.additionalContentId +
                '/' +
                action.collectionId;
            } else {
              url =
                baseApiUrl +
                'projects/' +
                action.projectId +
                '/translations/' +
                action.translationId +
                '/export/' +
                action.exportId +
                '/pdf/' +
                action.additionalContentId;
            }
          } else {
            url =
              baseApiUrl +
              'projects/' +
              action.projectId +
              '/translations/' +
              action.translationId +
              '/export/' +
              action.exportId;
          }
          break;

        case 13:
          url =
            baseApiUrl +
            'projects/' +
            action.projectId +
            '/translations/' +
            action.translationId +
            '/export/' +
            action.exportId +
            '/sourcesnapshot/' +
            action.sourceSnapshotId +
            '/translationsnapshot/' +
            action.translationSnapshotId;
          break;

        default:
          url =
            baseApiUrl +
            'projects/' +
            action.projectId +
            '/export/' +
            action.exportId +
            '/' +
            action.translationId;
      }

      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers })
        .map((res) => res.response)
        .map(exportTranslationFulfilled)
        .catch(exportTranslationRejected);
    });
};

const fetchTranslationEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.FETCH_TRANSLATION)
    .debounceTime(600) // TODO: 500 debounceTime wird rejected, mit Carsten schauen
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.translationId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(fetchTranslationFulfilled)
        .catch(fetchTranslationRejected);
    });
};

const fetchTranslationStatisticsEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.FETCH_TRANSLATION_STATISTICS)
    .debounceTime(750)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.translationId +
        '/items/status';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((res) => res.response.data)
        .map(fetchTranslationStatisticsFulfilled)
        .catch(fetchTranslationStatisticsRejected);
    });
};

const setForceApprovalEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.SET_FORCE_APPROVAL)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.translationId;
      let headers = getHttpAuthHeaders(store, url);
      let status = action.status ? 'approved' : 'not_approved';
      return ajax({
        url,
        method: 'PUT',
        headers: headers,
        body: { status: status }
      })
        .map((res) => res.response)
        .map(setForceApprovalFulfilled)
        .catch(setForceApprovalRejected);
    });
};

const fetchConfigEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.FETCH_CONFIG)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.projectId + '/config';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(fetchConfigFulfilled)
        .catch(fetchConfigRejected);
    });
};

const fetchAllExistingProjectsEpic = (action$, store) => {
  return action$
    .ofType(ProjectsAction.FETCH_EXISTING_PROJECT_FOLDERS)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'projects/getAllExistingProjectFolders';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(fetchAllExistingProjectsFulfilled)
        .catch(fetchAllExistingProjectsRejected);
    });
};

export {
  fetchProjectsEpic,
  fetchProjectEpic,
  fetchProjectAdditionalContentCollectionsEpic,
  importProjectEpic,
  editProjectEpic,
  deleteProjectEpic,
  validateProjectEpic,
  importUpdateEpic,
  validateUpdateEpic,
  addTranslationEpic,
  importTranslationEpic,
  importPDFTranslationEpic,
  validateTranslationEpic,
  validatePDFTranslationEpic,
  editTranslationEpic,
  deleteTranslationEpic,
  fetchAvailableExportsEpic,
  exportTranslationEpic,
  fetchTranslationEpic,
  linkProjectToUserEpic,
  linkTranslationToUserEpic,
  linkProjectToExportTypeEpic,
  fetchTranslationStatisticsEpic,
  setForceApprovalEpic,
  fetchConfigEpic,
  fetchAllExistingProjectsEpic
};
