import { GlobalSnackbarAction } from '../utils/ActionTypes';

const addToast = (text, action, autohide = true) => {
  return {
    type: GlobalSnackbarAction.ADD_TOAST,
    toast: { text, action, autohide }
  };
};

const removeToast = () => {
  return {
    type: GlobalSnackbarAction.REMOVE_TOAST
  };
};

export { addToast, removeToast };
