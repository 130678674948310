import { changeDatetimeToDate } from '../utils/HelperFunctions';
import { aclClient } from '../utils/Permissions';
import {
  defaultCreateFilterFunction,
  defaultCreateSearchFunction,
  defaultCreateSortFunction,
  defaultPrepareFiltersFunction
} from './defaultFunctions';

/**
 * Clients Model
 * @type {*[]}
 */
const model = {
  columns: [
    {
      id: 'name',
      // name: 'Client',
      translation_id: 'client',
      component: 'clientName'
    },
    {
      id: 'description',
      // name: 'Description',
      translation_id: 'description',
      component: 'clientDescription',
      sortable: 'false'
    },
    {
      id: 'active',
      // name: 'Active',
      translation_id: 'active',
      permissions: [aclClient.activate]
    },
    {
      id: 'created_at',
      // name: 'Created',
      translation_id: 'created',
      format: changeDatetimeToDate,
      time: true
    },
    {
      id: 'updated_at',
      // name: 'Updated',
      translation_id: 'updated',
      format: changeDatetimeToDate,
      time: true
    },
    {
      id: 'approved',
      // name: 'Actions',
      translation_id: 'approved',
      textAlign: 'right',
      permissions: [aclClient.delete, aclClient.update],
      sortable: 'false'
    }
  ],

  filters: [
    {
      // label: 'Name',
      translation_id: 'name',
      primaryText: 'Name',
      id: 'name'
    }
  ],

  prepareFilters: defaultPrepareFiltersFunction,
  createFilterFunction: defaultCreateFilterFunction,
  createSearchFunction: defaultCreateSearchFunction,
  createSortFunction: defaultCreateSortFunction
};

export default model;
