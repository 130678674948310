import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import LayoutContainer from '../layout/LayoutContainer';

import { ContactsModel } from '../../tableModels';
import FilterSearchPanel from '../general/FilterSearchPanel';
import CustomTable from '../general/CustomTable';
import LoadingBar from '../general/DumbComponent';

import DeleteContactWarning from './dialog/DeleteContactWarning';

import { ProcessStatus, Routes } from '../../utils/Constants';
import { arrayHelper } from '../../utils/HelperFunctions';
import ContentDrawer from '../../utils/ContentDrawer';

import AddContact from './addcontact/AddContact';
import EditContact from './editcontact/EditContact';

import { fetchContacts, deleteContact } from '../../actions/contacts';
import { showWarning } from '../../actions/globalDialog';
import { hasPermission } from '../../utils/AuthHelper';
import { aclContact } from '../../utils/Permissions';

@withTranslation(['contacts', 'general'], { wait: true })
class ListContacts extends Component {
  state = {
    searchTerm: '',
    filters: [],
    focusOnTable: false
  };

  UNSAFE_componentWillMount() {
    const { contacts } = this.props;

    if (contacts.size === 0) {
      this.props.fetchContacts();
    }
  }

  handleFilter = (type, value) => {
    const newFilters = arrayHelper(this.state.filters, type, value);
    this.setState({ filters: newFilters });
  };

  handleSearchInput = (value) => {
    this.setState({ searchTerm: value });
  };

  handleSearchReset = () => {
    this.setState({ searchTerm: '' });
  };

  hitEnterToFocusOnTable = () => {
    this.setState({ focusOnTable: true });
  };

  closeDrawer = () => {
    this.props.history.push('/contacts');
  };

  handleCellClick = (row, cell, idxSortedData, idxSequence, custom) => {
    if (custom && custom.name) {
      switch (custom.name) {
        case 'edit':
          this.handleEdit(row.id);
          break;
        case 'delete':
          this.showDeleteWarning(row);
          break;
        default:
          break;
      }
    }
  };

  handleEdit = (id) => {
    this.props.history.push('contacts/' + id + '/edit');
  };

  showDeleteWarning = (contact) => {
    const { showWarning, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let title = t(`${generalLNS}:warning`);
    let text =
      t('question_delete_contact') + ' ' + contact.project_leader_name + ' ?';

    let component = <DeleteContactWarning contactId={contact.id} />;

    showWarning({
      title: title,
      text: text,
      actions: [],
      component: component,
      isCancellable: false
    });
  };

  renderFiltersAndTable = () => {
    const {
      contacts,
      match: {
        params: { contactId }
      }
    } = this.props;

    const filters = ContactsModel.prepareFilters.call(
      ContactsModel, // focus
      ContactsModel.filters,
      ['company_name'],
      contacts
    );

    let route = hasPermission(aclContact.create) ? Routes.CONTACTS.ADD : null;

    return (
      <React.Fragment>
        <ContentDrawer
          key='1'
          children={<AddContact closeDrawer={this.closeDrawer} />}
          visible={this.props.match.path === Routes.CONTACTS.ADD}
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='2'
          children={<EditContact contactId={contactId} />}
          visible={this.props.match.path === Routes.CONTACTS.EDIT}
          closeDrawer={this.closeDrawer}
        />
        <FilterSearchPanel
          filters={filters}
          appliedFilters={this.state.filters}
          handleFilter={this.handleFilter}
          searchBox={true}
          searchTerm={this.state.searchTerm}
          handleSearchInput={this.handleSearchInput}
          handleSearchReset={this.handleSearchReset}
          hitEnterToFocus={this.hitEnterToFocusOnTable}
          routeToAddNewItem={route}
        />
        <CustomTable
          model={ContactsModel}
          rows={contacts.toArray()}
          searchTerm={this.state.searchTerm}
          appliedFilters={this.state.filters}
          focus={this.state.focusOnTable}
          onCellClick={this.handleCellClick}
          onRowClick={this.handleEdit}
        />
      </React.Fragment>
    );
  };

  render() {
    const { contacts, fetchingContacts, t } = this.props;

    if (fetchingContacts == ProcessStatus.STARTED) {
      return (
        <LayoutContainer>
          {/* <MangooluLoading show={true} />*/}
          <LoadingBar show={true} />
        </LayoutContainer>
      );
    }
    if (contacts == undefined || contacts.size <= 0) {
      return <div>{t('no_contacts_found')}</div>;
    }

    return <LayoutContainer>{this.renderFiltersAndTable()}</LayoutContainer>;
  }
}

function mapStateToProps(state) {
  return {
    fetchingContacts: state.getIn(['contacts', 'fetchingContacts']),
    contacts: state.getIn(['contacts', 'contacts'])
  };
}

export default connect(mapStateToProps, {
  fetchContacts,
  deleteContact,
  showWarning
})(ListContacts);
