import { StatusAction } from '../utils/ActionTypes';

const fetchStatus = () => {
  return {
    type: StatusAction.FETCH_STATUS,
    data: {}
  };
};

const receiveStatus = (data) => {
  return {
    type: StatusAction.FETCH_STATUS_FULFILLED,
    status: data,
    receivedAt: Date.now()
  };
};

export { fetchStatus, receiveStatus };
