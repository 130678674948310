import * as Rx from 'rxjs';
import { AdditionalContentsAction } from '../utils/ActionTypes';

const fetchAdditionalContents = (projectId, translationId) => {
  return {
    type: AdditionalContentsAction.FETCH_ADDITIONAL_CONTENTS,
    projectId: projectId,
    translationId: translationId
  };
};

const fetchAdditionalContentsFulfilled = (data) => {
  return {
    type: AdditionalContentsAction.FETCH_ADDITIONAL_CONTENTS_FULFILLED,
    data: data
  };
};

const fetchAdditionalContentsRejected = (error) => {
  return Rx.Observable.of({
    type: AdditionalContentsAction.FETCH_ADDITIONAL_CONTENTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const addAdditionalContents = (data) => {
  return {
    type: AdditionalContentsAction.ADD_ADDITIONAL_CONTENTS,
    project: data
  };
};

const addAdditionalContentsFulfilled = (data) => {
  return {
    type: AdditionalContentsAction.ADD_ADDITIONAL_CONTENTS_FULFILLED,
    data: data
  };
};

const addAdditionalContentsRejected = (error) => {
  return Rx.Observable.of({
    type: AdditionalContentsAction.ADD_ADDITIONAL_CONTENTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

// const fetchPdfFiles = (projectId, translationId) => {
const fetchPdfFiles = (projectId) => {
  return {
    type: AdditionalContentsAction.FETCH_PDF_FILES,
    projectId: projectId
    // ,
    // translationId: translationId
  };
};

const fetchPdfFilesFulfilled = (data) => {
  return {
    type: AdditionalContentsAction.FETCH_PDF_FILES_FULFILLED,
    data: data
  };
};

const fetchPdfFilesRejected = (error) => {
  return Rx.Observable.of({
    type: AdditionalContentsAction.FETCH_PDF_FILES_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const fetchVoFiles = (projectId) => {
  return {
    type: AdditionalContentsAction.FETCH_VO_FILES,
    projectId: projectId
  };
};

const fetchVoFilesFulfilled = (data) => {
  return {
    type: AdditionalContentsAction.FETCH_VO_FILES_FULFILLED,
    data: data
  };
};

const fetchVoFilesRejected = (error) => {
  return Rx.Observable.of({
    type: AdditionalContentsAction.FETCH_VO_FILES_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const updateAdditionalContents = (
  projectId,
  translationId,
  contentId,
  data
) => {
  return {
    type: AdditionalContentsAction.UPDATE_ADDITIONAL_CONTENTS,
    projectId: projectId,
    translationId: translationId,
    contentId: contentId,
    data: data
  };
};

const updateAdditionalContentsFulfilled = (data) => {
  return {
    type: AdditionalContentsAction.UPDATE_ADDITIONAL_CONTENTS_FULFILLED,
    data: data
  };
};

const updateAdditionalContentsRejected = (error) => {
  return Rx.Observable.of({
    type: AdditionalContentsAction.UPDATE_ADDITIONAL_CONTENTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  fetchAdditionalContents,
  fetchAdditionalContentsFulfilled,
  fetchAdditionalContentsRejected,
  fetchPdfFiles,
  fetchPdfFilesFulfilled,
  fetchPdfFilesRejected,
  fetchVoFiles,
  fetchVoFilesFulfilled,
  fetchVoFilesRejected,
  addAdditionalContents,
  addAdditionalContentsRejected,
  addAdditionalContentsFulfilled,
  updateAdditionalContents,
  updateAdditionalContentsFulfilled,
  updateAdditionalContentsRejected
};
