import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontIcon } from 'react-md';
import { withTranslation } from 'react-i18next';
import { performScrollToUnsavedText } from '../../utils/ScrollManager';
import { setScrollTo } from '../../actions/fieldLevel';

@withTranslation('translation_navigation', { wait: true })
class UnsavedTextFlag extends Component {
  state = {
    isScrolling: false,
    unsavedTextsScrollIndex: 0
  };

  UNSAFE_componentWillMount() {
    window.onbeforeunload = this.onBeforeUnloadPopup;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("UnsavedTextFlag: UNSAFE_componentWillReceiveProps, nextProps", nextProps);
  }

  onBeforeUnloadPopup = () => {
    const { unsavedTexts } = this.props;

    if (unsavedTexts.size !== 0) {
      return 'Es gibt noch ungespeicherte Änderungen - wollen Sie das Fenster wirklich schließen?';
    }
  };

  scrollToUnsavedText = () => {
    const { unsavedTexts } = this.props;
    const { unsavedTextsScrollIndex } = this.state;
    if (!this.state.isScrolling) {
      let index;
      let nextIndex;
      if (unsavedTextsScrollIndex >= unsavedTexts.size) {
        index = 0;
        nextIndex = 1;
      } else {
        index = unsavedTextsScrollIndex;
        if (unsavedTextsScrollIndex === unsavedTexts.size - 1) {
          nextIndex = 0;
        } else {
          nextIndex = unsavedTextsScrollIndex + 1;
        }
      }

      let unsavedText = unsavedTexts.get(index);
      performScrollToUnsavedText(unsavedText);

      if (unsavedText) {
        this.props.setScrollTo({
          collection: unsavedText.collection,
          textfield_id: unsavedText.id
        });
      }

      this.setState({ unsavedTextsScrollIndex: nextIndex });
    }
  };

  render() {
    const { unsavedTexts } = this.props;

    return (
      <div
        title={
          unsavedTexts.size +
          ' unsaved text-fields\nclick here to scroll to unsaved text-fields'
        }
        className={
          unsavedTexts.size > 0 ? 'unsavedTextIcon show' : 'unsavedTextIcon'
        }
        onClick={this.scrollToUnsavedText}
      >
        <span>{unsavedTexts.size}</span>
        <FontIcon>flag</FontIcon>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    unsavedTexts: state.getIn(['fieldLevel', 'unsavedTexts'])
  };
}

export default connect(mapStateToProps, { setScrollTo })(UnsavedTextFlag);
