import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';

import { ProcessStatus } from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';

import SnapshotPrime from './SnapshotPrime';

import { editSnapshot } from '../../../actions/snapshots';

import styles from '../../../styles/project_detail.scss';

//@withTranslation(['snapshots', 'general'], {wait: true})
@withTranslation(['general'], { wait: true })
class EditSnapshot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      snapshot: null
    };
  }

  UNSAFE_componentWillMount() {
    const { snapshots, snapshotId } = this.props;

    if (snapshots.size >= 0) {
      this.setState({
        snapshot: snapshots.find((snapshot) => snapshot.id == snapshotId)
      });
    }
  }

  handleSubmit = (snapshot) => {
    const { projectId, translationId } = this.props;

    this.props.editSnapshot(projectId, translationId, snapshot);
    this.switchEditMode();
  };

  switchEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    const { editingSnapshot, t } = this.props;
    const { editMode, snapshot } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let initialValues = snapshot;

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.formInTabWrapper}>
            <div className={styles.formContentWrapper}>
              {false && editingSnapshot == ProcessStatus.STARTED && (
                <LoadingBar
                  show='true'
                  wrapperClassname={styles.loadingBarWrapper}
                  message={t(`${generalLNS}:updating_snapshot`)}
                />
              )}
              <div
                className={
                  styles.buttonsRightWrapper +
                  ' ' +
                  styles.buttonsRightWrapperInTab
                }
              >
                <Button
                  title={t(`${generalLNS}:edit_mode`)}
                  className={styles.editButton + ' ' + styles.editButtonFilled}
                  style={{ display: !editMode ? 'block' : 'none' }}
                  onClick={this.switchEditMode}
                  icon
                  primary
                  swapTheming
                >
                  mode_edit
                </Button>

                <Button
                  children={t(`${generalLNS}:save_all_edits`)}
                  type='submit'
                  style={{ display: editMode ? 'block' : 'none' }}
                  className={styles.formSubmitButton}
                  disabled={editingSnapshot == ProcessStatus.STARTED}
                  flat
                  primary
                  swapTheming
                />

                <Button
                  children={t(`${generalLNS}:cancel`)}
                  type='button'
                  style={{ display: editMode ? 'block' : 'none' }}
                  className={styles.formCancelButton}
                  onClick={this.switchEditMode}
                  disabled={editingSnapshot == ProcessStatus.STARTED}
                  flat
                  primary
                />
              </div>
              <div className={styles.flexHorizontalWrapper}>
                <FormSpy
                  subscription={{ values: true }}
                  editMode={editMode}
                  component={SnapshotPrime}
                />
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    snapshots: state.getIn(['snapshots', 'snapshots']),
    editingSnapshot: state.getIn(['snapshots', 'editingSnapshot'])
  };
}

export default connect(mapStateToProps, { editSnapshot })(EditSnapshot);
