import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import Gallery from 'react-fine-uploader';
import store from '../../utils/store';
import { baseApiUrl, getHttpAuthHeaders } from '../../utils/AuthHelper';

// ...or load this specific CSS file using a <link> tag in your document
import stylesAdditional from '../../styles/uploader.scss';
import Portal from '../translationTool/draftEditorAdditional/components/Portal';

@withTranslation('general', { wait: true })
class UploadComponent extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.uploader = new FineUploaderTraditional({
      options: {
        multiple: false,
        maxConnections: 1,
        debug: false,
        callbacks: {
          onError: function (id, name, errorReason, xhrOrXdr) {
            console.log(id, name, errorReason, xhrOrXdr);
            alert(
              t('error_on_file_number') +
                ' ' +
                id +
                ' - ' +
                name +
                '. ' +
                t('reason') +
                ': ' +
                errorReason
            );
          },
          onDelete: function (id) {
            console.log('UploadComponent,onDelete', id);
          },
          onDeleteComplete: function (id) {
            console.log('UploadComponent::onDeleteComplete', id);
          },
          onComplete: function (id, name, responseJSON, xhr) {
            console.log(
              'UploadComponent:: onComplete',
              id,
              name,
              responseJSON,
              xhr
            );
          },
          onStatusChange: function (id, oldStatus, newStatus) {
            // TODO: Eigene Fehlermeldung, wenn neuer Status "rejected" ist (z.B. wenn ein zweiter Upload hochgeladen werden soll)
            console.log(
              'UploadComponent, onStatusChange::',
              id,
              oldStatus,
              newStatus
            );
          }
        },
        chunking: {
          enabled: true,
          concurrent: {
            enabled: false
          },
          success: {
            endpoint: baseApiUrl + 'fineUpload/done'
          }
        },
        deleteFile: {
          enabled: true,
          endpoint: baseApiUrl + 'fineUpload'
        },
        request: {
          endpoint: baseApiUrl + 'fineUpload',
          customHeaders: getHttpAuthHeaders(store, baseApiUrl + 'fineUpload')
        },
        validation: {
          allowedExtensions: [
            'jpeg',
            'jpg',
            'gif',
            'png',
            'zip',
            'xml',
            'json'
          ],
          sizeLimit: 1500000000 // 1500 MiB
        },
        retry: {
          enableAuto: true
        }
      }
    });

    this.state = {
      preventClosing: false
    };
  }

  UNSAFE_componentWillMount() {
    this.uploader.on('complete', this.onCustomComplete);
    this.uploader.on('statusChange', this.onCustomStatusChange);
    this.uploader.on('deleteComplete', this.onCustomDeleteComplete);
  }

  componentWillUnmount() {
    this.uploader.off('complete', this.onCustomComplete);
    this.uploader.off('statusChange', this.onCustomStatusChange);
    this.uploader.off('deleteComplete', this.onCustomDeleteComplete);
    this.uploader.methods.reset();
  }

  onCustomComplete = (id, name, responseJSON, xhr) => {
    let uuid = this.uploader.methods.getUuid(id);
    this.props.onChange({ id, uuid, name, responseJSON, xhr });
  };

  onCustomDeleteComplete = (id) => {
    this.props.onChange(null);
    let uuid = this.uploader.methods.getUuid(id);

    if (this.props.onDelete) {
      this.props.onDelete(uuid);
    }
  };

  onCustomStatusChange = (id, oldStatus, newStatus) => {
    // console.log("onCustomStatusChange:: id = ", id, ", oldStatus = ", oldStatus, ", newStatus = ", newStatus);

    switch (newStatus) {
      case 'submitted':
        // onUploadStarted();
        this.turnOnPreventClosing();
        break;

      case 'upload failed':
      case 'upload successful':
      case 'canceled':
      case 'rejected':
      case 'deleted':
        // onUploadEnded();
        this.turnOffPreventClosing();
        break;

      default:
      // console.log(newStatus, 'component still disabled');
    }
  };

  turnOnPreventClosing = () => {
    this.setState({
      preventClosing: true
    });
  };

  turnOffPreventClosing = () => {
    this.setState({
      preventClosing: false
    });
  };

  render() {
    const { t } = this.props;
    const { preventClosing } = this.state;
    const fileInputChildren = <span>{t('choose_files')}</span>;
    const statusTextOverride = {
      upload_successful: t('success') + '!'
    };
    return (
      <div className={stylesAdditional.uploadGallery}>
        <Gallery
          fileInput-children={fileInputChildren}
          status-text={{ text: statusTextOverride }}
          uploader={this.uploader}
        />
        {preventClosing && (
          <Portal>
            <div
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                zIndex: '19', // HINTER DRAWER!!
                backgroundColor: 'transparent'
              }}
            />
            <div
              style={{
                position: 'fixed',
                top: '0',
                right: '0',
                width: '100%',
                height: '65px',
                zIndex: '21', // vor DRAWER, über closebutton!!
                backgroundColor: 'transparent'
              }}
            />
          </Portal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // project: state.getIn(['projects','selectedProject']),
    store: state
  };
}

export default connect(mapStateToProps, {})(UploadComponent);
