import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { Avatar, MenuButton, ListItem, FontIcon } from 'react-md';
import { ProcessStatus, RoleAttributes } from '../../../utils/Constants';
import styles from '../../../styles/chip.scss';

class RoleAvatarChip extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      roles: List()
    };
  }

  UNSAFE_componentWillMount() {
    const { roles } = this.props;

    if (roles.size > 0) {
      this.formatRoles(roles);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { roles, fetchingRoles } = nextProps;

    if (
      fetchingRoles == ProcessStatus.FINISHED &&
      fetchingRoles != this.props.fetchingRoles
    ) {
      this.formatRoles(roles);
    }
  }

  formatRoles = (roles) => {
    const formattedRoles = roles.map((role) => {
      return (
        <ListItem
          key={role.id}
          leftAvatar={this.createAvatar(role.id)}
          primaryText={role.display_name}
          secondaryText={role.name}
          onClick={() => this.handleRoleClick(role)}
        />
      );
    });

    this.setState({ roles: formattedRoles });
  };

  handleRoleClick = (role) => {
    this.props.onUpdate(
      Map({ id: this.props.item.get('id'), roleId: role.id })
    );
  };

  handleChipClick = () => {
    this.props.onClick(this.props);
  };

  createAvatar = (roleId) => {
    let roleAttribute = RoleAttributes.find(
      (roleAttribute) => roleAttribute.id === parseInt(roleId)
    );
    if (roleAttribute == null) {
      roleAttribute = RoleAttributes.find(
        (roleAttribute) => roleAttribute.className === 'viewer'
      );
    }

    const className = [styles.role, styles[roleAttribute.className]].join(' ');

    return <Avatar className={className}>{roleAttribute.short}</Avatar>;
  };

  render() {
    const { item, removable, editable } = this.props;

    let localRole = item.get('localrole');
    let globalRole = item.get('globalrole'); // TODO: NOCH DIE ROLLEN ÄNDERN
    let currentRole = localRole != null ? localRole : globalRole;

    let chipClassName = 'mangooluChip ';
    chipClassName += item.get('unconfirmed')
      ? `${styles.unconfirmed} ${styles.chip}`
      : `${styles.chip}`;

    if (this.state.roles.size == 0) {
      return null;
    }

    let roleAttribute = RoleAttributes.find(
      (roleAttribute) => roleAttribute.id === parseInt(currentRole)
    );
    if (roleAttribute == null) {
      roleAttribute = RoleAttributes.find(
        (roleAttribute) => roleAttribute.className === 'viewer'
      );
    }
    let title = item.get('name') + '\nROLE: ' + roleAttribute.name;

    const ChipReference = (
      <span className={chipClassName}>
        <span className={'roleWrapper'}>
          <span className={'roleItem'}>{roleAttribute.short}</span>
          <span className={'roleItemFull'}>{roleAttribute.name}</span>
        </span>
        <span title={title} className={'text'}>
          {item.get('name')}
        </span>
        {removable && (
          <span className={'close'} onClick={this.handleChipClick}>
            <FontIcon>close</FontIcon>
          </span>
        )}
      </span>
    );

    if (removable || !editable) {
      return ChipReference;
    }

    return (
      <MenuButton
        flat
        id={item.get('name') + '_' + roleAttribute.name}
        className={styles.chipDropdown}
        menuItems={this.state.roles.toArray()}
        anchor={{ x: 'center', y: 'bottom' }}
        position={'tl'}
        animationposition={'below'}
        title={title}
      >
        {ChipReference}
      </MenuButton>
    );
  }
}

function mapStateToProps(state) {
  return {
    roles: state.getIn(['roles', 'roles']),
    fetchingRoles: state.getIn(['roles', 'fetchingRoles'])
  };
}

export default connect(mapStateToProps, {})(RoleAvatarChip);
