import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field } from 'react-final-form';

import { renderTextField } from '../../general/BasicFormComponents';

import styles from '../../../styles/project_detail.scss';
import { withTranslation } from 'react-i18next';
import RolePermissions from './RolePermissions';

@withTranslation(['roles', 'general' /* , 'types' */], { wait: true })
class RolePrime extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { editMode, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={'column'} editmode={editMode ? 'true' : 'false'}>
        <div className={styles.header}>
          <h2 style={{ textTransform: 'uppercase' }} className={'headName'}>
            {t(`${generalLNS}:permissions`)}
          </h2>
        </div>
        <div className={'editableElements'}>
          <Field
            id='display_name'
            name='display_name'
            label={t(`${generalLNS}:name`)}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
        </div>
        <div className={'notEditableElements'}>
          <RolePermissions roleId={this.props.match.params.roleId} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.getIn(['projects', 'selectedProject'])
  };
}

export default withRouter(connect(mapStateToProps, {})(RolePrime));
