import { ajax } from 'rxjs/observable/dom/ajax';
import { ProjectsAction, WbtViewerAction } from '../utils/ActionTypes';
import {
  fetchAllTextsFulfilled,
  fetchAllTextsRejected,
  fetchAllTextsGroupedByCollectionFulfilled,
  fetchAllTextsGroupedByCollectionRejected,
  fetchSourceFulfilled,
  fetchSourceRejected,
  fetchAssetsFulfilled,
  fetchAssetsRejected
} from '../actions/wbtViewer';
import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';
import {
  importTranslationFulfilled,
  importTranslationRejected
} from '../actions/projects';

const fetchAllTextsEpic = (action$, store) => {
  return action$
    .ofType(WbtViewerAction.FETCH_ALL_TEXTS)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'texts/external/' +
        action.sourceTranslationId +
        '/' +
        action.targetTranslationId +
        '/all/false';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .get(url, headers)
        .map((ajaxResponse) => ajaxResponse.response)
        .map(fetchAllTextsFulfilled)
        .catch(fetchAllTextsRejected);
    });
};

const fetchAllTextsGroupedByCollectionEpic = (action$, store) => {
  return action$
    .ofType(WbtViewerAction.FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'texts/external/' +
        action.sourceTranslationId +
        '/' +
        action.targetTranslationId +
        '/all/true';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .get(url, headers)
        .map((ajaxResponse) => ajaxResponse.response)
        .map(fetchAllTextsGroupedByCollectionFulfilled)
        .catch(fetchAllTextsGroupedByCollectionRejected);
    });
};

const fetchSourceEpic = (action$, store) => {
  return action$
    .ofType(WbtViewerAction.FETCH_SOURCE)
    .debounceTime(600) // TODO: 500 debounceTime wird rejected, mit Carsten schauen
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.sourceId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(fetchSourceFulfilled)
        .catch(fetchSourceRejected);
    });
};

/*
const importTranslationEpic = (action$, store) => {
  return action$.ofType(ProjectsAction.IMPORT_TRANSLATION)
    .debounceTime(500)
    .mergeMap((action) => {
      let translation = action.translation.toObject();
      let url = baseApiUrl + 'projects/' + translation.project_id + '/translations/import';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers, body: translation})
        .map(res => res)
        .map(importTranslationFulfilled)
        .catch(importTranslationRejected);
    });
};
*/

const fetchAssetsEpic = (action$, store) => {
  console.log('fetchAssetsEpic', action$);
  return action$
    .ofType(WbtViewerAction.FETCH_ASSETS)
    .debounceTime(600) // TODO: 500 debounceTime wird rejected, mit Carsten schauen
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.projectId + '/assets';
      let body = {
        projectId: action.projectId,
        assetsDir: action.wbtLink,
        siteId: action.siteId
      };
      console.log('url', url);
      console.log('body', body);
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers, body: body })
        .map((res) => res.response.data)
        .map(fetchAssetsFulfilled)
        .catch(fetchAssetsRejected);
    });
};

export {
  fetchAllTextsEpic,
  fetchAllTextsGroupedByCollectionEpic,
  fetchSourceEpic,
  fetchAssetsEpic
};
