/* global ENV */
/* eslint-disable no-console */

export const log = (...messages) => {
  if (!ENV.DEBUG) return;
  console.log(...messages);
};

export const warn = (...messages) => {
  if (!ENV.DEBUG) return;
  console.warn(...messages);
};

export const error = (...messages) => {
  if (!ENV.DEBUG) return;
  console.error(...messages);
};
