import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FontIcon } from 'react-md';
// import LoginParticles from './LoginParticles';
import LoginScreenPart from './LoginScreen';
import { withTranslation } from 'react-i18next';
import ImprintText from '../general/texts/ImprintText';
import PrivacyText from '../general/texts/PrivacyText';
import styles from '../../styles/login.scss';
import { packageVersion } from '../../utils/AuthHelper';

@withTranslation(['login', 'general'], { wait: true })
class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      modalCase: -1
    };

    console.warn('MANGOOLU v' + packageVersion);
  }

  showImprint = () => {
    this.setState({ openModal: true, modalCase: 0 });
  };

  showPrivacy = () => {
    this.setState({ openModal: true, modalCase: 1 });
  };

  hide = () => {
    this.setState({ openModal: false });
    setTimeout(() => {
      this.setState({ modalCase: -1 });
    }, 300);
  };

  render() {
    const { openModal, modalCase } = this.state;
    const { t } = this.props;

    const backgroundImage = {
      backgroundImage:
        'url(' + require('../../images/login-bg-without-points.jpg') + ')'
      // backgroundImage: 'url(' + require('../../images/login-bg.jpg') + ')'
    };

    return (
      <div className={styles.loginContainer} style={backgroundImage}>
        {/* {loggingIn == ProcessStatus.INITIAL && <LoginParticles />}*/}
        <LoginScreenPart />
        <div className={'version'}>v{packageVersion}</div>
        <div className='btnRow'>
          <Button flat={true} secondary onClick={this.showImprint}>
            {t('imprint_btn')}
          </Button>
          <Button flat={true} secondary onClick={this.showPrivacy}>
            {t('privacy_btn')}
          </Button>
        </div>
        <div
          className={openModal ? 'imprintContainer open' : 'imprintContainer'}
        >
          <div className='overlay' onClick={this.hide}></div>
          <div className='inner'>
            <div className='innerInner'>
              {modalCase === 0 && <ImprintText />}
              {modalCase === 1 && <PrivacyText />}
            </div>
            <button className={'closeModal'} onClick={this.hide}>
              <FontIcon>close</FontIcon>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggingIn: state.getIn(['auth', 'loggingIn'])
  };
}

export default connect(mapStateToProps, null)(LoginContainer);
