import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { DialogContainer } from 'react-md';
import { withTranslation } from 'react-i18next';

import { removeDialog } from '../../../actions/globalDialog';

import dialogStyles from '../../../styles/dialog.scss';

@withTranslation('general', { wait: true })
class GlobalDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dialog: null,
      visible: false,
      title: '',
      text: '',
      component: null,
      actions: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    let dialog = nextProps.dialogs.get(0);
    if (
      dialog &&
      dialog.message &&
      dialog.message.text &&
      dialog.message.actions
    ) {
      let actions = dialog.message.actions;

      if (dialog.isCancellable) {
        actions = actions.unshift({
          id: 'dialog-cancel',
          secondary: true,
          children: t('cancel'),
          onClick: this.closeDialog
        });
      }

      this.setState({
        dialog: dialog,
        visible: true,
        title: dialog.title,
        text: dialog.message.text,
        component: dialog.message.component,
        actions: actions
      });
    } else {
      this.setState({
        dialog: null,
        visible: false,
        title: '',
        text: '',
        component: null,
        actions: []
      });
    }
  }

  closeDialog = () => {
    this.props.removeDialog(this.state.dialog.identifier);
  };

  render() {
    const { dialog, visible, title, text, component, actions } = this.state;

    if (dialog == null) {
      return null;
    }

    return (
      <DialogContainer
        id='import-translation-error'
        className={dialogStyles.dialogContainer}
        visible={visible}
        title={title}
        onHide={this.closeDialog}
        aria-describedby='speed-boost-description'
        modal
        disableScrollLocking={true}
        actions={actions}
      >
        <p
          id='import-translation-error-description'
          className='md-color--secondary-text'
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
        {component}
      </DialogContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(mapStateToProps, { removeDialog })(GlobalDialog);
