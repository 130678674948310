import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { renderTextField } from '../../general/BasicFormComponents';
import styles from '../../../styles/project_detail.scss';
import { withTranslation } from 'react-i18next';

@withTranslation(['permissions', 'general' /* , 'types' */], { wait: true })
class PermissionPrime extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    // this.update(this.props);
  }

  // UNSAFE_componentWillReceiveProps (nextProps) {
  // this.update(nextProps);
  // }

  render() {
    const { editMode, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const typesLNS = 'types'; // typesLanguageNamespaceSource

    // let localeTypes = types.map(value => ({label: t(`${typesLNS}:${value}`), value:value}));

    return (
      <div className={'column'} editmode={editMode ? 'true' : 'false'}>
        <div className={styles.header}>
          <h2
            className={'headName'}
            style={{
              textTransform: 'uppercase'
            }}
          >
            {t(`${generalLNS}:permissions`)}
          </h2>
        </div>
        <div className={'editableElements'}>
          <Field
            id='display_name'
            name='display_name'
            label={t(`${generalLNS}:name`)}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.getIn(['projects', 'selectedProject'])
  };
}

export default connect(mapStateToProps, {})(PermissionPrime);
