import React from 'react';
import { parseCode } from '../../utils/RTEParser';
import { withTranslation } from 'react-i18next';
import styles from '../../styles/translationTool/rawEditor.scss';

@withTranslation(['translation_navigation', 'general'], { wait: true })
class RawEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ''
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ code: this.props.rawCode });
  }

  onChange = (e) => {
    this.setState({ code: e.target.value });
  };

  onSaveAndCloseRaw = () => {
    const { code } = this.state;
    this.props.handleContentChange(parseCode(code));
    setTimeout(() => {
      this.props.saveTranslation();
    }, 100);
  };

  render = () => {
    const { t, rawCode } = this.props;
    const { code } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const rawEditorSaveDisabled = rawCode === code;
    return (
      <div className={styles.raweditor}>
        <textarea
          spellCheck={false}
          rows='5'
          defaultValue={rawCode}
          onChange={this.onChange}
        />
        <div className={'buttonBar'}>
          <button
            onClick={this.onSaveAndCloseRaw}
            disabled={rawEditorSaveDisabled}
          >
            {t(`${generalLNS}:save`)} & {t(`${generalLNS}:close`)}
          </button>
          <button onClick={this.props.hideRawEditor}>
            {t(`${generalLNS}:close`)}
          </button>
        </div>
      </div>
    );
  };
}

export default RawEditor;
