import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

@withTranslation('general', { wait: true })
class PortalWindow extends Component {
  constructor(props) {
    super(props);
    this.containerEl = document.createElement('div');
    this.externalWindow = null;
    this.checkIfOpenInterval = null;
  }

  UNSAFE_componentWillMount() {
    const { t } = this.props;
    let left = (screen.width - 1024) / 2;
    let top = (screen.height - 720) / 2;
    let strWindowFeatures =
      'menubar=no,titlebar=no,toolbar=no,directories=no,location=no,personalbar=no,resizable,top=' +
      top +
      ',left=' +
      left +
      ',width=1024,height=720';

    this.externalWindow = window.open(
      '/projects/translationtool/' + this.props.translationViewId,
      '',
      strWindowFeatures
    );

    let isPopupBlockerActive = this.checkForPopupBlocker();

    if (isPopupBlockerActive) {
      // TODO: SHOW WARNING "POPUP BLOCKER"
      window.alert(t('popup_blocker_warning'));
    } else {
      this.checkIfOpenInterval = setInterval(this.checkIfOpen, 500);
    }
  }

  checkForPopupBlocker = () => {
    if (
      !this.externalWindow ||
      this.externalWindow.closed ||
      typeof this.externalWindow.closed === 'undefined'
    ) {
      return true;
    }
    return false;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.initiateSafeClosing &&
      nextProps.initiateSafeClosing !== this.props.initiateSafeClosing
    ) {
      this.props.resetSafeClosingInitiation();
      this.externalWindow.close();
    }
  }

  componentWillUnmount() {
    this.externalWindow.close();
  }

  checkIfOpen = () => {
    if (this.externalWindow.closed) {
      this.props.onWindowClosed();
      clearInterval(this.checkIfOpenInterval);
    }
  };

  render() {
    let isPopupBlockerActive = this.checkForPopupBlocker();
    if (isPopupBlockerActive) {
      return null;
    }

    let child = React.cloneElement(this.props.children, {
      parentwindow: this.externalWindow.document.body
    });
    return ReactDOM.createPortal(child, this.containerEl);
  }
}

export default PortalWindow;
