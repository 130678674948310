import React, { Component } from 'react';
import { RichUtils } from 'draft-js';
import { withTranslation } from 'react-i18next';
import { FontIcon } from 'react-md';
import { getSelectionInlineStyle } from '../helper/helperFunctions';

@withTranslation(['translation_navigation'], { wait: true })
class SuperButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { getEditorState } = nextProps;
    const editorState = getEditorState();
    const currentInlineStyle = getSelectionInlineStyle(editorState);
    if (currentInlineStyle.hasOwnProperty('SUPERSCRIPT')) {
      this.setState({
        active: currentInlineStyle.SUPERSCRIPT
      });
    }
  }

  onClick = (e) => {
    e.preventDefault();
    const { getEditorState, setEditorState } = this.props;
    const editorState = getEditorState();
    const selectionState = editorState.getSelection();
    // const content = editorState.getCurrentContent();

    if (!selectionState.isCollapsed()) {
      setEditorState(RichUtils.toggleInlineStyle(editorState, 'SUPERSCRIPT'));
      this.setState({ active: !this.state.active });
    }
  };

  render() {
    const { active } = this.state;
    return (
      <span
        className='toolbar-button'
        onMouseDown={this.onClick}
        title={this.props.description}
        data-active={active.toString()}
      >
        <FontIcon>format_size</FontIcon>
      </span>
    );
  }
}

export default SuperButton;
