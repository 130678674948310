import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { renderTextField } from '../../general/BasicFormComponents';
import styles from '../../../styles/project_detail.scss';

@withTranslation(['contacts', 'general' /* , 'types' */], { wait: true })
class ContactPrime extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    // this.update(this.props);
  }

  UNSAFE_componentWillReceiveProps() {
    // this.update(nextProps);
  }

  /*
    update = (props) => {
        const {project, languageOptions, countryOptions, values} = props;

        if(project.translations) {
            const tlcs = project.translations.map(translation => translation.languagecode);

            const translation_languagecode = values.languagecode;

            const input_lc = values.language.label.split(" - ")[0];
            let input_cc;
            let languagecode;

            // values.country.value != 1 -> if it is not an international version
            if(values.country && values.country.label && values.country.value != 1) {
                input_cc = values.country.label.split(" - ")[0];
            }

            languageOptions.map(languageOption => {
                let option_lc = languageOption.label.split(" - ")[0];

                if (input_cc) {
                    languagecode = option_lc + "-" + input_cc;
                } else {
                    languagecode = option_lc;
                }

                //the languagecode of the current translation is still viable
                if(languagecode != translation_languagecode) {
                    languageOption.disabled = tlcs.indexOf(languagecode) >= 0;
                }
            });

            countryOptions.map(countryOption => {
                let option_cc = countryOption.label.split(" - ")[0];

                if(option_cc == "Intl'") {
                    languagecode = input_lc;
                } else {
                    languagecode = input_lc + "-" + option_cc;
                }

                //the languagecode of the current translation is still viable
                if(languagecode != translation_languagecode) {
                    countryOption.disabled = tlcs.indexOf(languagecode) >= 0;
                }
            });

        }
    };
*/

  render() {
    const { editMode, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const typesLNS = 'types'; // typesLanguageNamespaceSource

    // let localeTypes = types.map(value => ({label: t(value), value:value}));

    return (
      <div className={'column'} editmode={editMode ? 'true' : 'false'}>
        <div className={styles.header}>
          <h2 className={'headName'}>INFORMATION</h2>
        </div>
        <div className={'editableElements'}>
          <Field
            id='project_leader_name'
            name='project_leader_name'
            label={t('project_leader')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='pl_phone_number'
            name='pl_phone_number'
            label={t('phone')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='pl_email'
            name='pl_email'
            label={t(`${generalLNS}:email`)}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='company_name'
            name='company_name'
            label={t('company_name')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='cp_adress_line_1'
            name='cp_adress_line_1'
            label={t('address_line_1')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='cp_adress_line_2'
            name='cp_adress_line_2'
            label={t('address_line_2')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='cp_city'
            name='cp_city'
            label={t('city')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='cp_country_id'
            name='cp_country_id'
            label={t('country')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='cp_zip'
            name='cp_zip'
            label={t('zip')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='cp_state'
            name='cp_state'
            label={t('state')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='cp_url'
            name='cp_url'
            label={t('url')}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
        </div>
        <div className={'notEditableElements'}>
          <Field
            id='id'
            name='id'
            label={t(`${generalLNS}:id`)}
            type='text'
            disabled
            className='md-cell md-cell--12'
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.getIn(['projects', 'selectedProject'])
  };
}

export default connect(mapStateToProps, {})(ContactPrime);
