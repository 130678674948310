import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { ExpansionPanel, TextField } from 'react-md';

import CustomTable from '../../general/CustomTable';
import { UserModel } from '../../../tableModels';

import styles from '../../../styles/projects/expansionpanel/general.scss';

@withTranslation(['project', 'general'], { wait: true })
class UserPanel extends Component {
  static propTypes = {
    // Notice these three props. They are injected via the `ExpansionList` component
    // and are required to get correct styling and keyboard accessibility.
    focused: PropTypes.bool,
    overflown: PropTypes.bool,
    columnWidths: PropTypes.arrayOf(PropTypes.number)
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false
    };
  }

  handleRowClick = (currentId) => {
    this.props.users.map((user) => {
      if (user.id === currentId[0]) {
        this.props.onChange(user);
        this.handleExpandToggle(!this.state.isExpanded, user.name, [user.id]);
      }
    });
  };

  handleExpandToggle = (
    isExpanded,
    name = this.state.name,
    selected = this.state.selected
  ) => {
    this.setState({ isExpanded: isExpanded, name: name, selected: selected });
  };

  render() {
    const { users, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const batch = users.length + ' ' + t('users');

    return (
      <ExpansionPanel
        expanded={this.state.isExpanded}
        onExpandToggle={this.handleExpandToggle}
        label={t(`${generalLNS}:users`)}
        secondaryLabel={batch}
        footer={null}
        className={styles.expansionPanel}
        headerClassName={styles.expansionHeader}
        focused={this.props.focused}
        columnWidths={this.props.columnWidths}
      >
        {users && (
          <CustomTable
            selected={this.state.selected}
            rows={users}
            model={UserModel}
            onRowClick={this.handleRowClick}
          />
        )}
      </ExpansionPanel>
    );
  }
}

export default connect()(UserPanel);
