import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { Checkbox, FontIcon } from 'react-md';
import { withTranslation } from 'react-i18next';
import { ProcessStatus } from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';
import ChipAllocationOld from '../../general/ChipAllocationOld';
import NamedChip from '../../general/chip/NamedChip';
import { editTranslation } from '../../../actions/projects';
import styles from '../../../styles/project_detail.scss';
import { sortAlphabetically } from '../../../utils/ChipHelperFunctions';

@withTranslation('project', { wait: true })
class TranslationSourceLanguages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locked: true,
      globalSourceLanguages: List(),
      localSourceLanguages: List()
    };
  }

  UNSAFE_componentWillMount() {
    const { project, translation } = this.props;

    if (translation.size > 0) {
      this.mergeSourceLanguageData(project.translations, translation);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { project, translation, editingTranslation } = nextProps;
    if (
      editingTranslation == ProcessStatus.FINISHED &&
      editingTranslation != this.props.editingTranslation
    ) {
      this.mergeSourceLanguageData(project.translations, translation);
    }
  }

  mergeSourceLanguageData = (translations, translation) => {
    let localSourceLanguages = translation.get('sources');

    let localSourceOptions = List(
      this.formatSourceLanguageOptions(localSourceLanguages).sort((a, b) =>
        sortAlphabetically(a, b)
      )
    );

    let globalSourceLanguages = List(
      this.formatGlobalLanguageOptions(translations).sort((a, b) =>
        sortAlphabetically(a, b)
      )
    );
    this.setState({
      globalSourceLanguages: globalSourceLanguages,
      localSourceLanguages: localSourceOptions
    });
  };

  formatSourceLanguageOptions = (languages) => {
    return languages.map((language) => {
      return Map({ id: language.id });
    });
  };

  formatGlobalLanguageOptions = (languages) => {
    return languages
      .filter((language) => language.id !== this.props.translation.get('id'))
      .map((language) =>
        Map({
          id: language.id,
          name: language.languagecode + ' - ' + language.name
        })
      );
  };

  toggleSourceLanguageLock = (checked) => {
    this.setState({ locked: checked });
  };

  handleAdd = (sourceLanguage) => {
    const { localSourceLanguages } = this.state;
    let sourceLanguages = localSourceLanguages.push(sourceLanguage);
    this.linkSourceLanguageToTranslation(sourceLanguages);
  };

  handleRemove = (sourceLanguage) => {
    const { localSourceLanguages } = this.state;
    let sourceLanguages = localSourceLanguages.filter(
      (language) => language.get('id') != sourceLanguage.get('id')
    );
    this.linkSourceLanguageToTranslation(sourceLanguages);
  };

  linkSourceLanguageToTranslation = (sourceLanguages) => {
    const { translation } = this.props;
    let sourceLanguagesIdString = sourceLanguages
      .map((language) => language.get('id'))
      .join(',');

    this.props.editTranslation(
      translation.get('project_id'),
      translation.get('id'),
      {
        sources: sourceLanguagesIdString
      }
    );
  };

  handleChange = (data) => {
    this.setState({ localSourceLanguages: data });
  };

  render() {
    const {
      editMode,
      fetchingSourceLanguages,
      fetchingTranslation,
      editingTranslation,
      t
    } = this.props;
    const { locked, globalSourceLanguages, localSourceLanguages } = this.state;

    const defaultLabel = 'Insert source language';
    const chipLabel = locked ? defaultLabel + ' (unlock first)' : defaultLabel;

    if (fetchingSourceLanguages == ProcessStatus.STARTED) {
      return (
        <div className={styles.contentWrapper}>
          <h2>{t('source_languages')}</h2>
          <LoadingBar
            show='true'
            wrapperClassname={styles.loadingBarWrapper}
            message={t('searching_for_source_languages')}
          />
        </div>
      );
    }

    return (
      <div
        className={styles.contentWrapper + ' column'}
        editmode={editMode === 1 ? 'true' : 'false'}
      >
        <div className={styles.header}>
          <h2 style={{ textTransform: 'uppercase' }} className={'headName'}>
            {t('source_languages')}
          </h2>
          <Checkbox
            id='sourceLanguages-lock'
            name='sourceLanguages-lock'
            className={styles.lock}
            label=''
            checkedCheckboxIcon={<FontIcon>lock</FontIcon>}
            uncheckedCheckboxIcon={<FontIcon>lock_open</FontIcon>}
            checked={locked}
            onChange={this.toggleSourceLanguageLock}
          />
        </div>
        <ChipAllocationOld
          label={chipLabel}
          className={styles.chipAllocation}
          input={{ value: localSourceLanguages, onChange: this.handleChange }}
          items={globalSourceLanguages}
          chip={NamedChip}
          onAdd={this.handleAdd}
          onRemove={this.handleRemove}
          asynchron={true}
          locked={
            locked ||
            editingTranslation == ProcessStatus.STARTED ||
            fetchingTranslation == ProcessStatus.STARTED
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.getIn(['projects', 'selectedProject']),
    translation: state.getIn(['projects', 'translation']),
    fetchingTranslation: state.getIn(['projects', 'fetchingTranslation']),
    editingTranslation: state.getIn(['projects', 'editingTranslation'])
  };
}

export default connect(mapStateToProps, { editTranslation })(
  TranslationSourceLanguages
);
