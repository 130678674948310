export const map = {
  login: { 'password-reset': {}, __param: ':token' },
  dashboard: {},
  projects: { __param: ':projectId' },
  imprint: {},
  privacy: {},
  test: {},
  '*': {},
  'Routes.PROJECTS.TRANSLATIONS.ADD_SNAPSHOTATROOT': {},
  'Routes.PROJECTS.TRANSLATIONS.ADD': {},
  'Routes.PROJECTS.TRANSLATIONS.EDIT': {},
  'Routes.PROJECTS.EDIT': {},
  'Routes.PROJECTS.UPDATE': {},
  'Routes.PROJECTS.TRANSLATIONS.VIEW': {},
  'Routes.PROJECTS.IMPORT': {},
  'Routes.CLIENTS.ADD': {},
  'Routes.CLIENTS.EDIT': {},
  clients: {},
  'Routes.USERS.ADD': {},
  'Routes.USERS.EDIT': {},
  users: {},
  'Routes.PERMISSIONS.ADD': {},
  'Routes.PERMISSIONS.EDIT': {},
  permissions: {},
  'Routes.ROLES.ADD': {},
  'Routes.ROLES.EDIT': {},
  roles: {},
  'Routes.CONTACTS.ADD': {},
  'Routes.CONTACTS.EDIT': {},
  contacts: {},
  logout: {},
  _: {}
};