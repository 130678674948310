import React from 'react';
import { connect } from 'react-redux';
import { Image } from '../../../utils/Constants';

import {
  fetchImage,
  fetchFullImage,
  fetchCroppedImage,
  setCropped,
  switchMode,
  switchPersisted
} from '../../../actions/image';

import styles from '../../../styles/image_input.scss';
import ImageEdit from './ImageEdit';

class ImageInput extends React.Component {
  componentDidMount() {
    const {
      projectImages,
      project,
      fetchImage,
      fetchFullImage,
      fetchCroppedImage,
      setCropped
    } = this.props;
    const index = projectImages.findIndex(
      (elem) => elem.projectId === project.id
    );
    fetchImage({ projectId: project.id });
    fetchFullImage({ projectId: project.id });
    if (index < 0) {
      setCropped(Image.DUMMY_IMAGE);
      fetchCroppedImage({ projectId: project.id });
    } else {
      setCropped(projectImages.get(index).cropped);
    }
  }

  componentWillUnmount() {
    const { persisted, switchPersisted } = this.props;
    if (persisted) {
      switchPersisted();
    }
  }

  render = () => {
    return (
      <div className={styles.imageInputContainer}>
        <ImageEdit src={this.props.cropped} alt={this.props.alt} />
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    projectImages: state.getIn(['image', 'projectImages']),
    cropped: state.getIn(['image', 'croppedImage']),
    project: state.getIn(['projects', 'selectedProject']),
    editMode: state.getIn(['image', 'editMode']),
    persisted: state.getIn(['image', 'persisted'])
  };
}

export default connect(mapStateToProps, {
  fetchImage,
  fetchFullImage,
  fetchCroppedImage,
  setCropped,
  switchMode,
  switchPersisted
})(ImageInput);
