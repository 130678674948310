import { List, Map, fromJS } from 'immutable';

import { ProjectsAction, UsersAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';
import { generateGuid } from '../utils/HelperFunctions';

const initialState = fromJS({
  projects: List(),
  selectedProject: null,
  selectedProjectName: '',
  fetchingProjects: ProcessStatus.INITIAL,
  fetchingProject: ProcessStatus.INITIAL,
  deletingProject: ProcessStatus.INITIAL,
  collectedInfos: Map(),
  extractPath: '',
  uploadExtractPath: '',
  importingProject: ProcessStatus.INITIAL,
  importedProjectId: null,
  validatingProject: ProcessStatus.INITIAL,
  importingUpdate: ProcessStatus.INITIAL,
  validatingUpdate: ProcessStatus.INITIAL,
  editingProject: ProcessStatus.INITIAL,
  importingTranslation: ProcessStatus.INITIAL,
  importingPDFTranslation: ProcessStatus.INITIAL,
  validatingTranslation: ProcessStatus.INITIAL,
  validatingPDFTranslation: ProcessStatus.INITIAL,
  availableExports: List(),
  fetchingAvailableExports: ProcessStatus.INITIAL,
  exportingTranslation: ProcessStatus.INITIAL,
  downloadPath: null,
  additionalContentInfo: null,
  translation: Map(),
  translationCommentsCount: 0,
  fetchingTranslation: ProcessStatus.INITIAL,
  editingTranslation: ProcessStatus.INITIAL,
  deletingTranslation: ProcessStatus.INITIAL,
  linkingProjectToUser: ProcessStatus.INITIAL,
  linkingTranslationToUser: ProcessStatus.INITIAL,
  linkingProjectToExportType: ProcessStatus.INITIAL,
  translationStatistics: List([]),
  fetchingTranslationStatistics: ProcessStatus.INITIAL,
  settingForceApproval: ProcessStatus.INITIAL,
  error: null,
  updateToken: '',
  fetchingConfig: ProcessStatus.INITIAL,
  config: null,
  fetchingExistingAdditionalContent: ProcessStatus.INITIAL,
  existingAdditionalContent: null,
  fetchingExistingProjectFolders: ProcessStatus.INITIAL,
  existingProjectFolders: []
});
let index = 0;
let projects = null;
let project = null;
let translation = null;
let selectedProject = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ProjectsAction.FETCH_PROJECTS:
      return state.set('fetchingProjects', ProcessStatus.STARTED);
    case ProjectsAction.FETCH_PROJECTS_FULFILLED:
      return state
        .set('fetchingProjects', ProcessStatus.FINISHED)
        .set('projects', List(action.projects));
    case ProjectsAction.FETCH_PROJECTS_REJECTED:
      return state
        .set('fetchingProjects', ProcessStatus.FAILED)
        .set('error', action.payload);
    case ProjectsAction.FETCH_PROJECT:
      return state
        .set('fetchingProject', ProcessStatus.STARTED)
        .set('selectedProject', null)
        .set('translation', Map())
        .set('fetchingTranslation', ProcessStatus.INITIAL);
    case ProjectsAction.FETCH_PROJECT_FULFILLED:
      return state
        .set('fetchingProject', ProcessStatus.FINISHED)
        .set('selectedProject', action.project)
        .set('selectedProjectName', action.project.name);
    case ProjectsAction.FETCH_PROJECT_REJECTED:
      return state
        .set('fetchingProject', ProcessStatus.FAILED)
        .set('error', action.payload);
    case ProjectsAction.FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS:
      return state.set(
        'fetchingExistingAdditionalContent',
        ProcessStatus.STARTED
      );
    case ProjectsAction.FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS_FULFILLED:
      return state
        .set('fetchingExistingAdditionalContent', ProcessStatus.FINISHED)
        .set('existingAdditionalContent', action.existingAdditionalContent);
    case ProjectsAction.FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS_REJECTED:
      return state
        .set('fetchingExistingAdditionalContent', ProcessStatus.FAILED)
        .set('error', action.payload);

    case ProjectsAction.FETCH_EXISTING_PROJECT_FOLDERS:
      return state.set('fetchingExistingProjectFolders', ProcessStatus.STARTED);
    case ProjectsAction.FETCH_EXISTING_PROJECT_FOLDERS_FULFILLED:
      return state
        .set('fetchingExistingProjectFolders', ProcessStatus.FINISHED)
        .set('existingProjectFolders', action.projects.existingProjectFolders);
    case ProjectsAction.FETCH_EXISTING_PROJECT_FOLDERS_REJECTED:
      return state
        .set('fetchingExistingProjectFolders', ProcessStatus.FAILED)
        .set('error', action.payload);

    case ProjectsAction.IMPORT_PROJECT:
      return state.set('importingProject', ProcessStatus.STARTED);
    case ProjectsAction.IMPORT_PROJECT_FULFILLED:
      // TODO: Die zurückgegebenen Werte stimmen noch nicht überein, erstmal neu fetchen
      return state
        .set('importingProject', ProcessStatus.FINISHED)
        .set('projects', state.get('projects').push(action.data.data.project))
        .set('importedProjectId', action.data.data.project.id);
    case ProjectsAction.IMPORT_PROJECT_REJECTED:
      console.log('IMPORT_PROJECT_REJECTED', action);
      return state
        .set('importingProject', ProcessStatus.FAILED)
        .set('error', action.payload);

    case ProjectsAction.VALIDATE_UPDATE:
      return state.set('validatingUpdate', ProcessStatus.STARTED);
    case ProjectsAction.VALIDATE_UPDATE_FULFILLED:
      return state
        .set('uploadExtractPath', action.data.extract_path)
        .set('validatingUpdate', ProcessStatus.FINISHED);
    // .set('validatingProject', ProcessStatus.FINISHED);
    case ProjectsAction.VALIDATE_UPDATE_REJECTED:
      return state
        .set('validatingUpdate', ProcessStatus.FAILED)
        .set('error', action.payload);

    case ProjectsAction.IMPORT_UPDATE:
      return state.set('importingUpdate', ProcessStatus.STARTED);
    case ProjectsAction.IMPORT_UPDATE_FULFILLED:
      console.log('IMPORT_UPDATE_FULFILLED', action);

      return state.set('importingUpdate', ProcessStatus.FINISHED);
    // .set('projects', state.get('projects').push(action.data.data.project));
    case ProjectsAction.IMPORT_UPDATE_REJECTED:
      return state
        .set('importingUpdate', ProcessStatus.FAILED)
        .set('error', action.payload);

    case ProjectsAction.DELETE_PROJECT:
      return state.set('deletingProject', ProcessStatus.STARTED);
    case ProjectsAction.DELETE_PROJECT_FULFILLED:
      console.log('1. DELETE_PROJECT_FULFILLED, action = ', action);
      console.log('1. DELETE_PROJECT_FULFILLED, action.data = ', action.data);
      console.log(
        '1. DELETE_PROJECT_FULFILLED, action.data.project_id = ',
        action.data.project_id
      );

      state = state.set('deletingProject', ProcessStatus.FINISHED);
      index = state.get('projects').findIndex(function (obj) {
        return obj.id === action.data.project_id;
      });
      if (index > -1) {
        state = state.set('projects', state.get('projects').delete(index));
        if (
          state.get('selectedProject') !== null &&
          state.get('selectedProject').id === action.projectId
        ) {
          state = state.set('selectedProject', null);
        }
      }
      return state;
    case ProjectsAction.DELETE_PROJECT_REJECTED:
      return state.set('deletingProject', ProcessStatus.FAILED);
    case UsersAction.UPDATE_CONNECTED_PROPERTY_SUCCESS: {
      return handleConnectedPropertyChange(state, action);
    }
    /*
     case ProjectsAction.ON_UPLOAD_STARTED:
     console.log('reducers:: projects, ON_UPLOAD_STARTED');
     return state.set('importingProject', true);
     case ProjectsAction.ON_UPLOAD_ENDED:
     console.log('reducers:: projects, ON_UPLOAD_ENDED');
     return state;
     */
    case ProjectsAction.RESET_COLLECTED_INFOS:
      console.log('RESET_COLLECTED_INFOS?');
      return state
        .set('collectedInfos', Map())
        .set('extractPath', '')
        .set('validatingProject', ProcessStatus.INITIAL);
    case ProjectsAction.VALIDATE_PROJECT:
      return state.set('validatingProject', ProcessStatus.STARTED);
    case ProjectsAction.VALIDATE_PROJECT_FULFILLED:
      return state
        .set('collectedInfos', Map(action.data.collected_infos))
        .set('extractPath', action.data.extract_path)
        .set('importingProject', ProcessStatus.INITIAL)
        .set('validatingProject', ProcessStatus.FINISHED);
    case ProjectsAction.VALIDATE_PROJECT_REJECTED:
      return state
        .set('validatingProject', ProcessStatus.FAILED)
        .set('error', action.payload);
    case ProjectsAction.EDIT_PROJECT:
      return state.set('editingProject', ProcessStatus.STARTED);
    case ProjectsAction.EDIT_PROJECT_FULFILLED:
      console.log('edit projects fulfilled');
      projects = state.get('projects');
      index = projects.findIndex((project) => {
        return project.id === action.project.id;
      });

      if (action.project.active) {
        action.project.active = +action.project.active; // TODO: KANN RAUS WENN CARSTEN CHANGED ACTIVE TO 1 0
      }

      if (index > -1) {
        projects = projects.set(index, action.project);
        state = state.set('projects', projects);
      }
      return state
        .set('editingProject', ProcessStatus.FINISHED)
        .set('selectedProject', action.project)
        .set('updateToken', generateGuid());
    case ProjectsAction.EDIT_PROJECT_REJECTED:
      return state
        .set('editingProject', ProcessStatus.FAILED)
        .set('error', action.payload)
        .set('updateToken', generateGuid());
    case ProjectsAction.IMPORT_TRANSLATION:
      console.log('REDUCER:: ProjectsAction.IMPORT_TRANSLATION');
      return state.set('importingTranslation', ProcessStatus.STARTED);
    case ProjectsAction.IMPORT_TRANSLATION_FULFILLED:
      console.log('REDUCER:: ProjectsAction.IMPORT_TRANSLATION_FULFILLED');
      return state.set('importingTranslation', ProcessStatus.FINISHED);
    case ProjectsAction.IMPORT_TRANSLATION_REJECTED:
      console.log('REDUCER:: ProjectsAction.IMPORT_TRANSLATION_REJECTED');
      return state
        .set('importingTranslation', ProcessStatus.FAILED)
        .set('error', action.payload);
    case ProjectsAction.IMPORT_TRANSLATION_RESET:
      return state.set('importingTranslation', ProcessStatus.INITIAL);
    case ProjectsAction.IMPORT_PDF_TRANSLATION:
      console.log('REDUCER:: ProjectsAction.IMPORT_PDF_TRANSLATION');
      return state.set('importingTranslation', ProcessStatus.STARTED);
    case ProjectsAction.IMPORT_PDF_TRANSLATION_FULFILLED:
      console.log('REDUCER:: ProjectsAction.IMPORT_PDF_TRANSLATION_FULFILLED');
      return state.set('importingTranslation', ProcessStatus.FINISHED);
    case ProjectsAction.IMPORT_PDF_TRANSLATION_REJECTED:
      console.log('REDUCER:: ProjectsAction.IMPORT_PDF_TRANSLATION_REJECTED');
      return state
        .set('importingTranslation', ProcessStatus.FAILED)
        .set('error', action.payload);

    case ProjectsAction.VALIDATE_TRANSLATION:
      return state.set('validatingTranslation', ProcessStatus.STARTED);
    case ProjectsAction.VALIDATE_TRANSLATION_FULFILLED:
      return state.set('validatingTranslation', ProcessStatus.FINISHED);
    case ProjectsAction.VALIDATE_TRANSLATION_REJECTED:
      return state
        .set('validatingTranslation', ProcessStatus.FAILED)
        .set('error', action.payload);

    case ProjectsAction.VALIDATE_PDF_TRANSLATION:
      return state.set('validatingTranslation', ProcessStatus.STARTED);
    case ProjectsAction.VALIDATE_PDF_TRANSLATION_FULFILLED:
      return state.set('validatingTranslation', ProcessStatus.FINISHED);
    case ProjectsAction.VALIDATE_PDF_TRANSLATION_REJECTED:
      return state
        .set('validatingTranslation', ProcessStatus.FAILED)
        .set('error', action.payload);

    case ProjectsAction.ADD_TRANSLATION_FULFILLED: {
      project = state.get('selectedProject');
      project.translations.push(action.data);
      return state
        .set('selectedProject', project)
        .set('updateToken', generateGuid());
    }
    case ProjectsAction.FETCH_AVAILABLE_EXPORTS: {
      return state.set('fetchingAvailableExports', ProcessStatus.STARTED);
    }
    case ProjectsAction.FETCH_AVAILABLE_EXPORTS_FULFILLED: {
      return state
        .set('fetchingAvailableExports', ProcessStatus.FINISHED)
        .set('availableExports', List(action.availableExports));
    }
    case ProjectsAction.FETCH_AVAILABLE_EXPORTS_REJECTED: {
      return state
        .set('fetchingAvailableExports', ProcessStatus.FAILED)
        .set('error', action.payload);
    }
    case ProjectsAction.EXPORT_TRANSLATION: {
      return state.set('exportingTranslation', ProcessStatus.STARTED);
    }
    case ProjectsAction.EXPORT_TRANSLATION_FULFILLED: {
      console.log('EXPORT_TRANSLATION_FULFILLED, action', action);
      state = state.set('exportingTranslation', ProcessStatus.FINISHED);
      if (action.data.status == 'success') {
        state = state
          .set('downloadPath', action.data.url)
          .set('additionalContentInfo', action.data.additional_content);
        console.log('EXPORT_TRANSLATION_FULFILLED, action', action.data.url);
      }
      return state;
    }
    case ProjectsAction.EXPORT_TRANSLATION_REJECTED: {
      return state
        .set('exportingTranslation', ProcessStatus.FAILED)
        .set('error', action.payload);
    }

    case ProjectsAction.FETCH_TRANSLATION: {
      return state.set('fetchingTranslation', ProcessStatus.STARTED);
    }
    case ProjectsAction.FETCH_TRANSLATION_FULFILLED: {
      // var translationCommentsCount = 0;
      for (var u = 0; u < action.translation.length; u++) {
        var item = action.translation[u];
        console.log('___item', item);
      }

      return (
        state
          .set('fetchingTranslation', ProcessStatus.FINISHED)
          // .set('translationCommentsCount', translationCommentsCount)
          .set('translation', Map(action.translation))
      );
    }
    case ProjectsAction.FETCH_TRANSLATION_REJECTED: {
      return state
        .set('fetchingTranslation', ProcessStatus.FAILED)
        .set('error', action.payload);
    }

    case ProjectsAction.EDIT_TRANSLATION: {
      return state.set('editingTranslation', ProcessStatus.STARTED);
    }
    case ProjectsAction.EDIT_TRANSLATION_FULFILLED: {
      project = state.get('selectedProject');

      if (project && project.translations) {
        project.translations = project.translations.map((translation) => {
          if (translation.id == action.translation.id) {
            return action.translation;
          }
          return translation;
        });
      }
      return state
        .set('editingTranslation', ProcessStatus.FINISHED)
        .set('translation', Map(action.translation))
        .set('updateToken', generateGuid());
    }
    case ProjectsAction.EDIT_TRANSLATION_REJECTED: {
      return state
        .set('editingTranslation', ProcessStatus.FAILED)
        .set('error', action.payload);
    }

    case ProjectsAction.FETCH_TRANSLATION_STATISTICS: {
      return state.set('fetchingTranslationStatistics', ProcessStatus.STARTED);
    }
    case ProjectsAction.FETCH_TRANSLATION_STATISTICS_FULFILLED: {
      state = state.set(
        'fetchingTranslationStatistics',
        ProcessStatus.FINISHED
      );

      if (action.statistics) {
        state = state.set('translationStatistics', action.statistics);
      }
      return state;
    }
    case ProjectsAction.FETCH_TRANSLATION_STATISTICS_REJECTED: {
      return state
        .set('fetchingTranslationStatistics', ProcessStatus.FAILED)
        .set('error', action.payload);
    }

    //--------------------------------------------------
    case ProjectsAction.DELETE_TRANSLATION:
      return state.set('deletingTranslation', ProcessStatus.STARTED);
    case ProjectsAction.DELETE_TRANSLATION_FULFILLED:
      selectedProject = state.get('selectedProject');
      selectedProject.translations = selectedProject.translations.filter(
        function (obj) {
          console.log(
            'DELETE_TRANSLATION_FULFILLED',
            obj.id !== action.data.translation_id
          );
          return obj.id !== action.data.translation_id;
        }
      );
      return state
        .set('selectedProject', selectedProject)
        .set('deletingTranslation', ProcessStatus.FINISHED)
        .set('updateToken', generateGuid());
    case ProjectsAction.DELETE_TRANSLATION_REJECTED:
      return state.set('deletingTranslation', ProcessStatus.FAILED);

    case ProjectsAction.SET_FORCE_APPROVAL: {
      return state.set('settingForceApproval', ProcessStatus.STARTED);
    }
    case ProjectsAction.SET_FORCE_APPROVAL_FULFILLED: {
      translation = state.get('translation');

      if (translation) {
        translation = translation.set('status', action.data.status);
      }
      return state
        .set('settingForceApproval', ProcessStatus.FINISHED)
        .set('translation', translation)
        .set('updateToken', generateGuid());
    }
    case ProjectsAction.SET_FORCE_APPROVAL_REJECTED: {
      return state
        .set('settingForceApproval', ProcessStatus.FAILED)
        .set('error', action.payload);
    }
    case ProjectsAction.RESET_DOWNLOAD_PATH: {
      console.log('ProjectsAction.RESET_PROJECT_WIZARD', action);
      return state.set('downloadPath', null);
    }
    case ProjectsAction.LINK_PROJECT_TO_USER: {
      return state.set('linkingProjectToUser', ProcessStatus.STARTED);
    }
    case ProjectsAction.LINK_PROJECT_TO_USER_FULFILLED: {
      project = state.get('selectedProject');

      if (project) {
        project.users = action.data;
      }
      return state
        .set('linkingProjectToUser', ProcessStatus.FINISHED)
        .set('selectedProject', project)
        .set('updateToken', generateGuid());
    }
    case ProjectsAction.LINK_PROJECT_TO_USER_REJECTED: {
      return state
        .set('linkingProjectToUser', ProcessStatus.FAILED)
        .set('updateToken', generateGuid());
    }
    case ProjectsAction.LINK_TRANSLATION_TO_USER: {
      return state.set('linkingTranslationToUser', ProcessStatus.STARTED);
    }
    case ProjectsAction.LINK_TRANSLATION_TO_USER_FULFILLED: {
      translation = state.get('translation');

      if (translation) {
        translation = translation.set('users', action.data);
      }
      return state
        .set('linkingTranslationToUser', ProcessStatus.FINISHED)
        .set('translation', translation)
        .set('updateToken', generateGuid());
    }
    case ProjectsAction.LINK_TRANSLATION_TO_USER_REJECTED: {
      return state
        .set('linkingTranslationToUser', ProcessStatus.FAILED)
        .set('updateToken', generateGuid());
    }
    case ProjectsAction.LINK_PROJECT_TO_EXPORT_TYPE: {
      return state.set('linkingProjectToExportType', ProcessStatus.STARTED);
    }
    case ProjectsAction.LINK_PROJECT_TO_EXPORT_TYPE_FULFILLED: {
      project = state.get('selectedProject');
      project.export_types = action.data;
      return state
        .set('linkingProjectToExportType', ProcessStatus.FINISHED)
        .set('selectedProject', project)
        .set('updateToken', generateGuid());
    }
    case ProjectsAction.LINK_CLIENT_TO_EXPORT_TYPE_REJECTED: {
      return state
        .set('linkingProjectToExportType', ProcessStatus.FAILED)
        .set('error', action.payload);
    }

    case ProjectsAction.FETCH_CONFIG:
      return state.set('fetchingConfig', ProcessStatus.STARTED);
    case ProjectsAction.FETCH_CONFIG_FULFILLED:
      return state
        .set('fetchingConfig', ProcessStatus.FINISHED)
        .set('config', action.projects.config);
    case ProjectsAction.FETCH_CONFIG_REJECTED:
      return state
        .set('fetchingConfig', ProcessStatus.FAILED)
        .set('error', action.payload);

    default:
      return state;
  }
};

const getTranslation = (project, id) => {
  return project.translations.find(function (translation) {
    if (translation.id === id) {
      return translation;
    }
  });
};

const handleConnectedPropertyChange = (state, action) => {
  let data = action.data;
  let project = state.get('selectedProject');

  if (data.action) {
    switch (data.action) {
      case UsersAction.ADD_USER_TO_PROJECT:
        project.users.push(data.user);
        return state
          .set('selectedProject', project)
          .set('updateToken', generateGuid());
      case UsersAction.REMOVE_USER_FROM_PROJECT:
        project.users = project.users.filter(function (user) {
          if (user.id === data.user.id) {
            return false;
          }
          return true;
        });
        return state
          .set('selectedProject', project)
          .set('updateToken', generateGuid());
      case UsersAction.ADD_USER_TO_TRANSLATION: {
        let translation = getTranslation(project, data.projectTranslationId);
        index = translation.users.findIndex(function (user) {
          return user.id === data.user.id;
        });
        if (index === -1) {
          translation.users.push(data.user);
        }
        return state
          .set('selectedProject', project)
          .set('updateToken', generateGuid());
      }
      case UsersAction.REMOVE_USER_FROM_TRANSLATION: {
        let translation = getTranslation(project, data.projectTranslationId);
        translation.users = translation.users.filter(function (user) {
          if (user.id === data.user.id) {
            return false;
          }
          return true;
        });
        return state
          .set('selectedProject', project)
          .set('updateToken', generateGuid());
      }
      case UsersAction.CHANGE_USER_ROLE_FOR_TRANSLATION: {
        let translation = getTranslation(project, data.projectTranslationId);
        index = translation.users.findIndex(function (user) {
          return user.id === data.user.id;
        });
        translation.users[index] = data.user;
        return state
          .set('selectedProject', project)
          .set('updateToken', generateGuid());
      }
      default:
        return state;
    }
  }
};

export { initialState, reducer as projects };
