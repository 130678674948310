import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationType } from '../../utils/Constants';
import styles from '../../styles/notification.scss';
import { removeNotification } from '../../actions/notifications';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.getClassName = this.getClassName.bind(this);
  }

  componentDidMount() {
    const { duration = 3000, identifier } = this.props.notification;
    setTimeout(() => this.props.removeNotification(identifier), duration);
  }

  getClassName() {
    let className = styles.neutral;
    switch (this.props.notification.type) {
      case NotificationType.SUCCESS:
        className = styles.success;
        break;
      case NotificationType.WARNING:
        className = styles.warning;
        break;
      case NotificationType.ERROR:
        className = styles.error;
        break;
      default:
        break;
    }
    return [styles.notification, className].join(' ');
  }

  render() {
    const { notification } = this.props;
    let dismiss = null;
    let actionButton = null;
    let title = null;
    let message = null;

    if (notification.title) {
      title = <h4 className='notification-title'>{notification.title}</h4>;
    }
    if (notification.message) {
      message = (
        <div className='notification-message'>{notification.message}</div>
      );
      /*
            message = (
                <Row className={styles.notification_message}>
                    <Col lg={5}>
                        <img src='/src/checked.png'/>
                    </Col>
                    <Col lg={7}>
                        { Notifications[notification.message] }
                    </Col>
                </Row>
            );
            */
    }
    if (notification.dismissible) {
      dismiss = <span className='notification-dismiss'>&times;</span>;
    }
    if (notification.action) {
      actionButton = (
        <div
          className='notification-action-wrapper'
          style={this._styles.actionWrapper}
        >
          <button
            className='notification-action-button'
            onClick={this._defaultAction}
            style={this._styles.action}
          >
            {notification.action.label}
          </button>
        </div>
      );
    }

    return (
      <div className={this.getClassName()}>
        {title}
        {message}
        {dismiss}
        {actionButton}
      </div>
    );
  }
}

export default connect(null, { removeNotification })(Notification);
