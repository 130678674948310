import { List, fromJS } from 'immutable';

import { LanguagesAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  languages: List(),
  formattedLanguages: List([]),
  countries: List([]),
  timestamp: 0,
  fetchingLanguages: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LanguagesAction.FETCH_LANGUAGES:
      return state.set('fetchingLanguages', ProcessStatus.STARTED);
    case LanguagesAction.FETCH_LANGUAGES_FULFILLED:
      return state
        .set('fetchingLanguages', ProcessStatus.FINISHED)
        .set('timestamp', Date.now())
        .set('countries', List(action.languages.countries))
        .set('languages', List(action.languages.languages))
        .set(
          'formattedLanguages',
          List(action.languages.languages).map(function (language) {
            return {
              value: language.lc,
              label: language.name,
              id: language.id
            };
          })
        );
    case LanguagesAction.FETCH_LANGUAGES_REJECTED:
      return state
        .set('fetchingLanguages', ProcessStatus.FAILED)
        .set('error', action.payload);
    default:
      return state;
  }
};

export { initialState, reducer as languages };
