import { Map, List, fromJS } from 'immutable';
import { TextScopesAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  textScopes: Map(),
  formattedTextScopes: List([]),
  timestamp: 0,
  selectedTextScopeId: null,
  fetchingTextScopes: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TextScopesAction.FETCH_TEXT_SCOPES:
      return state.set('fetchingTextScopes', ProcessStatus.STARTED);
    case TextScopesAction.FETCH_TEXT_SCOPES_FULFILLED:
      return state
        .set('fetchingTextScopes', ProcessStatus.FINISHED)
        .set('timestamp', Date.now())
        .set('textScopes', Map(action.data.projects))
        .set(
          'formattedTextScopes',
          Map(action.data).map((key, value) => {
            return { value: key, label: value };
          })
        );
    case TextScopesAction.FETCH_TEXT_SCOPES_REJECTED:
      return state
        .set('fetchingTextScopes', ProcessStatus.FAILED)
        .set('error', action.payload);
    default:
      return state;
  }
};

export { initialState, reducer as textScopes };
