import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'react-md';
import { withTranslation } from 'react-i18next';
import LoadingBar from './DumbComponent';
import { fetchUsers } from '../../actions/users';
import styles from '../../styles/project_detail.scss';

@withTranslation('general', { wait: true })
class Owner extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    if (this.props.users.size === 0) {
      this.props.fetchUsers();
    }
  }

  handleChange = () => {
    // TODO: If new owner is selected, send new id to Form Field
    let NEW_ID = 1;
    this.props.input.onChange(NEW_ID);
  };

  render() {
    const {
      users,
      input,
      input: { value },
      label,
      t
    } = this.props;

    if (users.size == 0) {
      return (
        <LoadingBar
          show='true'
          wrapperClassname={styles.loadingBarWrapper}
          progressClassName={styles.loadingBar}
          message={t('loading_all_owners')}
        />
      );
    }

    const owner = users.find((user) => {
      return user.id == value;
    });

    return (
      <TextField
        id={input.name}
        value={!owner ? "Owner doesn't exist anymore" : owner.name}
        label={label}
        disabled
        onChange={this.handleChange}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.getIn(['users', 'users'])
  };
}

export default connect(mapStateToProps, { fetchUsers })(Owner);
