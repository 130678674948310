// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes rotateStatus {\n  from {\n    transform: rotateZ(0deg) translateZ(0);\n  }\n  to {\n    transform: rotateZ(360deg) translateZ(0);\n  }\n}\n\n.processStatusDialog h2 {\n  padding-bottom: 0;\n}\n\n.processStatusDialog footer {\n  display: none !important;\n}\n\n.processStatusDialog .rowWrapper {\n  flex-direction: column;\n}\n\n.processStatusDialog .rowWrapper .textRow {\n  display: flex;\n  flex-wrap: nowrap;\n}\n\n.processStatusDialog .rowWrapper .textRow .abortButton {\n  background-color: rgba(0, 0, 0, 0.2);\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  padding-left: 7px;\n}\n\n.processStatusDialog .rowWrapper .textRow .abortButton:hover {\n  background-color: rgba(0, 0, 0, 0.3);\n}\n\n.processStatusDialog .rowWrapper .textRow .abortButton .md-icon {\n  margin-right: 7px;\n}\n\n.processStatusDialog .rowWrapper .textRow div {\n  flex-grow: 1;\n  flex-shrink: 1;\n  flex-basis: auto;\n}\n\n.processStatusDialog .rowWrapper .textRow div:first-child {\n  padding-right: 5px;\n}\n\n.processStatusDialog .rowWrapper .textRow div:last-child {\n  padding-left: 5px;\n  flex-grow: 0;\n  flex-shrink: 0;\n}\n\n.processStatusDialog .rowWrapper .textRow div .material-icons.md-icon {\n  display: inline-flex;\n  width: 20px;\n  height: 20px;\n  justify-content: center;\n  align-items: center;\n}\n\n.processStatusDialog .rowWrapper .textRow div .material-icons.md-icon.animated {\n  transform-origin: center;\n  transform: rotateZ(0deg) translateZ(0);\n  animation: rotateStatus 1s linear 0s infinite normal;\n}\n", ""]);
// Exports
exports.locals = {
	"processStatusDialog": "processStatusDialog",
	"rowWrapper": "rowWrapper",
	"textRow": "textRow",
	"abortButton": "abortButton",
	"md-icon": "md-icon",
	"mdIcon": "md-icon",
	"material-icons": "material-icons",
	"materialIcons": "material-icons",
	"animated": "animated",
	"rotateStatus": "rotateStatus"
};
module.exports = exports;
