import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';
import { ProcessStatus } from '../../../utils/Constants';
import {
  exportTranslation,
  resetDownloadPath
} from '../../../actions/projects';
import { addSnapshot } from '../../../actions/snapshots';
import { addToast } from '../../../actions/globalSnackbar';
import LoadingBar from '../../general/DumbComponent';
import styles from '../../../styles/export-translation.scss';
import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';

@withTranslation(['project', 'general', 'export'], { wait: true })
class FinalizeExport extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const { projectId, translationId, values } = this.props;

    const LANGUAGE_FOR_TRANSLATION = 'language_for_translation';

    // Wenn man Seite verlässt, ohne das DownloadPath gesetzt wurde,
    // kann er noch da sein. Kann man schöner lösen, ist aber mehr Arbeit.
    this.props.resetDownloadPath();
    console.log(
      'Finalize Export (additionalContentId): ',
      this.props.additionalContentId
    );
    console.log('Finalize Export (collectionId): ', this.props.collectionId);
    console.log('Finalize Export (values): ', this.props.values);

    let currentPackageType = values.exportType + '_package_type';

    const exportId = values[currentPackageType];
    const additionalContentId = this.props.isPDFExporter
      ? this.props.additionalContentId
      : '';
    const collectionId =
      this.props.isPDFExporter && this.props.collectionId
        ? this.props.collectionId
        : '';
    const sourceSnapshotId = values.sourceSnapshotId
      ? values.sourceSnapshotId
      : '';
    const translationSnapshotId = values.translationSnapshotId
      ? values.translationSnapshotId
      : '';

    this.props.exportTranslation(
      projectId,
      exportId,
      translationId,
      additionalContentId,
      collectionId,
      sourceSnapshotId,
      translationSnapshotId
    );

    if (this.props.isPDFExporter) {
      if (this.props.collectionId) {
        this.props.exportTranslation(
          projectId,
          values[currentPackageType],
          translationId,
          this.props.additionalContentId,
          this.props.collectionId
        );
      } else {
        this.props.exportTranslation(
          projectId,
          values[currentPackageType],
          translationId,
          this.props.additionalContentId
        );
      }
    } else {
      if (values.sourceSnapshotId && values.translationSnapshotId) {
        this.props.exportTranslation(
          projectId,
          values[currentPackageType],
          translationId,
          '',
          '',
          values.sourceSnapshotId,
          values.translationSnapshotId
        );
      } else {
        this.props.exportTranslation(
          projectId,
          values[currentPackageType],
          translationId
        );
      }
    }

    if (
      values.snapshot == 'yes' &&
      values.exportType == LANGUAGE_FOR_TRANSLATION
    ) {
      this.props.addSnapshot(
        projectId,
        translationId,
        values.version,
        values.name,
        values.description
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { addingSnapshot } = nextProps;
    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (
      addingSnapshot == ProcessStatus.FINISHED &&
      addingSnapshot != this.props.addingSnapshot
    ) {
      this.props.addToast(
        t('snapshot_created'),
        {
          children: t(`${generalLNS}:download`),
          onClick: () => {
            window.open(this.props.downloadPath);

            alert('Alert'); // eslint-disable-line no-alert
          }
        },
        false
      );
    } else if (
      addingSnapshot == ProcessStatus.FAILED &&
      addingSnapshot != this.props.addingSnapshot
    ) {
      this.props.addToast(t('snapshot_creation_failed'));
    }
  }

  componentWillUnmount() {
    this.props.resetDownloadPath();
  }

  handleDownload = () => {
    window.open(this.props.downloadPath);
    this.props.history.goBack();
  };

  getAdditionalWarning = () => {
    const { additionalContentInfo, t } = this.props;
    let isPDF = false;
    let isVO = false;
    let pdfOk = true;
    let voOk = true;
    let whichSite = '';
    let whichSiteVo = '';
    let missingFiles = null;
    let missingFilesVO = null;

    if (additionalContentInfo.hasOwnProperty('pdf')) {
      isPDF = true;
      let pdf = additionalContentInfo.pdf;
      if (
        pdf.currentLanguage.files.length !== pdf.defaultLanguage.files.length
      ) {
        let currentlanguageCode = pdf.currentLanguage.code;
        let defaultlanguageCode = pdf.defaultLanguage.code;
        let currentL = [];
        let defaultL = [];

        for (var i = 0; i < pdf.currentLanguage.files.length; i++) {
          let item = pdf.currentLanguage.files[i];
          let fileName = item.replace('_' + currentlanguageCode + '.pdf', '');
          currentL.push(fileName);
        }

        for (var ii = 0; ii < pdf.defaultLanguage.files.length; ii++) {
          let item = pdf.defaultLanguage.files[ii];
          let fileName = item.replace('_' + defaultlanguageCode + '.pdf', '');
          defaultL.push(fileName);
        }

        if (currentL.length !== defaultL.length) {
          let copy = currentL.length > defaultL.length ? currentL : defaultL;
          let copy2 = currentL.length > defaultL.length ? defaultL : currentL;
          whichSite =
            currentL.length > defaultL.length
              ? currentlanguageCode
              : defaultlanguageCode;
          for (var l = 0; l < copy2.length; l++) {
            if (copy.indexOf(copy2[l]) > -1) {
              copy.splice(copy.indexOf(copy2[l]), 1);
            }
          }
          missingFiles = copy;
          pdfOk = false;
        }
      }
    }
    if (additionalContentInfo.hasOwnProperty('vo')) {
      isVO = true;
      let vo = additionalContentInfo.vo;
      if (vo.currentLanguage.files.length !== vo.defaultLanguage.files.length) {
        let currentlanguageCode = vo.currentLanguage.code;
        let defaultlanguageCode = vo.defaultLanguage.code;
        let currentL = [];
        let defaultL = [];

        for (var iv = 0; iv < vo.currentLanguage.files.length; iv++) {
          let item = vo.currentLanguage.files[iv];
          let fileName = item.replace('_' + currentlanguageCode + '.mp3', '');
          currentL.push(fileName);
        }

        for (var iiv = 0; iiv < vo.defaultLanguage.files.length; iiv++) {
          let item = vo.defaultLanguage.files[iiv];
          let fileName = item.replace('_' + defaultlanguageCode + '.mp3', '');
          defaultL.push(fileName);
        }

        if (currentL.length !== defaultL.length) {
          let copyVo = currentL.length > defaultL.length ? currentL : defaultL;
          let copy2Vo = currentL.length > defaultL.length ? defaultL : currentL;
          whichSiteVo =
            currentL.length > defaultL.length
              ? currentlanguageCode
              : defaultlanguageCode;
          for (var ll = 0; ll < copy2Vo.length; ll++) {
            if (copyVo.indexOf(copy2Vo[ll]) > -1) {
              copyVo.splice(copyVo.indexOf(copy2Vo[ll]), 1);
            }
          }
          missingFilesVO = copyVo;
          voOk = false;
        }
      }
    }
    return (
      <>
        {!pdfOk && isPDF && (
          <TableRow>
            <TableColumn>
              {t('export:pdf')}&nbsp;{t('export:warning')}
            </TableColumn>
            <TableColumn>
              <div>
                {t('export:only_this_elements_exists_for')}&nbsp;
                <b>{whichSite}</b>:
              </div>
              <ul
                style={{ margin: '0' }}
                title={JSON.stringify(additionalContentInfo, 0, 2)}
              >
                {missingFiles.map((item, i) => {
                  return (
                    <li key={'-' + i}>{item + '_' + whichSite + '.pdf'}</li>
                  );
                })}
              </ul>
            </TableColumn>
          </TableRow>
        )}
        {pdfOk && isPDF && (
          <TableRow>
            <TableColumn>{t('export:pdf')}</TableColumn>
            <TableColumn>{t('export:ok')}</TableColumn>
          </TableRow>
        )}
        {!voOk && isVO && (
          <TableRow>
            <TableColumn>
              {t('export:voiceover') + '&nbsp;' + t('export:warning')}
            </TableColumn>
            <TableColumn>
              <div>
                {t('export:only_this_elements_exists_for')}&nbsp;
                <b>{whichSiteVo}</b>:
              </div>
              <ul
                style={{ margin: '0' }}
                title={JSON.stringify(additionalContentInfo, 0, 2)}
              >
                {missingFilesVO.map((item, i) => {
                  return (
                    <li key={'-' + i}>{item + '_' + whichSiteVo + '.mp3'}</li>
                  );
                })}
              </ul>
            </TableColumn>
          </TableRow>
        )}
        {voOk && isVO && (
          <TableRow>
            <TableColumn>{t('export:voiceover')}</TableColumn>
            <TableColumn>{t('export:ok')}</TableColumn>
          </TableRow>
        )}
      </>
    );
  };

  render() {
    const {
      projectId,
      translationId,
      values,
      exportingTranslation,
      t,
      additionalContentInfo
    } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const exportLNS = 'export'; // exportLanguageNamespaceSource

    switch (exportingTranslation) {
      case ProcessStatus.FAILED:
        return <div>Export translation has failed.</div>;

      case ProcessStatus.FINISHED:
        return (
          <div>
            <h3>{t('ready_for_download') + ': '}</h3>
            <DataTable plain>
              <TableBody>
                <TableRow>
                  <TableColumn>{t(`${generalLNS}:projectId`)}</TableColumn>
                  <TableColumn>{projectId}</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{t(`${generalLNS}:translation_id`)}</TableColumn>
                  <TableColumn>{translationId}</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{t(`${generalLNS}:export_type`)}</TableColumn>
                  <TableColumn>
                    {t(`${exportLNS}:` + values.exportType)}
                  </TableColumn>
                </TableRow>
                {additionalContentInfo && this.getAdditionalWarning()}
              </TableBody>
            </DataTable>
            <br />
            <br />
            <Button
              flat
              primary
              type='button'
              onClick={this.handleDownload}
              iconChildren='cloud_download'
              children={t(`${generalLNS}:download`)}
            />
          </div>
        );

      default:
        return (
          <LoadingBar
            show='true'
            wrapperClassname={styles.loadingBarWrapper}
            message={t('preparing_for_download')}
          />
        );
    }
  }
}

function mapStateToProps(state) {
  return {
    exportingTranslation: state.getIn(['projects', 'exportingTranslation']),
    additionalContentInfo: state.getIn(['projects', 'additionalContentInfo']),
    downloadPath: state.getIn(['projects', 'downloadPath']),
    addingSnapshot: state.getIn(['snapshots', 'addingSnapshot'])
  };
}

export default withRouter(
  connect(mapStateToProps, {
    exportTranslation,
    resetDownloadPath,
    addSnapshot,
    addToast
  })(FinalizeExport)
);
