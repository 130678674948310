// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".box-shadow-none {\n  -webkit-box-shadow: none;\n  -moz-box-shadow: none;\n  box-shadow: none;\n}\n", ""]);
// Exports
exports.locals = {
	"box-shadow-none": "box-shadow-none",
	"boxShadowNone": "box-shadow-none"
};
module.exports = exports;
