import * as Rx from 'rxjs';

import { FieldLevelAction } from '../utils/ActionTypes';

const setScrollTo = (scrollTo) => {
  return {
    type: FieldLevelAction.SET_SCROLL_TO,
    scrollTo: scrollTo
  };
};

const addUnsavedText = (text) => {
  return {
    type: FieldLevelAction.ADD_UNSAVED_TEXT,
    refName: text.refName,
    collection: text.collection,
    id: text.id
  };
};

const removeUnsavedText = (refName) => {
  return {
    type: FieldLevelAction.REMOVE_UNSAVED_TEXT,
    refName: refName
  };
};

export { setScrollTo, addUnsavedText, removeUnsavedText };
