import React, { Component } from 'react';
import { DialogContainer, FontIcon, Button } from 'react-md';
import { withTranslation } from 'react-i18next';
import styles from '../../styles/wbtViewer/processStatusDialog.scss';
import { withRouter } from 'react-router-dom';

@withTranslation(['translation_navigation', 'general'], { wait: true })
class ProcessStatusDialog extends Component {
  render() {
    const {
      title,
      active,
      processStatus,
      t,
      projectId,
      translationId,
      sourceLanguageId
    } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    const actions = [];
    actions.push({
      children: t(`${generalLNS}:cancel`),
      onClick: () => this.props.toggle()
    });
    // actions.push({
    //   children: 'reload',
    //   onClick: () => {
    //     window.location.reload();
    //   }
    // });

    /*
     * ACHTUNG: Die FOOTER-BUTTON-BAR wurde via CSS (processStatusDialog.scss, Zeile 9) ausgeblendet.
     * */
    let sourceIdPart = sourceLanguageId ? '/' + sourceLanguageId : '';

    return (
      <DialogContainer
        id='simple-action-dialog-wbt'
        visible={active}
        onHide={this.props.toggle} // MUSS DAS REIN?
        actions={actions}
        title={title}
        className={styles.processStatusDialog}
      >
        <br />
        <div className={'rowWrapper'}>
          <div className={'textRow'}>
            <div>{t('loading_text_1')}</div>
            <div>
              {processStatus.fetchingProject ? (
                <FontIcon className={'animated'}>refresh</FontIcon>
              ) : (
                <FontIcon>check</FontIcon>
              )}
            </div>
          </div>
          <div className={'textRow'}>
            <div>{t('loading_text_2')}</div>
            <div>
              {processStatus.fetchingStructure ? (
                <FontIcon className={'animated'}>refresh</FontIcon>
              ) : (
                <FontIcon>check</FontIcon>
              )}
            </div>
          </div>
          <div className={'textRow'}>
            <div>{t('loading_text_3')}</div>
            <div>
              {processStatus.fetchingTexts ? (
                <FontIcon className={'animated'}>refresh</FontIcon>
              ) : (
                <FontIcon>check</FontIcon>
              )}
            </div>
          </div>
          <div className={'textRow'}>
            <div>{t('loading_text_4')}</div>
            <div>
              {processStatus.packageConnected ? (
                <FontIcon>check</FontIcon>
              ) : (
                <FontIcon className={'animated'}>refresh</FontIcon>
              )}
            </div>
          </div>
          <div className={'textRow'}>
            <div>
              <br />
              <Button
                flat
                className={'abortButton'}
                onClick={() => {
                  this.props.history.push(
                    '/projects/' +
                      projectId +
                      '/translations/' +
                      translationId +
                      sourceIdPart
                  );
                }}
                style={{ marginRight: '20px' }}
              >
                <FontIcon>keyboard_arrow_left</FontIcon>
                <span>{t('abort_back_btn')}</span>
              </Button>
            </div>
          </div>
        </div>
      </DialogContainer>
    );
  }
}

export default withRouter(ProcessStatusDialog);
