// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\r\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.imprint p {\n  font-size: 16px;\n  line-height: 1.6;\n  font-weight: 400;\n}\n\n.imprint h4 {\n  margin-top: 3rem;\n  margin-bottom: 1rem;\n  font-weight: 900;\n  font-size: 22px;\n}\n\n.h3 {\n  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;\n  font-weight: 900;\n  font-size: 36px;\n  text-transform: uppercase;\n  color: inherit;\n  padding: 22px 0 20px 0;\n  display: block;\n}\n\na {\n  color: #ff5900;\n  text-decoration: none;\n}\n\n.data-table {\n  margin: 2rem 0;\n  border-collapse: collapse;\n  font-size: 16px;\n  line-height: 1.6;\n  font-weight: 400;\n}\n\n.data-table td {\n  padding: 0 10px;\n  line-height: 3rem;\n}\n\n.data-table td:first-of-type {\n  padding-left: 0;\n  min-width: 130px;\n  font-weight: bold;\n}\n", ""]);
// Exports
exports.locals = {
	"imprint": "imprint",
	"h3": "h3",
	"data-table": "data-table",
	"dataTable": "data-table"
};
module.exports = exports;
