import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardActions, CardText, CardTitle, Button } from 'react-md';
import { withTranslation } from 'react-i18next';
import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

@withTranslation(['project', 'general'], { wait: true })
class ImportUpload extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    console.log('UNSAFE_componentWillMount, this.props = ', this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('UNSAFE_componentWillReceiveProps, nextProps = ', nextProps);

    /*
        if (nextProps.validatingProject == ProcessStatus.FINISHED &&
            nextProps.validatingProject != this.props.validatingProject) {
            this.props.onChange("validated");
        }
        */
  }

  validateTranslation = () => {
    console.log('validateTranslation', this.props);
    /*
        const {uploadedFile} = this.props;

        this.props.validateTranslation({
            package_type: 'jsbeam_1_0',
            uuid: uploadedFile.uuid,
            filename: uploadedFile.name,
        });
        */
  };

  discardTranslation = () => {
    console.log('discardTranslation', this.props);
    /*
        //console.log("discardTranslation");
        this.props.onChange("");
        this.props.resetCollectedInfos();
        */
  };

  render() {
    const { importingTranslation, error, values, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let errorMessage = t(`${generalLNS}:error`);

    if (error && error.message) {
      console.log('error.message = ', error.message);
      errorMessage = error.message;
    }

    console.log('Import Upload, render:: this.props', this.props);

    return (
      <Card className='md-block-centered'>
        <CardTitle
          // title={ t(`${generalLNS}:title`) }
          // title={this.props.values.name}
          title={values.name}
        />
        <CardText>
          {importingTranslation == ProcessStatus.STARTED && (
            <LoadingBar
              show='true'
              message={t(`${generalLNS}:importing_translation`)}
            />
          )}
          {importingTranslation == ProcessStatus.FINISHED && (
            <div>
              <p>{t('project_imported')}</p>
            </div>
          )}
          {importingTranslation == ProcessStatus.FAILED && (
            <div>
              <p>
                {t('import_failed')}: {errorMessage}
              </p>
            </div>
          )}
        </CardText>
        {importingTranslation == ProcessStatus.FAILED && (
          <CardActions>
            <Button key='discard' flat onClick={this.discardTranslation}>
              {t(`${generalLNS}:discard`)}
            </Button>
            <Button key='try_again' flat onClick={this.validateTranslation}>
              {t(`${generalLNS}:try_again`)}
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    importingTranslation: state.getIn(['projects', 'importingTranslation']),
    error: state.getIn(['projects', 'error'])
  };
}

export default connect(mapStateToProps, {
  // validateTranslation,
})(ImportUpload);
