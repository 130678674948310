import React, { Component } from 'react';
import LayoutContainer from '../layout/LayoutContainer';
import PrivacyText from '../general/texts/PrivacyText';
import { Card } from 'react-md';

class Imprint extends Component {
  UNSAFE_componentWillMount() {}

  render() {
    return (
      <LayoutContainer width='small'>
        <Card style={{ marginTop: '2rem', padding: '24px' }}>
          <PrivacyText />
        </Card>
      </LayoutContainer>
    );
  }
}

export default Imprint;
