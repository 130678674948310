import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';
import { ProcessStatus } from '../../../utils/Constants';
import ContactPrime from './ContactPrime';
import { fetchLanguages } from '../../../actions/languages';
import { addContact, fetchContacts } from '../../../actions/contacts';
import styles from '../../../styles/project_detail.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
import setFieldData from 'final-form-set-field-data';
import { addToast, removeToast } from '../../../actions/globalSnackbar';

@withTranslation('general', { wait: true })
class AddContact extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const { fetchingContacts, contacts, fetchingLanguages, languages } =
      this.props;
    if (fetchingContacts != ProcessStatus.STARTED && contacts.size == 0) {
      this.props.fetchContacts();
    }

    if (fetchingLanguages != ProcessStatus.STARTED && languages.size === 0) {
      this.props.fetchLanguages();
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { addingContact, errorObject } = nextProps;

    if (addingContact === ProcessStatus.FAILED) {
      this.displayError(errorObject);
    }
    if (addingContact === ProcessStatus.FINISHED) {
      this.props.closeDrawer();
    }
  }

  /* displayWarning = (errorObject) => {
    const {addToast, removeToast} = this.props;
    let e = null;
    if (errorObject) {
      e = JSON.parse(JSON.stringify(errorObject));
    }
    // this.setState({submitClicked: false});
    addToast(
      (e && e.notify && e.notify.warning) ? 'WARNING: ' + e.notify.warning[0] : 'ERROR',
      {
        children: 'ok',
        onClick: () => {
          removeToast();
        }
      },
      true
    );
  }; */

  displayError = (errorObject) => {
    const { t, addToast, removeToast } = this.props;
    let e = null;
    if (errorObject) {
      e = JSON.parse(JSON.stringify(errorObject));
    }
    // this.setState({submitClicked: false});
    addToast(
      e && e.message ? `${t('error')}: ` + e.message : t('error'),
      {
        children: 'ok',
        onClick: () => {
          removeToast();
        }
      },
      true
    );
  };

  handleSubmit = (data) => {
    const { countries } = this.props;

    let country = null;

    if (data.country && data.country.value && !data.country.disabled) {
      country = countries.find((country) => country.id == data.country.value);
    }

    if (country) {
      data.cp_country_id = data.country.value;
      delete data.country;
      this.props.addContact(data);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className={stylesDrawer.drawer + ' editDrawer'}>
        <Form
          onSubmit={this.handleSubmit}
          mutators={{
            setFieldData,

            setCollectedInfos: (args, state) => {
              state.formState.values = {
                ...state.formState.values,
                ...args[0]
              };
            },
            changeValue: (args, state, utils) => {
              utils.changeValue(state, args[0].name, () => args[0].value);
            },
            changeValues: (args, state, utils) => {
              args.map((arg) =>
                utils.changeValue(state, arg.name, () => arg.value)
              );
            }
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={'body paddingLeftRight marginTop'}>
                <FormSpy
                  subscription={{ values: true }}
                  no
                  component={ContactPrime}
                />
                <div className={styles.buttonsRightWrapper}>
                  <Button
                    children={t('submit')}
                    type='submit'
                    flat
                    primary
                    swapTheming
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contacts: state.getIn(['contacts', 'contacts']),
    fetchingContacts: state.getIn(['contacts', 'fetchingContacts']),
    addingContact: state.getIn(['contacts', 'addingContact']),
    languages: state.getIn(['languages', 'languages']),
    countries: state.getIn(['languages', 'countries']),
    fetchingLanguages: state.getIn(['languages', 'fetchingLanguages']),
    errorObject: state.get('error')
  };
}

export default connect(mapStateToProps, {
  addContact,
  fetchContacts,
  fetchLanguages,
  addToast,
  removeToast
})(AddContact);
