import { AuthAction } from '../utils/ActionTypes';
import * as Rx from 'rxjs';
import { error, log } from '../utils/Logger';

const updateUserData = (user) => {
  return {
    type: AuthAction.UPDATE_STORE_USER,
    user: user
  };
};

const updateUserDataFulfilled = (user) => {
  return {
    type: AuthAction.UPDATE_STORE_USER_FULFILLED,
    user: user
  };
};

const updateUserDataRejected = (error) => {
  return Rx.Observable.of({
    type: AuthAction.UPDATE_STORE_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const userFromStore = (user) => {
  return {
    type: AuthAction.USER_FROM_STORE,
    user: user
  };
};

const impersonatedUserFromStore = (impersonatedUser) => {
  return {
    type: AuthAction.IMPERSONATED_USER_FROM_STORE,
    impersonatedUser: impersonatedUser
  };
};

const update = (name, value) => {
  return {
    type: AuthAction.UPDATE_FORM_VALUE,
    name: name,
    value: value
  };
};

const reset = () => {
  return {
    type: AuthAction.RESET_FORM
  };
};

const submit = (username, password) => {
  return {
    type: AuthAction.SUBMIT_FORM,
    username: username,
    password: password
  };
};

const login = (formData) => {
  return {
    type: AuthAction.LOGIN,
    formData: formData
  };
};

const authenticate = (data) => {
  if (data.status === 'success') {
    return {
      type: AuthAction.LOGIN_SUCCESS,
      auth: data.auth
    };
  }
  return {
    type: AuthAction.LOGIN_ERROR
  };
};

const loginRejected = (error) => {
  return Rx.Observable.of({
    type: AuthAction.LOGIN_ERROR,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const logout = () => {
  return {
    type: AuthAction.LOGOUT
  };
};

const logoutFulfilled = (data) => {
  return {
    type: AuthAction.LOGOUT_FULFILLED,
    data: data
  };
};

const logoutRejected = (error) => {
  return Rx.Observable.of({
    type: AuthAction.LOGOUT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const loginAsUser = (userId) => {
  return {
    type: AuthAction.LOGIN_AS_USER,
    userId: userId
  };
};

const loginAsUserRejected = (err) => {
  error('ACTIONS :: AUTH', err);
  return Rx.Observable.of({
    type: AuthAction.LOGIN_AS_USER_REJECTED,
    payload: err.xhr ? err.xhr.response : err,
    error: true
  });
};

const loginAsUserFulfilled = (data) => {
  log('ACTIONS :: AUTH', 'loginAsUserFulfilled', `userId = ${data}`);
  return {
    type: AuthAction.LOGIN_AS_USER_FULFILLED,
    data: data
  };
};

const logoutAsUser = (userId) => {
  log('ACTIONS :: AUTH', 'logoutAsUser', `userId = ${userId}`);
  return {
    type: AuthAction.LOGOUT_AS_USER,
    userId: userId
  };
};

const logoutAsUserRejected = (error) => {
  return Rx.Observable.of({
    type: AuthAction.LOGOUT_AS_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const logoutAsUserFulfilled = (data) => {
  log('ACTIONS :: AUTH', 'logoutAsUserFulfilled', data);
  return {
    type: AuthAction.LOGOUT_AS_USER_FULFILLED,
    data: data
  };
};

const editUserSettings = (userId, settings) => {
  return {
    type: AuthAction.EDIT_USER_SETTINGS,
    userId: userId,
    settings: settings
  };
};

const editUserSettingsFulfilled = (data) => {
  return {
    type: AuthAction.EDIT_USER_SETTINGS_FULFILLED,
    data: data
  };
};

const editUserSettingsRejected = (error) => {
  return Rx.Observable.of({
    type: AuthAction.EDIT_USER_SETTINGS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const requestResetPassword = (formData) => {
  log('ACTIONS :: AUTH', 'requestResetPassword formData', formData);
  return {
    type: AuthAction.REQUEST_RESET_PASSWORD,
    formData: formData
  };
};

const requestResetPasswordFulfilled = () => {
  return {
    type: AuthAction.REQUEST_RESET_PASSWORD_FULFILLED
  };
};

const requestResetPasswordRejected = (error) => {
  return Rx.Observable.of({
    type: AuthAction.REQUEST_RESET_PASSWORD_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const resetPassword = (formData) => {
  log('ACTIONS :: AUTH', 'resetPassword formData', formData);
  return {
    type: AuthAction.RESET_PASSWORD,
    formData: formData
  };
};

const resetPasswordFulfilled = () => {
  return {
    type: AuthAction.RESET_PASSWORD_FULFILLED
  };
};

const resetPasswordRejected = (error) => {
  return Rx.Observable.of({
    type: AuthAction.RESET_PASSWORD_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const getUsernameByToken = (token) => {
  return {
    type: AuthAction.GET_USERNAME_BY_TOKEN,
    token: token
  };
};

const getUsernameByTokenFulfilled = (data) => {
  return {
    type: AuthAction.GET_USERNAME_BY_TOKEN_FULFILLED,
    data: data
  };
};

const getUsernameByTokenRejected = (error) => {
  return Rx.Observable.of({
    type: AuthAction.GET_USERNAME_BY_TOKEN_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  userFromStore,
  impersonatedUserFromStore,
  update,
  reset,
  submit,
  login,
  authenticate,
  loginRejected,
  logout,
  logoutFulfilled,
  logoutRejected,
  loginAsUser,
  loginAsUserFulfilled,
  loginAsUserRejected,
  logoutAsUser,
  logoutAsUserFulfilled,
  editUserSettings,
  editUserSettingsFulfilled,
  editUserSettingsRejected,
  logoutAsUserRejected,
  requestResetPassword,
  requestResetPasswordFulfilled,
  requestResetPasswordRejected,
  resetPassword,
  resetPasswordFulfilled,
  resetPasswordRejected,
  updateUserData,
  updateUserDataFulfilled,
  updateUserDataRejected,
  getUsernameByToken,
  getUsernameByTokenFulfilled,
  getUsernameByTokenRejected
};
