import * as Rx from 'rxjs';
import { ajax } from 'rxjs/observable/dom/ajax';
import { StatusAction } from '../utils/ActionTypes';
import { receiveStatus } from '../actions/status';
import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchStatusEpic = (action$, store) => {
  return action$
    .ofType(StatusAction.FETCH_STATUS)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'enumdefaults/projects/status';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(receiveStatus)
        .catch((error) =>
          Rx.Observable.of({
            type: StatusAction.FETCH_STATUS_REJECTED,
            payload: error.xhr.response,
            error: true
          })
        );
    });
};

export { fetchStatusEpic };
