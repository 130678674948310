import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { fetchContacts } from '../../../actions/contacts';
import {
  renderTextField,
  renderSelectField
} from '../../general/BasicFormComponents';
import Owner from '../../general/Owner';

import styles from '../../../styles/project_detail.scss';
// import {ListItem} from 'react-md';

@withTranslation(['project', 'general'], { wait: true })
class ProjectPrime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      max: 400
    };
  }

  UNSAFE_componentWillMount() {
    const { contacts } = this.props;

    if (contacts.size === 0) {
      this.props.fetchContacts();
    }
  }

  renderListItems = () => {
    const { contacts } = this.props;
    if (contacts.size > 0) {
      return contacts.map((contact) => ({
        value: contact.id,
        label: (
          <div className={'contactFieldContentContainer'} key={contact.id}>
            <p className={'primaryText'}>{contact.project_leader_name}</p>
            <p className={'secondaryText'}>{contact.pl_email}</p>
          </div>
        )
      }));
    }
  };

  render() {
    const { editMode, contacts, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let renderContactsField = false;
    let listItems = null;
    let contactEditMode = editMode ? 'editActive' : null;

    if (contacts.size > 0) {
      renderContactsField = true;
      listItems = this.renderListItems();
      listItems = listItems.toArray();
      listItems.sort((a, b) => {
        return a.value - b.value || a.value.localeCompare(b.value);
      });
      listItems.unshift({
        value: 'no_contact',
        label: (
          <div className={'contactFieldContentContainer'} key={0}>
            <p className={'primaryText'}>{t(`${generalLNS}:no_contact`)}</p>
          </div>
        )
      });
    }

    return (
      <div>
        <div className={styles.header}>
          <h2 className={'headName'}>{t(`${generalLNS}:information`)}</h2>
        </div>
        <div className='editableElements'>
          <Field
            id='name'
            name='name'
            label={t(`${generalLNS}:name`)}
            type='text'
            className={styles.formField}
            resize={{ max: this.state.max }}
            disabled={!editMode}
            required
            component={renderTextField}
          />
          <Field
            id='version'
            name='version'
            label={t(`${generalLNS}:version`)}
            type='text'
            className={styles.formField}
            resize={{ max: this.state.max }}
            disabled={!editMode}
            required
            component={renderTextField}
          />
          <Field
            id='description'
            name='description'
            label={t(`${generalLNS}:description`)}
            type='text'
            className={styles.formField}
            disabled={!editMode}
            required
            component={renderTextField}
          />
          {renderContactsField && (
            <div className={'contactFieldContainer'}>
              <Field
                id='contact_id'
                name='contact_id'
                label={t(`${generalLNS}:contact`)}
                type='text'
                className={
                  styles.formField + ' contactField ' + contactEditMode
                }
                disabled={!editMode}
                menuItems={listItems}
                component={renderSelectField}
              />
            </div>
          )}
        </div>

        <div className='notEditableElements'>
          <Field
            id='identifier'
            name='identifier'
            label={t(`${generalLNS}:identifier`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
          <Field
            id='owner'
            name='owner'
            label={t(`${generalLNS}:owner`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={Owner}
          />
          <Field
            id='job_number'
            name='job_number'
            label={t('job_number')}
            type='text'
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contacts: state.getIn(['contacts', 'contacts'])
  };
}

export default connect(mapStateToProps, { fetchContacts })(ProjectPrime);
