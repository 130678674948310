import { List, fromJS } from 'immutable';

import { GlobalDialogAction } from '../utils/ActionTypes';

import { createDialog } from '../utils/HelperFunctions';

const initialState = fromJS({
  dialogs: List(),
  id: null
});

const reducer = (state = initialState, action) => {
  let dialog = null;
  let updatedList = null;
  let index = null;

  switch (action.type) {
    case GlobalDialogAction.SHOW_DIALOG:
      dialog = createDialog(
        action.dialogType,
        action.message,
        action.title,
        action.actions
      );
      updatedList = state.get('dialogs').push(dialog);
      return state.set('dialogs', updatedList).set('id', dialog.identifier);
    case GlobalDialogAction.REMOVE_DIALOG:
      index = state.get('dialogs').findIndex(function (obj) {
        return obj.identifier === action.identifier;
      });
      if (index > -1) {
        state = state.set('dialogs', state.get('dialogs').delete(index));
      }
      return state;
    default:
      return state;
  }
};

export { initialState, reducer as globalDialog };
