// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\r\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.button-menu-wrapper {\n  display: block;\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background-color: transparent;\n  border-radius: 1.1rem;\n}\n\n.button-menu-wrapper:hover {\n  background-color: #d4d4d4;\n}\n\n.button-point-wrapper {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background-color: transparent;\n  padding: 0.5rem;\n  display: flex;\n  flex-direction: column;\n}\n\n.button-row {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 0.2rem;\n}\n\n.button-row:last-of-type {\n  margin-bottom: 0;\n}\n\n.button-point {\n  width: 1.3rem;\n  height: 1.3rem;\n  border-radius: 50%;\n  margin-right: 0.25rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  background: deepskyblue;\n  box-shadow: 0 0 0 0 #5bd75b inset;\n}\n\n.button-point:last-of-type {\n  margin-right: 0;\n}\n\n.left-icon {\n  display: none;\n}\n\n.pdf-icon {\n  background: #5bd75b;\n}\n\n.inv-pdf-icon {\n  background: white;\n  box-shadow: 0 0 0 2px #5bd75b inset;\n}\n\n.newstext-icon {\n  background: #66ef66;\n}\n\n.inv-newstext-icon {\n  background: white;\n  box-shadow: 0 0 0 2px #66ef66 inset;\n}\n", ""]);
// Exports
exports.locals = {
	"button-menu-wrapper": "button-menu-wrapper",
	"buttonMenuWrapper": "button-menu-wrapper",
	"button-point-wrapper": "button-point-wrapper",
	"buttonPointWrapper": "button-point-wrapper",
	"button-row": "button-row",
	"buttonRow": "button-row",
	"button-point": "button-point",
	"buttonPoint": "button-point",
	"left-icon": "left-icon",
	"leftIcon": "left-icon",
	"pdf-icon": "pdf-icon",
	"pdfIcon": "pdf-icon",
	"inv-pdf-icon": "inv-pdf-icon",
	"invPdfIcon": "inv-pdf-icon",
	"newstext-icon": "newstext-icon",
	"newstextIcon": "newstext-icon",
	"inv-newstext-icon": "inv-newstext-icon",
	"invNewstextIcon": "inv-newstext-icon"
};
module.exports = exports;
