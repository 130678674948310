import { ajax } from 'rxjs/observable/dom/ajax';
import { RolesAction } from '../utils/ActionTypes';

import {
  fetchRolesFulfilled,
  fetchRolesRejected,
  addRoleFulfilled,
  addRoleRejected,
  editRoleFulfilled,
  editRoleRejected,
  deleteRoleFulfilled,
  deleteRoleRejected,
  fetchRolePermissionsFulfilled,
  fetchRolePermissionsRejected,
  changeRolePermissionsFulfilled,
  changeRolePermissionsRejected
} from '../actions/roles';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchRolesEpic = (action$, store) => {
  return action$
    .ofType(RolesAction.FETCH_ROLES)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'roles';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((ajaxResponse) => ajaxResponse.response.data)
        .map(fetchRolesFulfilled)
        .catch(fetchRolesRejected);
    });
};

const addRoleEpic = (action$, store) => {
  return action$
    .ofType(RolesAction.ADD_ROLE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'roles';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'POST', headers: headers, body: action.role })
        .map((res) => res.response.data)
        .map(addRoleFulfilled)
        .catch(addRoleRejected);
    });
};

const editRoleEpic = (action$, store) => {
  return action$
    .ofType(RolesAction.EDIT_ROLE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'roles/' + action.role.id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.role })
        .map((res) => res.response.data)
        .map(editRoleFulfilled)
        .catch(editRoleRejected);
    });
};

const deleteRoleEpic = (action$, store) => {
  return action$
    .ofType(RolesAction.DELETE_ROLE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'roles/' + action.roleId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'DELETE', headers: headers })
        .map((res) => res.response)
        .map(() => deleteRoleFulfilled(action.roleId))
        .catch(deleteRoleRejected);
    });
};

const fetchRolePermissionsEpic = (action$, store) => {
  return action$
    .ofType(RolesAction.FETCH_ROLE_PERMISSIONS)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'roles/' + action.roleId + '/permissions';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(fetchRolePermissionsFulfilled)
        .catch(fetchRolePermissionsRejected);
    });
};

const changeRolePermissionsEpic = (action$, store) => {
  return (
    action$
      .ofType(RolesAction.CHANGE_ROLE_PERMISSIONS)
      // .debounceTime(700)
      .mergeMap((action) => {
        let url = baseApiUrl + 'roles/' + action.roleId + '/permissions';
        let data = { permission_id: action.permissionId, allow: action.allow };
        let headers = getHttpAuthHeaders(store, url);
        return ajax({ url, method: 'POST', headers: headers, body: data })
          .map((ajaxResponse) => ajaxResponse.response)
          .map(changeRolePermissionsFulfilled)
          .catch(changeRolePermissionsRejected);
      })
  );
};

export {
  fetchRolesEpic,
  addRoleEpic,
  editRoleEpic,
  deleteRoleEpic,
  fetchRolePermissionsEpic,
  changeRolePermissionsEpic
};
