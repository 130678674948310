import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import LoadingBar from '../../general/DumbComponent';
import EditUser from './EditUser';
import UserProjects from './UserProjects';
import UserClients from './UserClients';
import { fetchUser } from '../../../actions/users';
import styles from '../../../styles/project_detail.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
import stylesTabs from '../../../styles/tabs.scss';

@withTranslation(['users', 'general'], { wait: true })
class EditUserContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      tabCount: 2
    };
  }

  setTab = (tabIndex) => {
    this.setState({ tabIndex: tabIndex });
  };

  UNSAFE_componentWillMount() {
    const {
      user,
      match: {
        params: { userId }
      }
    } = this.props;

    if (user == null || user.id != userId) {
      this.props.fetchUser(userId);
    }
  }

  // UNSAFE_componentWillReceiveProps (nextProps) {
  // const {
  //   match: {
  //     params: {userId}
  //   },
  //   updateToken
  // } = nextProps;
  // if (updateToken != this.props.updateToken) {
  // console.log("MUSS HIER Überhaupt gefetchted werden? WAHRSCHEINLCH NIUCHT; SCHAU NACH UPDATE");
  // this.props.fetchUser(userId);
  // }
  // }

  render() {
    const { user, t } = this.props;
    const { tabIndex, tabCount } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let transformValue = 100 * (tabIndex / tabCount) * -1;
    let tabContainerTransformValue =
      'translateX(' + transformValue + '%) translateZ(0)';
    let tabContainerWidth = 'calc(100% * ' + tabCount + ')';

    if (user == null) {
      return (
        <LoadingBar
          show='true'
          message={t(`${generalLNS}:loading_bar_users`)}
        />
      );
    }

    return (
      <div className={stylesDrawer.drawer + ' editDrawer'}>
        <div className={'head'}>
          <div className={'headlines'}>
            <h2>{user.name}</h2>
            <h4>
              {user.id} | {user.version}
            </h4>
          </div>
        </div>

        <div className={stylesTabs.tabBtnRow}>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 0).toString()}
            onClick={() => {
              this.setTab(0);
            }}
          >
            {t(`${generalLNS}:properties`)}
          </button>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 1).toString()}
            onClick={() => {
              this.setTab(1);
            }}
          >
            {t(`${generalLNS}:relations`)}
          </button>
        </div>

        <div className={stylesTabs.tabContainerRow}>
          <div
            className='tabContainerWrapper'
            style={{
              transform: tabContainerTransformValue,
              width: tabContainerWidth
            }}
          >
            <div className={'tabContainer'}>
              <EditUser />
            </div>
            <div className={'tabContainer'}>
              <div className={styles.flexHorizontalWrapper}>
                <UserProjects />
                <UserClients />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.getIn(['users', 'selectedUser']),
    fetchingUser: state.getIn(['users', 'fetchingUser']),
    updateToken: state.getIn(['users', 'updateToken'])
  };
}

export default withRouter(
  connect(mapStateToProps, { fetchUser })(EditUserContainer)
);
