import { fromJS, List } from 'immutable';

import { ExportTypesAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  exportTypes: List(),
  fetchingExportTypes: ProcessStatus.INITIAL
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ExportTypesAction.FETCH_EXPORT_TYPES:
      return state.set('fetchingExportTypes', ProcessStatus.STARTED);
    case ExportTypesAction.FETCH_EXPORT_TYPES_FULFILLED:
      return state
        .set('fetchingExportTypes', ProcessStatus.FINISHED)
        .set('exportTypes', List(action.exportTypes));
    case ExportTypesAction.FETCH_EXPORT_TYPES_REJECTED:
      return state
        .set('fetchingExportTypes', ProcessStatus.FAILED)
        .set('error', action.payload);
    default:
      return state;
  }
};

export { initialState, reducer as exportTypes };
