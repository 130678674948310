import { ajax } from 'rxjs/observable/dom/ajax';
import { PermissionsAction } from '../utils/ActionTypes';

import {
  fetchPermissionsFulfilled,
  fetchPermissionsRejected,
  addPermissionFulfilled,
  addPermissionRejected,
  editPermissionFulfilled,
  editPermissionRejected,
  deletePermissionFulfilled,
  deletePermissionRejected
} from '../actions/permissions';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchPermissionsEpic = (action$, store) => {
  return action$
    .ofType(PermissionsAction.FETCH_PERMISSIONS)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'permissions';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((ajaxResponse) => ajaxResponse.response.data)
        .map(fetchPermissionsFulfilled)
        .catch(fetchPermissionsRejected);
    });
};

const addPermissionEpic = (action$, store) => {
  return action$
    .ofType(PermissionsAction.ADD_PERMISSION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'permissions';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({
        url,
        method: 'POST',
        headers: headers,
        body: action.permission
      })
        .map((res) => res.response.data)
        .map(addPermissionFulfilled)
        .catch(addPermissionRejected);
    });
};

const editPermissionEpic = (action$, store) => {
  return action$
    .ofType(PermissionsAction.EDIT_PERMISSION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'permissions/' + action.permission.id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({
        url,
        method: 'PUT',
        headers: headers,
        body: action.permission
      })
        .map((res) => res.response.data)
        .map(editPermissionFulfilled)
        .catch(editPermissionRejected);
    });
};

const deletePermissionEpic = (action$, store) => {
  return action$
    .ofType(PermissionsAction.DELETE_PERMISSION)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'permissions/' + action.permissionId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'DELETE', headers: headers })
        .map((res) => res.response)
        .map(() => deletePermissionFulfilled(action.permissionId))
        .catch(deletePermissionRejected);
    });
};

export {
  fetchPermissionsEpic,
  addPermissionEpic,
  editPermissionEpic,
  deletePermissionEpic
};
