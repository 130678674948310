import { Map, fromJS } from 'immutable';

import { EnumDefaultsAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  projectEnumDefaults: Map(),
  projectTimestamp: 0,
  fetchingProjectEnumDefaults: ProcessStatus.INITIAL,

  translationEnumDefaults: Map(),
  translationTimestamp: 0,
  fetchingTranslationsEnumDefaults: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EnumDefaultsAction.FETCH_PROJECT_ENUM_DEFAULTS:
      return state.set('fetchingProjectEnumDefaults', ProcessStatus.STARTED);
    case EnumDefaultsAction.FETCH_PROJECT_ENUM_DEFAULTS_FULFILLED:
      return state
        .set('fetchingProjectEnumDefaults', ProcessStatus.FINISHED)
        .set('projectTimestamp', Date.now())
        .set('projectEnumDefaults', Map(action.enumDefaults.projects));
    case EnumDefaultsAction.FETCH_PROJECT_ENUM_DEFAULTS_REJECTED:
      return state
        .set('fetchingProjectEnumDefaults', ProcessStatus.FAILED)
        .set('error', action.payload);

    case EnumDefaultsAction.FETCH_TRANSLATION_ENUM_DEFAULTS:
      return state.set(
        'fetchingTranslationsEnumDefaults',
        ProcessStatus.STARTED
      );
    case EnumDefaultsAction.FETCH_TRANSLATION_ENUM_DEFAULTS_FULFILLED:
      return state
        .set('fetchingTranslationsEnumDefaults', ProcessStatus.FINISHED)
        .set('translationTimestamp', Date.now())
        .set('translationEnumDefaults', Map(action.enumDefaults.translations));
    case EnumDefaultsAction.FETCH_TRANSLATION_ENUM_DEFAULTS_REJECTED:
      return state
        .set('fetchingTranslationsEnumDefaults', ProcessStatus.FAILED)
        .set('error', action.payload);
    default:
      return state;
  }
};

export { initialState, reducer as enumDefaults };
