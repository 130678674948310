import React, { Component } from 'react';
import { Button, FontIcon } from 'react-md';
import { withRouter } from 'react-router-dom';
import VirtualizedSelect from 'react-select';
import { withTranslation } from 'react-i18next';
import StructureNode from './StructureNode';
import SearchBox from '../general/SearchBox';
import { showWarning, removeDialog } from '../../actions/globalDialog';
import GlobalHelp from '../general/help/GlobalHelp';

import styles from '../../styles/wbtViewer/wbtViewer.scss';
import buttonWhiteTheme from '../../styles/theme/_buttonWhite.scss';
import helpStyles from '../../styles/help.scss';
import { connect } from 'react-redux';
import { aclFilter } from '../../utils/ACL';
import { fetchTranslations } from '../../actions/translationTool';

@withTranslation(['translation_navigation', 'general'], { wait: true })
class StructureView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      unfoldAll: true,
      width: 500,
      labeledViewports: [],
      commentsCount: null,
      commentsAllCount: null
    };
    this.showHeadlines = true;
  }

  componentDidMount() {
    const {
      match: {
        params: { translationId }
      }
    } = this.props;

    this.setState({
      labeledViewports: this.labelViewports(this.props.viewports)
    });

    let translationsData = {
      currentExternalId: undefined,
      targetTranslationId: translationId,
      sourceTranslationId: translationId
    };
    this.props.fetchTranslations(translationsData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { viewports, t } = nextProps;
    if (
      t !== this.props.t ||
      JSON.stringify(viewports) !== JSON.stringify(this.props.viewports)
    ) {
      this.setState({ labeledViewports: this.labelViewports(viewports) });
    }

    if (nextProps.iframeLoaded && !this.state.commentsCount) {
      const commentsCountArray = this.getCommentsCountArray();
      this.setState(
        {
          commentsCount: commentsCountArray,
          commentsAllCount: this.getAllCommentsCountHelper(commentsCountArray)
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  getCommentsCountArray = () => {
    const { translations } = this.props;
    const trArray = translations.toArray();
    const siteTexts = trArray.map((item) => {
      return {
        id: item.id,
        commentsCount: this.getCommentsCountHelper(item.data)
      };
    });
    return siteTexts;
  };

  getCommentsCountHelper = (a) => {
    let siteCommentCount = 0;
    for (var u = 0; u < a.length; u++) {
      if (a[u].to.comments) {
        siteCommentCount += a[u].to.comments.length;
      }
    }
    return siteCommentCount;
  };

  getAllCommentsCountHelper = (a) => {
    let commentCount = 0;
    for (var u = 0; u < a.length; u++) {
      commentCount += a[u].commentsCount;
    }
    return commentCount;
  };

  getCommentsCountPerSiteId = (siteId) => {
    if (!this.state.commentsCount) {
      return null;
    }
    let foundComment = 0;
    for (var i = 0; i < this.state.commentsCount.length; i++) {
      if (this.state.commentsCount[i].id === siteId) {
        foundComment = this.state.commentsCount[i].commentsCount;
        break;
      }
    }
    return foundComment;
  };

  changeMode = (newMode) => {
    this.setState({ mode: newMode });
  };

  toggleUnfoldAll = () => {
    this.setState({ unfoldAll: !this.state.unfoldAll });
  };

  navBackToProject = () => {
    const {
      match: {
        params: { projectId, translationId, sourceLanguageId }
      },
      showWarning,
      removeDialog,
      t
    } = this.props;

    let sourceIdPart = sourceLanguageId ? '/' + sourceLanguageId : '';
    let component = (
      <div className={styles.warningBtnRow}>
        <Button
          raised
          className={'warningBtn'}
          onClick={() => {
            // window.removeEventListener('beforeunload', this.handleLeavePage);
            removeDialog(this.props.dialogs.get(0).identifier);
            this.props.history.push(
              '/projects/' +
                projectId +
                '/translations/' +
                translationId +
                sourceIdPart
            );
          }}
          style={{ marginRight: '20px' }}
        >
          {t('warning_back_btn')}
        </Button>
        <Button
          flat
          onClick={() => {
            removeDialog(this.props.dialogs.get(0).identifier);
          }}
        >
          {t('warning_stay_btn')}
        </Button>
      </div>
    );
    showWarning({
      title: t('warning_title'),
      text: t('warning_text'),
      actions: [],
      component: component,
      isCancellable: false
    });
  };

  onDownResizer = (e) => {
    e.preventDefault();
    document.body.addEventListener('mousemove', this.onMoveResizer);
    e.target.setAttribute('active', 'true');
  };

  onMoveResizer = (e) => {
    let transformValue =
      e.clientX - e.target.parentNode.getBoundingClientRect().width;
    e.target.style.transform = 'translateX(' + transformValue + 'px)';
  };

  onUpResizer = (e) => {
    document.body.removeEventListener('mousemove', this.onMoveResizer);
    e.target.style.transform = 'translateX(0px)';
    e.target.removeAttribute('active');
    this.setState({ width: e.clientX });
  };

  getActiveTranslationName = () => {
    if (!this.props.project) {
      return;
    }
    let translationId = parseInt(this.props.match.params.translationId);
    let currentTranslation = null;
    this.props.project.translations.map((translation) => {
      if (translationId === translation.id) {
        currentTranslation = translation;
        return;
      }
    });
    let returnString =
      currentTranslation.languagecode + ' - ' + currentTranslation.name;
    return returnString;
  };

  getSourceLanguageName = () => {
    const { t, source } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (source.size == 0) {
      return;
    }
    let returnString = t(`${generalLNS}:source`) + ': ' + source.get('name');
    return returnString;
  };

  renderChildren = (
    children,
    collapsed = false,
    level = 0,
    expandParent = null
  ) => {
    const {
      currentPageId,
      currentCoursePageId,
      makeExternalLink,
      searchTerm,
      matchingIds,
      onNodeSelect
    } = this.props;

    const { unfoldAll } = this.state;

    if (typeof children !== 'undefined' && children.length > 0) {
      let className = 'level' + level;
      if (collapsed) {
        className += ' childrenCollapsed';
      }

      // TODO: Parent expanden, wenn direktzugriff mit Hash erfolgt
      return (
        <ul className={className} showheadlines={this.showHeadlines.toString()}>
          {children.map((child, key) => {
            if (
              searchTerm.length > 0 &&
              !matchingIds.includes(child.external_id)
            ) {
              return false;
            }

            const isSelected = currentPageId === child.external_id;
            const isInCourseSelected =
              currentCoursePageId === child.external_id;
            const showingHeadlines = this.showHeadlines;
            let refName = 'ref_structurenode_' + child.external_id; // wird zum scrollen in translationView genutzt

            return (
              <StructureNode
                refName={refName}
                key={key}
                node={child}
                level={level}
                chapter={key + 1}
                expandParent={expandParent}
                onNodeSelect={onNodeSelect}
                childHandler={this.renderChildren}
                makeExternalLink={makeExternalLink}
                isSelected={isSelected}
                isInCourseSelected={isInCourseSelected}
                showingHeadlines={showingHeadlines}
                foldAll={!unfoldAll}
                getCommentsCountPerSiteId={this.getCommentsCountPerSiteId}
              />
            );
          })}
        </ul>
      );
    }
  };

  labelViewports = (viewports) => {
    const { t } = this.props;

    return viewports.map((viewport) => {
      viewport.label = `${t(viewport.name)}: ${viewport.width} x ${
        viewport.height
      } px`;
      return viewport;
    });
  };

  render() {
    const {
      project,
      screenHeight,
      toggleStructureView,
      structure,
      searchTerm,
      handleSearchInput,
      handleSearchReset,
      t,
      helpOpened,
      openHelp,
      closeHelp,
      selectedViewport,
      currentPageId,
      invisible
    } = this.props;
    const { width, labeledViewports, commentsAllCount } = this.state;
    const scrollerWrapperHeight = screenHeight - 280 + 'px';
    const unfoldIcon = this.state.unfoldAll
      ? 'keyboard_arrow_down'
      : 'keyboard_arrow_up';
    const unfoldText = this.state.unfoldAll
      ? t('fold_all_chapters')
      : t('unfold_all_chapters');
    const themeUrl = this.props.isDark
      ? 'url(' + require('../../images/theme_on.svg') + ')'
      : 'url(' + require('../../images/theme_off_dark.svg') + ')';
    const generalLNS = 'general';

    if (labeledViewports == null) {
      return null;
    }

    // SEARCH SITE-ID AND MARK SELECTED CHAPTER:
    let newStructure = structure.childs;
    if (currentPageId && typeof currentPageId === 'string') {
      if (structure.childs) {
        let activeChapter = -1;
        let activeSubChapter = -1;
        for (var z = 0; z < newStructure.length; z++) {
          let c = newStructure[z];
          if (currentPageId === c.external_id) {
            activeChapter = newStructure[z].id;
            break;
          }
          for (var zz = 0; zz < c.childs.length; zz++) {
            let cc = c.childs[zz];
            if (currentPageId === cc.external_id) {
              activeChapter = newStructure[z].id;
              break;
            }
            for (var zzz = 0; zzz < cc.childs.length; zzz++) {
              let ccc = cc.childs[zzz];
              if (currentPageId === ccc.external_id) {
                activeChapter = newStructure[z].id;
                activeSubChapter = c.childs[zz].id;
                break;
              }
            }
            if (activeChapter !== -1) {
              break;
            }
          }
          if (activeChapter !== -1) {
            break;
          }
        }
        if (activeChapter !== -1) {
          for (var r = 0; r < newStructure.length; r++) {
            let item = newStructure[r];
            newStructure[r].isActiveChapter = activeChapter === item.id;

            if (activeSubChapter !== -1 && item.childs) {
              for (var rr = 0; rr < item.childs.length; rr++) {
                let item2 = item.childs[rr];
                item.childs[rr].isActiveChapter = activeSubChapter === item2.id;
              }
            }
          }
        }
      }
    }

    return (
      <div
        className={
          invisible ? styles.structureView + ' invisible' : styles.structureView
        }
        style={{ width: width + 'px' }}
      >
        <div className={'structureViewOverflowWrapper'} help-tool='1'>
          <div className={'logoAndBtnRow'}>
            <button
              className={'backBtn'}
              onClick={this.navBackToProject}
              theme={buttonWhiteTheme}
              title={'back to project overview'}
            >
              <FontIcon>keyboard_arrow_left</FontIcon>
            </button>

            <span
              className={'logoImgWrapper'}
              title={t('click_to_get_back_to_slash_projects')}
            >
              <img
                className={'logoLight'}
                src={require('../../images/logo-structureview_light.png')}
              />
              <img
                className={'logoDark'}
                src={require('../../images/logo-structureview_dark.png')}
              />
            </span>

            {aclFilter(
              <button
                className={'liveTranslateInlineButton'}
                active={this.props.isTranslationToolInline.toString()}
                title={'set translationtool inline'}
                onClick={this.props.toggleTranslationInline}
              >
                <FontIcon>layers</FontIcon>
              </button>
            )(['admin'])}

            <button
              help-tool='2'
              title={
                this.props.isDark
                  ? t(`${generalLNS}:theme_btn_off`)
                  : t(`${generalLNS}:theme_btn_on`)
              }
              onClick={this.props.toggleTheme}
              className={this.props.isDark ? 'themeButton dark' : 'themeButton'}
            >
              <span style={{ backgroundImage: themeUrl }}></span>
            </button>
            <button
              key={'help'}
              help-tool='3'
              title={t(`${generalLNS}:open_help`)}
              className={helpStyles.helpButton + ' helpBtn'}
              onClick={openHelp}
            >
              <FontIcon>help</FontIcon>
            </button>
            <GlobalHelp show={helpOpened} close={closeHelp} />
            <button
              help-tool='4'
              className={'toggleStructureView'}
              onClick={toggleStructureView}
              title={'hide sidebar'}
              theme={buttonWhiteTheme}
            >
              <FontIcon>vertical_align_bottom</FontIcon>
            </button>
          </div>

          <div className={'wbtNameWrapper'} help-tool='5'>
            <div
              className={'wbtName'}
              title={project !== null ? project.name : ''}
            >
              {project !== null && project.name}
            </div>

            <div className={'translationNameWrapper'}>
              <span
                className={'translationName'}
                title={this.getActiveTranslationName()}
              >
                {this.getActiveTranslationName()}
              </span>
              {this.props.match.params.sourceLanguageId && (
                <span
                  className={'sourceName'}
                  title={this.getSourceLanguageName()}
                >
                  &nbsp;&nbsp; - &nbsp;{this.getSourceLanguageName()}
                </span>
              )}
            </div>

            {commentsAllCount !== null && commentsAllCount > 0 && (
              <div className='commentsCount'>
                {t(`${generalLNS}:comments_count`)}: {commentsAllCount}
              </div>
            )}
          </div>

          <div className={'searchWrapper'} help-tool='7'>
            <SearchBox
              placeholder={t('search')}
              customClass={'searchBox'}
              searchTerm={searchTerm}
              handleSearchInput={handleSearchInput}
              handleSearchReset={handleSearchReset}
            />
          </div>

          <div className={'structureTools'}>
            <Button
              help-tool='8'
              children={unfoldText}
              className={'unfoldButton'}
              onClick={this.toggleUnfoldAll}
              flat
              primary
              swapTheming
              iconChildren={unfoldIcon}
              iconBefore={true}
            />

            <div className={'structureToolsButtonsRight'}>
              <button
                help-tool='9'
                className={'headlineButton'}
                onClick={() => {
                  this.showHeadlines = !this.showHeadlines;
                  this.forceUpdate();
                  this.headlineText1.style.opacity = this.showHeadlines
                    ? '1'
                    : '0.3';
                  this.headlineText2.style.opacity = this.showHeadlines
                    ? '1'
                    : '0.3';
                }}
              >
                <span>ID</span>
                <span ref={(span) => (this.headlineText1 = span)}>
                  &nbsp;/&nbsp;
                </span>
                <span ref={(span) => (this.headlineText2 = span)}>
                  {t('headline')}
                </span>
              </button>
            </div>
          </div>

          <div
            help-tool='6'
            ref-name='structure-wrapper' // wird zum scrollen in translationView genutzt
            className={'scrollerWrapper'}
            style={{ height: scrollerWrapperHeight }}
          >
            <div className={'scroller'}>
              <div>
                {commentsAllCount !== null && this.renderChildren(newStructure)}
              </div>
            </div>
          </div>

          <div className={'bottomBar'} help-tool='10'>
            {selectedViewport && (
              <VirtualizedSelect
                name='select-language'
                simpleValue={false}
                className={'responsiveSelect'}
                classNamePrefix={'language-select-'}
                clearable={false}
                searchable={false}
                hideSelectedOptions={false}
                isDisabled={false}
                blurInputOnSelect
                ref={(VirtualizedSelect) =>
                  (this.resizeSelect = VirtualizedSelect)
                }
                value={selectedViewport}
                setValue={selectedViewport}
                title={selectedViewport}
                options={labeledViewports}
                onChange={this.props.handleResizeSelect}
                isOptionSelected={(labeledViewports) =>
                  labeledViewports.selected
                }
                // menuIsOpen={true} // ALWAYS OPEN FOR DEV

                theme={
                  // theme => {
                  () => {
                    // console.log('theme', theme); // => zum debuggen
                    return {
                      spacing: {
                        baseUnit: 4,
                        controlHeight: 65,
                        menuGutter: 8
                      },
                      borderRadius: 0,
                      colors: {
                        primary25: '#ff5900', // hover
                        primary: '#ff8b4c', // selected
                        primary50: '#ff5900', // clicked
                        primary75: '#4C9AFF',
                        danger: '#DE350B',
                        dangerLight: '#FFBDAD',
                        neutral0: 'transparent', // HINTERGRUND
                        neutral5: 'hsl(0, 0%, 95%)',
                        neutral10: 'hsl(0, 0%, 90%)',
                        neutral20: 'hsl(0, 0%, 80%)',
                        neutral30: 'hsl(0, 0%, 70%)',
                        neutral40: 'hsl(0, 0%, 60%)',
                        neutral50: 'hsl(0, 0%, 50%)',
                        neutral60: 'hsl(0, 0%, 40%)',
                        neutral70: 'hsl(0, 0%, 30%)',
                        neutral80: 'hsl(0, 0%, 20%)',
                        neutral90: 'hsl(0, 0%, 10%)'
                      }
                    };
                  }
                }
              />
            )}
          </div>
          <div
            className={'structureViewResizer'}
            onMouseDown={this.onDownResizer}
            onMouseUp={this.onUpResizer}
          >
            {' '}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    dialogs: state.getIn(['globalDialog', 'dialogs']),
    translations: state.getIn(['translationTool', 'translations'])
  };
}

export default withRouter(
  connect(mapStateToProps, { showWarning, removeDialog, fetchTranslations })(
    StructureView
  )
);
