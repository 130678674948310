import React, { Component } from 'react';
import { RichUtils } from 'draft-js';
import Modal from '../components/Modal';
import stylesEditor from '../style/mainEditorStyle.scss';
import { Button, TextField } from 'react-md';
import { withTranslation } from 'react-i18next';

@withTranslation(['translation_navigation'], { wait: true })
class LinkSource extends Component {
  constructor(props) {
    super(props);
    const { entity } = this.props;
    const state = {
      url: ''
    };
    if (entity) {
      const data = entity.getData();
      state.url = data.url;
    }
    this.state = state;
  }

  // UNSAFE_componentWillMount () {
  //   setTimeout(() => {
  //     this.inputRef.focus();
  //   }, 500);
  // }
  // componentWillUnmount () {
  //   this.inputRef.blur();
  // }

  onConfirm = () => {
    const { editorState, onComplete } = this.props;
    const { url } = this.state;
    const contentState = editorState.getCurrentContent();
    const data = {
      url: url.replace(/\s/g, '')
    };
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      data
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const nextState = RichUtils.toggleLink(
      editorState,
      editorState.getSelection(),
      entityKey
    );
    onComplete(nextState);
    this.props.focusEditor();
  };

  onRequestClose = (e) => {
    const { onClose } = this.props;
    e.preventDefault();
    onClose();
  };

  onAfterOpen = () => {
    // const input = this.inputRef;
    //
    // if (input) {
    this.inputRef.focus();
    // input.select();
    // }
  };

  onChangeURL = (value) => {
    this.setState({ url: value });
  };

  // onChangeURL = e => {
  //   if (e.target) {
  //     const url = e.target.value;
  //     this.setState({ url });
  //   }
  // };

  render() {
    const { url } = this.state;
    const { t, onClose } = this.props;
    return (
      <Modal
        onRequestClose={this.onRequestClose}
        onAfterOpen={this.onAfterOpen}
        isOpen
        contentLabel='Link chooser'
      >
        <form
          className={stylesEditor.linkSource + ' md-background--card'}
          onSubmit={this.onConfirm}
        >
          <TextField
            ref={(el) => {
              this.inputRef = el;
            }}
            id='url'
            name='url'
            value={url}
            label={t('url_or_email')}
            placeholder='www.example.com'
            onChange={this.onChangeURL}
          />
          {/*
          <input
            ref={(inputRef) => {
              this.inputRef = inputRef;
            }}
            type="text"
            onChange={this.onChangeURL}
            value={url}
            placeholder="www.example.com"
          />
          */}
          <Button
            children={t('insert')}
            raised
            primary
            className={'submit'}
            type='submit'
          />
          <Button icon className={'close'} onClick={onClose} primary>
            close
          </Button>
        </form>
      </Modal>
    );
  }
}

export default LinkSource;
