import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';

import {
  renderTextField,
  renderDatePicker
} from '../../general/BasicFormComponents';

import styles from '../../../styles/project_detail.scss';
import { normalizeDatePickerDate } from '../../../utils/HelperFunctions';

@withTranslation(['project', 'general'], { wait: true })
class ProjectTimestamps extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { editMode, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div>
        <div className={styles.header}>
          <h2 className={'headName'}>{t(`${generalLNS}:timestamps`)}</h2>
        </div>

        <div className='editableElements'>
          <Field
            id='go_live'
            name='go_live'
            label={t('go_live')}
            type='text'
            // className="md-cell md-cell--4-tablet md-cell--4-phone md-cell--bottom"
            className={styles.formField}
            required
            disabled={!editMode}
            component={renderDatePicker}
            normalize={normalizeDatePickerDate}
          />
          <Field
            id='project_end'
            name='project_end'
            label={t('project_end')}
            type='text'
            className={styles.formField}
            required
            disabled={!editMode}
            component={renderDatePicker}
            normalize={normalizeDatePickerDate}
          />
        </div>

        <div className='notEditableElements'>
          <Field
            id='created_at'
            name='created_at'
            label={t(`${generalLNS}:created_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
          <Field
            id='imported_at'
            name='imported_at'
            label={t(`${generalLNS}:imported_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
          <Field
            id='updated_at'
            name='updated_at'
            label={t(`${generalLNS}:updated_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
          <Field
            id='exported_at'
            name='exported_at'
            label={t(`${generalLNS}:exported_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

export default connect()(ProjectTimestamps);
