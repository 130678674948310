import { Map, List, fromJS } from 'immutable';
import { ViewportsAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  viewports: Map(),
  formattedViewports: List([]),
  timestamp: 0,
  width: '800',
  height: '600',
  viewport: 'el',
  fetchingViewports: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ViewportsAction.FETCH_VIEWPORTS:
      return state.set('fetchingViewports', ProcessStatus.STARTED);
    case ViewportsAction.FETCH_VIEWPORTS_FULFILLED:
      return state
        .set('fetchingViewports', ProcessStatus.FINISHED)
        .set('timestamp', Date.now())
        .set('viewports', Map(action.viewports))
        .set(
          'formattedViewports',
          Map(action.viewports).map((key, value) => {
            return { value: key, label: value };
          })
        );
    case ViewportsAction.FETCH_VIEWPORTS_REJECTED:
      return state
        .set('fetchingViewports', ProcessStatus.FAILED)
        .set('error', action.payload);
    case ViewportsAction.UPDATE_WIDTH:
      return state.set('width', action.width.toString());
    case ViewportsAction.UPDATE_HEIGHT:
      return state.set('height', action.height.toString());
    case ViewportsAction.UPDATE_VIEWPORT:
      return state.set('viewport', action.viewport);
    case ViewportsAction.SET_CUSTOM_VIEWPORT:
      console.log('reducers:: setCustomViewport');
      break;
    default:
      return state;
  }
};

export { initialState, reducer as viewports };
