import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ExpansionPanel } from 'react-md';
import CustomTable from '../../general/CustomTable';
import { VoFilesModel } from '../../../tableModels';
import styles from '../../../styles/projects/expansionpanel/general.scss';
import { aclFilter } from '../../../utils/ACL';
import { aclTranslation } from '../../../utils/Permissions';
import { Scope } from '../../../utils/constants/ACL';
import AsyncSwitch from '../../general/AsyncSwitch';
import { updateAdditionalContents } from '../../../actions/additionalContents';
import { ProcessStatus } from '../../../utils/Constants';

@withTranslation(['additional_content', 'general'], { wait: true })
class AdditionalContentPanelVO extends Component {
  static propTypes = {
    // Notice these three props. They are injected via the `ExpansionList` component
    // and are required to get correct styling and keyboard accessibility.
    focused: PropTypes.bool,
    overflown: PropTypes.bool,
    columnWidths: PropTypes.arrayOf(PropTypes.number)
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      isExpandedOther: false,
      additionalContentType: 'voiceover',
      additionalContent: null
    };
  }

  UNSAFE_componentWillMount() {
    const { additionalContents } = this.props;
    let acArray = additionalContents.toArray();
    this.setState({
      additionalContent: this.getAC(acArray),
      disabled: false
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updatingAdditionalContents, additionalContents } = nextProps;

    if (updatingAdditionalContents === ProcessStatus.STARTED) {
      this.setState({ disabled: true });
    }
    if (
      updatingAdditionalContents !== this.props.updatingAdditionalContents &&
      updatingAdditionalContents === ProcessStatus.FINISHED
    ) {
      let acArray = additionalContents.toArray();
      this.setState({
        additionalContent: this.getAC(acArray),
        disabled: false
      });
    }
  }

  getAC = (arrayFromStore) => {
    let returnValue = -1;
    for (let i = 0; i < arrayFromStore.length; i++) {
      if (arrayFromStore[i].type === this.state.additionalContentType) {
        returnValue = arrayFromStore[i];
        break;
      }
    }
    return returnValue;
  };

  handleRowClick = (currentId) => {
    // tut noch nichts
    console.log('my handleSortClick, currentId = ', currentId);
  };

  handleExpandToggle = (
    isExpanded,
    name = this.state.name,
    selected = this.state.selected
  ) => {
    this.setState({
      isExpanded: isExpanded,
      name: name,
      selected: selected
    });
  };

  handleExpandOtherToggle = (
    isExpandedOther,
    name = this.state.name,
    selected = this.state.selected
  ) => {
    this.setState({
      isExpandedOther: isExpandedOther,
      name: name,
      selected: selected
    });
  };

  handleChangeVo = () => {
    // console.log('handleChangeVo');
    const { projectId, translationId } = this.props;
    const { additionalContent } = this.state;
    this.props.updateAdditionalContents(
      projectId,
      translationId,
      additionalContent.id,
      {
        status:
          additionalContent.status === 'approved' ? 'not_approved' : 'approved'
      }
    );
  };

  render() {
    const { additionalContentVo, t, additionalContentVoOther } = this.props;
    const { isExpanded, isExpandedOther, disabled, additionalContent } =
      this.state;

    const batch = additionalContentVo.length + t('vo_files');
    const approved = additionalContent.status === 'approved';

    return (
      <ExpansionPanel
        expanded={isExpanded}
        onExpandToggle={this.handleExpandToggle}
        label={t('additionalContent_vo')}
        secondaryLabel={batch}
        footer={null}
        className={styles.expansionPanel}
        headerClassName={styles.expansionHeader}
        focused={this.props.focused}
        columnWidths={this.props.columnWidths}
      >
        <div className={disabled ? 'actionRow disabled' : 'actionRow'}>
          {additionalContent && (
            <AsyncSwitch
              key={'activateAllVoFiles'}
              id={'activateAllVoFiles'}
              name={'activateAllVoFiles'}
              label={t('general:all_approved')}
              labelBefore
              checked={approved}
              value={approved}
              disabled={disabled}
              onChange={this.handleChangeVo}
            />
          )}
        </div>
        <CustomTable
          selected={this.state.selected}
          rows={additionalContentVo}
          model={VoFilesModel}
          onRowClick={null}
        />
        {aclFilter(
          <ul style={{ padding: '20px 0 0 0', listStyle: 'none' }}>
            <ExpansionPanel
              expanded={isExpandedOther}
              onExpandToggle={this.handleExpandOtherToggle}
              label={t('additionalContent_vo_other')}
              secondaryLabel={batch}
              footer={null}
              className={styles.expansionPanel}
              headerClassName={styles.expansionHeader}
              focused={this.props.focused}
              columnWidths={this.props.columnWidths}
            >
              <CustomTable
                selected={this.state.selected}
                rows={additionalContentVoOther}
                model={VoFilesModel}
                onRowClick={null}
              />
            </ExpansionPanel>
          </ul>
        )(
          [],
          [aclTranslation.read_additional_content_other],
          Scope.TRANSLATION
        )}
      </ExpansionPanel>
    );
  }
}

function mapStateToProps(state) {
  return {
    updatingAdditionalContents: state.getIn([
      'additionalContents',
      'updatingAdditionalContents'
    ]),
    additionalContents: state.getIn([
      'additionalContents',
      'additionalContents'
    ])
  };
}

export default connect(mapStateToProps, { updateAdditionalContents })(
  AdditionalContentPanelVO
);
