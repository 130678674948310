import React from 'react';
import { connect } from 'react-redux';
import { Button, FileUpload } from 'react-md';
import DeleteImageDialog from './warnings/DeleteImageWarning';
import WrongFormatDialog from './warnings/WrongFormatWarning';
import {
  switchMode,
  loadImage,
  deleteImage,
  restoreImage,
  addImage,
  editImage
} from '../../../actions/image';
import { showWarning } from '../../../actions/globalDialog';
import { withTranslation } from 'react-i18next';

@withTranslation(['project', 'general'], { wait: true })
class ImageEdit extends React.Component {
  state = {
    ctx: null,
    width: 350,
    height: 197,
    dragStart: null,
    dragged: null,
    scaleFactor: 1.05,
    lastX: null,
    lastY: null,
    src: null,
    img: null,
    imageDataURL: null,
    proportion: null,
    initialProportion: null,
    oldImage: null,
    alt: null,
    canvas: null,
    prescaledCanvas: null,
    persisted: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.container) {
      this.container.addEventListener('wheel', this.handlePrevent);
    }
    const { img, alt, persisted } = this.props;
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');

    this.setState({ src: img, alt, ctx, canvas, persisted });
    this.trackTransforms(ctx);
    this.prescale();
  }

  componentWillUnmount() {
    if (this.container) {
      this.container.removeEventListener('wheel', this.handlePrevent);
    }
  }

  handlePrevent = (e) => {
    e.preventDefault();
  };

  getRightProportion = (width, height, canvas) => {
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const canvasProportion = height / width;
    const imgProportion = imgHeight / imgWidth;
    if (imgProportion < canvasProportion) {
      return height / imgHeight;
    }
    return width / imgWidth;
  };

  prescale = () => {
    const { height, width } = this.state;
    const offScreenCanvas = document.createElement('canvas');
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      const heightImg = image.height;
      const widthImg = image.width;
      offScreenCanvas.height = heightImg;
      offScreenCanvas.width = widthImg;
      const context = offScreenCanvas.getContext('2d');
      const prop = this.getRightProportion(width, height, image);
      context.drawImage(image, 0, 0, widthImg, heightImg);
      let i = 1;
      while (i * prop < 0.25) {
        context.drawImage(
          offScreenCanvas,
          0,
          0,
          widthImg / i,
          heightImg / i,
          0,
          0,
          widthImg / (2 * i),
          heightImg / (2 * i)
        );
        i = i * 2;
      }
      context.drawImage(
        offScreenCanvas,
        0,
        0,
        widthImg / i,
        heightImg / i,
        0,
        0,
        widthImg,
        heightImg
      );
      this.initImage(offScreenCanvas);
    };
    image.src = this.props.img;
  };

  initImage = (prescaledCanvas) => {
    const canvas = this.refs.canvas;
    const { zoom, translationX, translationY } = this.props;
    canvas.height = 197;
    canvas.width = 350;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const ctx = canvas.getContext('2d');
    const proportion = this.getRightProportion(width, height, prescaledCanvas);
    let currentProportion;
    if (zoom !== null) {
      ctx.setTransform(zoom, 0, 0, zoom, translationX, translationY);
      currentProportion = zoom;
    } else {
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.scale(proportion, proportion);
    }
    this.setState({
      proportion: currentProportion || proportion,
      initialProportion: proportion
    });

    this.setState({
      width,
      height,
      lastX: width / 2,
      lastY: height / 2,
      ctx,
      canvas,
      prescaledCanvas
    });
  };

  componentDidUpdate() {
    const { persisted } = this.props;
    if (persisted === false && this.state.persisted !== persisted) {
      this.setState({ src: this.props.img, persisted }, this.prescale);
    }
    this.drawImage();
  }

  drawImage = () => {
    const { width, height, prescaledCanvas } = this.state;
    if (prescaledCanvas) {
      const context = this.state.ctx;
      const p1 = context.transformedPoint(0, 0);
      const p2 = context.transformedPoint(width, height);
      context.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
      context.save();
      context.setTransform(1, 0, 0, 1, 0, 0);
      context.clearRect(0, 0, width, height);
      context.restore();
      context.drawImage(prescaledCanvas, 0, 0);
    }
  };

  handleSave = () => {
    const canvas = this.refs.canvas;
    const projectId = this.props.project.id;
    const dataURL = canvas.toDataURL('image/jpeg', 1.0);
    const { ctx } = this.state;
    this.setState({ src: dataURL });
    const SVGMatrix = ctx.getTransform();
    this.props.switchMode();
    const payload = {
      image: this.props.img,
      croppedImage: dataURL,
      originalExtension: this.props.originalExtension,
      zoom: SVGMatrix.a,
      translationX: SVGMatrix.e,
      translationY: SVGMatrix.f,
      projectId
    };
    if (this.props.persisted) {
      this.props.editImage(payload);
    } else {
      this.props.addImage(payload);
    }
  };

  handleRestore = () => {
    this.setState({ img: this.state.oldImage }, this.prescale);
    this.props.restoreImage();
  };

  handleDelete = () => {
    const { showWarning, t } = this.props;

    let title = t('general:warning');
    let text = t('delete_image_confirmation');

    let component = <DeleteImageDialog projectId={this.props.project.id} />;

    showWarning({
      title: title,
      text: text,
      actions: [],
      component: component,
      isCancellable: false
    });
    // this.props.deleteImage(this.props.project.id);
  };

  showFormatWarning = () => {
    const { showWarning, t } = this.props;

    let title = t('general:warning');
    let text = t('wrong_file_format');

    let component = <WrongFormatDialog />;

    showWarning({
      title,
      text,
      actions: [],
      component,
      isCancellable: false
    });
  };

  handleLoad = (uploadedFile, uploadedData) => {
    const { name, size, type, lastModified } = uploadedFile;
    const file = {
      id: 1, // TODO: give a right id
      name,
      size,
      type,
      data: uploadedData,
      lastModified: new Date(lastModified)
    };
    const whitelistedTypes = ['image/png', 'image/jpeg'];

    if (whitelistedTypes.includes(file.type)) {
      const ext = file.type.split('/')[1];
      this.props.loadImage({ data: uploadedData, originalExtension: ext });
      this.setState({
        oldImage: this.state.img,
        imageDataURL: uploadedData,
        dragStart: null,
        dragged: null
      });

      this.prescale();
    } else {
      this.showFormatWarning();
    }
  };

  handleMouseDown = (evt) => {
    evt.preventDefault();
    document.body.addEventListener('mousemove', this.handleMouseMove, false);
    document.body.addEventListener('mouseup', this.handleMouseUp, false);
    let target = this.container;
    let targetRect = target.getBoundingClientRect();
    const { ctx } = this.state;
    const lastX = evt.pageX - targetRect.left;
    const lastY = evt.pageY - targetRect.top;
    this.setState({
      dragStart: ctx.transformedPoint(lastX, lastY),
      dragged: false,
      lastX,
      lastY
    });
  };

  handleMouseMove = (evt) => {
    let target = this.container;
    let targetRect = target.getBoundingClientRect();
    const { dragStart, ctx } = this.state;
    const lastX = evt.pageX - targetRect.left;
    const lastY = evt.pageY - targetRect.top;
    this.setState({ dragged: true, lastX, lastY });
    if (dragStart) {
      const pt = ctx.transformedPoint(lastX, lastY);
      ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
    }
  };

  zoom = (clicks) => {
    const { ctx, proportion, initialProportion } = this.state;
    let factor = Math.pow(this.state.scaleFactor, clicks);
    const newProportion = proportion * factor;

    if (newProportion <= initialProportion) {
      factor = initialProportion / proportion;

      ctx.scale(factor, factor);
      this.setState({
        proportion: initialProportion
      });
    } else {
      ctx.scale(factor, factor);
      this.setState({
        proportion: newProportion
      });
    }
    ctx.translate(0, 0);
  };

  handleMouseUp = (evt) => {
    if (!this.state.dragged) {
      this.zoom(evt.shiftKey ? -1 : 1);
    }
    this.setState({ dragStart: null, dragged: false });
    document.body.removeEventListener('mousemove', this.handleMouseMove, false);
    document.body.removeEventListener('mouseup', this.handleMouseUp, false);
  };

  handleWheel = (evt) => {
    const variant1 = evt.deltaY / 40;
    const variant2 = evt.detail ? -evt.detail : 0;
    const delta = evt.deltaY ? variant1 : variant2;
    if (delta) {
      this.zoom(delta);
    }
  };

  handleZoomIn = () => {
    this.zoom(2.5);
  };

  handleZoomOut = () => {
    this.zoom(-2.5);
  };

  trackTransforms = (ctx) => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let xForm = svg.createSVGMatrix();
    ctx.getTransform = function () {
      return xForm;
    };

    let savedTransforms = [];

    const save = ctx.save;
    ctx.save = function () {
      savedTransforms.push(xForm.translate(0, 0));
      return save.call(ctx);
    };

    const restore = ctx.restore;
    ctx.restore = function () {
      xForm = savedTransforms.pop();
      return restore.call(ctx);
    };

    const _scale = ctx.scale;
    ctx.scale = (sx, sy) => {
      xForm = xForm.scale(sx);
      return _scale.apply(ctx, [sx, sy]);
    };

    const rotate = ctx.rotate;
    ctx.rotate = function (radians) {
      xForm = xForm.rotate((radians * 180) / Math.PI);
      return rotate.call(ctx, radians);
    };

    const _translate = ctx.translate;
    ctx.translate = (dx, dy) => {
      const { height, width, prescaledCanvas } = this.state;
      const proportion = xForm.d;
      const currentWidth = prescaledCanvas.width * proportion;
      const currentHeight = prescaledCanvas.height * proportion;
      const translationX = xForm.e;
      const translationY = xForm.f;
      const newTranslationX = translationX + dx;
      const newTranslationY = translationY + dy;
      if (newTranslationX >= 0) {
        dx = -translationX;
      } else if (
        newTranslationX < 0 &&
        width > newTranslationX + currentWidth
      ) {
        if (dx === 0) {
          dx = (width - translationX - currentWidth) / proportion;
        } else {
          dx = width - translationX - currentWidth;
        }
      }

      if (newTranslationY >= 0) {
        dy = -translationY;
      } else if (
        newTranslationY < 0 &&
        height > newTranslationY + currentHeight
      ) {
        if (dy === 0) {
          dy = (height - translationY - currentHeight) / proportion;
        } else {
          dy = height - translationY - currentHeight;
        }
      }
      xForm = xForm.translate(dx, dy);
      return _translate.apply(ctx, [dx, dy]);
    };

    const transform = ctx.transform;
    ctx.transform = function (a, b, c, d, e, f) {
      let m2 = svg.createSVGMatrix();
      m2.a = a;
      m2.b = b;
      m2.c = c;
      m2.d = d;
      m2.e = e;
      m2.f = f;
      xForm = xForm.multiply(m2);
      return transform.call(ctx, a, b, c, d, e, f);
    };

    const setTransform = ctx.setTransform;
    ctx.setTransform = function (a, b, c, d, e, f) {
      xForm.a = a;
      xForm.b = b;
      xForm.c = c;
      xForm.d = d;
      xForm.e = e;
      xForm.f = f;
      return setTransform.call(ctx, a, b, c, d, e, f);
    };

    const pt = svg.createSVGPoint();
    ctx.transformedPoint = function (x, y) {
      pt.x = x;
      pt.y = y;
      return pt.matrixTransform(xForm.inverse());
    };
  };

  render() {
    const { isDummy, t, alt, croppedImg, editMode } = this.props;
    const disabledCanvas = isDummy ? 'disabledCanvas' : 'enabled';
    const controlsFade = this.state.dragged ? 'controlsFade' : '';
    const editButtonIcon = editMode ? 'close' : 'edit';
    const containerClassname = editMode ? 'imageEdit editing' : 'imageEdit';

    return (
      <div ref={(div) => (this.container = div)} className={containerClassname}>
        <canvas
          className={'imageContainer ' + disabledCanvas}
          ref='canvas'
          onMouseDown={this.handleMouseDown}
          onWheel={this.handleWheel}
        />
        <img
          className={'imageContainer'}
          src={croppedImg}
          alt={alt}
          draggable={false}
        />
        <div className={'controls controlsTop visible' + controlsFade}>
          <Button
            icon
            onClick={this.handleDelete}
            title={t('general:delete')}
            disabled={isDummy}
            className={'showOnlyOnEditing'}
          >
            delete
          </Button>
          <Button
            icon
            onClick={this.handleZoomIn}
            title={t('zoom_in')}
            disabled={isDummy}
            className={'showOnlyOnEditing'}
          >
            add
          </Button>
          <Button
            icon
            onClick={this.handleZoomOut}
            title={t('zoom_out')}
            disabled={isDummy}
            className={'showOnlyOnEditing'}
          >
            remove
          </Button>
          <FileUpload
            flat
            id='image-input'
            allowDuplicates
            accept='image/jpeg, image/png, .jpg, .jpeg'
            name={null}
            label=''
            className={'fileSelector showOnlyOnEditing'}
            onLoad={this.handleLoad}
            title={t('image_upload')}
          />
          <Button
            icon
            onClick={this.props.switchMode}
            title={t('general:close')}
            className={'editButton'}
            children={editButtonIcon}
          />
        </div>
        <div
          className={
            'controls controlsBottom showOnlyOnEditing ' + controlsFade
          }
        >
          <Button
            icon
            onClick={this.handleSave}
            title={t('general:save')}
            disabled={isDummy}
          >
            check
          </Button>
          <Button
            icon
            onClick={this.handleRestore}
            title={t('restore_image')}
            disabled={isDummy}
          >
            undo
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    editMode: state.getIn(['image', 'editMode']),
    croppedImg: state.getIn(['image', 'croppedImage']),
    persisted: state.getIn(['image', 'persisted']),
    isDummy: state.getIn(['image', 'isDummy']),
    img: state.getIn(['image', 'imgData']),
    zoom: state.getIn(['image', 'zoom']),
    translationX: state.getIn(['image', 'translationX']),
    translationY: state.getIn(['image', 'translationY']),
    originalExtension: state.getIn(['image', 'originalExtension']),
    project: state.getIn(['projects', 'selectedProject'])
  };
}

export default connect(mapStateToProps, {
  switchMode,
  loadImage,
  deleteImage,
  restoreImage,
  addImage,
  editImage,
  showWarning
})(ImageEdit);
