import * as Rx from 'rxjs';
import { ajax } from 'rxjs/observable/dom/ajax';

import { EnumDefaultsAction } from '../utils/ActionTypes';
import {
  receiveProjectEnumDefaults,
  receiveTranslationEnumDefaults
} from '../actions/enumDefaults';
import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchProjectEnumDefaultsEpic = (action$, store) => {
  return action$
    .ofType(EnumDefaultsAction.FETCH_PROJECT_ENUM_DEFAULTS)
    .debounceTime(850)
    .mergeMap(() => {
      let url = baseApiUrl + 'enumdefaults/projects';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(receiveProjectEnumDefaults)
        .catch((error) =>
          Rx.Observable.of({
            type: EnumDefaultsAction.FETCH_PROJECT_ENUM_DEFAULTS_REJECTED,
            payload: error.xhr.response,
            error: true
          })
        );
    });
};

const fetchTranslationEnumDefaultsEpic = (action$, store) => {
  return action$
    .ofType(EnumDefaultsAction.FETCH_TRANSLATION_ENUM_DEFAULTS)
    .debounceTime(880)
    .mergeMap(() => {
      let url = baseApiUrl + 'enumdefaults/translations';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map((res) => res.data)
        .map(receiveTranslationEnumDefaults)
        .catch((error) =>
          Rx.Observable.of({
            type: EnumDefaultsAction.FETCH_TRANSLATION_ENUM_DEFAULTS_REJECTED,
            payload: error.xhr.response,
            error: true
          })
        );
    });
};

export { fetchProjectEnumDefaultsEpic, fetchTranslationEnumDefaultsEpic };
