import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, FormSpy, Form } from 'react-final-form';
import { cryptedStore } from '../../utils/CryptedStore';
import { ProcessStatus } from '../../utils/Constants';
import i18n from '../../utils/i18n';
import { Button, FontIcon, DialogContainer } from 'react-md';
import ResetPasswordScreen from './ResetPasswordScreen';
import {
  login,
  resetPassword,
  requestResetPassword,
  resetPasswordFulfilled,
  getUsernameByToken
} from '../../actions/auth';
import LoadingBar from '../general/DumbComponent';
import {
  renderTextField,
  renderPasswordTextField
} from '../general/BasicFormComponents';
import GlobalHelp from '../general/help/GlobalHelp';
import { addToast, removeToast } from '../../actions/globalSnackbar';

import styles from '../../styles/login.scss';
import helpStyles from '../../styles/help.scss';

@withTranslation(['login', 'general'], { wait: true })
class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: null,
      hash: null,
      cubeSite: 'login',
      authenticated: false,
      submitRequestClicked: false,
      submitLoginClicked: false,
      token: '',
      newPasswordFilled: false,
      newPasswordConfirmed: false,
      helpOpened: false,
      showBackToLoginButtonOnReset: false,
      disableResetButton: false,
      showMobileWarning: false,
      showIEWarning: false,
      // IEVersion: null,
      usernameByToken: '',
      isSafari: false
    };
  }

  UNSAFE_componentWillMount() {
    this.checkIEVersion();
    this.checkIfMobile();

    if (this.props.match.params.token) {
      this.props.getUsernameByToken(this.props.match.params.token);
    }

    // Check if is Safari
    var ua = navigator.userAgent.toLowerCase();
    console.log('ua', ua);
    console.log('navigator.vendor', navigator.vendor);
    if (
      ua.indexOf('safari') !== -1 &&
      ua.indexOf('chrome') === -1 &&
      !this.state.safari
    ) {
      this.setState({ isSafari: true });
    }

    if (
      this.props.match.path === '/login/password-reset' ||
      this.props.match.path.includes('/login/password-reset/')
    ) {
      if (this.props.match.params.token) {
        this.setState({
          cubeSite: 'reset',
          token: this.props.match.params.token
        });
      } else {
        this.setState({ cubeSite: 'request' });
      }
    }
  }

  getFrom = () => {
    const { location } = this.props;
    let from = { pathname: '/' };
    if (location && location.state && location.state.from) {
      from = location.state.from;
    }
    return from;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { cubeSite, submitLoginClicked, submitRequestClicked } = this.state;
    const { history } = this.props;
    const {
      errorObject,
      resettingPassword,
      authenticated,
      loggingIn,
      requestingResetPassword
    } = nextProps;
    if (cubeSite === 'login') {
      if (authenticated) {
        cryptedStore.set('user', nextProps.user);
        i18n.changeLanguage(nextProps.user.languagecode, (err) => {
          if (err) return console.log('something went wrong loading', err);
        });
        this.setState({ authenticated: true });
        history.push(this.getFrom());
      }
      if (submitLoginClicked) {
        if (!authenticated && loggingIn === ProcessStatus.FAILED) {
          this.setState({ submitLoginClicked: false });
          this.displayWarning(errorObject);
        }
      }
    }

    if (cubeSite === 'reset') {
      if (resettingPassword === ProcessStatus.FAILED) {
        this.displayError(errorObject);
        this.setState({ disableResetButton: false });
      }
    }

    if (cubeSite === 'request') {
      if (submitRequestClicked) {
        if (requestingResetPassword === ProcessStatus.FAILED) {
          this.setState({ submitRequestClicked: false });
          this.displayError(errorObject);
        }
      }
    }

    if (nextProps.fetchingUsernameByToken === ProcessStatus.FINISHED) {
      this.setState({ usernameByToken: nextProps.usernameByToken });
    }
  }

  checkIEVersion = () => {
    let sAgent = window.navigator.userAgent;
    let Idx = sAgent.indexOf('MSIE');

    // If IE, return version number.
    if (Idx > 0) {
      return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)));
      // eslint-disable-next-line brace-style
    }
    // If IE 11 then look for Updated user agent string.
    else if (navigator.userAgent.match(/Trident\/7\./)) {
      this.setState({
        showIEWarning: true // ,
        // IEVersion: 11
      });
      // return 11;
    }
    // return 0; // It is not IE
  };

  hideIEWarning = () => {
    this.setState({
      showIEWarning: false
    });
  };

  checkIfMobile = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.setState({
        showMobileWarning: true
      });
    }
  };

  hideMobileWarning = () => {
    this.setState({
      showMobileWarning: false
    });
  };

  displayWarning = (errorObject) => {
    const { t, addToast, removeToast } = this.props;
    let e = null;
    if (errorObject) {
      if (typeof errorObject === 'string') {
        e = JSON.parse(errorObject);
      } else {
        e = JSON.parse(JSON.stringify(errorObject));
      }
    }
    this.setState({ submitRequestClicked: false, submitLoginClicked: false });
    let message;
    if (e && e.notify && e.notify.warning) {
      const warning = e.notify.warning[0];
      message =
        warning === 'Incorrect username and/or password'
          ? t('general:wrong_password')
          : warning;
    }
    addToast(
      message ? `${t('general:warning')}: ${message}` : t('general:warning'),
      {
        children: 'ok',
        onClick: () => {
          removeToast();
        }
      },
      true
    );
  };

  displayError = (errorObject) => {
    const { t, addToast, removeToast } = this.props;
    let e = null;
    if (errorObject) {
      e = JSON.parse(JSON.stringify(errorObject));
    }
    this.setState({ submitRequestClicked: false, submitLoginClicked: false });
    addToast(
      e && e.message
        ? `${t('general:error')}: ` + e.message
        : t('general:error'),
      {
        children: 'ok',
        onClick: () => {
          removeToast();
        }
      },
      true
    );
  };

  openHelp = () => {
    this.setState({ helpOpened: true });
  };

  closeHelp = () => {
    this.setState({ helpOpened: false });
  };

  handlePasswordSubmit = (formData) => {
    const { token } = this.props.match.params;
    const { new_password, confirm_new_password } = formData;
    const dataOfInterest = { token, new_password, confirm_new_password };
    this.setState({ disableResetButton: true });
    this.props.resetPassword(dataOfInterest);
  };

  handleLoginSubmit = (formData) => {
    const { username, password } = formData;
    let trimmedData = {
      username: username.trim(),
      password: password.trim()
    };

    this.props.login(trimmedData);
    this.setState({ submitLoginClicked: true });
  };

  handleRequestResetPassword = (formData) => {
    this.props.requestResetPassword(formData);
    this.setState({ submitRequestClicked: true });
  };

  showNewPasswordForm = () => {
    this.props.removeToast();
    this.setSite({ cubeSite: 'request' });
  };

  hideNewPasswordForm = () => {
    this.props.removeToast();
    this.setSite({ cubeSite: 'login' });
  };

  goToResetForm = () => {
    // ONLY FOR DEV
    this.props.removeToast();
    this.setSite({ cubeSite: 'reset', token: 'test-token' });
  };

  setSite = ({ cubeSite, ...token }) => {
    switch (cubeSite) {
      case 'login':
        this.setState({ cubeSite: 'login' });
        this.props.history.push('/login');
        break;
      case 'request':
        this.setState({ cubeSite: 'request' });
        this.props.history.push('/login/password-reset');
        break;
      case 'reset':
        this.setState({ cubeSite: 'reset' });
        this.props.history.push('/login/password-reset/test-token');
        this.setState({
          disableResetButton: false,
          showBackToLoginButtonOnReset: false,
          cubeSite: 'reset',
          token
        });
        break;
      default:
        break;
    }
  };

  setFormFields = ({ ...args }) => {
    this.setState({
      ...args
    });
  };

  showBackToLoginButton = () => {
    this.setState({
      showBackToLoginButtonOnReset: true
    });
  };

  render() {
    const { t, resettingPassword, requestingResetPassword } = this.props;
    const {
      helpOpened,
      submitLoginClicked,
      cubeSite,
      showBackToLoginButtonOnReset,
      disableResetButton,
      showIEWarning,
      showMobileWarning,
      isSafari
    } = this.state;

    const generalLNS = 'general'; // generalLanguageNamespaceSource
    let logo = require('../../images/login-logo-etms2.png');
    let logoEtms = require('../../images/etms2-5.png');

    const { newPasswordFilled, newPasswordConfirmed, usernameByToken } =
      this.state;

    const actionIE11Warning = [
      {
        onClick: this.hideIEWarning,
        primary: true,
        children: 'Ok'
      }
    ];

    const actionMobileWarning = [
      {
        onClick: this.hideMobileWarning,
        primary: true,
        children: 'Ok'
      }
    ];
    // const showLoading = waitingOnLogin || submitClicked;

    return (
      <div
        className={isSafari ? styles.login + ' safari' : styles.login}
        data-cube-site={this.state.cubeSite}
      >
        {!helpOpened && cubeSite === 'login' && (
          <button
            key={'help'}
            title={t('open_help')}
            className={helpStyles.helpButton + ' helpButton'}
            onClick={this.openHelp}
          >
            <FontIcon>help</FontIcon>
          </button>
        )}
        <div className={'loginCenter'}>
          <div className={'logoImageWrapper'} help-tool='1'>
            <img className={'loginLogo'} src={logo} />
            <img className={'loginLogoEtms'} src={logoEtms} />
          </div>

          <div className={'loginCube'}>
            <div className={'cubeTab cubeTabLogin'}>
              <div className={'loginCard'} help-tool='2'>
                <h3 style={{ textTransform: 'uppercase' }}>{t('login')}</h3>
                <Form
                  onSubmit={this.handleLoginSubmit}
                  initialValues={{ username: usernameByToken }}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={styles.loginForm}>
                      <div>
                        <Field
                          id='username'
                          name='username'
                          type='text'
                          className={'loginFormInput'}
                          label={t(`${generalLNS}:username`)}
                          component={renderTextField}
                        />
                        <Field
                          id='password'
                          name='password'
                          type='password'
                          passwordIcon={<FontIcon>remove_red_eye</FontIcon>}
                          passwordInitiallyVisible={false}
                          className={'loginFormInput'}
                          label={t(`${generalLNS}:password`)}
                          component={renderPasswordTextField}
                        />
                      </div>
                      <div
                        className={
                          styles.buttonsRightWrapper + ' paddingLeftRight'
                        }
                      >
                        <Button
                          type='submit'
                          children={t('login')}
                          className={'loginFormSubmitButton'}
                          disabled={submitLoginClicked}
                          raised
                        />
                        <div className={'loginLoader'}>
                          <LoadingBar show={submitLoginClicked} />
                        </div>
                        <div
                          className={'loginAnimation'}
                          data-clicked={
                            this.state.submitLoginClicked &&
                            !this.state.authenticated
                          }
                          data-authenticated={this.state.authenticated}
                        >
                          {' '}
                        </div>
                      </div>
                    </form>
                  )}
                />
              </div>
              <Button
                help-tool='3'
                onClick={this.showNewPasswordForm}
                children={t('forgot_password')}
                flat
                className={'loginFormForgotButton'}
              />
            </div>

            <div className={'cubeTab cubeTabResRequest'}>
              <div className={'loginCard'}>
                <h3
                  style={{ textTransform: 'uppercase' }}
                  dangerouslySetInnerHTML={{
                    __html: t('request_new_password')
                  }}
                ></h3>
                {requestingResetPassword === ProcessStatus.FINISHED && (
                  <div className={styles.successMessage}>
                    <FontIcon>done_outline</FontIcon>
                    <div className='text'>
                      <p>{t('confirmation_email_sent')}</p>
                      <p>{t('follow_email_instructions')}</p>
                    </div>
                  </div>
                )}
                {requestingResetPassword !== ProcessStatus.FINISHED && (
                  <Form
                    onSubmit={this.handleRequestResetPassword}
                    render={({ handleSubmit }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={styles.loginForm}
                      >
                        <div>
                          <Field
                            id='email'
                            name='email'
                            label={t(`${generalLNS}:email_address`)}
                            type='email'
                            required
                            className={'loginFormInput'}
                            component={renderTextField}
                          />
                        </div>
                        <div
                          className={
                            styles.buttonsRightWrapper + ' paddingLeftRight'
                          }
                        >
                          <Button
                            children={t(`${generalLNS}:continue`)}
                            flat
                            primary
                            swapTheming
                            className={'loginFormForgotButton'}
                            disabled={
                              requestingResetPassword === ProcessStatus.STARTED
                            }
                            type='submit'
                          />
                        </div>
                      </form>
                    )}
                  />
                )}
              </div>
              <Button
                onClick={this.hideNewPasswordForm}
                children={t('back_to_login')}
                flat
                className={'loginFormForgotButton'}
              />
            </div>

            <div className={'cubeTab cubeTabReset'}>
              <div className={'loginCard'}>
                <h3 style={{ textTransform: 'uppercase' }}>
                  {t('reset_password')}
                </h3>
                <Form
                  onSubmit={this.handlePasswordSubmit}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <FormSpy
                        setFormFields={this.setFormFields}
                        formFields={{ newPasswordFilled, newPasswordConfirmed }}
                        showBackButton={this.showBackToLoginButton}
                        resettingPassword={resettingPassword}
                        subscription={{ values: true }}
                        component={ResetPasswordScreen}
                      />
                      <div
                        className={
                          styles.buttonsRightWrapper + ' paddingLeftRight'
                        }
                      >
                        {!showBackToLoginButtonOnReset && (
                          <Button
                            children={t(`${generalLNS}:reset`)}
                            flat
                            primary
                            swapTheming
                            disabled={disableResetButton}
                            className={'loginFormForgotButton'}
                            type='submit'
                          />
                        )}
                      </div>
                    </form>
                  )}
                />
              </div>
              {showBackToLoginButtonOnReset && (
                <Button
                  onClick={this.hideNewPasswordForm}
                  children={t('back_to_login')}
                  flat
                  className={'loginFormForgotButton'}
                />
              )}
            </div>
          </div>

          <div className={'ie11Warning'}>
            <DialogContainer
              id='simple-list-dialog'
              visible={showIEWarning}
              title={t('ie11_warning_headline')}
              onHide={this.hideIEWarning}
              modal
              actions={actionIE11Warning}
            >
              <div className='ieWarningTextContent'>
                <p
                  id='speed-boost-description'
                  className='md-color--secondary-text'
                >
                  {t('ie11_warning_text')}
                </p>
                <ul>
                  <li>Google Chrome (v75)</li>
                  <li>Firefox (v67)</li>
                </ul>
              </div>
            </DialogContainer>
          </div>

          <div className={'mobileWarning'}>
            <DialogContainer
              id='simple-list-dialog'
              visible={showMobileWarning}
              title={t('mobile_device_warning_headline')}
              onHide={this.hideMobileWarning}
              modal
              actions={actionMobileWarning}
            >
              <p
                id='speed-boost-description'
                className='md-color--secondary-text'
              >
                {t('mobile_device_warning_text')}
              </p>
            </DialogContainer>
          </div>
        </div>
        <GlobalHelp show={helpOpened} close={this.closeHelp} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const auth = state.get('auth');

  return {
    authenticated: auth.get('authenticated'),
    user: auth.get('user'),
    impersonatedUser: auth.get('impersonatedUser'),
    token: auth.get('token'),
    loggingIn: auth.get('loggingIn'),
    resettingPassword: auth.get('resettingPassword'),
    requestingResetPassword: auth.get('requestingResetPassword'),
    errorObject: auth.get('error'),
    usernameByToken: auth.get('username'),
    fetchingUsernameByToken: auth.get('fetchingUsernameByToken')
  };
}

export default withRouter(
  connect(mapStateToProps, {
    requestResetPassword,
    resetPasswordFulfilled,
    resetPassword,
    login,
    addToast,
    removeToast,
    getUsernameByToken
  })(LoginScreen)
);
