import React from 'react';
import { Field } from 'react-final-form';
import {
  TextField,
  SelectField,
  SelectionControl,
  SelectionControlGroup,
  DatePicker,
  FontIcon
} from 'react-md';
import Switch from '../general/SwitchComponent';
import UploadComponent from './UploadComponent';
import styles from '../../styles/basic_form_elements.scss';

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const renderSelectionControl = ({ input, label, ...custom }) => {
  let isChecked = Boolean(input.value);

  return (
    <SelectionControl
      id={input.name}
      name={input.name}
      type='checkbox'
      label={label}
      checked={isChecked}
      onChange={input.onChange}
      {...custom}
    />
  );
};

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  errorText,
  ...custom
}) => {
  return (
    <SelectField
      id={input.name}
      label={label}
      value={input.value}
      onChange={input.onChange}
      error={touched && error}
      errorText={errorText}
      {...custom}
    />
  );
};

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  errorText,
  ...custom
}) => {
  return (
    <TextField
      id={input.name}
      label={label}
      value={input.value === null ? '' : input.value}
      onChange={input.onChange}
      error={touched && error}
      errorText={errorText}
      {...custom}
    />
  );
};

const renderPasswordTextField = ({
  input,
  label,
  meta: { touched, error },
  errorText,
  ...custom
}) => {
  return (
    <TextField
      id={input.name}
      type={'password'}
      label={label}
      value={input.value === null ? '' : input.value}
      onChange={input.onChange}
      error={touched && error}
      errorText={errorText}
      {...custom}
    />
  );
};

const renderSelectionGroup = ({
  input,
  label,
  controls,
  meta: { initial },
  ...custom
}) => {
  /*
    console.log("-- renderSelectionGroup --");
    console.log("input = ", input);
    console.log("label = ", label);
    console.log("controls = ", controls);
    console.log("initial = ", initial);
    console.log("touched = ", touched);
    console.log("error = ", error);
    console.log("pristine = ", pristine);
    console.log("custom = ", custom);
    console.log("-- ------------------- --");
    */
  return (
    <SelectionControlGroup
      id={input.name}
      name={input.name}
      {...custom}
      label={label}
      type='radio'
      value={input.value}
      // defaultValue={pristine ? initial : input.value}
      defaultValue={initial}
      onChange={input.onChange}
      controls={controls}
    />
  );
};

const renderSwitch = ({ input, label, ...custom }) => {
  let isChecked = Boolean(input.value);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        paddingTop: '10px',
        width: '100%'
      }}
    >
      <Switch
        id={input.name}
        name={input.name}
        label={label}
        labelBefore={true}
        checked={isChecked}
        value={isChecked}
        onChange={input.onChange}
        {...custom}
      />
    </div>
  );
};

const renderStarSwitch = ({ input, label, ...custom }) => {
  let isChecked = Boolean(input.value);

  return (
    <div className={styles.checkboxWithSuperIcon}>
      <div title={isChecked ? label : 'NOT ' + label}>
        <SelectionControl
          id={input.name}
          name={input.name}
          type='checkbox'
          label={''}
          checked={isChecked}
          onChange={input.onChange}
          {...custom}
        />
        <FontIcon className={isChecked ? 'filled' : ''}>
          {isChecked ? 'star' : 'star_border'}
        </FontIcon>
      </div>
    </div>
  );
};

const renderDatePicker = ({ input, label, meta: { error }, ...custom }) => {
  return (
    <DatePicker
      id={input.name}
      label={label}
      value={input.value}
      {...custom}
      error={error}
      errorText={error}
      onChange={input.onChange}
    />
  );
};

const renderUploader = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  // console.log("------------------------");
  // console.log("0. input = ", input);
  // console.log("1. input.name = ", input.name);
  // console.log("1. renderUploader: touched = ", touched);
  // console.log("2. renderUploader: error = ", error);
  // console.log("3. renderUploader: errorText = ", errorText);

  let inputValue = input.value === '' ? null : input.value;

  return (
    <UploadComponent
      id={input.name}
      label={label}
      value={inputValue}
      error={touched && error}
      onChange={input.onChange}
      {...custom}
    />
  );
};

export {
  Condition,
  renderTextField,
  renderPasswordTextField,
  renderSelectField,
  renderSelectionControl,
  renderSelectionGroup,
  renderSwitch,
  renderStarSwitch,
  renderDatePicker,
  renderUploader
};
