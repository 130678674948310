import { ClientsAction } from '../utils/ActionTypes';
import { changeOccurringNullIntoEmptyString } from '../utils/HelperFunctions';
import * as Rx from 'rxjs';

const fetchClients = () => {
  return {
    type: ClientsAction.FETCH_CLIENTS
  };
};

const fetchClientsFulfilled = (clients) => {
  return {
    type: ClientsAction.FETCH_CLIENTS_FULFILLED,
    clients: clients,
    receivedAt: Date.now()
  };
};

const fetchClientsRejected = (error) => {
  return Rx.Observable.of({
    type: ClientsAction.FETCH_CLIENTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const fetchClient = (clientId) => {
  return {
    type: ClientsAction.FETCH_CLIENT,
    clientId: clientId,
    isEdit: false
  };
};

const fetchClientFulfilled = (data) => {
  return {
    type: ClientsAction.FETCH_CLIENT_FULFILLED,
    client: data,
    receivedAt: Date.now()
  };
};

const fetchClientRejected = (error) => {
  return Rx.Observable.of({
    type: ClientsAction.FETCH_CLIENT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const addClient = (client) => {
  return {
    type: ClientsAction.ADD_CLIENT,
    client: client
  };
};

const addClientFulfilled = (data) => {
  return {
    type: ClientsAction.ADD_CLIENT_FULFILLED,
    client: data
  };
};

const addClientRejected = (error) => {
  return Rx.Observable.of({
    type: ClientsAction.ADD_CLIENT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const editClient = (client) => {
  client = changeOccurringNullIntoEmptyString(client, 'parent_id');
  return {
    type: ClientsAction.EDIT_CLIENT,
    client: client
  };
};

const editClientFulfilled = (data) => {
  return {
    type: ClientsAction.EDIT_CLIENT_FULFILLED,
    client: data
  };
};

const editClientRejected = (error) => {
  return Rx.Observable.of({
    type: ClientsAction.EDIT_CLIENT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const deleteClient = (clientId) => {
  return {
    type: ClientsAction.DELETE_CLIENT,
    clientId: clientId
  };
};

const deleteClientFulfilled = (clientId) => {
  return {
    type: ClientsAction.DELETE_CLIENT_FULFILLED,
    clientId: clientId
  };
};

const deleteClientRejected = (error) => {
  return Rx.Observable.of({
    type: ClientsAction.DELETE_CLIENT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const linkClientToUser = (data) => {
  return {
    type: ClientsAction.LINK_CLIENT_TO_USER,
    data: data
  };
};

const linkClientToUserFulfilled = (data) => {
  return {
    type: ClientsAction.LINK_CLIENT_TO_USER_FULFILLED,
    data: data
  };
};

const linkClientToUserRejected = (error) => {
  return Rx.Observable.of({
    type: ClientsAction.LINK_CLIENT_TO_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const linkClientToExportType = (data) => {
  return {
    type: ClientsAction.LINK_CLIENT_TO_EXPORT_TYPE,
    data: data
  };
};

const linkClientToExportTypeFulfilled = (data) => {
  return {
    type: ClientsAction.LINK_CLIENT_TO_EXPORT_TYPE_FULFILLED,
    data: data
  };
};

const linkClientToExportTypeRejected = (error) => {
  return Rx.Observable.of({
    type: ClientsAction.LINK_CLIENT_TO_EXPORT_TYPE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  fetchClients,
  fetchClientsFulfilled,
  fetchClientsRejected,
  fetchClient,
  fetchClientFulfilled,
  fetchClientRejected,
  addClient,
  addClientFulfilled,
  addClientRejected,
  editClient,
  editClientFulfilled,
  editClientRejected,
  deleteClient,
  deleteClientFulfilled,
  deleteClientRejected,
  linkClientToUser,
  linkClientToUserFulfilled,
  linkClientToUserRejected,
  linkClientToExportType,
  linkClientToExportTypeFulfilled,
  linkClientToExportTypeRejected
};
