import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

import { removeDialog } from '../../../actions/globalDialog';

@withTranslation(['users', 'general'], { wait: true })
class Warning extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let process = nextProps.process;

    console.log('1. UNSAFE_componentWillReceiveProps, nextProps', nextProps);
    console.log('2. UNSAFE_componentWillReceiveProps, process', process);

    if (process == ProcessStatus.FINISHED && process != this.props.process) {
      this.closeDialog();
    }
  }

  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    this.props.removeDialog(dialog.identifier);
  };

  action = () => {
    this.props.action();
  };

  render() {
    const { process, actionLabel, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (process == ProcessStatus.STARTED) {
      return <LoadingBar show='true' />;
    }

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          {t(`${generalLNS}:cancel`)}
        </Button>
        <Button flat onClick={this.action}>
          {actionLabel}
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(mapStateToProps, { removeDialog })(Warning);
