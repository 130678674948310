import { List, fromJS } from 'immutable';

import { SourceSnapshotsAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  sourceSnapshots: List,
  fetchingSourceSnapshots: ProcessStatus.INITIAL,
  sourceSnapshotId: -1,
  projectId: null,
  translationId: null
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SourceSnapshotsAction.FETCH_SOURCE_SNAPSHOTS:
      return state
        .set('fetchingSourceSnapshots', ProcessStatus.STARTED)
        .set('projectId', action.projectId)
        .set('translationId', action.translationId)
        .set('sourceSnapshotId', action.sourceSnapshotId);
    case SourceSnapshotsAction.FETCH_SOURCE_SNAPSHOTS_FULFILLED:
      return state
        .set('sourceSnapshots', List(action.sourceSnapshots))
        .set('fetchingSourceSnapshots', ProcessStatus.FINISHED);
    case SourceSnapshotsAction.FETCH_SOURCE_SNAPSHOTS_REJECTED:
      return state
        .set('fetchingSourceSnapshots', ProcessStatus.FAILED)
        .set('sourceSnapshots', List())
        .set('projectId', null)
        .set('translationId', null);
    case SourceSnapshotsAction.SET_SOURCE_SNAPSHOT_ID:
      return state.set('sourceSnapshotId', action.id);
    default:
      return state;
  }
};

export { initialState, reducer as sourceSnapshots };
