import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

@withTranslation('layout', { wait: true })
class NotFound extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return <div>{t('page_not_found')}</div>;
  }
}
export default NotFound;
