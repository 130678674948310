import { RolesAction } from '../utils/ActionTypes';

import { Observable } from 'rxjs';

const fetchRoles = () => {
  return {
    type: RolesAction.FETCH_ROLES
  };
};

const fetchRolesFulfilled = (roles) => {
  return {
    type: RolesAction.FETCH_ROLES_FULFILLED,
    roles: roles,
    receivedAt: Date.now()
  };
};

const fetchRolesRejected = (error) => {
  return Observable.of({
    type: RolesAction.FETCH_ROLES_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const fetchRolePermissions = (roleId) => {
  return {
    type: RolesAction.FETCH_ROLE_PERMISSIONS,
    roleId: roleId
  };
};

const fetchRolePermissionsFulfilled = (data) => {
  return {
    type: RolesAction.FETCH_ROLE_PERMISSIONS_FULFILLED,
    permissions: data,
    receivedAt: Date.now()
  };
};

const fetchRolePermissionsRejected = (error) => {
  return Observable.of({
    type: RolesAction.FETCH_ROLE_PERMISSIONS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const changeRolePermissions = (roleId, permissionId, allow) => {
  return {
    type: RolesAction.CHANGE_ROLE_PERMISSIONS,
    roleId: roleId,
    permissionId: permissionId,
    allow: allow
  };
};

const changeRolePermissionsFulfilled = (response) => {
  return {
    type: RolesAction.CHANGE_ROLE_PERMISSIONS_FULFILLED,
    roleId: response.role_id,
    permissionId: response.permission_id,
    allow: response.allow,
    message: response.message
  };
};

const changeRolePermissionsRejected = (error) => {
  return Observable.of({
    type: RolesAction.CHANGE_ROLE_PERMISSIONS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const resetRolePermissions = () => {
  return {
    type: RolesAction.RESET_ROLE_PERMISSIONS
  };
};

const addRole = (role) => {
  return {
    type: RolesAction.ADD_ROLE,
    role: role
  };
};

const addRoleFulfilled = (data) => {
  return {
    type: RolesAction.ADD_ROLE_FULFILLED,
    role: data
  };
};

const addRoleRejected = (error) => {
  return Observable.of({
    type: RolesAction.ADD_ROLE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const editRole = (role) => {
  return {
    type: RolesAction.EDIT_ROLE,
    role: role
  };
};

const editRoleFulfilled = (data) => {
  return {
    type: RolesAction.EDIT_ROLE_FULFILLED,
    role: data
  };
};

const editRoleRejected = (error) => {
  return Observable.of({
    type: RolesAction.EDIT_ROLE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const deleteRole = (roleId) => {
  return {
    type: RolesAction.DELETE_ROLE,
    roleId: roleId
  };
};

const deleteRoleFulfilled = (roleId) => {
  return {
    type: RolesAction.DELETE_ROLE_FULFILLED,
    roleId: roleId
  };
};

const deleteRoleRejected = (error) => {
  return Observable.of({
    type: RolesAction.DELETE_ROLE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  fetchRoles,
  fetchRolesFulfilled,
  fetchRolesRejected,
  fetchRolePermissions,
  fetchRolePermissionsFulfilled,
  fetchRolePermissionsRejected,
  changeRolePermissions,
  changeRolePermissionsFulfilled,
  changeRolePermissionsRejected,
  resetRolePermissions,
  addRole,
  addRoleFulfilled,
  addRoleRejected,
  editRole,
  editRoleFulfilled,
  editRoleRejected,
  deleteRole,
  deleteRoleFulfilled,
  deleteRoleRejected
};
