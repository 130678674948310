import { Map } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = Map({});

const reducer = (state = initialState, action) => {
  return action.type === LOCATION_CHANGE
    ? state.merge({ locationBeforeTransitions: action.payload })
    : state;
};

export { initialState, reducer as routing };
