import { TranslationToolAction } from '../utils/ActionTypes';

/* eslint-disable */
export default function () {
  return (next) => (action) => {
    let serverData,
      browserData,
      itemData,
      dataSection,
      element,
      contentCompareWith,
      targetContentCompareWith,
      contentFrom,
      contentTo,
      comments;
    const propExceptions = [];
    switch (action.type) {
      case TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_FULFILLED:
      case TranslationToolAction.FETCH_TRANSLATIONS_FULFILLED:
        serverData = action.translations.data;
        browserData = [];

        let fieldIndex = 0;

        for (const section in serverData) {
          dataSection = { data: [], id: '' };
          if (!propExceptions.includes(section)) {
            for (const id in serverData[section]) {
              element = { from: {}, to: {} };

              itemData = serverData[section][id];

              if (itemData) {
                let index = 0;

                /*
                {
                  de: ...
                  en-CA: ...
                  snapshot_de_1: ...
                  t_snapsh_en-CA_2: ...
                }
                */

                for (const key of Object.keys(itemData)) {
                  var content = itemData[key].text;

                  switch (index) {
                    case 0:
                      contentFrom = contentTo = itemData[key].text;
                      if (itemData[key].hasOwnProperty('comments')) {
                        comments = itemData[key].comments;
                      }
                      break;
                    case 1:
                      if (key.substr(0, 8) === 'snapshot') {
                        contentCompareWith = content;
                      } else if (key.substr(0, 8) === 't_snapsh') {
                        targetContentCompareWith = content;
                      } else {
                        contentTo = content;
                        if (itemData[key].hasOwnProperty('comments')) {
                          comments = itemData[key].comments;
                        }
                      }
                      break;
                    default:
                      if (key.substr(0, 8) === 'snapshot') {
                        contentCompareWith = content;
                      } else if (key.substr(0, 8) === 't_snapsh') {
                        targetContentCompareWith = content;
                      }
                  }
                  index++;
                }
              }

              if (contentCompareWith !== undefined) {
                element.compareWith = { id: id, content: contentCompareWith };
              }
              if (targetContentCompareWith !== undefined) {
                element.targetCompareWith = {
                  id: id,
                  content: targetContentCompareWith
                };
              }
              element.from = { id: id, content: contentFrom };
              element.to = {
                id: id,
                content: contentTo
              };
              if (comments) {
                element.to.comments = comments;
              }

              element.index = fieldIndex;
              dataSection.data.push(element);
              fieldIndex++;
            }
            dataSection.id = section;
            browserData.push(dataSection);
          }
        }
        action.translations = browserData;
        break;
      case TranslationToolAction.SAVE_TRANSLATION_SUCCESS:
        serverData = action.translation;
        browserData = {};
        browserData = {
          section: serverData.collection,
          id: serverData.textfield_id,
          value: serverData.text,
          textsForLocalStorage: serverData.textsForLocalStorage
        };
        action.translation = browserData;
        break;
      default:
        break;
    }
    next(action);
  };
}
/* eslint-enable */
