import { ImageAction } from '../utils/ActionTypes';
import * as Rx from 'rxjs';

const switchMode = () => {
  return {
    type: ImageAction.SWITCH_MODE
  };
};

const switchPersisted = () => {
  return {
    type: ImageAction.SWITCH_PERSISTED
  };
};

const setCropped = (src) => {
  return {
    type: ImageAction.SET_CROPPED,
    payload: src
  };
};

const loadImage = (imgData) => {
  return {
    type: ImageAction.LOAD_IMAGE,
    payload: imgData
  };
};

const addImage = (imgData) => {
  return {
    type: ImageAction.SAVE_IMAGE,
    payload: imgData
  };
};

const deleteImage = (projectId) => {
  return {
    type: ImageAction.DELETE_IMAGE,
    payload: projectId
  };
};

const restoreImage = () => {
  return {
    type: ImageAction.RESTORE_IMAGE
  };
};

const fetchImage = ({ projectId }) => {
  return {
    type: ImageAction.FETCH_IMAGE,
    payload: projectId
  };
};

const fetchImageFulfilled = (data) => {
  return {
    type: ImageAction.FETCH_IMAGE_FULFILLED,
    image: data[0]
  };
};

const fetchImageRejected = (error) => {
  return Rx.Observable.of({
    type: ImageAction.FETCH_IMAGE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchFullImage = (data) => {
  const { projectId } = data;
  return {
    type: ImageAction.FETCH_FULL_IMAGE,
    payload: projectId
  };
};

const fetchFullImageFulfilled = (data) => {
  return {
    type: ImageAction.FETCH_FULL_IMAGE_FULFILLED,
    image: data
  };
};

const fetchFullImageRejected = (error) => {
  return Rx.Observable.of({
    type: ImageAction.FETCH_FULL_IMAGE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchCroppedImage = ({ projectId }) => {
  return {
    type: ImageAction.FETCH_CROPPED_IMAGE,
    payload: projectId
  };
};

const fetchCroppedImageFulfilled = (data) => {
  return {
    type: ImageAction.FETCH_CROPPED_IMAGE_FULFILLED,
    image: data
  };
};

const fetchCroppedImageRejected = (error) => {
  return Rx.Observable.of({
    type: ImageAction.FETCH_CROPPED_IMAGE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const addImageFulfilled = () => {
  return {
    type: ImageAction.ADD_IMAGE_FULFILLED
  };
};

const addImageRejected = (error) => {
  return Rx.Observable.of({
    type: ImageAction.ADD_IMAGE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const editImage = (imgData) => {
  return {
    type: ImageAction.EDIT_IMAGE,
    payload: imgData
  };
};

const editImageFulfilled = () => {
  return {
    type: ImageAction.EDIT_IMAGE_FULFILLED
  };
};

const editImageRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: ImageAction.EDIT_IMAGE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const deleteImageFulfilled = () => {
  return {
    type: ImageAction.DELETE_IMAGE_FULFILLED
  };
};

const deleteImageRejected = () => {
  return {
    type: ImageAction.DELETE_IMAGE_REJECTED
  };
};

export {
  switchMode,
  switchPersisted,
  setCropped,
  loadImage,
  deleteImage,
  restoreImage,
  addImage,
  fetchImage,
  fetchImageFulfilled,
  fetchImageRejected,
  fetchFullImage,
  fetchFullImageFulfilled,
  fetchFullImageRejected,
  fetchCroppedImage,
  fetchCroppedImageFulfilled,
  fetchCroppedImageRejected,
  addImageFulfilled,
  addImageRejected,
  editImage,
  editImageFulfilled,
  editImageRejected,
  deleteImageFulfilled,
  deleteImageRejected
};
