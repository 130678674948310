import { fromJS, Map } from 'immutable';
import { AuthAction, ProfileAction, RolesAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';
import { cryptedStore } from '../utils/CryptedStore';
import i18n from '../utils/i18n';

const initialState = fromJS({
  formData: {
    username: '',
    password: ''
  },
  loggingIn: ProcessStatus.INITIAL,
  loggingOut: ProcessStatus.INITIAL,
  authenticated: false,
  user: null,
  impersonatedUser: null,
  token: '',
  pageNotFound: false,
  fetchingUserSettings: ProcessStatus.INITIAL,
  resettingPassword: ProcessStatus.INITIAL,
  requestingResetPassword: ProcessStatus.INITIAL,
  updatingUser: ProcessStatus.INITIAL,
  loggingInAsUser: ProcessStatus.INITIAL,
  loggingOutAsUser: ProcessStatus.INITIAL,
  error: null,
  fetchingUsernameByToken: ProcessStatus.INITIAL,
  username: ''
});

let user;
let impersonatedUser;
let mergedUser;

/* eslint-disable */
function auth(state = initialState, action) {
  switch (action.type) {
    case AuthAction.PAGE_NOT_FOUND:
      return state.set('pageNotFound', action.payload);
    case AuthAction.USER_FROM_STORE:
      /* i18n.changeLanguage(action.user.languagecode, (err) => {
        if (err) return console.log('something went wrong loading', err);
      }); */
      if (cryptedStore.get('user')) {
        return state
          .set('token', action.user.auth_token)
          .set('user', action.user)
          .set('authenticated', true);
      } else {
        return state;
      }
    case AuthAction.IMPERSONATED_USER_FROM_STORE:
      if (cryptedStore.get('impersonatedUser')) {
        console.warn('is setting ---------impersonated user----------');
        return state.set(
          'impersonatedUser',
          cryptedStore.get('impersonatedUser')
        );
      } else {
        console.warn('is !!not!! setting ---------impersonated user----------');
        return state;
      }
    case AuthAction.UPDATE_FORM_VALUE:
      return state.setIn(['formData', action.name], action.value);
    case AuthAction.LOGIN:
      return state.set('loggingIn', ProcessStatus.STARTED);
    case AuthAction.LOGIN_SUCCESS:
      /*if (action.auth.user.languagecode !== i18n.language) {
        i18n.changeLanguage(action.auth.user.languagecode, (err, t) => {
          if (err) return console.log('something went wrong loading', err);
          // t(''); // -> same as i18next.t
        });
      }*/
      return state
        .set('loggingIn', ProcessStatus.FINISHED)
        .set('token', action.auth.token)
        .set('user', action.auth.user)
        .set('impersonatedUser', null)
        .set('authenticated', true);
    case AuthAction.LOGIN_ERROR:
      return state
        .set('loggingIn', ProcessStatus.FAILED)
        .set('token', '')
        .set('user', null)
        .set('authenticated', false)
        .set('error', action.payload);
    case AuthAction.LOGOUT:
      /* i18n.changeLanguage('en', (err, t) => {
        if (err) return console.log('something went wrong loading', err);
        // t(''); // -> same as i18next.t
      }); */
      return state
        .set('loggingIn', ProcessStatus.INITIAL)
        .set('loggingOut', ProcessStatus.STARTED)
        .set('token', '')
        .set('user', null)
        .set('impersonatedUser', null)
        .set('authenticated', false)
        .set('loggingInAsUser', ProcessStatus.INITIAL);
    case AuthAction.LOGOUT_FULFILLED:
      return state.set('loggingOut', ProcessStatus.FINISHED);
    case AuthAction.LOGOUT_REJECTED:
      return state.set('loggingOut', ProcessStatus.FAILED);
    case AuthAction.LOGIN_AS_USER:
      return state.set('loggingInAsUser', ProcessStatus.STARTED);
    case AuthAction.LOGIN_AS_USER_FULFILLED:
      delete action.data.status;
      impersonatedUser = action.data;
      // console.log('impersonatedUser', impersonatedUser);
      user = state.get('user');
      user.loggedin_as = impersonatedUser.id;
      cryptedStore.set('user', user);
      cryptedStore.set('impersonatedUser', impersonatedUser);
      return state
        .set('loggingInAsUser', ProcessStatus.FINISHED)
        .set('impersonatedUser', impersonatedUser);
    //.set('user', user);
    case AuthAction.LOGIN_AS_USER_REJECTED:
      return state
        .set('loggingInAsUser', ProcessStatus.FAILED)
        .set('error', action.payload);
    case AuthAction.LOGOUT_AS_USER:
      return state.set('loggingOutAsUser', ProcessStatus.STARTED);
    case AuthAction.LOGOUT_AS_USER_FULFILLED:
      user = state.get('user');
      user.loggedin_as = null;
      cryptedStore.set('user', user);
      cryptedStore.remove('impersonatedUser');
      return state
        .set('loggingOutAsUser', ProcessStatus.FINISHED)
        .set('impersonatedUser', null)
        .set('user', user);
    case AuthAction.LOGOUT_AS_USER_REJECTED:
      return state
        .set('loggingOutAsUser', ProcessStatus.FAILED)
        .set('error', action.payload);
    case AuthAction.REQUEST_RESET_PASSWORD:
      return state.set('requestingResetPassword', ProcessStatus.STARTED);
    case AuthAction.REQUEST_RESET_PASSWORD_FULFILLED:
      return state.set('requestingResetPassword', ProcessStatus.FINISHED);
    case AuthAction.REQUEST_RESET_PASSWORD_REJECTED:
      return state
        .set('requestingResetPassword', ProcessStatus.FAILED)
        .set('error', action.payload);
    case AuthAction.RESET_PASSWORD:
      return state.set('resettingPassword', ProcessStatus.STARTED);
    case AuthAction.RESET_PASSWORD_FULFILLED:
      return state.set('resettingPassword', ProcessStatus.FINISHED);
    case AuthAction.RESET_PASSWORD_REJECTED:
      return state
        .set('resettingPassword', ProcessStatus.FAILED)
        .set('error', action.payload);
    case AuthAction.EDIT_USER_SETTINGS:
      return state.set('fetchingUserSettings', ProcessStatus.STARTED);
    case AuthAction.EDIT_USER_SETTINGS_FULFILLED:
      if (action.data.settings) {
        user = state.get('user');
        if (action.data.userId === user.id) {
          console.warn('action.data.settings', action.data.settings);
          user.settings = _.merge(user.settings, action.data.settings);
          cryptedStore.set('user', user);
          return state
            .set('user', user)
            .set('fetchingUserSettings', ProcessStatus.FINISHED);
        } else {
          impersonatedUser = state.get('impersonatedUser');
          if (impersonatedUser && action.data.userId === impersonatedUser.id) {
            impersonatedUser.settings = _.merge(
              impersonatedUser.settings,
              action.data.settings
            );
            return state
              .set('impersonatedUser', impersonatedUser)
              .set('fetchingUserSettings', ProcessStatus.FINISHED);
          }
        }
      }
      return state.set('fetchingUserSettings', ProcessStatus.FINISHED);
    case AuthAction.EDIT_USER_SETTINGS_REJECTED:
      return state
        .set('fetchingUserSettings', ProcessStatus.FAILED)
        .set('error', action.payload);
    case ProfileAction.EDIT_PROFILE_FULFILLED:
      mergedUser = Map(state.get('user')).merge(action.user);
      cryptedStore.set('user', mergedUser.toJSON());
      return state
        .set('updatingUser', ProcessStatus.FINISHED)
        .set('user', mergedUser.toJSON());

    // TODO: Kann UPDATE_STORE_USER_FULFILLED komplett entfernt werden?
    case AuthAction.UPDATE_STORE_USER:
      return state.set('updatingUser', ProcessStatus.STARTED);
    case AuthAction.UPDATE_STORE_USER_FULFILLED:
      // user = state.get('user');
      mergedUser = Map(state.get('user')).merge(action.user.user);
      // user.name = action.user.user.name;
      // user.email = action.user.user.email;
      // user.languagecode = action.user.user.languagecode;

      cryptedStore.set('user', mergedUser);
      return state
        .set('updatingUser', ProcessStatus.FINISHED)
        .set('user', mergedUser.toJSON());
    case AuthAction.UPDATE_STORE_USER_REJECTED:
      return state
        .set('updatingUser', ProcessStatus.FAILED)
        .set('error', action.payload);

    case RolesAction.CHANGE_ROLE_PERMISSIONS_FULFILLED:
      user = state.get('user');

      if (user.roles[0] && user.roles[0].id === action.roleId) {
        console.warn(
          'Warning! The role that has been changed is the role of the current user.' +
            '# If you want to see the changes, log in again.'
        );
      }
      return state;

    case RolesAction.CHANGE_ROLE_PERMISSIONS_REJECTED:
      return state.set('changingRolePermissions', ProcessStatus.FAILED);

    case AuthAction.GET_USERNAME_BY_TOKEN:
      return state.set('fetchingUsernameByToken', ProcessStatus.STARTED);
    case AuthAction.GET_USERNAME_BY_TOKEN_FULFILLED:
      return state
        .set('fetchingUsernameByToken', ProcessStatus.FINISHED)
        .set('username', action.data.username);
    case AuthAction.GET_USERNAME_BY_TOKEN_REJECTED:
      return state
        .set('fetchingUsernameByToken', ProcessStatus.FAILED)
        .set('error', action.payload);
    default:
      return state;
  }
}
/* eslint-enable */

export { initialState, auth };
