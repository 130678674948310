import * as Rx from 'rxjs';
import { WbtViewerAction } from '../utils/ActionTypes';

const fetchAllTexts = (
  sourceTranslationId,
  targetTranslationId,
  groupbycollection = true
) => {
  return {
    type: WbtViewerAction.FETCH_ALL_TEXTS,
    sourceTranslationId: sourceTranslationId,
    targetTranslationId: targetTranslationId,
    groupbycollection: groupbycollection
  };
};

const fetchAllTextsFulfilled = (data) => {
  return {
    type: WbtViewerAction.FETCH_ALL_TEXTS_FULFILLED,
    data: data
  };
};

const fetchAllTextsRejected = (error) => {
  return Rx.Observable.of({
    type: WbtViewerAction.FETCH_ALL_TEXTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const fetchAllTextsGroupedByCollection = (
  sourceTranslationId,
  targetTranslationId,
  groupbycollection = false
) => {
  return {
    type: WbtViewerAction.FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION,
    sourceTranslationId: sourceTranslationId,
    targetTranslationId: targetTranslationId,
    groupbycollection: groupbycollection
  };
};

const fetchAllTextsGroupedByCollectionFulfilled = (data) => {
  return {
    type: WbtViewerAction.FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION_FULFILLED,
    data: data
  };
};

const fetchAllTextsGroupedByCollectionRejected = (error) => {
  return Rx.Observable.of({
    type: WbtViewerAction.FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const fetchSource = (projectId, sourceId) => {
  return {
    type: WbtViewerAction.FETCH_SOURCE,
    projectId: projectId,
    sourceId: sourceId
  };
};

const fetchSourceFulfilled = (data) => {
  return {
    type: WbtViewerAction.FETCH_SOURCE_FULFILLED,
    source: data
  };
};

const fetchSourceRejected = (error) => {
  return Rx.Observable.of({
    type: WbtViewerAction.FETCH_SOURCE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const toggleLiveEdit = () => {
  return {
    type: WbtViewerAction.TOGGLE_LIVE_EDIT
  };
};

const fetchAssets = (projectId, siteId, wbtLink) => {
  console.log('fetchAssets', projectId, siteId, wbtLink);
  return {
    type: WbtViewerAction.FETCH_ASSETS,
    projectId: projectId,
    siteId: siteId,
    wbtLink: wbtLink
  };
};

const fetchAssetsFulfilled = (data) => {
  console.log('fetchAssetsFulfilled', data);
  return {
    type: WbtViewerAction.FETCH_ASSETS_FULFILLED,
    source: data
  };
};

const fetchAssetsRejected = (error) => {
  return Rx.Observable.of({
    type: WbtViewerAction.FETCH_ASSETS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

export {
  fetchAllTexts,
  fetchAllTextsFulfilled,
  fetchAllTextsRejected,
  fetchAllTextsGroupedByCollection,
  fetchAllTextsGroupedByCollectionFulfilled,
  fetchAllTextsGroupedByCollectionRejected,
  fetchSource,
  fetchSourceFulfilled,
  fetchSourceRejected,
  toggleLiveEdit,
  fetchAssets,
  fetchAssetsFulfilled,
  fetchAssetsRejected
};
