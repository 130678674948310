import { fromJS } from 'immutable';
import { UploaderAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  discardingUpload: ProcessStatus.INITIAL,
  error: null
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UploaderAction.DISCARD_UPLOAD:
      return state.set('discardingUpload', ProcessStatus.STARTED);
    case UploaderAction.DISCARD_UPLOAD_FULFILLED:
      return state.set('discardingUpload', ProcessStatus.FINISHED);
    case UploaderAction.DISCARD_UPLOAD_REJECTED:
      return state
        .set('discardingUpload', ProcessStatus.FAILED)
        .set('error', action.payload);
    default:
      return state;
  }
};

export { initialState, reducer as uploader };
