import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

import { deleteSnapshot } from '../../../actions/snapshots';
import { removeDialog } from '../../../actions/globalDialog';

@withTranslation(['project', 'general'], { wait: true })
class DeleteSnapshotWarning extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let deletingSnapshot = nextProps.deletingSnapshot;
    if (
      deletingSnapshot == ProcessStatus.FINISHED &&
      deletingSnapshot != this.props.deletingSnapshot
    ) {
      this.closeDialog();
    }
  }

  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    this.props.removeDialog(dialog.identifier);
  };

  delete = () => {
    let projectId = this.props.projectId;
    let translationId = this.props.translationId;
    let snapshotId = this.props.snapshotId;

    this.props.deleteSnapshot(projectId, translationId, snapshotId);
  };

  render() {
    const { deletingSnapshot, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (deletingSnapshot == ProcessStatus.STARTED) {
      return <LoadingBar show='true' />;
    }

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          {t(`${generalLNS}:cancel`)}
        </Button>
        <Button flat onClick={this.delete}>
          {t(`${generalLNS}:delete`)}
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deletingSnapshot: state.getIn(['snapshots', 'deletingSnapshot']),
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(mapStateToProps, { deleteSnapshot, removeDialog })(
  DeleteSnapshotWarning
);
