import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { renderTextField } from '../../general/BasicFormComponents';
import styles from '../../../styles/translation_detail.scss';

@withTranslation(['project', 'general' /* , 'export' */], { wait: true })
class AddSnapshotPrime extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, translationName, version } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    let max = 400;

    return (
      <div className={'md-grid ' + styles.translationPrime}>
        <div className={'translationFormHead'}>
          <div className={'translationHeadHeadlines'}>
            <h2>{t('add_snapshot')}</h2>
            <p>
              {t(`${generalLNS}:for`)}: {translationName}
            </p>
          </div>
        </div>
        <div className={'formContentWrapper'}>
          <div>
            <p>
              {t(`${generalLNS}:version`)}: {version}
            </p>
          </div>
          <Field
            name='name'
            label={t(`${generalLNS}:name`)}
            type='text'
            className='form-field'
            style={{ width: '100%' }}
            resize={{ max: max }}
            required
            component={renderTextField}
          />
          <Field
            name='description'
            label={t(`${generalLNS}:description`)}
            type='text'
            className='form-field'
            style={{ width: '100%' }}
            resize={{ max: max }}
            rows={3}
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(AddSnapshotPrime);
