import { ContactsAction } from '../utils/ActionTypes';
import * as Rx from 'rxjs';

const fetchContacts = () => {
  return {
    type: ContactsAction.FETCH_CONTACTS
  };
};

const fetchContactsFulfilled = (contacts) => {
  return {
    type: ContactsAction.FETCH_CONTACTS_FULFILLED,
    contacts: contacts,
    receivedAt: Date.now()
  };
};

const fetchContactsRejected = (error) => {
  return Rx.Observable.of({
    type: ContactsAction.FETCH_CONTACTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const addContact = (contact) => {
  return {
    type: ContactsAction.ADD_CONTACT,
    contact: contact
  };
};

const addContactFulfilled = (data) => {
  return {
    type: ContactsAction.ADD_CONTACT_FULFILLED,
    contact: data
  };
};

const addContactRejected = (error) => {
  return Rx.Observable.of({
    type: ContactsAction.ADD_CONTACT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const editContact = (contact) => {
  return {
    type: ContactsAction.EDIT_CONTACT,
    contact: contact
  };
};

const editContactFulfilled = (data) => {
  return {
    type: ContactsAction.EDIT_CONTACT_FULFILLED,
    contact: data
  };
};

const editContactRejected = (error) => {
  return Rx.Observable.of({
    type: ContactsAction.EDIT_CONTACT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const deleteContact = (contactId) => {
  return {
    type: ContactsAction.DELETE_CONTACT,
    contactId: contactId
  };
};

const deleteContactFulfilled = (contactId) => {
  return {
    type: ContactsAction.DELETE_CONTACT_FULFILLED,
    contactId: contactId
  };
};

const deleteContactRejected = (error) => {
  return Rx.Observable.of({
    type: ContactsAction.DELETE_CONTACT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  fetchContacts,
  fetchContactsFulfilled,
  fetchContactsRejected,
  addContact,
  addContactFulfilled,
  addContactRejected,
  editContact,
  editContactFulfilled,
  editContactRejected,
  deleteContact,
  deleteContactFulfilled,
  deleteContactRejected
};
