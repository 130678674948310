import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import { List } from 'immutable';
import { withTranslation } from 'react-i18next';
import PortalWindow from '../general/PortalWindow';
import { fetchAllTexts } from '../../actions/wbtViewer';
import styles from '../../styles/projects/project.scss';

@withTranslation('translation_navigation', { wait: true })
class TranslationToolStarter extends Component {
  id = 0;

  constructor(props) {
    super(props);

    this.state = {
      inSafeClosingMode: false,
      initiateSafeClosing: false,
      isTranslationWindowVisible: false,
      isTranslationWindowVisibleWhenReady: false,
      allTexts: []
    };
  }

  UNSAFE_componentWillMount() {
    this.id = Math.floor(Math.random() * 1001);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { texts } = nextProps;

    if (Object.keys(texts).length === 0 && texts.constructor === Object) {
      // console.log('TranslationToolStarter: no texts');
    } else {
      let ttTexts = List(texts).map((text) => {
        return { collection: text.collection, textfieldId: text.textfield_id };
      });

      this.setState(
        {
          isTranslationWindowVisible: true,
          allTexts: ttTexts.toArray()
        },
        this.setLocalStorageForTranslationTool
      );
    }
  }

  setLocalStorageForTranslationTool = () => {
    const { projectId, translationId, sourceLanguageId } = this.props;

    let languageTitleTranslation = this.getLanguageTitle(translationId);

    let obj = {
      id: this.id,
      startedFromProject: true,
      textsOnStage: this.state.allTexts,
      currentPageId: this.state.allTexts[1].textfield_id,
      projectId: parseInt(projectId),
      translationId: parseInt(translationId),
      sourceLanguageId: parseInt(
        sourceLanguageId ? sourceLanguageId : translationId
      ),
      scrollTo: {
        collection: '',
        textfieldId: ''
      },
      translationLanguageCode: languageTitleTranslation,
      sourceLanguageCode: sourceLanguageId
        ? this.getLanguageTitle(sourceLanguageId)
        : languageTitleTranslation,
      showAllTexts: true
    };

    if (this.props.sourceSnapshotId) {
      obj.sourceSnapshotId = parseInt(this.props.sourceSnapshotId);
    }
    localStorage.setItem('openProject_' + this.id, JSON.stringify(obj));
  };

  handleTranslationButton = () => {
    const { sourceLanguageId, translationId } = this.props;
    this.props.fetchAllTexts(sourceLanguageId, translationId);

    this.setState({ isTranslationWindowVisibleWhenReady: true });
  };

  updateTextInIframe = (texts) => {
    console.log('updateTextInIframe', texts);
    /*
        this.postMessageToIframe({
            'command': 'textsUpdated',
            'status': 'success',
            'texts': texts
        });
        */
  };

  /*
  preventWindowClose = (prevent = false) => {
    console.log('preventWindowClose');
    this.setState({inSafeClosingMode: prevent});
  };
  */

  resetSafeClosingInitiation = () => {
    console.log('resetSafeClosingInitiation');
  };

  handleWindowClosed = () => {
    console.log('handleWindowClosed');
    this.setState({
      isTranslationWindowVisible: false,
      isTranslationWindowVisibleWhenReady: false
    });
  };

  getLanguageTitle = (id) => {
    if (!this.props.project) {
      return;
    }

    let currentTranslation = null;
    let returnString = null;

    this.props.project.translations.map((translation) => {
      if (id == translation.id) {
        currentTranslation = translation;
        return;
      }
    });

    if (currentTranslation) {
      returnString =
        currentTranslation.languagecode + ' - ' + currentTranslation.name;
    } else {
      console.log('no currentTranslation');
    }

    return returnString;
  };

  render() {
    const { t, showAllTexts } = this.props;
    const {
      inSafeClosingMode,
      initiateSafeClosing,
      isTranslationWindowVisible,
      isTranslationWindowVisibleWhenReady
    } = this.state;

    return (
      <div>
        <Button
          children={t('editor_uppercase')}
          className={styles.translateButton}
          onClick={this.handleTranslationButton}
          raised
          secondary
          swapTheming
          iconChildren='translate'
          iconBefore={true}
        />
        {isTranslationWindowVisible && isTranslationWindowVisibleWhenReady && (
          <div>
            <PortalWindow
              inSafeClosingMode={inSafeClosingMode}
              initiateSafeClosing={initiateSafeClosing}
              resetSafeClosingInitiation={this.resetSafeClosingInitiation}
              onWindowClosed={this.handleWindowClosed}
              translationViewId={this.id}
              isPdf={false}
            >
              <div></div>
            </PortalWindow>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    texts: state.getIn(['wbtViewer', 'courseTexts']),
    fetchingTexts: state.getIn(['wbtViewer', 'fetchingTexts']),
    activeTranslationId: state.getIn(['wbtViewer', 'activeTranslationId']),
    isLiveEditEnabled: state.getIn(['wbtViewer', 'isLiveEditEnabled'])
  };
}

export default connect(mapStateToProps, { fetchAllTexts })(
  TranslationToolStarter
);
