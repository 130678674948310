import {
  defaultCreateFilterFunction,
  defaultCreateSearchFunction,
  defaultCreateSortFunction,
  defaultPrepareFiltersFunction
} from './defaultFunctions';
import { changeDatetimeToDate } from '../utils/HelperFunctions';
import { aclUser } from '../utils/Permissions';

/**
 * Users Model
 * @type {*[]}
 */
const model = {
  columns: [
    {
      id: 'name',
      translation_id: 'name'
      // name: 'Name'
    },
    {
      id: 'username',
      // name: 'Username'
      translation_id: 'username'
    },
    {
      id: 'is_super',
      translation_id: 'super',
      component: 'super',
      textAlign: 'right'
    },
    {
      id: 'role',
      translation_id: 'role'
    },
    {
      id: 'email',
      // name: 'Email',
      translation_id: 'email',
      sortable: 'false'
    },
    {
      id: 'active',
      // name: 'Active',
      translation_id: 'active',
      permissions: [aclUser.activate]
    },
    {
      id: 'languagecode',
      translation_id: 'language_code'
      // name: 'LC'            // 'Language code'
    },
    /*
    {
       id: 'updated_at',
       name: 'Updated at',
       format: changeDatetimeToDate
   },
   */
    {
      id: 'created_at',
      translation_id: 'created_at',
      format: changeDatetimeToDate
    },
    {
      id: 'actions',
      // name: 'Actions',
      translation_id: 'actions',
      textAlign: 'right',
      permissions: [
        aclUser.delete,
        aclUser.invite,
        aclUser.login_as,
        aclUser.update
      ],
      sortable: 'false'
    }
  ],

  filters: [
    {
      translation_id: 'active',
      // label: 'Active',
      primaryText: 'Active',
      id: 'active'
    }
  ],

  prepareFilters: defaultPrepareFiltersFunction,
  createFilterFunction: defaultCreateFilterFunction,
  createSearchFunction: defaultCreateSearchFunction,
  createSortFunction: defaultCreateSortFunction,

  defaultSearchProps: ['name', 'username', 'role', 'email'],

  initRowsPerPage: 12
};

export default model;
