import React, { Component } from 'react';
import { Container } from 'react-grid-system';
import flexStyles from '../../styles/content_flex_wrapper.scss';

class LayoutContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { width, children } = this.props;

    if (width === 'small') {
      return <Container style={this.props.style}>{children}</Container>;
    } else if (width === 'big' || width === undefined) {
      return (
        <Container
          fluid
          className={flexStyles.topMargin}
          style={this.props.style}
        >
          {children}
        </Container>
      );
    }
  }
}

export default LayoutContainer;
