import React from 'react';

/*
 * DIESER TEIL BEHEBT EINEN DRAFT-JS Fehler beim Löschen eines Immutable Element
 * <span style={{display: 'none'}}>{props.children}</span>
 * */

const ZwspDecorator = (props) => {
  return (
    <span className={'zwsp'} style={{ color: '#ff5900', fontWeight: 'bolder' }}>
      |<span style={{ display: 'none' }}>{props.children}</span>
    </span>
  );
};

export default ZwspDecorator;
