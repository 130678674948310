import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Avatar } from 'react-md';

@withTranslation('layout', { wait: true })
class AvatarWithInitials extends PureComponent {
  render() {
    const { user, t } = this.props;
    const { name } = user;
    let username = name;

    let initials = '';

    if (username && username.length > 0) {
      let length = username.length;

      if (length == 1 || length == 2) {
        initials = username;
      } else if (username.lastIndexOf(' ') < 0) {
        initials = username.substr(0, 2);
      } else {
        let firstLetter = username.substr(0, 1);
        let secondLetter = username.substr(username.lastIndexOf(' ') + 1, 1);
        initials = firstLetter + secondLetter;
      }
    }

    return (
      <Avatar
        onClick={this.props.onClick}
        title={
          t('logged_in') +
          ': ' +
          username +
          '\n\n' +
          t('click_to_edit_profile') +
          '.'
        }
      >
        {initials}
      </Avatar>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.get('auth').get('user') || null
  };
}

export default connect(mapStateToProps, {})(AvatarWithInitials);
