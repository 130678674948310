import * as Rx from 'rxjs';
import { UsersAction } from '../utils/ActionTypes';

const fetchUsers = (skinny = false) => {
  return {
    type: UsersAction.FETCH_USERS,
    skinny: skinny
  };
};

const fetchUsersFulfilled = (data) => {
  return {
    type: UsersAction.FETCH_USERS_FULFILLED,
    users: data,
    receivedAt: Date.now()
  };
};

const fetchUsersRejected = (error) => {
  return Rx.Observable.of({
    type: UsersAction.FETCH_USERS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const fetchUser = (userId) => {
  return {
    type: UsersAction.FETCH_USER,
    userId: userId,
    isEdit: false
  };
};

const fetchUserFulfilled = (data) => {
  return {
    type: UsersAction.FETCH_USER_FULFILLED,
    user: data,
    receivedAt: Date.now()
  };
};

const fetchUserRejected = (error) => {
  return Rx.Observable.of({
    type: UsersAction.FETCH_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const addUser = (user) => {
  return {
    type: UsersAction.ADD_USER,
    user: user
  };
};

const addUserFulfilled = (data) => {
  return {
    type: UsersAction.ADD_USER_FULFILLED,
    user: data
  };
};

const addUserRejected = (error) => {
  return Rx.Observable.of({
    type: UsersAction.ADD_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const deleteUser = (userId) => {
  return {
    type: UsersAction.DELETE_USER,
    userId: userId
  };
};

const deleteUserFulfilled = (userId) => {
  return {
    type: UsersAction.DELETE_USER_FULFILLED,
    userId: userId
  };
};

const deleteUserRejected = (error) => {
  return Rx.Observable.of({
    type: UsersAction.DELETE_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const editUser = (user) => {
  return {
    type: UsersAction.EDIT_USER,
    user: user
  };
};

const editUserFulfilled = (data) => {
  return {
    type: UsersAction.EDIT_USER_FULFILLED,
    user: data
  };
};

const editUserRejected = (error) => {
  return Rx.Observable.of({
    type: UsersAction.EDIT_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const addUserToProject = (userId, projectId, roleId) => {
  return {
    type: UsersAction.ADD_USER_TO_PROJECT,
    userId: userId,
    body: {
      version: 'v2',
      property_type: 'users_x_projects',
      project_id: projectId,
      role_id: roleId,
      mode: 'grant'
    }
  };
};

const removeUserFromProject = (userId, projectId) => {
  return {
    type: UsersAction.REMOVE_USER_FROM_PROJECT,
    userId: userId,
    body: {
      version: 'v2',
      property_type: 'users_x_projects',
      project_id: projectId,
      mode: 'revoke'
    }
  };
};

const updateConnectedPropertySuccess = (data) => {
  return {
    type: UsersAction.UPDATE_CONNECTED_PROPERTY_SUCCESS,
    data: data
  };
};

// const updateConnectedPropertyError = data => {
//   return {
//     type: UsersAction.UPDATE_CONNECTED_PROPERTY_ERROR
//   };
// };

// const changeUserRole = (userId, roleId) => {
//   return {
//     type: UsersAction.CHANGE_USER_ROLE
//   };
// };

const changeUserRoleForTranslation = (userId, translationId, roleId) => {
  return {
    type: UsersAction.CHANGE_USER_ROLE_FOR_TRANSLATION,
    userId: userId,
    body: {
      version: 'v2',
      property_type: 'users_x_translations',
      project_translation_id: translationId,
      role_id: roleId
    }
  };
};

const addUserToTranslation = (userId, translationId, roleId) => {
  return {
    type: UsersAction.ADD_USER_TO_TRANSLATION,
    userId: userId,
    body: {
      version: 'v2',
      property_type: 'users_x_translations',
      project_translation_id: translationId,
      role_id: roleId
    }
  };
};

const removeUserFromTranslation = (userId, translationId) => {
  return {
    type: UsersAction.REMOVE_USER_FROM_TRANSLATION,
    userId: userId,
    body: {
      version: 'v2',
      property_type: 'users_x_translations',
      project_translation_id: translationId
    }
  };
};

const linkUserToProject = (data) => {
  return {
    type: UsersAction.LINK_USER_TO_PROJECT,
    data: data
  };
};

const linkUserToProjectFulfilled = (data) => {
  return {
    type: UsersAction.LINK_USER_TO_PROJECT_FULFILLED,
    data: data
  };
};

const linkUserToProjectRejected = (error) => {
  return Rx.Observable.of({
    type: UsersAction.LINK_USER_TO_PROJECT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const linkUserToTranslation = (data) => {
  return {
    type: UsersAction.LINK_USER_TO_TRANSLATION,
    data: data
  };
};

const linkUserToTranslationFulfilled = (data) => {
  return {
    type: UsersAction.LINK_USER_TO_TRANSLATION_FULFILLED,
    data: data
  };
};

const linkUserToTranslationRejected = (error) => {
  return Rx.Observable.of({
    type: UsersAction.LINK_USER_TO_TRANSLATION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const linkUserToClient = (data) => {
  return {
    type: UsersAction.LINK_USER_TO_CLIENT,
    data: data
  };
};

const linkUserToClientFulfilled = (data) => {
  return {
    type: UsersAction.LINK_USER_TO_CLIENT_FULFILLED,
    data: data
  };
};

const linkUserToClientRejected = (error) => {
  return Rx.Observable.of({
    type: UsersAction.LINK_USER_TO_CLIENT_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const inviteUser = (data) => {
  return {
    type: UsersAction.INVITE_USER,
    userId: data.userId,
    password: data.password
  };
};

const inviteUserFulfilled = (user) => {
  return {
    type: UsersAction.INVITE_USER_FULFILLED,
    user: user
  };
};

const inviteUserRejected = (error) => {
  return Rx.Observable.of({
    type: UsersAction.INVITE_USER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  fetchUsers,
  fetchUsersFulfilled,
  fetchUsersRejected,
  fetchUser,
  fetchUserFulfilled,
  fetchUserRejected,
  addUser,
  addUserFulfilled,
  addUserRejected,
  deleteUser,
  deleteUserFulfilled,
  deleteUserRejected,
  editUser,
  editUserFulfilled,
  editUserRejected,
  addUserToProject,
  removeUserFromProject,
  addUserToTranslation,
  removeUserFromTranslation,
  updateConnectedPropertySuccess,
  changeUserRoleForTranslation,
  linkUserToProject,
  linkUserToProjectFulfilled,
  linkUserToProjectRejected,
  linkUserToTranslation,
  linkUserToTranslationFulfilled,
  linkUserToTranslationRejected,
  linkUserToClient,
  linkUserToClientFulfilled,
  linkUserToClientRejected,
  inviteUser,
  inviteUserFulfilled,
  inviteUserRejected
};
