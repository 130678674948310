import React, { Component } from 'react';

import styles from '../styles/radio_bar.scss';

export default class RadioBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: parseInt(props.activeElement),
      barHeight: 40,
      buttonWidth: 140
    };
  }

  componentDidMount() {
    const { activeElement } = this.props;
    this.setState({ active: activeElement });

    setTimeout(() => {
      this.props.onChange(activeElement);
    }, 100);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeElement } = nextProps;

    if (activeElement != this.props.activeElement) {
      this.setState({ active: activeElement });
    }
  }

  onClick = (event) => {
    let index = parseInt(event.target.getAttribute('index'));
    if (index === this.state.active) {
      return;
    }
    this.setState({ active: index });
    this.props.onChange(index);
  };

  render() {
    const { children, radioName } = this.props;
    const { barHeight, buttonWidth } = this.state;

    return (
      <div className={styles.radioBar}>
        <div className={'bar'} style={{ height: barHeight + 'px' }}>
          <div className={'indicatorWrapper'}>
            <div
              style={{
                width: buttonWidth + 'px',
                left: this.state.active * buttonWidth + 'px'
              }}
            >
              &nbsp;
            </div>
          </div>
          {children.map((childButton, index) => (
            <div
              className={'buttonElement'}
              title={childButton.text}
              key={index}
              style={{ width: buttonWidth + 'px' }}
              // eslint-disable-next-line react/no-unknown-property
              index={index}
              // eslint-disable-next-line react/no-unknown-property
              is-active={index === this.state.active ? 'true' : 'false'}
              onClick={this.onClick}
            >
              <span className={'buttonInner'}>{childButton.text}</span>
              <input
                className={'hiddenRadio'}
                type='radio'
                name={radioName}
                readOnly
                checked={index === this.state.active ? 'checked' : ''}
                value={childButton.value}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
