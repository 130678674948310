import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutContainer from '../layout/LayoutContainer';
import { withTranslation } from 'react-i18next';

import { RolesModel } from '../../tableModels';
import FilterSearchPanel from '../general/FilterSearchPanel';
import CustomTable from '../general/CustomTable';
import LoadingBar from '../general/DumbComponent';
import DeleteRoleWarning from './dialog/DeleteRoleWarning';
import { ProcessStatus, Routes } from '../../utils/Constants';
import { arrayHelper } from '../../utils/HelperFunctions';
import ContentDrawer from '../../utils/ContentDrawer';
import AddRole from './addrole/AddRole';
import EditRole from './editrole/EditRole';
import { fetchRoles, deleteRole } from '../../actions/roles';
import { showWarning } from '../../actions/globalDialog';
import { aclRole } from '../../utils/Permissions';
import { hasPermission } from '../../utils/AuthHelper';

@withTranslation(['roles', 'general'], { wait: true })
class ListRoles extends Component {
  state = {
    searchTerm: '',
    filters: [],
    focusOnTable: false,
    selectedRoleId: 1
  };

  UNSAFE_componentWillMount() {
    const { roles } = this.props;

    if (roles.size === 0) {
      this.props.fetchRoles();
    }
  }

  handleFilter = (type, value) => {
    const newFilters = arrayHelper(this.state.filters, type, value);
    this.setState({ filters: newFilters });
  };

  handleSearchInput = (value) => {
    this.setState({ searchTerm: value });
  };

  handleSearchReset = () => {
    this.setState({ searchTerm: '' });
  };

  hitEnterToFocusOnTable = () => {
    this.setState({ focusOnTable: true });
  };

  closeDrawer = () => {
    this.props.history.push('/roles');
    // this.props.history.push(Routes.PROJECTS.IMPORT_WARNING);
  };

  handleCellClick = (row, cell, idxSortedData, idxSequence, custom) => {
    if (custom && custom.name) {
      switch (custom.name) {
        case 'edit':
          this.handleEdit(row.id);
          break;

        case 'delete':
          this.showDeleteWarning(row);
          break;
        default:
          break;
      }
    }
  };

  handleEdit = (id) => {
    if (hasPermission(aclRole.update)) {
      this.props.history.push('roles/' + id + '/edit');
    }
  };

  showDeleteWarning = (role) => {
    const { showWarning, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let title = t(`${generalLNS}:warning`);
    let text = t('warning_delete_role') + ' ' + role.display_name + ' ?';

    let component = <DeleteRoleWarning roleId={role.id} />;

    showWarning({
      title: title,
      text: text,
      actions: [],
      component: component,
      isCancellable: false
    });
  };

  renderFiltersAndTable = () => {
    const {
      roles,
      match: {
        params: { roleId }
      }
    } = this.props;

    const filters = RolesModel.prepareFilters.call(
      RolesModel, // focus
      RolesModel.filters,
      ['company_name'],
      roles
    );

    return (
      <React.Fragment>
        <ContentDrawer
          key='1'
          children={<AddRole closeDrawer={this.closeDrawer} />}
          visible={this.props.match.path === Routes.ROLES.ADD}
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='2'
          children={<EditRole roleId={roleId} />}
          visible={this.props.match.path === Routes.ROLES.EDIT}
          closeDrawer={this.closeDrawer}
        />
        <FilterSearchPanel
          filters={filters}
          appliedFilters={this.state.filters}
          handleFilter={this.handleFilter}
          searchBox={true}
          searchTerm={this.state.searchTerm}
          handleSearchInput={this.handleSearchInput}
          handleSearchReset={this.handleSearchReset}
          hitEnterToFocus={this.hitEnterToFocusOnTable}
          routeToAddNewItem={Routes.ROLES.ADD}
        />
        <CustomTable
          model={RolesModel}
          rows={roles.toArray()}
          searchTerm={this.state.searchTerm}
          appliedFilters={this.state.filters}
          focus={this.state.focusOnTable}
          onCellClick={this.handleCellClick}
          onRowClick={this.handleEdit}
        />
      </React.Fragment>
    );
  };

  render() {
    const { roles, fetchingRoles, t } = this.props;

    if (fetchingRoles == ProcessStatus.STARTED) {
      return (
        <LayoutContainer>
          {/* <MangooluLoading show={true}/>*/}
          <LoadingBar show={true} />
        </LayoutContainer>
      );
    }

    if (roles == undefined || roles.size <= 0) {
      return <div>{t('no_roles_found')}</div>;
    }

    return (
      <LayoutContainer width={'small'}>
        {this.renderFiltersAndTable()}
      </LayoutContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchingRoles: state.getIn(['roles', 'fetchingRoles']),
    roles: state.getIn(['roles', 'roles']),
    role: state.getIn(['auth', 'selectedRole'])
  };
}

export default connect(mapStateToProps, {
  fetchRoles,
  deleteRole,
  showWarning
})(ListRoles);
