// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".raweditor {\n  position: absolute;\n  top: -30px;\n  left: -10px;\n  width: calc(100% + 20px);\n  height: calc(100% + 60px);\n  padding: 0;\n  z-index: 50000;\n}\n\n.raweditor .buttonBar {\n  position: absolute;\n  top: -30px;\n  left: 0;\n  width: 100%;\n  height: 30px;\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  background-color: rgba(0, 0, 0, 0.9);\n  border-top-left-radius: 15px;\n  border-top-right-radius: 15px;\n  overflow: hidden;\n}\n\n.raweditor .buttonBar button {\n  height: 30px;\n  line-height: 30px;\n  margin: 0;\n  outline: none;\n  border: 0;\n  text-transform: uppercase;\n  width: auto;\n  padding: 0 10px;\n  background-color: transparent;\n  color: white;\n  font-size: 12px;\n}\n\n.raweditor .buttonBar button:disabled {\n  opacity: 0.3;\n  cursor: default !important;\n  pointer-events: none;\n}\n\n.raweditor .buttonBar button:hover {\n  background-color: rgba(255, 255, 255, 0.3);\n}\n\n.raweditor textarea {\n  min-height: 50px;\n  width: 100% !important;\n  height: 100% !important;\n  box-sizing: border-box;\n  display: block;\n  max-width: 100%;\n  padding: 10px;\n  font-family: monospace;\n  background-color: #ffffff;\n  color: #252525;\n  font-size: 13px;\n  border: 2px solid black;\n  border-top: 0 solid black;\n}\n", ""]);
// Exports
exports.locals = {
	"raweditor": "raweditor",
	"buttonBar": "buttonBar"
};
module.exports = exports;
