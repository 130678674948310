import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { ExpansionPanel, Button } from 'react-md';
import { withTranslation } from 'react-i18next';
import CustomTable from '../../general/CustomTable';
import { editTranslation } from '../../../actions/projects';
import styles from '../../../styles/projects/expansionpanel/general.scss';
import { hasPermission } from '../../../utils/AuthHelper';
import { aclTranslation } from '../../../utils/Permissions';

const CREATE_ALREADY_TRANSLATED_TEXTFIELDS =
  'create_already_translated_textfields';

@withTranslation('types', { wait: true })
class TranslationPanel extends Component {
  static propTypes = {
    // Notice these three props. They are injected via the `ExpansionList` component
    // and are required to get correct styling and keyboard accessibility.
    focused: PropTypes.bool,
    overflown: PropTypes.bool,
    columnWidths: PropTypes.arrayOf(PropTypes.number)
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: [113],
      isExpanded: false
    };
  }

  UNSAFE_componentWillMount() {
    const translation = this.props.translation;

    if (translation != null) {
      this.update(translation);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { translation } = this.props;

    if (nextProps.translation.id !== translation.id) {
      this.update(nextProps.translation);
    }
  }

  update = (translation) => {
    this.setState({ selected: [translation.id] });
  };

  handleProgressMenu = (action) => {
    console.log('handleProgressMenu', action);
  };

  handleExpandToggle = (isExpanded, selected = this.state.selected) => {
    this.setState({ isExpanded: isExpanded, selected: selected });
  };

  setTargetLanguage = (translationId) => {
    this.setState({ isExpanded: false });
    let projectId = this.props.match.params.projectId;
    this.props.history.push(
      '/projects/' + projectId + '/translations/' + translationId
    );
  };

  handleTranslationAdd = () => {
    this.changeRoute(this.props.match.params.translationId, '/add-translation');
  };

  handleTranslationImport = (translation) => {
    this.changeRoute(translation.id, '/import-translation');
  };

  handleTranslationEdit = (translation) => {
    this.changeRoute(translation.id, '/edit-translation');
  };

  changeRoute = (translationId, action) => {
    let projectsPart = '/projects/' + this.props.match.params.projectId;
    let sourceLanguagePart = this.props.match.params.sourceLanguageId
      ? '/' + this.props.match.params.sourceLanguageId
      : '';

    this.props.history.push(
      projectsPart +
        '/translations/' +
        translationId +
        sourceLanguagePart +
        action
    );
  };

  handleCellClick = (row, cell, idxSortedData, idxSequence, custom) => {
    if (custom.name) {
      switch (custom.name) {
        case 'upload':
          this.handleTranslationImport(row);
          break;

        case 'edit':
          this.handleTranslationEdit(row);
          break;
        default:
          console.log('handleCellClick, custom = ' + custom);
          break;
      }
    }
    if (cell && cell.id) {
      switch (cell.id) {
        case 'active':
          this.handleActive(row);
          break;
        default:
          break;
      }
    }
  };

  handleActive = (translation) => {
    let data = {};
    data.active = translation.active === 1 ? 0 : 1;
    this.props.editTranslation(translation.project_id, translation.id, data);
  };

  handleBackgroundProcessStatusUpdate = (data) => {
    const { translations } = this.props;
    const { backgroundProcessStatusUpdate } = this.state;
    let item = null;
    let translation = null;

    switch (data.type) {
      case CREATE_ALREADY_TRANSLATED_TEXTFIELDS:
        item = backgroundProcessStatusUpdate[data.type];
        translation = translations.find(
          (translation) => translation.id == data.id
        );

        item = item == null ? {} : item;

        if (translation) {
          if (data.percent == 100) {
            delete item[data.id];
            translation.isPending = false;
          } else {
            item[data.id] = data.percent;
            translation.isPending = true;
          }
        } else {
          console.log('BackgroundProcess: No translation found.');
        }

        this.setState({ backgroundProcessStatusUpdate: { [data.type]: item } });
        break;
      default:
        console.log('Unknown backgroundProcessStatusUpdate: ', data);
    }
  };

  render() {
    const { translations, model, t } = this.props;
    const { selected } = this.state;
    // const typesLNS = 'types'; // typesLanguageNamespaceSource

    let localeTranslations = fromJS(translations);
    localeTranslations = localeTranslations.toJS();

    localeTranslations.map((translation) => {
      translation.type_label = t(translation.type); // t(`${typesLNS}:${(translation.type}`)
      return translation;
    });

    return (
      <ExpansionPanel
        expanded={this.state.isExpanded}
        onExpandToggle={this.handleExpandToggle}
        label={this.props.label}
        footer={null}
        className={'expansionPanel'}
        headerClassName={'expansionHeader'}
        focused={this.props.focused}
        columnWidths={this.props.columnWidths}
      >
        <div className={'expansionSubHeader'}>
          {hasPermission(aclTranslation.create) && (
            <Button
              onClick={this.handleTranslationAdd}
              className={'addButton'}
              icon
              secondary
              swapTheming
            >
              add
            </Button>
          )}
        </div>
        <CustomTable
          model={model}
          rows={localeTranslations}
          selected={selected}
          onCellClick={this.handleCellClick}
          onRowClick={this.setTargetLanguage}
        />
      </ExpansionPanel>
    );
  }
}

export default withRouter(connect(null, { editTranslation })(TranslationPanel));
