import React, { PureComponent } from 'react';
import { Button, Drawer, Toolbar } from 'react-md';
import { NodeId } from '../utils/Constants';

import styles from '../styles/content_drawer.scss';

export default class ContentDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mainContainer: null
    };
  }

  componentDidMount() {
    this.setMainContainer();
  }

  UNSAFE_componentWillReceiveProps() {
    this.setMainContainer();
  }

  setMainContainer = () => {
    if (this.state.mainContainer == null) {
      let mc = document.getElementById(NodeId.MAIN_CONTAINER);

      if (mc != null) {
        this.setState({ mainContainer: mc });
      }
    }
  };

  handleVisibility = (visible) => {
    if (!visible) {
      this.props.closeDrawer();
    }
  };

  render() {
    const { visible, closeDrawer, children } = this.props;
    const closeBtn = (
      <Button icon onClick={closeDrawer}>
        {'close'}
      </Button>
    );

    if (this.state.mainContainer == null) {
      return null;
    }

    return (
      <Drawer
        className={styles.drawer}
        id='content-drawer'
        type={Drawer.DrawerTypes.TEMPORARY}
        visible={visible}
        position={'right'}
        onVisibilityChange={this.handleVisibility}
        clickableDesktopOverlay={true}
        overlay={true}
        renderNode={this.state.mainContainer}
        children={children}
        header={
          <Toolbar
            nav={closeBtn}
            className='md-divider-border md-divider-border--bottom'
          />
        }
      />
    );
  }
}
