import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { diffWords } from 'diff';
import { markSearchTerm, parseCode, parseTags } from '../../utils/RTEParser';
import StandardWithoutContentTools from './StandardWithoutContentTools';
import ContentTools from './ContentTools';
import { renderToString } from 'react-dom/server';

import { addUnsavedText, removeUnsavedText } from '../../actions/fieldLevel';

import styles from '../../styles/translationTool/translationTool.scss';
import { connect } from 'react-redux';
import { Button, FontIcon, Badge } from 'react-md';
import CommentsDrawer from './CommentsDrawer';
import classNames from 'classnames';
import { aclFilter } from '../../utils/ACL';
import { aclTranslationTool } from '../../utils/Permissions';
import { Scope } from '../../utils/constants/ACL';

const TARGET_COMPARE = 'target_compare';
const COMPARE = 'compare';
const FROM = 'from';
const TO = 'to';

@withTranslation('translation_navigation', { wait: true })
class FieldSection extends Component {
  tweenTimeout = null;

  /*
   * INFO: Diese Componente updated sich nur,
   * wenn man es in der shouldComponentUpdate speziell angibt
   */

  constructor(props) {
    super(props);

    this.state = {
      isRTEVisible: false,
      areContentToolsVisible: false,
      indicator: false,
      showCommentsWrapper: false,
      newCommentTextareaShown: false,
      commentsCount: 0
    };
  }

  componentWillUnmount() {
    const { isRTEVisible } = this.state;

    if (isRTEVisible) {
      this.removeUnsavedText();
    }
  }

  // checkForSelected = props => {
  //   const {scrollTo, collectionId, from} = props;
  //   const {isRTEVisible} = this.state;
  //
  //   if (!isRTEVisible && scrollTo.collection == collectionId &&
  //     scrollTo.textfield_id == from.id) {
  //     this.showRTE();
  //   }
  // };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { refName } = this.props;
    const { scrollTo, unsavedTexts } = nextProps;

    const { isRTEVisible } = this.state;

    if (!isRTEVisible) {
      // eslint-disable-next-line no-prototype-builtins
      if (
        scrollTo.hasOwnProperty('collection') &&
        scrollTo.hasOwnProperty('textfield_id') &&
        scrollTo.collection === this.props.collectionId &&
        scrollTo.textfield_id === this.props.from.id
      ) {
        this.showRTE();
      }
    }

    if (
      isRTEVisible &&
      !unsavedTexts.find((unsavedText) => unsavedText.refName === refName)
    ) {
      this.hideRTE();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      areTagsShown,
      areOnlyDifferencesShown,
      areDifferencesHighlighted,
      searchTerm
    } = nextProps;
    const {
      isRTEVisible,
      areContentToolsVisible,
      showCommentsWrapper,
      newCommentTextareaShown,
      commentsCount
    } = nextState;

    if (
      // props
      searchTerm !== this.props.searchTerm ||
      areTagsShown !== this.props.areTagsShown ||
      areOnlyDifferencesShown !== this.props.areOnlyDifferencesShown ||
      areDifferencesHighlighted !== this.props.areDifferencesHighlighted ||
      // states
      isRTEVisible !== this.state.isRTEVisible ||
      areContentToolsVisible !== this.state.areContentToolsVisible ||
      showCommentsWrapper !== this.state.showCommentsWrapper ||
      newCommentTextareaShown !== this.state.newCommentTextareaShown ||
      commentsCount !== this.state.commentsCount
    ) {
      return true;
    }
    return false;
  }

  showRTE = () => {
    this.addUnsavedText();

    this.setState({ isRTEVisible: true });
    clearTimeout(this.tweenTimeout);
    this.tweenTimeout = setTimeout(() => {
      this.setState({
        areContentToolsVisible: true,
        indicator: true
      });
    }, 200);
  };

  hideRTE = () => {
    // const {scrollTo} = this.props;
    this.removeUnsavedText();

    this.setState({ isRTEVisible: false });
    clearTimeout(this.tweenTimeout);
    this.tweenTimeout = setTimeout(() => {
      this.setState({
        areContentToolsVisible: false,
        indicator: false
      });
    }, 200);
  };

  addUnsavedText = () => {
    const { refName, collectionId, from } = this.props;

    this.props.addUnsavedText({
      refName: refName,
      collection: collectionId,
      id: from.id
    });
  };

  removeUnsavedText = () => {
    const { refName } = this.props;
    this.props.removeUnsavedText(refName);
  };

  createHighlight = (compareWith, from, version) => {
    let element = null;
    let className = '';

    let diff = diffWords(compareWith.toString(), from.toString());

    return (
      <div>
        {diff.map((part, index) => {
          element = null;

          className =
            // eslint-disable-next-line no-nested-ternary
            part.added ? 'added' : part.removed ? 'removed' : 'unchanged';

          if (className === 'removed' && version === COMPARE) {
            element = (
              <span key={index} className='removed'>
                {part.value}
              </span>
            );
          } else if (className === 'added' && version === FROM) {
            element = (
              <span key={index} className='added'>
                {part.value}
              </span>
            );
          } else if (className === 'unchanged') {
            element = (
              <span key={index} className='unchanged'>
                {part.value}
              </span>
            );
          }
          return element;
        })}
      </div>
    );
  };

  toggleCommentsWrapper = () => {
    this.setState({
      showCommentsWrapper: !this.state.showCommentsWrapper
    });
  };

  createElements = (modus) => {
    const {
      compareWith,
      targetCompareWith,
      from,
      to,
      areTagsShown,
      areDifferencesHighlighted,
      searchTerm
    } = this.props;

    let highlighted = null;
    let renderedToString = null;
    let parsed = null;

    switch (modus) {
      case COMPARE:
        if (areDifferencesHighlighted) {
          highlighted = this.createHighlight(
            compareWith.content,
            from.content,
            COMPARE
          );
          renderedToString = renderToString(highlighted);
        } else {
          renderedToString = compareWith ? compareWith.content : from.content;
        }
        break;
      case TARGET_COMPARE:
        if (areDifferencesHighlighted) {
          highlighted = this.createHighlight(
            targetCompareWith.content,
            to.content,
            COMPARE
          );
          renderedToString = renderToString(highlighted);
        } else {
          renderedToString = targetCompareWith
            ? targetCompareWith.content
            : to.content;
        }
        break;
      case TO:
        if (areDifferencesHighlighted && targetCompareWith !== undefined) {
          highlighted = this.createHighlight(
            targetCompareWith.content,
            to.content,
            FROM
          );
          renderedToString = renderToString(highlighted);
        } else {
          renderedToString = to.content;
        }
        break;
      case FROM:
        if (areDifferencesHighlighted && compareWith !== undefined) {
          highlighted = this.createHighlight(
            compareWith.content,
            from.content,
            FROM
          );
          renderedToString = renderToString(highlighted);
        } else {
          renderedToString = from.content;
        }
        break;
      default:
        renderedToString = from.content;
        break;
    }

    if (!areTagsShown) {
      // breaks korrekt anzeigen lassen => Zero-Width-Space wird genutzt
      let regexBreak = /\[br\]/g;
      let newRenderedToString = renderedToString
        .toString()
        .replace(regexBreak, '\n&#8203;');

      parsed = parseCode(newRenderedToString);
    } else {
      let regexBreak = /\[br\]/g;
      let newRenderedToString = renderedToString
        .toString()
        .replace(regexBreak, '[br]\n'); // Einrückungen korrekt anzeigen
      parsed = parseTags(newRenderedToString);
    }

    let stringifiedContent = (parsed + '').replace(/<p>(.*)<\/p>/, '$1'); // Int crasht!
    stringifiedContent =
      searchTerm !== ''
        ? markSearchTerm(stringifiedContent, searchTerm)
        : stringifiedContent;

    return (
      <div
        className={modus}
        dangerouslySetInnerHTML={{ __html: stringifiedContent }}
      />
    );
  };

  setCommentsCount = (i) => {
    this.setState({
      commentsCount: i
    });
  };

  render() {
    const {
      collectionId,
      compareWith,
      targetCompareWith,
      from,
      to,
      translationType,
      translationId,
      sourceLanguageId,
      areTagsShown,
      searchTerm,
      updateTextInIframe,
      t,
      refName,
      fieldIndex,
      areDifferencesHighlighted
    } = this.props;

    const {
      areContentToolsVisible,
      isRTEVisible,
      showCommentsWrapper,
      commentsCount
    } = this.state;

    let compareWithContent = null;
    let targetCompareWithContent = null;

    if (compareWith) {
      compareWithContent = this.createElements(COMPARE);
    }

    if (targetCompareWith) {
      targetCompareWithContent = this.createElements(TARGET_COMPARE);
    }

    let fromContent = this.createElements(FROM);
    let leftStyles = styles.nonEditableContent;
    let toContentWithTools = to.content;
    if (!to.content && translationType === 'adaption') {
      toContentWithTools = from.content;
    }

    var toContent = this.createElements(TO);

    let dataColumns = 2;
    if (compareWithContent) {
      dataColumns += 1;
    }
    if (targetCompareWithContent) {
      dataColumns += 1;
    }
    const columnWidth = 100 / dataColumns;

    // eslint-disable-next-line no-prototype-builtins
    const hasComments = to.hasOwnProperty('comments') && commentsCount > 0;

    return (
      <div
        className={styles.fieldSection}
        field-section-refname={refName}
        data-isopen={isRTEVisible.toString()}
        ref={(myRef) => (this.myRef = myRef)}
      >
        <div
          className={'fieldSectionLabel'}
          title={t('textfield_identifier_label')}
        >
          {fieldIndex + ' | ' + from.id}
        </div>
        <div className={'fieldSectionInner'}>
          <div
            className={
              areContentToolsVisible
                ? 'fieldSectionInnerBoxShadow innerField expanded'
                : 'fieldSectionInnerBoxShadow innerField'
            }
            data-indicator={this.state.indicator.toString()}
          >
            {compareWithContent && (
              <div
                className={'fieldCompareWith'}
                style={{ width: columnWidth + '%' }}
                active={this.state.isRTEVisible ? 'true' : 'false'}
              >
                <div
                  className={
                    areTagsShown ? leftStyles + ' styled-tags' : leftStyles
                  }
                >
                  {compareWithContent}
                </div>
              </div>
            )}
            <div
              className={'fieldFrom'}
              style={{ width: columnWidth + '%' }}
              active={this.state.isRTEVisible ? 'true' : 'false'}
            >
              <div
                className={
                  areTagsShown ? leftStyles + ' styled-tags' : leftStyles
                }
              >
                {fromContent}
              </div>
            </div>

            {targetCompareWithContent && (
              <div
                className={'fieldTargetCompareWith'}
                style={{ width: columnWidth + '%' }}
                active={this.state.isRTEVisible ? 'true' : 'false'}
              >
                <div
                  data-target-compare='true'
                  className={
                    areTagsShown ? leftStyles + ' styled-tags' : leftStyles
                  }
                >
                  {targetCompareWithContent}
                </div>
              </div>
            )}

            {/*
            <div
              className={'fieldTargetCompareWith'}
              style={{width: columnWidth + '%'}}
              active={this.state.isRTEVisible ? 'true' : 'false'}
            >
              <div data-target-compare="true" className={areTagsShown ? leftStyles + ' styled-tags' : leftStyles}>
                <span>bla bla bla</span>
              </div>
            </div>
            */}

            <div
              className={'fieldTo'}
              style={{ width: columnWidth + '%' }}
              active={this.state.isRTEVisible ? 'true' : 'false'}
              has-target-snapshot={targetCompareWithContent ? 'true' : 'false'}
            >
              {areContentToolsVisible && (
                <ContentTools
                  translationId={translationId}
                  sourceLanguageId={sourceLanguageId}
                  id={to.id}
                  content={toContentWithTools}
                  collectionId={collectionId}
                  textId={to.id}
                  searchTerm={searchTerm}
                  updateTextInIframe={updateTextInIframe}
                  hideRTE={this.hideRTE}
                  editable
                  textfieldId={from.id}
                />
              )}
              {!areContentToolsVisible && (
                <StandardWithoutContentTools
                  translationType={translationType}
                  content={to.content}
                  searchTerm={searchTerm}
                  showRTE={this.showRTE}
                  highlightedContent={toContent}
                  showHighlightedContent={
                    areTagsShown || areDifferencesHighlighted
                  }
                />
              )}
            </div>
          </div>

          {aclFilter(
            <CommentsDrawer
              show={showCommentsWrapper}
              toggle={this.toggleCommentsWrapper}
              comments={to.comments}
              translationId={translationId}
              collectionId={collectionId}
              textId={to.id}
              setCommentsCount={this.setCommentsCount}
            />
          )([], [aclTranslationTool.comments_read], Scope.TRANSLATION)}
        </div>

        {aclFilter(
          <Badge
            className={classNames('commentsButton', {
              hasComments: hasComments
            })}
            badgeClassName={'badge_of_button'}
            badgeContent={commentsCount}
            secondary
            badgeId='notifications-2'
          >
            <Button icon onClick={this.toggleCommentsWrapper}>
              <FontIcon>mode_comment</FontIcon>
            </Button>
          </Badge>
        )([], [aclTranslationTool.comments_read], Scope.TRANSLATION)}
      </div>
    );
  }
}

FieldSection.propTypes = {
  // from: PropTypes.object,
  // to: PropTypes.object,
  // id: PropTypes.string
};

function mapStateToProps(state) {
  return {
    scrollTo: state.getIn(['fieldLevel', 'scrollTo']),
    unsavedTexts: state.getIn(['fieldLevel', 'unsavedTexts']),
    areTagsShown: state.getIn(['translationTool', 'areTagsShown']),
    areOnlyDifferencesShown: state.getIn([
      'translationTool',
      'areOnlyDifferencesShown'
    ]),
    areDifferencesHighlighted: state.getIn([
      'translationTool',
      'areDifferencesHighlighted'
    ])
  };
}

export default connect(mapStateToProps, { addUnsavedText, removeUnsavedText })(
  FieldSection
);
