import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import LayoutContainer from '../layout/LayoutContainer';
import { UsersModel } from '../../tableModels';
import FilterSearchPanel from '../general/FilterSearchPanel';
import CustomTable from '../general/CustomTable';
import LoadingBar from '../general/DumbComponent';
import DeleteUserWarning from './dialog/DeleteUserWarning';
import InviteUserWarning from './dialog/InviteUserWarning';
import { ProcessStatus, Routes } from '../../utils/Constants';
import { arrayHelper } from '../../utils/HelperFunctions';
import ContentDrawer from '../../utils/ContentDrawer';
import AddUser from './adduser/AddUser';
import EditUserContainer from './edituser/EditUserContainer';
import { fetchClients } from '../../actions/clients';
import { loginAsUser } from '../../actions/auth';
import { fetchUsers, deleteUser, editUser } from '../../actions/users';
import { showWarning } from '../../actions/globalDialog';
import { hasPermission } from '../../utils/AuthHelper';
import { aclUser } from '../../utils/Permissions';

@withTranslation(['users', 'general'], { wait: true })
class ListUsers extends Component {
  state = {
    searchTerm: '',
    filters: [],
    focusOnTable: false,
    newItem: false,
    hasNewItem: false
  };

  UNSAFE_componentWillMount() {
    this.update(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loggingInAsUser, users } = nextProps;

    if (this.props.users) {
      this.setState({
        hasNewItem: this.props.users.size < users.size
      });
    }

    if (loggingInAsUser !== ProcessStatus.STARTED) {
      this.update(nextProps);
    }
  }

  update = (props) => {
    const { fetchingUsers, fetchingClients } = props;

    if (fetchingUsers == ProcessStatus.INITIAL) {
      this.props.fetchUsers();
    }

    if (fetchingClients == ProcessStatus.INITIAL) {
      this.props.fetchClients();
    }
  };

  handleFilter = (type, value) => {
    const newFilters = arrayHelper(this.state.filters, type, value);
    this.setState({ filters: newFilters });
  };

  handleSearchInput = (value) => {
    this.setState({ searchTerm: value });
  };

  handleSearchReset = () => {
    this.setState({ searchTerm: '' });
  };

  hitEnterToFocusOnTable = () => {
    this.setState({ focusOnTable: true });
  };

  closeDrawer = () => {
    this.props.history.push('/users');
  };

  handleCellClick = (row, cell, idxSortedData, idxSequence, custom) => {
    if (custom && custom.name) {
      switch (custom.name) {
        case 'edit':
          this.handleEdit(row.id);
          break;

        case 'delete':
          this.showDeleteWarning(row);
          break;

        case 'invite':
          this.showInviteWarning(row);
          break;

        case 'loginAs':
          this.props.loginAsUser(row.id);
          break;
        default:
          break;
      }
    }
    if (cell && cell.id) {
      switch (cell.id) {
        case 'active':
          this.handleActive(row);
          break;
        default:
          break;
      }
    }
  };

  handleEdit = (id) => {
    this.props.history.push('users/' + id + '/edit');
  };

  handleActive = (user) => {
    user.active = parseInt(+!user.active);
    this.props.editUser(user);
  };

  showDeleteWarning = (user) => {
    const { showWarning, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let title = t(`${generalLNS}:warning`);
    let text =
      t('warning_delete_user') + ' ' + user.name + ' (' + user.username + ') ?';

    let component = <DeleteUserWarning userId={user.id} />;

    showWarning({
      title: title,
      text: text,
      actions: [],
      component: component,
      isCancellable: false
    });
  };

  showInviteWarning = (user) => {
    const { showWarning, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let title = t(`${generalLNS}:warning`);
    let text =
      t('warning_invite_user') + ' ' + user.name + ' (' + user.username + ') ?';

    let component = <InviteUserWarning user={user} />;

    showWarning({
      title: title,
      text: text,
      actions: [],
      component: component,
      isCancellable: false
    });
  };

  renderFiltersAndTable = () => {
    const { hasNewItem } = this.state;
    const { users, user, loggingInAsUser } = this.props;

    const filters = UsersModel.prepareFilters.call(
      UsersModel, // focus
      UsersModel.filters,
      ['active'],
      users
    );

    let usersWithAdvancedSuper = [];
    if (users) {
      let usersArray = users.toArray();
      for (let k = 0; k < usersArray.length; k++) {
        let user = usersArray[k];
        if (user.roles[0]) {
          user.role = user.roles[0].display_name;
        } else {
          user.role = 'NO ROLE YET';
        }
        usersWithAdvancedSuper.push(user);
      }
    }

    let route = hasPermission(aclUser.create) ? Routes.USERS.ADD : null;

    return (
      <React.Fragment>
        <ContentDrawer
          key='1'
          children={<AddUser closeDrawer={this.closeDrawer} />}
          visible={this.props.match.path === Routes.USERS.ADD}
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='2'
          children={<EditUserContainer />}
          visible={this.props.match.path === Routes.USERS.EDIT}
          closeDrawer={this.closeDrawer}
        />
        <FilterSearchPanel
          filters={filters}
          appliedFilters={this.state.filters}
          handleFilter={this.handleFilter}
          searchBox={true}
          searchTerm={this.state.searchTerm}
          handleSearchInput={this.handleSearchInput}
          handleSearchReset={this.handleSearchReset}
          hitEnterToFocus={this.hitEnterToFocusOnTable}
          routeToAddNewItem={route}
        />
        <CustomTable
          model={UsersModel}
          rows={usersWithAdvancedSuper}
          searchTerm={this.state.searchTerm}
          appliedFilters={this.state.filters}
          focus={this.state.focusOnTable}
          onCellClick={this.handleCellClick}
          onRowClick={this.handleEdit}
          hasLoginButton={
            loggingInAsUser != ProcessStatus.STARTED && user.loggedin_as == null
          }
          userId={user.id}
          hasNewItem={hasNewItem}
        />
      </React.Fragment>
    );
  };

  render() {
    const { clients, users, fetchingUsers, loggingInAsUser } = this.props;

    if (
      clients.size <= 0 ||
      fetchingUsers == ProcessStatus.STARTED ||
      loggingInAsUser == ProcessStatus.STARTED
    ) {
      return (
        <LayoutContainer>
          <LoadingBar show={true} />
        </LayoutContainer>
      );
    }
    if (users == undefined || users.length <= 0) {
      return <div>No users found.</div>;
    }

    return <LayoutContainer>{this.renderFiltersAndTable()}</LayoutContainer>;
  }
}

function mapStateToProps(state) {
  return {
    fetchingUsers: state.getIn(['users', 'fetchingUsers']),
    users: state.getIn(['users', 'users']),
    fetchingClients: state.getIn(['clients', 'fetchingClients']),
    clients: state.getIn(['clients', 'clients']),
    user: state.getIn(['auth', 'user']),
    loggingInAsUser: state.getIn(['auth', 'loggingInAsUser']),
    loggingOutAsUser: state.getIn(['auth', 'loggingOutAsUser'])
  };
}

export default connect(mapStateToProps, {
  loginAsUser,
  fetchClients,
  fetchUsers,
  deleteUser,
  editUser,
  showWarning
})(ListUsers);
