// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\r\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.list_item:hover {\n  background-color: #eeeeee;\n  cursor: pointer;\n}\n\n.hover-round-button {\n  min-width: 36px;\n  padding: 0;\n  margin: 0;\n  margin-left: 5px;\n  width: 36px;\n  height: 36px;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  background-color: transparent !important;\n  color: #6f7b80;\n}\n\n.hover-round-button:disabled {\n  opacity: 0.15;\n  pointer-events: none;\n  cursor: none;\n}\n\n.hover-round-button:before {\n  z-index: 3;\n  content: '';\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 36px;\n  height: 36px;\n  background-color: #6f7b80;\n  border-radius: 50%;\n  transform-origin: top left;\n  transform: scale(0) translateX(-50%) translateY(-50%);\n  transition: all 0.3s ease;\n}\n\n.hover-round-button > span {\n  position: relative;\n  margin: 0 !important;\n  color: #6f7b80;\n  z-index: 4;\n  background-color: transparent;\n  transition: all 0.3s ease;\n}\n\n.hover-round-button:hover {\n  color: white !important;\n}\n\n.hover-round-button:hover > span {\n  color: white !important;\n}\n\n.hover-round-button:hover:before {\n  transform: scale(1) translateX(-50%) translateY(-50%);\n}\n", ""]);
// Exports
exports.locals = {
	"list_item": "list_item",
	"listItem": "list_item",
	"hover-round-button": "hover-round-button",
	"hoverRoundButton": "hover-round-button"
};
module.exports = exports;
