import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Routes, ProcessStatus } from '../../utils/Constants';
import ContentDrawer from '../../utils/ContentDrawer';
import MangooluLoading from '../general/MangooluLoading';
import ProjectOverview from './projectoverview/ProjectOverview';
import TranslationOverview from './translationoverview/TranslationOverview';
import AddTranslation from './addtranslation/AddTranslation';
import EditTranslationContainer from './edittranslation/EditTranslationContainer';
import ImportTranslationWizard from './importtranslationwizard/ImportTranslationWizard';
// import ImportPDFTranslationWizard from './importtranslationwizard/ImportPDFTranslationWizard';
import ExportTranslationWizard from './exporttranslationwizard/ExportTranslationWizard';
import AddSnapshot from './addsnapshot/AddSnapshot';
import EditSnapshot from './editsnapshot/EditSnapshot';
import EditProject from './editproject/EditProjectContainer';
import UpdateProject from './updateproject/UpdateProject';
import LayoutContainer from '../layout/LayoutContainer';
import { fetchProject, fetchTranslation } from '../../actions/projects';

@withTranslation(['project', 'general'], { wait: true })
class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      translation: null,
      isProjectDrawer: false,
      isTranslationDrawer: false,
      isTranslationViewVisible: false,
      newTabTranslationWindow: null
    };
  }

  UNSAFE_componentWillMount() {
    let projectId = parseInt(this.props.match.params.projectId);
    this.props.fetchProject(projectId);
  }

  // eslint-disable
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      project,
      translation,
      fetchingProject,
      fetchingTranslation,
      match
    } = nextProps;

    let projectId = match.params.projectId;
    let translationId = match.params.translationId;
    let translations =
      project && project.translations ? project.translations : [];

    if (project == null || fetchingProject === ProcessStatus.STARTED) {
      return null;
    }

    if (project.translations) {
      if (
        project.id != projectId ||
        project.translations.length !== nextProps.project.translations.length
      ) {
        this.props.fetchProject(projectId);
      }
    }

    if (translationId) {
      let associatedTranslation = translations.find(
        (translation) => translation.id == translationId
      );

      if (match.params.sourceLanguageId == undefined) {
        // ...
      } else {
        // match.params.sourceLanguageId
      }

      // wenn es die translation aus der ROUTE nicht gibt
      if (associatedTranslation == null) {
        let formerlyAssociatedTranslation = translations.find(
          (translation) => translation.id == translationId
        );

        // wenn es die translation auch im vorherigen Zyklus nicht gab
        if (formerlyAssociatedTranslation == null) {
          let nextTranslationId = project.master_translation;

          if (nextTranslationId == translationId) {
            // wenn es die masterTranslationId nicht gibt, nehme die erste vorhandene
            if (translations.length > 0) {
              nextTranslationId = translations[0].id;
            } else {
              nextTranslationId = null;
              console.warn('FEHLER: translations.length === 0');
            }
          }
          if (nextTranslationId) {
            this.changeRoute(nextProps, projectId, nextTranslationId);
          } else {
            console.warn('Project has no translations!');
          }
        } else {
          // wenn es sie gab, wurde sie wahrscheinlich deleted
          this.setTranslationAfterDelete(
            projectId,
            translations,
            translationId
          );
        }
      } else {
        // wenn es noch keine detaillierte Translation gibt oder sie veraltet ist
        if (translation.size == 0 || translation.get('id') != translationId) {
          console.log(
            'wenn es noch keine detaillierte Translation gibt oder sie veraltet ist'
          );
          // wenn der Drawer geöffnet ist
          if (
            nextProps.match.path == Routes.PROJECTS.TRANSLATIONS.IMPORT ||
            nextProps.match.path == Routes.PROJECTS.TRANSLATIONS.IMPORT_ERROR ||
            nextProps.match.path == Routes.PROJECTS.TRANSLATIONS.EDIT ||
            nextProps.match.path == Routes.PROJECTS.TRANSLATIONS.EDIT_WARNING
          ) {
            if (translation.size == 0) {
              console.log('kommt das vor?');
              this.props.fetchTranslation(projectId, translationId);
            } else {
              console.log('bin im Drawer Mode');
            }
          } else {
            if (fetchingTranslation != ProcessStatus.STARTED) {
              this.props.fetchTranslation(projectId, translationId);
            }
          }
        } else {
          if (nextProps.translation != this.props.translation) {
            if (translation.get('id') != this.props.translation.get('id')) {
              if (translationId != translation.get('id')) {
                this.changeRoute(nextProps, projectId, translation.get('id'));
              }
            }
          }
        }
      }
    }
  }
  // eslint-enable

  changeRoute = (props, projectId, translationId) => {
    let translations = props.project.translations;
    let projectsPart = '/projects/' + projectId;
    let sourceLanguageId = props.match.params.sourceLanguageId;
    let sourceLanguagePart = '';

    if (translationId === props.project.master_translation) {
      sourceLanguagePart = '';
    } else if (sourceLanguageId == null && translations.length > 0) {
      let translation = translations.find((translation) => {
        return translation.id === translationId;
      });
      if (
        translation.sources &&
        translation.sources.length > 0 &&
        translation.sources[0].id
      ) {
        sourceLanguagePart = '/' + translation.sources[0].id;
      } else {
        sourceLanguagePart = '';
      }
    } else if (sourceLanguageId != null) {
      sourceLanguagePart = '/' + sourceLanguageId;
    }
    this.props.history.push(
      projectsPart + '/translations/' + translationId + sourceLanguagePart
    );
  };

  closeDrawer = () => {
    let projectsPart = '/projects/' + this.props.match.params.projectId;
    let translationsPart =
      '/translations/' + this.props.match.params.translationId;
    let sourceLanguagePart = this.props.match.params.sourceLanguageId
      ? '/' + this.props.match.params.sourceLanguageId
      : '';
    this.props.history.push(
      projectsPart + translationsPart + sourceLanguagePart
    );
  };

  setTranslationAfterDelete = (projectId, translations, translationId) => {
    let deletedTranslationPosIndex = translations.findIndex(
      (translation) => translation.id == translationId
    );
    let newTranslationPosIndex = 0;

    if (translations.length - 1 > deletedTranslationPosIndex) {
      newTranslationPosIndex = deletedTranslationPosIndex + 1;
    } else if (translations.length >= 1) {
      newTranslationPosIndex = deletedTranslationPosIndex - 1;
    } else {
      // TODO: Was ist, wenn es keine Translation gibt?
    }
    let newTranslation = translations[newTranslationPosIndex];
    let newTranslationIndex = newTranslation.id;
    this.props.history.push(
      '/projects/' + projectId + '/translations/' + newTranslationIndex
    );
  };

  render() {
    const {
      project,
      fetchingProject,
      sourceSnapshotId,
      translation,
      t,
      match: {
        params: { projectId, translationId, sourceLanguageId, snapshotId }
      }
    } = this.props;

    let translations =
      project && project.translations ? project.translations : [];

    if (
      fetchingProject == ProcessStatus.STARTED ||
      (project && project.id != projectId)
    ) {
      return (
        <LayoutContainer>
          <MangooluLoading show='true' />
        </LayoutContainer>
      );
    }
    if (project == null) {
      return (
        <LayoutContainer>{t('project_with_id_not_found')}</LayoutContainer>
      );
    }

    let route = '/projects/translate/' + projectId + '/' + translationId;

    let sources = null;
    if (translation.size > 0) {
      sources = translation.get('sources');
    } else {
      console.warn('translation.length === 0');
    }

    if (sourceLanguageId) {
      route += '/' + sourceLanguageId;
    } else if (sources && sources.length > 0) {
      route += '/' + sources[sources.length - 1].id;
    }

    if (sourceSnapshotId >= 0) {
      route += '/?sourcesnapshot-id=' + sourceSnapshotId;
    }

    let snapshotsLength = 0;
    if (translation) {
      if (translation.get('snapshots')) {
        snapshotsLength = translation.get('snapshots').length;
      }
    }

    const currentTranslation = translations.find((translation) => {
      return translation.id === parseInt(translationId);
    });

    let translationSegment = () => {
      return (
        <div help-tool='2'>
          <TranslationOverview
            project={project}
            translations={translations}
            translation={currentTranslation}
            route={route}
            projectId={projectId}
            projectIdentifier={project.identifier}
            translationId={translationId}
            sourceSnapshotId={sourceSnapshotId}
            soureLanguageId={sourceLanguageId}
          />
        </div>
      );
    };

    return (
      <div>
        <ContentDrawer
          key='1'
          children={<EditProject />}
          visible={this.props.match.path === Routes.PROJECTS.EDIT}
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='2'
          children={<UpdateProject projectId={project.id} />}
          visible={this.props.match.path === Routes.PROJECTS.UPDATE}
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='3'
          children={
            <AddTranslation
              projectId={project.id}
              closeDrawer={this.closeDrawer}
            />
          }
          visible={this.props.match.path === Routes.PROJECTS.TRANSLATIONS.ADD}
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='4'
          children={<EditTranslationContainer />}
          visible={this.props.match.path === Routes.PROJECTS.TRANSLATIONS.EDIT}
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='5'
          children={<ImportTranslationWizard />}
          visible={
            this.props.match.path === Routes.PROJECTS.TRANSLATIONS.IMPORT
          }
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='6'
          children={
            <ExportTranslationWizard
              projectId={project.id}
              translationId={translation.get('id')}
            />
          }
          visible={
            this.props.match.path === Routes.PROJECTS.TRANSLATIONS.EXPORT
          }
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='7'
          children={
            <AddSnapshot
              snapshotLength={snapshotsLength}
              translationName={
                translation.get('languagecode') +
                ' - ' +
                translation.get('name')
              }
            />
          }
          visible={
            this.props.match.path === Routes.PROJECTS.TRANSLATIONS.ADD_SNAPSHOT
          }
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='8'
          children={
            <EditSnapshot
              projectId={project.id}
              translationId={translation.get('id')}
              snapshotId={snapshotId}
            />
          }
          visible={
            this.props.match.path === Routes.PROJECTS.TRANSLATIONS.EDIT_SNAPSHOT
          }
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='9'
          children={
            <ImportTranslationWizard
              isPDFImporter={true}
              collectionId={
                this.props.match.params.collectionId
                  ? this.props.match.params.collectionId
                  : ''
              }
            />
          }
          visible={
            this.props.match.path === Routes.PROJECTS.TRANSLATIONS.IMPORT_PDF
          }
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='10'
          children={
            <ExportTranslationWizard
              isPDFExporter={true}
              projectId={project.id}
              translationId={translation.get('id')}
              collectionId={
                this.props.match.params.collectionId
                  ? this.props.match.params.collectionId
                  : ''
              }
            />
          }
          visible={
            this.props.match.path === Routes.PROJECTS.TRANSLATIONS.EXPORT_PDF
          }
          closeDrawer={this.closeDrawer}
        />
        {/* <ContentDrawer
          key="11"
          children={<ExportTranslationWizard isPDFExporter={true}
            projectId={project.id} translationId={translation.get('id')}
            collectionId={this.props.collectionId ? this.props.collectionId : null}/>}
          visible={this.props.match.path === Routes.PROJECTS.TRANSLATIONS.EXPORT_PDF}
          closeDrawer={this.closeDrawer}
        /> */}
        <LayoutContainer width='small'>
          <div help-tool='1'>
            <ProjectOverview
              translations={translations}
              translation={translation}
            />
          </div>
          {translationSegment()}
        </LayoutContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchingProject: state.getIn(['projects', 'fetchingProject']),
    project: state.getIn(['projects', 'selectedProject']),
    fetchingTranslation: state.getIn(['projects', 'fetchingTranslation']),
    translation: state.getIn(['projects', 'translation']),
    sourceSnapshotId: state.getIn(['sourceSnapshots', 'sourceSnapshotId']),
    updateToken: state.getIn(['projects', 'updateToken'])
  };
}

export default withRouter(
  connect(mapStateToProps, { fetchProject, fetchTranslation })(Project)
);
