import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { linkClientToUser } from '../../../actions/clients';
import { fetchUsers } from '../../../actions/users';
import RelationBase from '../../general/RelationBase';
import RoleAvatarChip from '../../general/chip/RoleAvatarChip';

@withTranslation(['clients', 'general'], { wait: true })
class ClientUsers extends Component {
  linkSourceToTarget = (mode, userId, roleId) => {
    this.props.linkClientToUser({
      mode: mode,
      user_id: userId,
      client_id: this.props.client.id,
      role_id: roleId,
      property_type: 'clients_x_users'
    });
  };

  render() {
    const { client, users, fetchingUsers, linkingClientToUser, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <RelationBase
        fetchTargets={this.props.fetchUsers}
        fetchingTargets={fetchingUsers}
        label={t(`${generalLNS}:users`)}
        source={client}
        sourceTargets={client.users}
        targets={users}
        chip={RoleAvatarChip}
        linkSourceToTarget={this.linkSourceToTarget}
        linkingSourceToTargets={linkingClientToUser}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    client: state.getIn(['clients', 'selectedClient']),
    users: state.getIn(['users', 'users']),
    fetchingUsers: state.getIn(['users', 'fetchingUsers']),
    linkingClientToUser: state.getIn(['clients', 'linkingClientToUser']),
    updateToken: state.getIn(['clients', 'updateToken'])
  };
}

export default connect(mapStateToProps, { linkClientToUser, fetchUsers })(
  ClientUsers
);
