import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontIcon, Button } from 'react-md';
import Search from '../general/search/Search';
import ControlMenu from './ControlMenu';
import LoadingBar from '../general/DumbComponent';
import SourceSnapshots from './SourceSnapshots';
import TargetSnapshots from './TargetSnapshots';
import UnsavedTextFlag from './UnsavedTextFlag';
import { withTranslation } from 'react-i18next';
import { fetchProject, fetchTranslation } from '../../actions/projects';
import {
  fetchTranslations,
  fetchAdditionalContentTranslations,
  setAreAllTextsShown,
  setCommentsCount,
  setOnlyWithCommentsShown
} from '../../actions/translationTool';
import { showNotification } from '../../actions/notifications';
import { fetchSourceSnapshots } from '../../actions/sourceSnapshots';
import { fetchSnapshots } from '../../actions/snapshots';
import { ProcessStatus } from '../../utils/Constants';
import styles from '../../styles/translationTool/translationTool.scss';
import images from './translationTool/dataUrlImages';
import { setScrollTo } from '../../actions/fieldLevel';
import {
  performScrollToUnsavedText,
  performScrollToCollection,
  getFieldSection
} from '../../utils/ScrollManager';
import FieldSection from './FieldSection';
// import is from 'is_js';

import { parseHTML } from '../../utils/RTEParser';
// import classNames from 'classnames';
// import {fromJS} from 'immutable';
// import {checkMemorySizeOf} from '../../utils/HelperFunctions';

@withTranslation('translation_navigation', { wait: true })
class TranslationTool extends Component {
  state = {
    searchTerm: '',
    projectId: 0,
    translationId: 0,
    textsOnStage: 0,
    currentPageId: 0,
    firstScroll: true,
    translationLanguageCode: '',
    shouldUpdateTexts: false,
    darkMode: true,
    helpOpened: false,
    textUpdate: false,
    sections: [],
    firstFieldSectionIndex: 0,
    lastFieldSectionIndex: 100,
    maxItems: 100,
    scrollBarMoveStart: 0,
    scrollbarIsMoving: false,
    scrollBarMoveStartValues: {},
    moveSBH: 0,
    firstInitialisation: false,
    movingPositionHeadline: '',
    translationType: '',
    newScrollTop: 40,
    handleHandler: 40,
    hasScrollbar: false,
    // isIE: is.ie(),
    isIE: false,
    sourceType: '',
    sourceLanguageId: 0,
    sourceLanguageCode: '',
    sourceSnapshotTranslationId: -1,
    sourceSnapshotId: -1,
    targetSnapshotTranslationId: -1,
    targetSnapshotId: -1,
    selectedUpdateAndNotOnStage: false,
    commentsCount: 0
  };
  textWasSelected = false;
  translationToolWithCollection = false;

  constructor(props) {
    super(props);
    this.translationToolRef = React.createRef();
    this.handleRef = React.createRef();
    this.afterRenderTimeout = null;
  }

  UNSAFE_componentWillMount() {
    if (!this.props.startedToolAsLayer) {
      let root = document.querySelector('#root');
      root.setAttribute(
        'style',
        // 'overflow-y: scroll!important; min-width: 900px; height: 100vh;'
        // 'overflow-y: scroll!important; min-width: 900px; height: 100vh; width: calc(100vw + 17px); padding-right: 0px;'
        'min-width: 900px; height: 100vh'
      );
    }

    window.addEventListener('storage', () => {
      this.updateFromLocalStorage(this.props);
    });

    this.updateFromLocalStorageOnStart();
    this.checkOnDarkLightMode();
  }

  componentDidMount() {
    const { projectId, translationId, sourceLanguageId } = this.state;
    this.props.fetchProject(projectId);
    this.props.fetchTranslation(projectId, translationId);
    this.props.fetchSourceSnapshots(projectId, sourceLanguageId); // fetch all sourceSnapshots
    this.props.fetchSnapshots(projectId, translationId); // fetch all targetSnapshots

    setTimeout(() => {
      if (this.props.scrollTo && this.state.firstScroll) {
        this.setState({
          firstScroll: false
        });
        this.getScrollingPositionHeadline();
      }
    }, 3000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      textsForLocalStorage,
      fetchingTranslations,
      areAllTextsShown,
      areOnlyDifferencesShown,
      areOnlyEmptyShown,
      onlyWithCommentsShown,
      scrollTo
      // translations,
      // fetchingTargetSnapshots
    } = nextProps;

    if (textsForLocalStorage !== this.props.textsForLocalStorage) {
      this.localDispatchTextUpdate(textsForLocalStorage);

      this.updateSections(nextProps, null, false);
    } else if (
      areAllTextsShown !== this.props.areAllTextsShown ||
      areOnlyDifferencesShown !== this.props.areOnlyDifferencesShown ||
      areOnlyEmptyShown !== this.props.areOnlyEmptyShown ||
      onlyWithCommentsShown !== this.props.onlyWithCommentsShown
    ) {
      this.updateSections(nextProps);

      if (this.state.selectedUpdateAndNotOnStage !== false) {
        setTimeout(() => {
          console.log('LOG 1');
          performScrollToUnsavedText(
            this.state.selectedUpdateAndNotOnStage.ref
          );
          this.props.setScrollTo(
            this.state.selectedUpdateAndNotOnStage.scrollTo
          );
          this.setState({ selectedUpdateAndNotOnStage: false });
        }, 1500);
      }
    }

    if (
      this.props.startedToolAsLayer &&
      nextProps.inlineTranslationToolObject.textWasSelected
    ) {
      this.updateFromLocalStorage(nextProps);
    }

    if (
      fetchingTranslations === ProcessStatus.FINISHED &&
      fetchingTranslations !== this.props.fetchingTranslations
    ) {
      this.updateSections(nextProps);
    }

    if (this.state.firstInitialisation === false) {
      // UPDATE
      if (
        scrollTo.collection !== this.props.scrollTo.collection ||
        scrollTo.textfield_id !== this.props.scrollTo.textfield_id
      ) {
        this.setStartItemToNewTextfield(scrollTo);
      }
    } else {
      // INITIAL START:
      if (
        fetchingTranslations === ProcessStatus.FINISHED &&
        fetchingTranslations !== this.props.fetchingTranslations &&
        this.textWasSelected
      ) {
        this.updateSections(nextProps);

        setTimeout(() => {
          this.setStartItemToNewTextfield(this.state.firstInitialisation);
          let ref =
            'ref_' +
            this.state.firstInitialisation.collection +
            '_' +
            this.state.firstInitialisation.textfield_id;
          setTimeout(() => {
            performScrollToUnsavedText(ref); // initaler Scroll!
          }, 1400);
          this.props.setScrollTo(
            this.state.firstInitialisation === false
              ? { collection: '', textfield_id: '' }
              : this.state.firstInitialisation
          );
          setTimeout(() => {
            this.setState({ firstInitialisation: true });
          }, 1500);
        }, 150);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('storage', () => {
      this.updateFromLocalStorage(this.props);
    });
  }

  checkOnDarkLightMode = () => {
    let mangooluMode = JSON.parse(localStorage.getItem('mangoolu_darkmode'));
    if (mangooluMode) {
      this.setTheme(mangooluMode.darkMode);
    } else {
      if (this.state.darkMode) {
        this.setTheme(this.state.darkMode);
      }
    }
  };

  setTheme = (dark) => {
    let html = document.querySelector('html');
    let htmlHasClassDark = html.classList.contains('dark');
    if (dark) {
      if (!htmlHasClassDark) {
        document.querySelector('html').classList.add('dark');
      }
    } else {
      if (htmlHasClassDark) {
        document.querySelector('html').classList.remove('dark');
      }
    }
    this.setState({ darkMode: dark });
  };

  setTargetSnapshot = (id) => {
    this.setState({ targetSnapshotId: id }, () => {
      this.fetchTranslationsAfterSettingNewSnapshots();
    });
  };

  setSourceSnapshot = (id) => {
    this.setState({ sourceSnapshotId: id }, () => {
      this.fetchTranslationsAfterSettingNewSnapshots();
    });
  };

  fetchTranslationsAfterSettingNewSnapshots = () => {
    const { viewId } = this.props.match.params;
    let vid = this.props.startedToolAsLayer ? this.props.vid : viewId;
    let idsFromLocalStorage = this.props.startedToolAsLayer
      ? this.props.inlineTranslationToolObject
      : JSON.parse(localStorage.getItem('openProject_' + vid));
    this.fetchTranslations(idsFromLocalStorage);
  };

  fetchSourceSnapshots = () => {
    const { projectId, sourceLanguageId, sourceSnapshotId } = this.state;
    this.props.fetchSourceSnapshots(
      projectId,
      sourceLanguageId,
      sourceSnapshotId
    );
  };

  fetchTargetSnapshots = (localStorage) => {
    const { projectId, translationId } = localStorage;
    const { targetSnapshotId } = this.state;

    this.props.fetchSnapshots(projectId, translationId, targetSnapshotId);
  };

  fetchTranslations = (localStorage) => {
    const { translationId, sourceLanguageId, currentPageId } = localStorage;
    const { sourceSnapshotId, targetSnapshotId } = this.state;

    let translationsData = {
      currentExternalId: currentPageId,
      targetTranslationId: translationId,
      sourceTranslationId: sourceLanguageId,
      compare_with: sourceSnapshotId,
      target_compare_with: targetSnapshotId
    };

    if (sourceSnapshotId == null || sourceSnapshotId == -1) {
      delete translationsData.compare_with;
    }

    if (targetSnapshotId == null || targetSnapshotId == -1) {
      delete translationsData.target_compare_with;
    }

    if (localStorage.collection) {
      this.translationToolWithCollection = true;
      translationsData.collection = localStorage.collection;
      // translationsData = {...translationsData};
      this.props.fetchAdditionalContentTranslations(translationsData);
      this.props.setAreAllTextsShown(!this.props.areAllTextsShown);
      // this.props.fetchTranslations(translationsData);
    } else {
      this.props.fetchTranslations(translationsData);
    }

    this.setState({
      translationFetched: true,
      shouldUpdateTexts: false
    });
  };

  updateFromLocalStorageOnStart = () => {
    const { viewId } = this.props.match.params;
    let vid = this.props.startedToolAsLayer ? this.props.vid : viewId;
    let idsFromLocalStorage = this.props.startedToolAsLayer
      ? this.props.inlineTranslationToolObject
      : JSON.parse(localStorage.getItem('openProject_' + vid));

    if (
      this.state.currentLocalStorage !== idsFromLocalStorage &&
      this.state.firstScroll
    ) {
      let tos = this.formatTextsOnStage(idsFromLocalStorage.textsOnStage);

      this.setState(
        {
          currentLocalStorage: idsFromLocalStorage,
          id: idsFromLocalStorage.id,
          projectId: idsFromLocalStorage.projectId,
          translationId: idsFromLocalStorage.translationId,
          sourceLanguageId: idsFromLocalStorage.sourceLanguageId,
          sourceSnapshotId: idsFromLocalStorage.sourceSnapshotId,
          currentPageId: idsFromLocalStorage.currentPageId,
          textsOnStage: tos,
          translationLanguageCode: idsFromLocalStorage.translationLanguageCode,
          sourceLanguageCode: idsFromLocalStorage.sourceLanguageCode,
          sourceType: idsFromLocalStorage.sourceType,
          translationType: idsFromLocalStorage.translationType,
          startedFromAdditionalContentPanelPDF:
            idsFromLocalStorage.startedFromAdditionalContentPanelPDF,
          firstInitialisation: idsFromLocalStorage.textWasSelected
            ? idsFromLocalStorage.scrollTo
            : false
        },
        () => {
          this.fetchTranslations(idsFromLocalStorage);
          this.fetchSourceSnapshots();
          this.fetchTargetSnapshots(idsFromLocalStorage);
          // eslint-disable-next-line no-prototype-builtins
          if (idsFromLocalStorage.hasOwnProperty('showAllTexts')) {
            this.props.setAreAllTextsShown(true);
          }
          this.updateSections(this.props, tos);
        }
      );
      this.textWasSelected = idsFromLocalStorage.textWasSelected;
    }
  };

  formatTextsOnStage = (textsOnStage) => {
    let tos = [];
    for (let i = 0; i < textsOnStage.length; i++) {
      let item = textsOnStage[i];
      tos.push({
        collection: item.c,
        textfieldId: item.t
      });
    }
    return tos;
  };

  updateFromLocalStorage = (props) => {
    const { viewId } = props.match.params;
    const { scrollTo } = props;

    let vid = props.startedToolAsLayer ? props.vid : viewId;
    let idsFromLocalStorage = props.startedToolAsLayer
      ? props.inlineTranslationToolObject
      : JSON.parse(localStorage.getItem('openProject_' + vid));
    const mangooluTheme = JSON.parse(localStorage.getItem('mangoolu_darkmode'));

    if (mangooluTheme.darkMode !== this.state.darkMode) {
      this.setTheme(mangooluTheme.darkMode);
    }

    // SELECTED-UPDATE
    if (idsFromLocalStorage.textWasSelected) {
      if (
        idsFromLocalStorage.scrollTo &&
        // && scrollTo.collection !== ''
        // && scrollTo.textfield_id !== ''
        (idsFromLocalStorage.scrollTo.collection !== scrollTo.collection ||
          idsFromLocalStorage.scrollTo.textfield_id !== scrollTo.textfield_id)
      ) {
        this.setStartItemToNewTextfield(idsFromLocalStorage.scrollTo);

        let ref =
          'ref_' +
          idsFromLocalStorage.scrollTo.collection +
          '_' +
          idsFromLocalStorage.scrollTo.textfield_id;

        if (getFieldSection(ref) === null) {
          this.props.setAreAllTextsShown(!this.props.areAllTextsShown);
          this.setState({
            selectedUpdateAndNotOnStage: {
              ref: ref,
              scrollTo: idsFromLocalStorage.scrollTo
            }
          });
        }
        props.setScrollTo(idsFromLocalStorage.scrollTo);
        setTimeout(() => {
          performScrollToUnsavedText(ref); // scroll nach scrollTo Update!
        }, 100);
      }
    } else {
      // SCROLL-UPDATE
      if (idsFromLocalStorage.currentPageId !== this.state.currentPageId) {
        let tos = this.formatTextsOnStage(idsFromLocalStorage.textsOnStage);

        this.updateSections(props, tos);
        this.setState({
          currentPageId: idsFromLocalStorage.currentPageId
        });

        setTimeout(() => {
          this.setStartItemToNewCollection(this.state.currentPageId);
          performScrollToCollection(this.state.currentPageId);
        }, 100);
      }
    }

    // TEXT-UPDATE:
    if (idsFromLocalStorage.textsOnStage) {
      if (idsFromLocalStorage.date !== this.state.date) {
        let tos = [];
        for (let p = 0; p < idsFromLocalStorage.textsOnStage.length; p++) {
          let item = idsFromLocalStorage.textsOnStage[p];
          tos.push({
            collection: item.c,
            textfieldId: item.t
          });
        }
        this.setStartItemToNewTextfield(idsFromLocalStorage.scrollTo);

        this.setState(
          {
            shouldUpdateTexts: true,
            textsOnStage: tos
          },
          () => props.setScrollTo(idsFromLocalStorage.scrollTo)
        );
      }
    }

    if (
      props.startedToolAsLayer &&
      props.inlineTranslationToolObject.textWasSelected
    ) {
      this.props.setOffTWS();
      setTimeout(() => {
        this.updateFromLocalStorage(props);
      }, 2000);
    }
  };

  setStartItemToNewCollection = (collectionId) => {
    const { sections } = this.state;

    let index = 0;
    for (let j = 0; j < sections.length; j++) {
      let s = sections[j];
      if (s.type !== 'div') {
        if (s.props.collectionId === collectionId) {
          index = j;
          break;
        }
      }
    }
    this.update(-1, index);
  };

  setStartItemToNewTextfield = (scrollTo) => {
    if (!scrollTo.textfield_id || !scrollTo.collection) {
      return;
    }
    const { sections } = this.state;
    let index = 0;
    for (let j = 0; j < sections.length; j++) {
      let s = sections[j];
      if (s.type !== 'div') {
        if (
          s.props.collectionId === scrollTo.collection &&
          s.props.from.id === scrollTo.textfield_id
        ) {
          index = j;
          break;
        }
      }
    }
    this.update(-1, index, true, false, true);
  };

  localDispatchTextUpdate = (texts) => {
    const { viewId } = this.props.match.params;
    let vid = viewId;
    if (this.props.startedToolAsLayer) {
      vid = this.props.vid;
    }
    const idsFromLocalStorage = JSON.parse(
      localStorage.getItem('openProject_' + vid)
    );
    idsFromLocalStorage.textUpdate = '1';

    // Parsing all Texts to HTML --> For perfect view in WBT
    for (let property in texts) {
      // eslint-disable-next-line no-prototype-builtins
      if (texts.hasOwnProperty(property)) {
        let oldTexts = texts[property].headline_text;
        texts[property].headline_text = parseHTML(oldTexts);
      }
    }

    for (let property in texts) {
      // eslint-disable-next-line no-prototype-builtins
      if (texts.hasOwnProperty(property) && property !== '__proto__') {
        let innerProperty = texts[property];
        for (let property2 in innerProperty) {
          // eslint-disable-next-line no-prototype-builtins
          if (innerProperty.hasOwnProperty(property2)) {
            let notHTMLtexts = innerProperty[property2];
            if (notHTMLtexts) {
              texts[property][property2] = parseHTML(notHTMLtexts);
            }
          }
        }
      }
    }

    idsFromLocalStorage.newTexts = texts;
    localStorage.setItem(
      'openProject_' + vid,
      JSON.stringify(idsFromLocalStorage)
    );
    if (this.props.startedToolAsLayer) {
      this.props.dispatchTextUpdate(texts);
    }
    // checkMemorySizeOf(idsFromLocalStorage);
  };

  handleWheel = (e) => {
    const { firstFieldSectionIndex, sections, maxItems } = this.state;

    let element = this.translationToolRef.current;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    let offset = 10;
    let customFfsi = firstFieldSectionIndex;

    if (scrollTop + e.deltaY <= 0) {
      customFfsi = this.addToTop();
    } else if (scrollTop + e.deltaY > scrollHeight - clientHeight - offset) {
      customFfsi = this.addToBottom();
    }

    let realScrollTop = scrollTop + e.deltaY;

    if (realScrollTop >= scrollHeight - clientHeight) {
      realScrollTop = scrollHeight - clientHeight;
    } else if (realScrollTop <= 0) {
      realScrollTop = 0;
    }
    let allSectionsLength = sections.length;
    let range = allSectionsLength > maxItems ? maxItems : allSectionsLength;
    let lastFieldSectionIndex = customFfsi + range;
    let estimatedTopHeight = customFfsi * 136;
    let middleHeight = scrollHeight - clientHeight;

    let estimatedBottomHeight =
      (allSectionsLength - (lastFieldSectionIndex + 1)) * 136;
    let estimatedCompleteHeight =
      estimatedTopHeight + middleHeight + estimatedBottomHeight;

    let percent =
      (estimatedTopHeight + realScrollTop) / estimatedCompleteHeight;

    if (percent < 0) {
      percent = 0;
    } else if (percent > 1) {
      percent = 1;
    }
    this.update(percent, customFfsi);
  };

  addToTop = () => {
    let ffsi = this.state.firstFieldSectionIndex;
    if (ffsi > 0) {
      ffsi--;
    }
    return ffsi;
  };

  addToBottom = () => {
    const { firstFieldSectionIndex, sections, maxItems } = this.state;
    let ffsi = firstFieldSectionIndex;
    if (ffsi < sections.length - maxItems - 1) {
      ffsi++;
    }
    return ffsi;
  };

  handleSearchInput = (value) => {
    this.setState(
      {
        searchTerm: value,
        firstFieldSectionIndex: 0
      },
      () => this.updateSections(this.props)
    );
  };

  handleSearchReset = () => {
    this.setState({ searchTerm: '', firstFieldSectionIndex: 0 }, () =>
      this.updateSections(this.props)
    );
  };

  openHelp = () => {
    this.setState({ helpOpened: true });
  };

  closeHelp = () => {
    this.setState({ helpOpened: false });
  };

  updateSections = (props, tos = null, shouldUpdate = true) => {
    let percent = 0;

    if (tos == null) {
      tos = this.state.textsOnStage;
    }

    this.setCommentsCountToReduxState(props.translations);

    this.setState(
      {
        sections: this.createSections(props, tos)
      },
      () => {
        if (shouldUpdate) {
          this.update(percent, -1, true);
        }
      }
    );
  };

  setCommentsCountToReduxState = (translations) => {
    let count = 0;
    translations.map((collection) => {
      collection.data.map((section) => {
        if (section.to.comments) {
          count += section.to.comments.length;
        }
      });
    });
    console.log('___count', count);
    if (this.props.commentsCount !== count) {
      this.props.setCommentsCount(count);
    }
  };

  createSections = (props, customTextsOnStage = null) => {
    const { translations, translation, areAllTextsShown } = props;

    let sections = [];
    let textsOnStage = customTextsOnStage
      ? customTextsOnStage
      : props.textsOnStage;

    let translationType = 'translation';
    if (translation.get('type')) {
      translationType = translation.get('type');
    }

    let textbase = textsOnStage;

    translations.map((collection) => {
      if (!areAllTextsShown && textsOnStage) {
        textbase = textsOnStage.filter(
          (text) => text.collection === collection.id
        );
      }

      let fieldSections = this.renderFieldSections(
        props,
        collection,
        textbase,
        translationType
      );

      if (fieldSections.length > 0) {
        sections.push(this.addCollection(collection.id));
        sections.push(...fieldSections);
      }
    });
    return sections;
  };

  addCollection = (id) => {
    return (
      <div
        className={'headlineCollection'}
        key={'ref_' + id}
        data-collection={'ref_' + id}
      >
        <span>{id}</span>
      </div>
    );
  };

  componentDidUpdate() {
    const {
      scrollTop,
      customFfsi,
      forceUpdate,
      myPercent,
      scrollbarIsMoving,
      startsAtTop
      // ,
      // scrollTo
    } = this.state;

    if (this.translationToolRef && this.translationToolRef.current) {
      if (customFfsi < 0 || startsAtTop) {
        // this.translationToolRef.current.scrollTop = scrollTop;
      }
      this.afterRenderTimeout = setTimeout(
        this.getScrollingPositionHeadline,
        250
      );
    }

    if (forceUpdate) {
      this.update(myPercent, customFfsi, false, scrollbarIsMoving);
    }
  }

  update = (
    percentValue = 0,
    customFfsi = -1,
    forceUpdate = false,
    scrollbarIsMoving = false,
    startsAtTop = false
  ) => {
    const { sections, maxItems, handleHeight, firstFieldSectionIndex } =
      this.state;

    let element = this.translationToolRef.current;

    if (element === null) {
      return null;
    }

    clearTimeout(this.afterRenderTimeout);

    let percent = percentValue;

    let { clientHeight, scrollHeight } = element;
    let offset = 40;
    let minHandleHeightInPixel = 40;
    let scrollbarHeight = clientHeight - offset;
    let overflow = scrollHeight - clientHeight;

    // let hasScrollbar = (overflow > 0) ? true : false;
    let hasScrollbar = false;
    let allSectionsLength = sections.length;
    let range = allSectionsLength > maxItems ? maxItems : allSectionsLength;

    let ffsi = firstFieldSectionIndex;
    let lfsi = ffsi + range;

    if (customFfsi >= 0) {
      ffsi = customFfsi;
      lfsi = ffsi + range;

      let estimatedTopHeight = customFfsi * 136;
      let middleHeight = scrollHeight - clientHeight;

      let estimatedBottomHeight = (allSectionsLength - (lfsi + 1)) * 136;
      let estimatedCompleteHeight =
        estimatedTopHeight + middleHeight + estimatedBottomHeight;

      if (percent < 0) {
        percent = estimatedTopHeight / estimatedCompleteHeight;
      }

      if (percent < 0) {
        percent = 0;
      } else if (percent > 1) {
        percent = 1;
      }
    } else {
      ffsi = Math.floor((allSectionsLength - range) * percent);
      lfsi = ffsi + range;
    }

    let estimatedHeight = scrollHeight + (allSectionsLength - range) * 136;
    let newHandleHeight = handleHeight;

    if (hasScrollbar) {
      newHandleHeight = scrollbarHeight / estimatedHeight;
      let minHandleHeightInPercent = minHandleHeightInPixel / scrollbarHeight;

      if (newHandleHeight < minHandleHeightInPercent) {
        newHandleHeight = minHandleHeightInPercent;
      }
    }

    let handleTop = percent * (1 - newHandleHeight);

    this.getScrollingPositionHeadline();

    if (startsAtTop) {
      overflow = 1;
    }

    this.setState({
      myPercent: percent,
      estimatedHeight: estimatedHeight,

      firstFieldSectionIndex: ffsi,
      lastFieldSectionIndex: lfsi,
      handleTop: handleTop * 100,
      handleHeight: newHandleHeight,
      scrollTop: Math.floor(overflow * percent),
      scrollbarIsMoving: scrollbarIsMoving,
      // hasScrollbar: hasScrollbar,
      customFfsi: customFfsi,
      forceUpdate: forceUpdate,
      startsAtTop: startsAtTop
    });
  };

  handleScrollBarClick = (e) => {
    if (!e.target.classList.contains('customScrollbar')) {
      return false;
    }

    let handlePercent = this.clientYToPercent(e.clientY);
    this.update(handlePercent);
  };

  clientYToPercent = (clientY, diffTop = 0) => {
    let element = this.translationToolRef.current;
    let handle = this.handleRef.current;
    let offset = 40;
    let handleHeight = handle.getBoundingClientRect().height;

    let handlePercent =
      (clientY - diffTop - offset) /
      (element.clientHeight - offset - handleHeight);

    if (handlePercent < 0) {
      handlePercent = 0;
    } else if (handlePercent > 1) {
      handlePercent = 1;
    }
    return handlePercent;
  };

  handleScrollBarHandleMouseDown = (e) => {
    e.preventDefault();

    let handle = this.handleRef.current;
    let handleTop = handle.getBoundingClientRect().top;
    let diffTop = e.clientY - handleTop;

    this.setState({
      diffTop: diffTop
    });

    window.addEventListener('mousemove', this.handleScrollBarMouseMove, false);
    window.addEventListener('mouseup', this.handleScrollBarMouseUp, false);
  };

  handleScrollBarMouseMove = (e) => {
    let handlePercent = this.clientYToPercent(e.clientY, this.state.diffTop);
    this.update(handlePercent, -1, false, true);
  };

  handleScrollBarMouseUp = (e) => {
    e.preventDefault();
    let handlePercent = this.clientYToPercent(e.clientY, this.state.diffTop);
    this.update(handlePercent);

    window.removeEventListener(
      'mousemove',
      this.handleScrollBarMouseMove,
      false
    );
    window.removeEventListener('mouseup', this.handleScrollBarMouseUp, false);
  };

  getScrollbarMovingPositionHeadline = (percent) => {
    const { sections } = this.state;

    if (sections && sections.length > 0) {
      let index = parseInt(sections.length * (percent / 100));
      if (index > sections.length) {
        index = sections.length - 1;
      }
      return sections[index].type === 'div'
        ? sections[index].props.children.props.children
        : sections[index].props.collectionId;
    }
    return null;
  };

  getScrollingPositionHeadline = () => {
    const { sections, movingPositionHeadline } = this.state;
    if (sections.length < 1) {
      return '';
    }

    let allHeadlines = [];
    for (var k = 0; k < sections.length; k++) {
      let section = sections[k];
      if (section.type === 'div') {
        let collectionId = section.props.children.props.children;
        let headlineEl = document.querySelector(
          '[data-collection="ref_' + collectionId + '"]'
        );
        let isPassed = 0; // default ==> not rendered
        if (headlineEl) {
          isPassed = this.isElementPassedOnScrolling(headlineEl);
        }
        allHeadlines.push({
          headline: collectionId,
          passedView: isPassed
        });
      }
    }
    let lastVisibleheadline = null;

    for (var o = 0; o < allHeadlines.length; o++) {
      if (allHeadlines[o].passedView === 0) {
        continue;
      } else if (allHeadlines[o].passedView === 'passed') {
        lastVisibleheadline = allHeadlines[o];
      } else if (allHeadlines[o].passedView === 'not_passed') {
        lastVisibleheadline = allHeadlines[o - 1];
        break;
      }
    }
    if (
      lastVisibleheadline &&
      lastVisibleheadline.headline !== movingPositionHeadline
    ) {
      this.setState({ movingPositionHeadline: lastVisibleheadline.headline });
    }
  };

  isElementPassedOnScrolling = (el) => {
    return el.getBoundingClientRect().top <= 40 ? 'passed' : 'not_passed';
  };

  toggleOnlyWithComment = () => {
    this.props.setOnlyWithCommentsShown(!this.props.onlyWithCommentsShown);
  };

  renderHeaderBar = () => {
    const {
      searchTerm,
      sourceLanguageCode,
      translationLanguageCode,
      startedFromAdditionalContentPanelPDF,
      sections
    } = this.state;

    const {
      targetSnapshots,
      sourceSnapshots,
      fetchingTranslations,
      selectedProjectName,
      commentsCount,
      onlyWithCommentsShown
    } = this.props;

    const isFetching =
      fetchingTranslations === ProcessStatus.INITIAL ||
      fetchingTranslations === ProcessStatus.STARTED;

    const sectionLength = sections.filter((item) => item.type !== 'div').length;

    return (
      <div>
        <div className={styles.translationToolHeader}>
          <div
            className={'wbt-name'}
            title={
              'MANGOOLU LANGUAGE EDITOR\n' +
              'translate:\n' +
              sourceLanguageCode +
              ' -> ' +
              translationLanguageCode +
              '\n(source language)  (target language)'
            }
          >
            <img src={images.logoTransparent} />
            <FontIcon className='orange'>translate</FontIcon>
            <span className='orange padding-right'>EDITOR</span>
            <span
              title={selectedProjectName}
              className='padding-left invert name-info'
            >
              {selectedProjectName}
            </span>
            <span
              title={sourceLanguageCode + ' to ' + translationLanguageCode}
              className='padding-left invert lang-info'
            >
              <span>{sourceLanguageCode}</span>
              <span>to {translationLanguageCode}</span>
            </span>
          </div>
          <div className='rightButtons'>
            <UnsavedTextFlag />
            {commentsCount > 0 && (
              <span
                className={
                  onlyWithCommentsShown ? 'commentCount active' : 'commentCount'
                }
                title={
                  commentsCount +
                  ' comments of lang "' +
                  translationLanguageCode +
                  '"'
                }
                onClick={this.toggleOnlyWithComment}
              >
                <span>{commentsCount}</span>
                <FontIcon>mode_comment</FontIcon>
              </span>
            )}
            <Button
              disabled={isFetching}
              icon
              className={'translationToolHelpButton'}
              onClick={this.openHelp}
            >
              <FontIcon>help</FontIcon>
            </Button>
            <Search
              isFetching={isFetching}
              searchTerm={searchTerm}
              handleSearchInput={this.handleSearchInput}
              handleSearchReset={this.handleSearchReset}
            />

            {sourceSnapshots && targetSnapshots && (
              <ControlMenu
                withCollection={this.translationToolWithCollection}
                hideListItemTextOnStage={startedFromAdditionalContentPanelPDF}
                targetSnapshots={targetSnapshots}
                sourceSnapshots={sourceSnapshots}
                targetSnapshotId={this.state.targetSnapshotId}
                sourceSnapshotId={this.state.sourceSnapshotId}
                setTargetSnapshot={this.setTargetSnapshot}
                setSourceSnapshot={this.setSourceSnapshot}
                isFetching={isFetching}
                sourceLanguageCode={sourceLanguageCode}
                translationLanguageCode={translationLanguageCode}
                sectionCount={sectionLength}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  /* eslint-disable-next-line-max-params */ setSourceSnapshotId;
  renderFieldSections = (
    props,
    collection,
    textsOnStageFromCollection,
    translationType
  ) => {
    const { sourceLanguageId, translationId, searchTerm, currentPageId } =
      this.state;

    const {
      areAllTextsShown,
      areOnlyDifferencesShown,
      areOnlyEmptyShown,
      onlyWithCommentsShown,
      scrollTo,
      match: {
        params: { viewId }
      }
    } = props;

    const data = collection.data;
    const collectionId = collection.id;

    return data
      .filter((section) => {
        const { compareWith, targetCompareWith, from, to } = section;

        if (compareWith) {
          if (areOnlyDifferencesShown && compareWith.content == from.content) {
            return false;
          }
        }
        if (targetCompareWith) {
          if (
            areOnlyDifferencesShown &&
            targetCompareWith.content == to.content
          ) {
            return false;
          }
        }

        if (textsOnStageFromCollection) {
          textsOnStageFromCollection.find((text) => {
            return text.textfieldId === section.from.id;
          });
        }

        if (
          areAllTextsShown ||
          currentPageId === collectionId ||
          (textsOnStageFromCollection &&
            textsOnStageFromCollection.find(
              (text) => text.textfieldId === section.from.id
            ))
        ) {
          if (areOnlyEmptyShown && to.content !== '') {
            return false;
          }

          if (
            onlyWithCommentsShown &&
            // eslint-disable-next-line no-prototype-builtins
            (!section.to.hasOwnProperty('comments') ||
              section.to.comments.length === 0)
          ) {
            return false;
          }

          if (searchTerm === '') {
            return true;
          }

          const searchStr = searchTerm.toLowerCase();
          const fromStr = String(section.from.content + '').toLowerCase();
          if (fromStr.includes(searchStr)) {
            return true;
          }
          const toStr = String(section.to.content + '').toLowerCase();
          return toStr.includes(searchStr);
        }
      })
      .map((section) => {
        let refName = 'ref_' + collectionId + '_' + section.from.id;

        let isExternalSelected = false;
        if (
          scrollTo &&
          // eslint-disable-next-line no-prototype-builtins
          scrollTo.hasOwnProperty('collection') &&
          // eslint-disable-next-line no-prototype-builtins
          scrollTo.hasOwnProperty('textfield_id')
        ) {
          isExternalSelected =
            scrollTo.collection === collectionId &&
            scrollTo.textfield_id === section.from.id;
        }

        return (
          <FieldSection
            refName={refName}
            key={refName}
            translationType={translationType}
            collectionId={collectionId}
            translationId={translationId}
            isExternalSelected={isExternalSelected}
            sourceLanguageId={sourceLanguageId}
            compareWith={section.compareWith}
            targetCompareWith={section.targetCompareWith}
            from={section.from}
            to={section.to}
            fieldIndex={section.index}
            searchTerm={searchTerm}
            updateTextInIframe={this.localDispatchTextUpdate}
            viewId={viewId}
          />
        );
      });
  };

  renderFooterBar = () => {
    const { t } = this.props;
    const {
      sourceSnapshotId,
      sourceLanguageId,
      projectId,
      translationLanguageCode,
      sourceLanguageCode,
      sourceType,
      translationType,
      targetSnapshotId,
      translationId
    } = this.state;

    let baseClassName = 'headerHeadline';

    let openCloseTooltip = (e) => {
      let parent = e.target.parentNode.classList;
      if (parent.contains('open')) {
        parent.remove('open');
      } else {
        parent.add('open');
      }
    };

    return (
      <div className={styles.translationToolFooter}>
        <div>
          {sourceSnapshotId > 0 && (
            <div
              className={' headerHeadline snapshot'}
              data-open={sourceSnapshotId >= -1}
              title={t('source_snapshot')}
            >
              <SourceSnapshots
                projectId={projectId}
                sourceLanguageId={sourceLanguageId}
                sourceSnapshotId={sourceSnapshotId}
              />
              <span className='tooltip-wrapper'>
                <FontIcon onClick={openCloseTooltip}>info</FontIcon>
                <span className='tooltip'>{`${t('source_snapshot')}`}</span>
              </span>
            </div>
          )}
          <div
            className={baseClassName + ' source'}
            title={`${t('source_language')} - Type: ${t(sourceType)}`}
          >
            <b>{sourceLanguageCode}</b>
            <span className='tooltip-wrapper'>
              <FontIcon onClick={openCloseTooltip}>info</FontIcon>
              <span className='tooltip'>{`${t('source_language')} - Type: ${t(
                sourceType
              )}`}</span>
            </span>
          </div>
          {targetSnapshotId > 0 && (
            <div
              className={' headerHeadline snapshot targSnapshot'}
              data-open={targetSnapshotId >= -1}
              title={t('source_snapshot')}
            >
              <TargetSnapshots
                projectId={projectId}
                targetLanguageId={translationId}
                targetSnapshotId={targetSnapshotId}
              />
              <span className='tooltip-wrapper'>
                <FontIcon onClick={openCloseTooltip}>info</FontIcon>
                <span className='tooltip'>{`${t('target_snapshot')}`}</span>
              </span>
            </div>
          )}
          <div
            className={baseClassName + ' target'}
            title={`${t('target_language')}  - Type: ${t(translationType)}`}
            has-target-snapshot={targetSnapshotId > 0 ? 'true' : 'false'}
          >
            <b>{translationLanguageCode}</b>
            <span className='tooltip-wrapper'>
              <FontIcon onClick={openCloseTooltip}>info</FontIcon>
              <span className='tooltip'>{`${t('target_language')} - Type: ${t(
                translationType
              )}`}</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  getVisibleSections = () => {
    const { sections, firstFieldSectionIndex, lastFieldSectionIndex } =
      this.state;

    let array = [];
    let top = null;
    let sEl = null;
    let s = null;
    let unsavedText = null;

    for (let j = 0; j < sections.length; j++) {
      s = sections[j];

      if (s.props && s.props.refName) {
        // eslint-disable-next-line no-loop-func
        unsavedText = this.props.unsavedTexts.find((unsavedText) => {
          return unsavedText.refName === s.props.refName;
        });
      }

      if (unsavedText) {
        // console.log('unsavedText', unsavedText);
      }

      if (j <= lastFieldSectionIndex && j >= firstFieldSectionIndex) {
        sEl = document.querySelector(
          '[field-section-refname="' + s.props.refName + '"]'
        );
        if (sEl !== null) {
          top = sEl.getBoundingClientRect().top;
        }
        array.push({ positionIndex: j, top: top, element: s });
      }
    }
    return array;
  };

  render() {
    const { translations, fetchingTranslations, t } = this.props;
    const {
      helpOpened,
      scrollbarIsMoving,
      movingPositionHeadline,
      handleHeight,
      handleTop,
      hasScrollbar,
      isIE
    } = this.state;
    const pdfDe =
      '/documents/help/translation-tool/de/mangoolu_v2_Anleitung_de.pdf';
    const pdfEn =
      '/documents/help/translation-tool/en/mangoolu_v2_user_guide_en.pdf';

    let allVisibleSections = this.getVisibleSections();
    let visibleSections = allVisibleSections.map(
      (sections) => sections.element
    );

    const fetchingTrans =
      fetchingTranslations === ProcessStatus.INITIAL ||
      fetchingTranslations === ProcessStatus.STARTED;
    const noTranslationFound = !translations.toArray().length;

    return (
      <div
        className={
          this.props.startedToolAsLayer
            ? styles.translationTool + ' view'
            : styles.translationTool
        }
        ref={this.translationToolRef}
        onWheel={this.handleWheel}
      >
        <div className={'translationToolWrapper'}>
          <div className={'fixedWrapper'}>{this.renderHeaderBar()}</div>

          {fetchingTrans && (
            <LoadingBar show='true' message={t('loading_all_texts')} />
          )}

          {noTranslationFound && !fetchingTrans && (
            <div>No translations found.</div>
          )}

          {!fetchingTrans && !noTranslationFound && (
            <>
              <div className={'scrollWrapper'}>{visibleSections}</div>
              <div className={'legendWrapper'}>{this.renderFooterBar()}</div>
            </>
          )}

          <div
            className={'translationToolHelp'}
            visible={helpOpened.toString()}
          >
            <button onClick={this.closeHelp}>
              <FontIcon>close</FontIcon>
            </button>
            <h2>{t('help_headline')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('help_content') }}></p>
            <a href={pdfDe} download>
              {t('help_button_german')} <FontIcon>picture_as_pdf</FontIcon>
            </a>
            <a href={pdfEn} download>
              {t('help_button_english')} <FontIcon>picture_as_pdf</FontIcon>
            </a>
          </div>
        </div>

        {hasScrollbar && (
          <>
            <div
              className={
                scrollbarIsMoving && isIE
                  ? 'scrollDragOverLay moving'
                  : 'scrollDragOverLay'
              }
            ></div>
            <div
              className={
                scrollbarIsMoving ? 'customScrollbar moving' : 'customScrollbar'
              }
              onClick={this.handleScrollBarClick}
            >
              <div
                className={'handle'}
                ref={this.handleRef}
                style={{
                  top: handleTop + '%',
                  height: handleHeight * 100 + '%'
                }}
                onMouseDown={this.handleScrollBarHandleMouseDown}
              ></div>
            </div>
          </>
        )}
        <div
          className={scrollbarIsMoving && isIE ? 'whereAmI moving' : 'whereAmI'}
        >
          <span>{movingPositionHeadline}</span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchingTranslations: state.getIn([
      'translationTool',
      'fetchingTranslations'
    ]),
    translation: state.getIn(['projects', 'translation']),
    translations: state.getIn(['translationTool', 'translations']),
    textsForLocalStorage: state.getIn([
      'translationTool',
      'textsForLocalStorage'
    ]),
    areAllTextsShown: state.getIn(['translationTool', 'areAllTextsShown']),
    areOnlyDifferencesShown: state.getIn([
      'translationTool',
      'areOnlyDifferencesShown'
    ]),
    areOnlyEmptyShown: state.getIn(['translationTool', 'areOnlyEmptyShown']),
    scrollTo: state.getIn(['fieldLevel', 'scrollTo']),
    unsavedTexts: state.getIn(['fieldLevel', 'unsavedTexts']),
    sourceSnapshots: state.getIn(['sourceSnapshots', 'sourceSnapshots']),
    targetSnapshots: state.getIn(['snapshots', 'snapshots']),
    fetchingTargetSnapshots: state.getIn(['snapshots', 'fetchingSnapshots']),
    selectedProjectName: state.getIn(['projects', 'selectedProjectName']),
    onlyWithCommentsShown: state.getIn([
      'translationTool',
      'onlyWithCommentsShown'
    ]),
    commentsCount: state.getIn(['translationTool', 'commentsCount'])
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchProject,
    fetchTranslation,
    fetchTranslations,
    fetchAdditionalContentTranslations,
    showNotification,
    setScrollTo,
    setAreAllTextsShown,
    fetchSourceSnapshots,
    fetchSnapshots,
    setCommentsCount,
    setOnlyWithCommentsShown
  })(TranslationTool)
);
