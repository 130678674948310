// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Tooltip {\n  position: absolute;\n  z-index: 500000;\n  display: block;\n  flex-wrap: nowrap;\n  justify-content: center;\n  align-items: center;\n}\n\n.Tooltip > div {\n  border-radius: 0;\n  padding: 5px 10px;\n  box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.14), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);\n}\n\n.Tooltip:before {\n  content: \"\";\n  position: absolute;\n  border: 15px solid transparent;\n}\n\n.Tooltip--top {\n  margin-top: 25px;\n  transform: translateX(-50%);\n}\n\n.Tooltip--top::before {\n  bottom: 100%;\n  left: 50%;\n  transform: translateX(-50%);\n  border-bottom-color: white;\n}\n\n.Tooltip--left {\n  margin-left: 25px;\n  transform: translateY(-50%);\n}\n\n.Tooltip--left::before {\n  top: 50%;\n  right: 100%;\n  transform: translateY(-50%);\n  border-right-color: white;\n}\n\n.Tooltip--top-left {\n  margin-top: 25px;\n}\n\n.Tooltip--top-left::before {\n  bottom: 100%;\n  left: 25px;\n  border-bottom-color: white;\n}\n\n.Tooltip .Tooltip__link {\n  display: block;\n  font-weight: 600;\n  color: #ff5900;\n}\n\n.Tooltip .Tooltip__link:hover {\n  text-decoration: underline;\n}\n\n.Tooltip button {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.Tooltip button:hover .md-icon {\n  color: #ff5900 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"Tooltip": "Tooltip",
	"tooltip": "Tooltip",
	"Tooltip--top": "Tooltip--top",
	"tooltipTop": "Tooltip--top",
	"Tooltip--left": "Tooltip--left",
	"tooltipLeft": "Tooltip--left",
	"Tooltip--top-left": "Tooltip--top-left",
	"tooltipTopLeft": "Tooltip--top-left",
	"Tooltip__link": "Tooltip__link",
	"tooltipLink": "Tooltip__link",
	"md-icon": "md-icon",
	"mdIcon": "md-icon"
};
module.exports = exports;
