import React, { Component } from 'react';
import { connect } from 'react-redux';

import { inviteUser } from '../../../actions/users';
import Warning from './Warning';

class InviteUserWarning extends Component {
  inviteUser = () => {
    console.log('inviteUser', this.props.user);

    this.props.inviteUser({
      userId: this.props.user.id,
      password: this.generatePassword()
    });
  };

  shuffle = (a) => {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  };

  generatePassword = () => {
    // Math.random().toString(36).slice(-10) // https://stackoverflow.com/a/9719815
    let chars =
      'yxcvbnmasdfghjklpoiuztrewqYXCVBNMASDFGHJKLPOIUZTREWQ1234567890!?&#*_-<>$%=+';
    let charArray = Array.from(chars);
    let pa = this.shuffle(charArray);
    let password = pa.slice(0, 10).toString().replace(/,/g, '');
    console.log('password', password);
    return password;
  };

  render() {
    const { invitingUser } = this.props;

    return (
      <Warning
        process={invitingUser}
        action={this.inviteUser}
        actionLabel='invite'
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    invitingUser: state.getIn(['users', 'invitingUser']),
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(mapStateToProps, { inviteUser })(InviteUserWarning);
