import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { DialogContainer } from 'react-md';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { logout } from '../../../actions/auth';
import { removeDialog } from '../../../actions/errorDialog';
import dialogStyles from '../../../styles/dialog.scss';
// import {Routes} from '../../../utils/Constants';

@withTranslation('general', { wait: true })
class ErrorDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dialog: null,
      visible: false,
      title: '',
      text: '',
      component: null,
      isAllNull: true,
      actions: []
    };
  }

  componentDidUpdate() {
    const { t } = this.props;

    let dialog = this.props.dialogs.get(0);

    let actions = [];

    if (!this.state.dialog) {
      if (dialog) {
        // console.log('_____dialog', dialog);
        // let id = 'no-identifier';
        // eslint-disable-next-line no-prototype-builtins
        // if (dialog.hasOwnProperty('identifier')) {
        //   id = dialog.identifier;
        // }
        switch (dialog.status) {
          case 401:
            actions = [
              {
                onClick: this.logout,
                primary: true,
                children: t('login_again')
              }
            ];
            break;

          default:
            actions = [
              {
                onClick: this.closeDialog,
                primary: true,
                children: t('no_thanks')
              }
            ];
        }
        this.setState({
          isAllNull: false,
          dialog: dialog,
          visible: true,
          title: t('error_dialog_inactivity_title'),
          text: t('error_dialog_inactivity'),
          actions: actions
        });
      } else {
        if (!this.state.isAllNull) {
          this.setState({
            isAllNull: true,
            dialog: null,
            visible: false,
            title: '',
            text: '',
            component: null,
            actions: []
          });
        }
      }
    }
  }

  logout = () => {
    this.props.history.push('/logout');
  };

  closeDialog = () => {
    this.props.removeDialog(this.state.dialog.identifier);
  };

  render() {
    const { dialog, visible, title, text, actions } = this.state;

    if (dialog == null) {
      return null;
    }

    if (this.props.dialogs.get(0).message > 86400) {
      // 86400 -> 24h
      this.closeDialog();
      this.logout();
    }

    return (
      <DialogContainer
        id='import-translation-error'
        className={dialogStyles.dialogContainer}
        visible={visible}
        title={title}
        focusOnMount={false}
        onHide={this.closeDialog}
        aria-describedby='speed-boost-description'
        modal
        disableScrollLocking={true}
        actions={actions}
      >
        <p
          id='import-translation-error-description'
          className='md-color--secondary-text'
        >
          {text}
        </p>
      </DialogContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    dialogs: state.getIn(['errorDialog', 'dialogs']),
    user: state.getIn(['auth', 'user']),
    authenticated: state.getIn(['auth', 'authenticated'])
  };
}

export default withRouter(
  connect(mapStateToProps, {
    removeDialog,
    logout
  })(ErrorDialog)
);
