import { TypesAction } from '../utils/ActionTypes';

const fetchTypes = () => {
  return {
    type: TypesAction.FETCH_TYPES,
    data: {}
  };
};

const receiveTypes = (data) => {
  return {
    type: TypesAction.FETCH_TYPES_FULFILLED,
    types: data,
    receivedAt: Date.now()
  };
};

export { fetchTypes, receiveTypes };
