// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sourceSnapshots span {\n  color: white;\n}\n\n.onlyOneSnapshot {\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n", ""]);
// Exports
exports.locals = {
	"sourceSnapshots": "sourceSnapshots",
	"onlyOneSnapshot": "onlyOneSnapshot"
};
module.exports = exports;
