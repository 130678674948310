import React, { Component } from 'react';
import { List, Map } from 'immutable';
import { Checkbox, FontIcon } from 'react-md';
import { withTranslation } from 'react-i18next';

import { ProcessStatus } from '../../utils/Constants';
import LoadingBar from './DumbComponent';
import ChipAllocation from './ChipAllocation';

import styles from '../../styles/project_detail.scss';

@withTranslation('general', { wait: true })
class RelationBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locked: true,
      allTargets: List(),
      linkedTargets: List(),
      updating: false
    };
  }

  UNSAFE_componentWillMount() {
    const { targets, fetchingTargets } = this.props;
    if (fetchingTargets != ProcessStatus.STARTED && targets.size == 0) {
      this.props.fetchTargets();
    } else if (targets.size > 0) {
      this.mergeTargetData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { source, targets, linkingSourceToTargets } = nextProps;
    if (
      (source && source != this.props.source) ||
      (targets && targets != this.props.targets) ||
      ((linkingSourceToTargets == ProcessStatus.FINISHED ||
        linkingSourceToTargets == ProcessStatus.FAILED) &&
        linkingSourceToTargets != this.props.linkingSourceToTargets)
    ) {
      this.mergeTargetData(nextProps);
    }
  }

  mergeTargetData = (props) => {
    const { source, targets, sourceTargets } = props;

    let normalizedTargets = [];
    let normalizedLinkedTargets = [];
    let formattedTarget;
    let data;
    let globalRole;
    let localRole;

    let roles = source.roles;

    if (roles) {
      globalRole =
        roles && roles[0] && roles[0].id ? roles[0].id.toString() : null;
    }

    targets.map((target) => {
      let dataRole = null;

      if (sourceTargets) {
        data = sourceTargets.find(
          (sourceTargets) => sourceTargets.id === target.id
        );
      }

      if (!globalRole) {
        dataRole =
          data && data.role && data.role.id ? data.role.id.toString() : null;

        if (!dataRole) {
          dataRole =
            data && data.roles && data.roles[0] && data.roles[0].id
              ? data.roles[0].id.toString()
              : null;
        }
      }

      localRole =
        data && data.pivot && data.pivot.role_id
          ? data.pivot.role_id.toString()
          : null;

      formattedTarget = Map({
        id: target.id,
        name: target.name,
        localrole: localRole,
        globalrole: globalRole ? globalRole : dataRole
      });

      normalizedTargets.push(formattedTarget);
      if (data) {
        normalizedLinkedTargets.push(formattedTarget);
      }
    });
    this.setState({
      allTargets: List(normalizedTargets),
      linkedTargets: List(normalizedLinkedTargets),
      updating: false
    });
  };

  handleAdd = (target) => {
    this.props.linkSourceToTarget(
      'grant',
      target.get('id'),
      target.get('localrole')
    );
    this.setState({ updating: true });
  };

  handleRemove = (target) => {
    this.props.linkSourceToTarget(
      'revoke',
      target.get('id'),
      target.get('localrole')
    );
    this.setState({ updating: true });
  };

  handleUpdate = (target) => {
    this.props.linkSourceToTarget(
      'grant',
      target.get('id'),
      target.get('roleId')
    );
    this.setState({ updating: true });
  };

  toggleTargetLock = (checked) => {
    this.setState({ locked: checked });
  };

  handleChange = (data) => {
    this.setState({ linkedTargets: data });
  };

  render() {
    const {
      targets,
      fetchingTargets,
      linkingSourceToTarget,
      label,
      chip,
      editable,
      t
    } = this.props;
    const { allTargets, linkedTargets, updating } = this.state;
    const { locked } = this.state;

    const defaultLabel = t('insert') + ' ' + label;
    const chipLabel = locked
      ? defaultLabel + ' (' + t('unlock_first') + ')'
      : defaultLabel;

    if (fetchingTargets == ProcessStatus.INITIAL) {
      return (
        <div className={styles.contentWrapper}>
          <h2>{label}</h2>
          <p>{t('waiting_of')}</p>
        </div>
      );
    } else if (fetchingTargets == ProcessStatus.STARTED) {
      return (
        <div className={styles.contentWrapper}>
          <h2>{label}</h2>
          <LoadingBar
            show='true'
            message={t('searching_for') + ' ' + label + ' ...'}
          />
        </div>
      );
    } else if (targets.size == 0) {
      return (
        <div className={styles.contentWrapper}>
          <h2>{label}</h2>
          <p>
            {t('relation_base_no') +
              ' ' +
              label +
              ' ' +
              t('relation_base_found') +
              ' .'}
          </p>
        </div>
      );
    }

    return (
      <div>
        <div className={styles.header}>
          <h2 className={'headName'}>{label}</h2>

          {editable && (
            <Checkbox
              id={label + '-lock'}
              name={label + '-lock'}
              className={styles.lock}
              label=''
              checkedCheckboxIcon={<FontIcon>lock</FontIcon>}
              uncheckedCheckboxIcon={<FontIcon>lock_open</FontIcon>}
              checked={locked}
              onChange={this.toggleTargetLock}
            />
          )}
        </div>
        <div className='editableElements'>
          <ChipAllocation
            label={chipLabel}
            className={styles.chipAllocation}
            input={{ value: linkedTargets, onChange: this.handleChange }}
            items={allTargets}
            chip={chip}
            onAdd={this.handleAdd}
            onRemove={this.handleRemove}
            onUpdate={this.handleUpdate}
            asynchron={true}
            editable={editable}
            locked={
              locked ||
              linkingSourceToTarget == ProcessStatus.STARTED ||
              fetchingTargets == ProcessStatus.STARTED
            }
          />
          <LoadingBar show={updating} message='' />
        </div>
      </div>
    );
  }

  static defaultProps = {
    editable: true
  };
}

export default RelationBase;
