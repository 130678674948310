import React from 'react';
import { FontIcon } from 'react-md';
import { BLOCK_TYPE, ENTITY_TYPE, INLINE_STYLE } from 'draftail';
import LinkDecorator from './decorator/LinkDecorator';
import ShyDecorator from './decorator/ShyDecorator';
import ZwspDecorator from './decorator/ZwspDecorator';
import LinkSource from './helper/LinkSource';
import ShyInject from './helper/ShyInject';
import ZwspInject from './helper/ZwspInject';

const entityTypes = [
  {
    type: ENTITY_TYPE.LINK,
    source: LinkSource,
    decorator: LinkDecorator,
    attributes: ['url'],
    whitelist: {
      href: '^(?![#/])'
    },
    icon: <FontIcon>insert_link</FontIcon>
  },
  {
    type: 'SHY',
    source: ShyInject,
    decorator: ShyDecorator,
    attributes: [],
    whitelist: {},
    icon: <span>-</span>
  },
  {
    type: 'ZWSP',
    source: ZwspInject,
    decorator: ZwspDecorator,
    attributes: [],
    whitelist: {},
    icon: <span>|</span>
  }
];
const blockTypes = [
  // { type: BLOCK_TYPE.HEADER_THREE },
  {
    type: BLOCK_TYPE.BLOCKQUOTE,
    icon: <FontIcon>format_quote</FontIcon>
  },
  {
    type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
    icon: <FontIcon>format_list_bulleted</FontIcon>
  }
  // ,
  // {
  //   type: BLOCK_TYPE.ORDERED_LIST_ITEM,
  //   icon: <FontIcon>format_list_numbered</FontIcon>
  // }
];
const inlineStyles = [
  {
    type: INLINE_STYLE.BOLD,
    style: { fontWeight: '400' },
    icon: <FontIcon>format_bold</FontIcon>
  },
  // {type: INLINE_STYLE.ITALIC,},
  {
    type: INLINE_STYLE.SUPERSCRIPT,
    class: 'super',
    style: {
      fontSize: '75%',
      verticalAlign: 'super',
      lineHeight: '0'
    },
    icon: <FontIcon>format_size</FontIcon>
  },
  {
    type: INLINE_STYLE.SUBSCRIPT,
    style: {
      fontSize: '75%',
      verticalAlign: 'sub',
      lineHeight: '0'
    },
    icon: <FontIcon>text_fields</FontIcon>
  },
  // {
  //   type: INLINE_STYLE.KEYBOARD,
  //   style: {
  //     fontFamily: 'MONOSPACE',
  //     padding: '3px 5px',
  //     fontSize: '11px',
  //     lineHeight: '10px',
  //     color: '#444d56',
  //     verticalAlign: 'middle',
  //     backgroundColor: '#fafbfc',
  //     border: 'solid 1px #c6cbd1',
  //     borderBottomColor: '#959da5',
  //     borderRadius: '3px',
  //     boxShadow: 'inset 0 -1px 0 #959da5'
  //   },
  //   icon: <FontIcon>keyboard</FontIcon>
  // },
  {
    // ################### nobreak: ####################
    type: 'NOBREAK',
    style: {
      boxShadow:
        '0 -2px 0 0 #ff5900 inset, ' +
        '-2px 0 0 0 #ff5900 inset, ' +
        '2px 0 0 0 #ff5900 inset',
      backgroundColor: 'transparent',
      position: 'relative',
      display: 'inline'
    },
    icon: <FontIcon>wrap_text</FontIcon>
  }
];

export { entityTypes, blockTypes, inlineStyles };
