import { List, fromJS } from 'immutable';

import { NotificationsAction } from '../utils/ActionTypes';

import { createNotification } from '../utils/HelperFunctions';

const initialState = fromJS({
  notifications: List()
});

const reducer = (state = initialState, action) => {
  let notification = null;
  let updatedList = null;
  let index = -1;
  switch (action.type) {
    case NotificationsAction.SHOW_NOTIFICATION:
      notification = createNotification(
        action.notificationType,
        action.message,
        action.title
      );
      updatedList = state.get('notifications').push(notification);
      return state.set('notifications', updatedList);
    case NotificationsAction.REMOVE_NOTIFICATION:
      index = state.get('notifications').findIndex(function (obj) {
        return obj.identifier === action.identifier;
      });
      if (index > -1) {
        state = state.set(
          'notifications',
          state.get('notifications').delete(index)
        );
      }
      return state;
    default:
      return state;
  }
};

export { initialState, reducer as notifications };
