import { fromJS, List } from 'immutable';

import { ImageAction } from '../utils/ActionTypes';
import { Image } from '../utils/Constants';

const initialState = fromJS({
  projectImages: List([]),
  editMode: false,
  persisted: false,
  imgData: Image.DUMMY_IMAGE,
  croppedImage: null,
  zoom: null,
  translationX: null,
  translationY: null,
  oldZoom: null,
  oldTranslationX: null,
  oldTranslationY: null,
  oldImgData: null,
  isDummy: true,
  originalExtension: null
});
let index = 0;
let data = {};
let projectId = 0;
let element = null;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ImageAction.ADD_IMAGE:
    case ImageAction.SAVE_IMAGE:
    case ImageAction.EDIT_IMAGE:
      projectId = action.payload.projectId;
      index = state
        .get('projectImages')
        .findIndex((obj) => obj.projectId === projectId);
      if (index < 0) {
        data = { cropped: action.payload.croppedImage, projectId };
        return state
          .set('projectImages', state.get('projectImages').push(data))
          .set('croppedImage', action.payload.croppedImage)
          .set('zoom', action.payload.zoom)
          .set('translationX', action.payload.translationX)
          .set('translationY', action.payload.translationY)
          .set('oldImgData', state.get('imgData'))
          .set('oldZoom', action.payload.zoom)
          .set('oldTranslationX', action.payload.translationX)
          .set('oldTranslationY', action.payload.translationY);
      }
      element = state.get('projectImages').get(index);
      element.cropped = action.payload.croppedImage;
      return state
        .set('projectImages', state.get('projectImages').set(index, element))
        .set('croppedImage', action.payload.croppedImage)
        .set('zoom', action.payload.zoom)
        .set('translationX', action.payload.translationX)
        .set('translationY', action.payload.translationY)
        .set('oldImgData', state.get('imgData'))
        .set('oldZoom', action.payload.zoom)
        .set('oldTranslationX', action.payload.translationX)
        .set('oldTranslationY', action.payload.translationY);

    case ImageAction.ADD_IMAGE_FULFILLED:
      return state.set('persisted', true);
    case ImageAction.FETCH_IMAGE_FULFILLED:
      return state
        .set('translationX', action.image.translation_x)
        .set('translationY', action.image.translation_y)
        .set('zoom', action.image.zoom)
        .set('oldTranslationX', action.image.translation_x)
        .set('oldTranslationY', action.image.translation_y)
        .set('oldZoom', action.image.zoom)
        .set('originalExtension', action.image.extension)
        .set('persisted', true)
        .set('isDummy', false);
    case ImageAction.FETCH_CROPPED_IMAGE_FULFILLED:
      projectId = action.image.projectId;
      data = { cropped: action.image.image, projectId };
      index = state
        .get('projectImages')
        .findIndex((obj) => obj.projectId === projectId);
      if (index < 0) {
        return state
          .set('projectImages', state.get('projectImages').push(data))
          .set('croppedImage', action.image.image);
      }
      element = state.get('projectImages').get(index);
      element.cropped = action.image.image;
      return state
        .set('projectImages', state.get('projectImages').set(index, element))
        .set('croppedImage', action.image.image);

    case ImageAction.FETCH_FULL_IMAGE_FULFILLED:
      projectId = action.image.projectId;
      data = { full: action.image.image, projectId };
      index = state
        .get('projectImages')
        .findIndex((obj) => obj.projectId === projectId);
      if (index < 0) {
        return state
          .set('projectImages', state.get('projectImages').push(data))
          .set('imgData', action.image.image)
          .set('oldImgData', action.image.image);
      }
      element = state.get('projectImages').get(index);
      element.full = action.image.image;
      return state
        .set('projectImages', state.get('projectImages').set(index, element))
        .set('imgData', action.image.image)
        .set('oldImgData', action.image.image);

    case ImageAction.FETCH_IMAGE_REJECTED:
    case ImageAction.DELETE_IMAGE:
      let projectId = action.payload;
      index = state
        .get('projectImages')
        .findIndex((obj) => obj.projectId === projectId);
      if (index > 0) {
        return state
          .set('projectImages', state.get('projectImages').delete(index))
          .set('imgData', Image.DUMMY_IMAGE)
          .set('croppedImage', Image.DUMMY_IMAGE)
          .set('oldImgData', null)
          .set('oldZoom', null)
          .set('oldTranslationX', null)
          .set('oldTranslationY', null)
          .set('zoom', null)
          .set('translationX', null)
          .set('translationY', null)
          .set('isDummy', true);
      }
      return state
        .set('imgData', Image.DUMMY_IMAGE)
        .set('croppedImage', Image.DUMMY_IMAGE)
        .set('oldImgData', null)
        .set('oldZoom', null)
        .set('oldTranslationX', null)
        .set('oldTranslationY', null)
        .set('zoom', null)
        .set('translationX', null)
        .set('translationY', null)
        .set('isDummy', true);

    case ImageAction.DELETE_IMAGE_FULFILLED:
      return state.set('persisted', false);
    case ImageAction.SWITCH_MODE:
      return state.set('editMode', !state.get('editMode'));
    case ImageAction.SWITCH_PERSISTED:
      return state.set('persisted', !state.get('persisted'));
    case ImageAction.SET_CROPPED:
      return state.set('croppedImage', action.payload).set('isDummy', false);
    case ImageAction.LOAD_IMAGE:
      return state
        .set('oldImgData', state.get('imgData'))
        .set('oldZoom', state.get('zoom'))
        .set('oldTranslationX', state.get('translationX'))
        .set('oldTranslationY', state.get('translationY'))
        .set('imgData', action.payload.data)
        .set('originalExtension', action.payload.originalExtension)
        .set('zoom', null)
        .set('translationX', null)
        .set('translationY', null)
        .set('croppedImage', null)
        .set('isDummy', false);
    case ImageAction.RESTORE_IMAGE:
      if (state.get('oldImgData') === Image.DUMMY_IMAGE) {
        return state
          .set('imgData', Image.DUMMY_IMAGE)
          .set('zoom', null)
          .set('translationX', null)
          .set('translationY', null)
          .set('isDummy', true);
      }
      return state
        .set('imgData', state.get('oldImgData'))
        .set('zoom', state.get('oldZoom'))
        .set('translationX', state.get('oldTranslationX'))
        .set('translationY', state.get('oldTranslationY'));

    default:
      return state;
  }
};

export { initialState, reducer as image };
