// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog-container .dialog-content {\n  height: calc(100% - 72px - 52px);\n}\n", ""]);
// Exports
exports.locals = {
	"dialog-container": "dialog-container",
	"dialogContainer": "dialog-container",
	"dialog-content": "dialog-content",
	"dialogContent": "dialog-content"
};
module.exports = exports;
