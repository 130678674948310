import React from 'react';
import TooltipEntity from './linkDecorator/TooltipEntity';

const getLinkType = (url, linkType) => {
  const isEmailLink = linkType === 'email' || url.startsWith('mailto:');
  if (isEmailLink) {
    return 'Mail';
  }
  return 'Link';
};

const LinkDecorator = ({
  entityKey,
  contentState,
  children,
  onEdit,
  onRemove
}) => {
  const { url, linkType } = contentState.getEntity(entityKey).getData();
  const type = getLinkType(url, linkType);
  const label = url.replace(/(^\w+:|^)\/\//, '').split('/')[0];

  return (
    <TooltipEntity
      entityKey={entityKey}
      contentState={contentState}
      onEdit={onEdit}
      onRemove={onRemove}
      label={label}
      type={type}
    >
      {children}
    </TooltipEntity>
  );
};

export default LinkDecorator;
