import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'react-md';
import styles from '../../../styles/chip.scss';

export default class NamedChip extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  };

  handleChipClick = () => {
    this.props.onClick(this.props);
  };

  render() {
    const { item, removable } = this.props;
    let chipClassName = 'mangooluChip ';
    chipClassName += item.get('unconfirmed')
      ? `${styles.unconfirmed} ${styles.chip}`
      : `${styles.chip}`;

    let name = item.get('name');
    let avatarName = name.substr(0, 2);
    let title = avatarName + ' - ' + item.get('name');

    return (
      <span className={chipClassName}>
        {/* <span className={'roleWrapper'}>
          <span className={'roleItem'}>{avatarName}</span>
           <span className={'roleItemFull'}>{roleAttribute.name}</span>
        </span>*/}
        <span title={title} className={'text'}>
          {item.get('name')}
        </span>
        {removable && (
          <span className={'close'} onClick={this.handleChipClick}>
            <FontIcon>close</FontIcon>
          </span>
        )}
      </span>
    );
  }
}
