import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

import { deleteProject } from '../../../actions/projects';
import { removeDialog } from '../../../actions/globalDialog';

@withTranslation(['project', 'general'], { wait: true })
class DeleteProjectWarning extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let deletingProject = nextProps.deletingProject;
    console.log(
      'UNSAFE_componentWillReceiveProps, nextProps.deletingProject = ',
      nextProps.deletingProject
    );

    if (
      deletingProject == ProcessStatus.FINISHED &&
      deletingProject != this.props.deletingProject
    ) {
      this.closeDialog();
      this.props.history.push('/projects');
    }
  }

  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    console.log('closeDialog, bam, dialog = ', dialog);
    this.props.removeDialog(dialog.identifier);
  };

  delete = () => {
    let projectId = this.props.projectId;
    this.props.deleteProject(projectId);
  };

  render() {
    const { deletingProject, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (deletingProject == ProcessStatus.STARTED) {
      return <LoadingBar show='true' />;
    }

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          {t(`${generalLNS}:cancel`)}
        </Button>
        <Button flat onClick={this.delete}>
          {t(`${generalLNS}:delete`)}
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deletingProject: state.getIn(['projects', 'deletingProject']),
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default withRouter(
  connect(mapStateToProps, { deleteProject, removeDialog })(
    DeleteProjectWarning
  )
);
