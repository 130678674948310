// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\r\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n.itemlink > a {\n  height: 50px;\n}\n\n.itemlinkactive {\n  background-color: #ff5900;\n}\n\n.itemlinkactive [class*=\"-icon\"], .itemlinkactive [class*=\"-text\"] {\n  color: white !important;\n}\n\n.icon {\n  transform: translateX(-42px);\n}\n", ""]);
// Exports
exports.locals = {
	"itemlink": "itemlink",
	"itemlinkactive": "itemlinkactive",
	"icon": "icon"
};
module.exports = exports;
