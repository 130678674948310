import 'core-js/es';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import './theme/main.scss';

import './polyfills';

if (module.hot) {
  module.hot.accept();
}

setTimeout(function () {
  ReactDOM.render(<App />, document.getElementById('root'));
}, 1000);
