import { HelpAction } from '../utils/ActionTypes';

const setHasHelp = (hasHelp) => {
  return {
    type: HelpAction.SET_HELP,
    hasHelp: hasHelp
  };
};

export { setHasHelp };
