import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';

import { ProcessStatus } from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';

import ClientPrime from './ClientPrime';
import ClientTimestamps from './ClientTimestamps';

import { editClient } from '../../../actions/clients';

import styles from '../../../styles/project_detail.scss';

@withTranslation(['profile', 'general'], { wait: true })
class EditClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      translation: null
    };
  }

  UNSAFE_componentWillMount() {}

  // UNSAFE_componentWillReceiveProps (nextProps) {
  // }

  handleSubmit = (data) => {
    this.props.editClient(data);
    this.switchEditMode();
  };

  switchEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    const { client, t } = this.props;
    const { editMode } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <Form
        mutators={{
          setFieldData,
          setCollectedInfos: (args, state) => {
            state.formState.values = { ...state.formState.values, ...args[0] };
          },
          changeValue: (args, state, utils) => {
            utils.changeValue(state, args[0].name, () => args[0].value);
          },
          changeValues: (args, state, utils) => {
            args.map((arg) =>
              utils.changeValue(state, arg.name, () => arg.value)
            );
          }
        }}
        onSubmit={this.handleSubmit}
        initialValues={client}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.formInTabWrapper}>
            <div className={styles.formContentWrapper}>
              {false && editingProject == ProcessStatus.STARTED && (
                <LoadingBar
                  show='true'
                  wrapperClassname={styles.loadingBarWrapper}
                  message={t('updating_project')}
                />
              )}
              <div
                className={
                  styles.buttonsRightWrapper +
                  ' ' +
                  styles.buttonsRightWrapperInTab
                }
              >
                <Button
                  title={t(`${generalLNS}:edit_mode`)}
                  className={styles.editButton + ' ' + styles.editButtonFilled}
                  style={{ display: !editMode ? 'block' : 'none' }}
                  onClick={this.switchEditMode}
                  icon
                  primary
                  swapTheming
                >
                  mode_edit
                </Button>

                <Button
                  children={t(`${generalLNS}:save_all_edits`)}
                  type='submit'
                  style={{ display: editMode ? 'block' : 'none' }}
                  className={styles.formSubmitButton}
                  flat
                  primary
                  swapTheming
                />

                <Button
                  children={t(`${generalLNS}:cancel`)}
                  type='button'
                  style={{ display: editMode ? 'block' : 'none' }}
                  className={styles.formCancelButton}
                  onClick={this.switchEditMode}
                  flat
                  primary
                />
              </div>
              <div className={styles.flexHorizontalWrapper}>
                <FormSpy
                  subscription={{ values: true }}
                  editMode={editMode}
                  component={ClientPrime}
                />
                <FormSpy
                  subscription={{ values: true }}
                  editMode={editMode}
                  component={ClientTimestamps}
                />
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    client: state.getIn(['clients', 'selectedClient']),
    editingClient: state.getIn(['clients', 'editingClient'])
  };
}

export default connect(mapStateToProps, { editClient })(EditClient);
