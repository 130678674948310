import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  // FilterType,
  ProcessStatus
} from '../../utils/Constants';
import {
  DialogContainer,
  FontIcon,
  /* Switch, */ TextField,
  Button
} from 'react-md';
// import {Drawer} from 'react-md';
import StructureView from './StructureView';
import HoverPanel from './HoverPanel';
import ProjectStatusDialog from './ProcessStatusDialog';
import PortalWindow from '../general/PortalWindowHooks';
import { baseApiUrl, basePackagesUrl } from '../../utils/AuthHelper';
import {
  treeToList,
  getFirstPageId,
  getPrevPageId,
  getNextPageId
} from '../../utils/HelperFunctions';
import {
  fetchProject,
  fetchTranslation,
  fetchConfig
} from '../../actions/projects';
import {
  fetchStructure,
  filterStructure,
  selectItem
} from '../../actions/structure';
import {
  fetchAllTexts,
  fetchAllTextsGroupedByCollection,
  toggleLiveEdit,
  fetchSource
} from '../../actions/wbtViewer';
import { updateStorage } from '../../actions/translationTool';
import { fetchSourceSnapshots } from '../../actions/sourceSnapshots';
import { addToast } from '../../actions/globalSnackbar';
import styles from '../../styles/wbtViewer/wbtViewer.scss';
import Draggable from 'react-draggable';
import GlobalSnackbar from '../general/GlobalSnackbar';
import GlobalDialog from '../general/dialog/GlobalDialog';
import ErrorDialog from '../general/dialog/ErrorDialog';

import { matchPath } from 'react-router';
import helpTexts from '../general/help/helpTexts';
import { setHasHelp } from '../../actions/help';
import { List } from 'immutable';
// import TranslationTool from '../translationTool/TranslationTool';
// import {parseHTML} from '../../utils/RTEParser';
import { checkMemorySizeOf, queryString } from '../../utils/HelperFunctions';
import AssetsView from './AssetsView';

function mapStateToProps(state) {
  // Resize-Handler
  // https://github.com/AlecAivazis/redux-responsive#tracking-window-attributes
  return {
    screenWidth: state.get('browser').width,
    screenHeight: state.get('browser').height,

    project: state.getIn(['projects', 'selectedProject']),
    translation: state.getIn(['projects', 'translation']),
    texts: state.getIn(['wbtViewer', 'textsGroupedByCollection']),
    allCourseTexts: state.getIn(['wbtViewer', 'courseTexts']),
    source: state.getIn(['wbtViewer', 'source']),

    fetchingProject: state.getIn(['projects', 'fetchingProject']),
    fetchingTranslation: state.getIn(['projects', 'fetchingTranslation']),
    fetchingTexts: state.getIn([
      'wbtViewer',
      'fetchingAllTextsGroupedByCollection'
    ]),
    fetchingAllTexts: state.getIn(['wbtViewer', 'fetchingAllTexts']),
    fetchingSource: state.getIn(['wbtViewer', 'fetchingSource']),

    activeTranslationId: state.getIn(['wbtViewer', 'activeTranslationId']),
    isLiveEditEnabled: state.getIn(['wbtViewer', 'isLiveEditEnabled']),
    structure: state.getIn(['structure', 'structure']),
    fetchingStructure: state.getIn(['structure', 'fetchingStructure']),
    selectedItem: state.getIn(['structure', 'selectedItem']),
    matchingIds: state.getIn(['structure', 'matchingIds']),
    updateToken: state.getIn(['structure', 'updateToken']),
    hasHelp: state.getIn(['help', 'hasHelp']),
    config: state.getIn(['projects', 'config']),
    fetchingConfig: state.getIn(['projects', 'fetchingConfig'])
  };
}

@connect(mapStateToProps, {
  fetchProject,
  fetchTranslation,
  selectItem,
  fetchAllTexts,
  fetchAllTextsGroupedByCollection,
  fetchSource,
  fetchStructure,
  toggleLiveEdit,
  filterStructure,
  updateStorage,
  fetchSourceSnapshots,
  addToast,
  setHasHelp,
  fetchConfig
})
@withTranslation(['translation_navigation', 'general'], { wait: true })
class TranslationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packageConnected: false,
      dispatched: false,
      callbackChain: {
        handshake: null,
        initialTexts: null,
        hash: null
      },
      isIframeLoaded: false,
      isHandshakeInitiated: false,
      showStructureViewDrawer: true,
      showDirectLinkModal: false,
      directLink: '',
      textsOnStage: null,
      allCourseTexts: null,
      currentPageId: null,
      currentCoursePageId: null,
      currentRequestedId: null,
      courseGoToStatus: ProcessStatus.INITIAL,
      scrollTo: {
        collection: '',
        textfield_id: ''
      },
      searchText: '',
      isTranslationWindowVisible: false,
      inSafeClosingMode: false,
      initiateSafeClosing: false,
      isReadyForEditing: false,
      darkMode: true,
      helpOpened: false,
      selectedViewport: null,
      viewports: [
        {
          id: 0,
          name: 'your_current_viewport',
          width: undefined,
          height: undefined
        },
        {
          id: 1,
          name: 'tablet_landscape',
          width: 1024,
          height: 768
        },
        {
          id: 2,
          name: 'smartphone_portrait',
          width: 360,
          height: 640
        },
        {
          id: 3,
          name: 'smartphone_landscape',
          width: 640,
          height: 360
        }
      ],
      showModal: true,
      src: '',
      isTranslationToolAway: true,
      inlineTranslationTool: false,
      inlineTranslationToolObject: {},
      textWasSelected: false,
      assetsPopup: false,
      assetsPopupPageX: 0,
      assetsPopupPageY: 0,
      projectIframeUrl: '',
      viewPortFromConfigAdded: false
    };

    this.updateInterval = null;
    this.selectItemTimeout = null;
    this.id = 0;
    this.t = this.props.t;
    this.iframeLoaded = false;

    // Default responsive Array:
    this.resizeDefault = [
      {
        id: 0,
        label:
          this.t('your_current_viewport') +
          ': ' +
          this.props.screenWidth +
          'x' +
          this.props.screenHeight +
          'px',
        value: {
          width: this.props.screenWidth,
          height: this.props.screenHeight
        }
      },
      // {
      //     label: 'TABLET PORTRAIT - 768x1024px',
      //     value: {
      //         width: 768,
      //         height: 1024
      //     }
      // },
      {
        id: 1,
        label: 'TABLET LANDSCAPE - 1024x768px',
        value: {
          width: 1024,
          height: 768
        }
      },
      {
        id: 2,
        label: 'SMARTPHONE PORTRAIT - 360x640px',
        value: {
          width: 360,
          height: 640
        }
      },
      {
        id: 3,
        label: 'SMARTPHONE LANDSCAPE - 640x360px',
        value: {
          width: 640,
          height: 360
        }
      }
    ];
  }

  handleLeavePage = (event) => {
    const { t } = this.props;
    let messege = t('warning_close_text');
    event.returnValue = 'messege';
    return messege;
  };

  UNSAFE_componentWillMount() {
    const { projectId } = this.props.match.params;
    this.setState({
      currentIframeSize: this.resizeDefault[0]
    });
    this.id = Math.floor(Math.random() * 10001);

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');

    // IE FIX FOR BETTER PERFORMANCE
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      document.querySelector('html').classList.add('ie');
    }

    window.addEventListener('storage', this.lookForTextEdits, false);
    window.addEventListener('beforeunload', this.handleLeavePage, false);
    window.onbeforeunload = this.unload;

    this.checkOnDarkLightMode();

    this.props.fetchConfig(projectId);
  }

  componentWillUnmount() {
    this.unload();
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize, false);
    window.addEventListener('message', this.receiveMessage, false);
    const { projectId, sourceLanguageId } = this.props.match.params;

    const values = queryString(this.props.location.search);

    if (values['sourcesnapshot-id']) {
      this.props.fetchSourceSnapshots(
        projectId,
        sourceLanguageId,
        parseInt(values['sourcesnapshot-id'])
      );
    }

    let hash = '';
    let rawHash = this.props.location.hash;
    if (rawHash.length > 1) {
      hash = rawHash.substring(1, rawHash.length); // entfernt die Raute!
      this.addToCallbackChain('hash', () => {
        this.goToPage(hash);
      });
    }

    if (this.props.project === null || this.props.project.id !== projectId) {
      this.props.fetchProject(projectId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isLiveEditEnabled,
      selectedItem,
      allCourseTexts,
      fetchingProject,
      fetchingTranslation,
      fetchingStructure,
      fetchingAllTexts,
      fetchingTexts,
      fetchingSource,
      fetchingConfig
    } = nextProps;
    const { projectId, translationId, sourceLanguageId } =
      this.props.match.params;
    if (selectedItem !== this.props.selectedItem) {
      let nodeSelector =
        '[structure-node-refname="ref_structurenode_' +
        selectedItem.toObject().external_id +
        '"]';
      let foundStructureNode = document.querySelector(nodeSelector);
      if (foundStructureNode && !this.isElementInViewport(foundStructureNode)) {
        foundStructureNode.scrollIntoView();
      }
    }

    if (isLiveEditEnabled !== this.props.isLiveEditEnabled) {
      if (isLiveEditEnabled) {
        this.postMessageToIframe({
          command: 'toggleLiveEdit',
          arg: true
        });
      } else {
        this.postMessageToIframe({
          command: 'toggleLiveEdit',
          arg: false
        });
      }
    }

    if (
      fetchingTexts === ProcessStatus.FINISHED &&
      fetchingTexts !== this.props.fetchingTexts
    ) {
      let textsLoaded = () =>
        this.postMessageToIframe({
          command: 'textsLoaded',
          status: 'success',
          texts: nextProps.texts
        });
      if (
        !this.state.dispatched &&
        typeof this.state.initialTexts !== 'function'
      ) {
        this.addToCallbackChain('initialTexts', textsLoaded);
      } else {
        textsLoaded();
      }
    }

    if (
      fetchingStructure === ProcessStatus.FINISHED &&
      fetchingStructure !== this.props.fetchingStructure
    ) {
      const fpid = getFirstPageId(nextProps.structure);
      this.requestPage(fpid);
    }

    if (
      fetchingAllTexts === ProcessStatus.FINISHED &&
      fetchingAllTexts !== this.props.fetchingAllTexts
    ) {
      if (
        Object.keys(allCourseTexts).length === 0 &&
        allCourseTexts.constructor === Object
      ) {
        //
      } else {
        let ttTexts = List(allCourseTexts).map((text) => {
          return {
            collection: text.collection,
            textfieldId: text.textfield_id
          };
        });

        this.setState(
          { allCourseTexts: ttTexts.toArray() },
          this.setLocalStorageForTranslationTool
        );
      }
    }

    // LOADING AFTER PROJECT IS LOADING:
    if (fetchingProject === ProcessStatus.FINISHED) {
      if (
        fetchingStructure !== ProcessStatus.FINISHED &&
        fetchingStructure !== ProcessStatus.STARTED
      ) {
        this.props.fetchStructure(projectId, translationId);
      } else {
        if (
          fetchingStructure === ProcessStatus.FINISHED &&
          fetchingTranslation !== ProcessStatus.FINISHED &&
          fetchingTranslation !== ProcessStatus.STARTED
        ) {
          this.props.fetchTranslation(projectId, translationId);
        } else {
          if (
            fetchingStructure === ProcessStatus.FINISHED &&
            fetchingTranslation === ProcessStatus.FINISHED &&
            fetchingSource !== ProcessStatus.FINISHED &&
            fetchingSource !== ProcessStatus.STARTED
          ) {
            this.props.fetchSource(
              projectId,
              sourceLanguageId ? sourceLanguageId : translationId
            );
          } else {
            if (
              fetchingStructure === ProcessStatus.FINISHED &&
              fetchingTranslation === ProcessStatus.FINISHED &&
              fetchingSource === ProcessStatus.FINISHED &&
              fetchingTexts !== ProcessStatus.FINISHED &&
              fetchingTexts !== ProcessStatus.STARTED
            ) {
              this.props.fetchAllTextsGroupedByCollection(
                sourceLanguageId ? sourceLanguageId : null,
                translationId
              );
            } else {
              if (
                fetchingStructure === ProcessStatus.FINISHED &&
                fetchingTranslation === ProcessStatus.FINISHED &&
                fetchingSource === ProcessStatus.FINISHED &&
                fetchingTexts === ProcessStatus.FINISHED &&
                fetchingAllTexts !== ProcessStatus.FINISHED &&
                fetchingAllTexts !== ProcessStatus.STARTED
              ) {
                this.props.fetchAllTexts(
                  sourceLanguageId ? sourceLanguageId : null,
                  translationId
                );
              } else {
                if (
                  fetchingStructure === ProcessStatus.FINISHED &&
                  fetchingTranslation === ProcessStatus.FINISHED &&
                  fetchingSource === ProcessStatus.FINISHED &&
                  fetchingTexts === ProcessStatus.FINISHED &&
                  fetchingAllTexts === ProcessStatus.FINISHED &&
                  !this.iframeLoaded
                ) {
                  if (this.refs.iframe) {
                    if (!this.state.packageConnected) {
                      if (this.refs.iframe.onload === null) {
                        this.refs.iframe.onload = this.onIframeLoaded;

                        // PROJEKT-URL IN IFRAME LADEN:
                        let projectUrl =
                          basePackagesUrl + nextProps.project.identifier;
                        this.setState({ projectIframeUrl: projectUrl });
                        this.refs.iframe.src = projectUrl;

                        // FIREFOX BACKBUTTON ISSUE FIX
                        this.refs.iframe.contentWindow.location.replace(
                          projectUrl
                        );

                        this.iframeLoaded = true;

                        setTimeout(() => {
                          this.setState({ showModal: false }, () => {
                            this.checkOnHelpTexts(this.props.location.pathname);
                          });
                        }, 1000);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (
      fetchingConfig === ProcessStatus.FINISHED &&
      fetchingProject === ProcessStatus.FINISHED
    ) {
      // console.log('config: ', JSON.stringify(nextProps.config, null, 2));

      if (nextProps.config) {
        var configViewPort = JSON.parse('[' + nextProps.config.viewport + ']');
        var tempViewports = [...this.state.viewports];
        if (Array.isArray(configViewPort) && configViewPort.length === 2) {
          if (!tempViewports[0].isConfigViewport) {
            if (!this.state.viewPortFromConfigAdded) {
              if (nextProps.project.type === 'clt') {
                // add at first position at CLT
                tempViewports.unshift({
                  id: 0,
                  isConfigViewport: true,
                  name: 'viewport_from_config',
                  width: configViewPort[0],
                  height: configViewPort[1]
                });
                this.setState({
                  viewports: tempViewports,
                  selectedViewport: tempViewports[0]
                });
              } else {
                // add at last position at all projects
                tempViewports.push({
                  id: 0,
                  isConfigViewport: true,
                  name: 'viewport_from_config',
                  width: configViewPort[0],
                  height: configViewPort[1]
                });
                this.setState({
                  viewports: tempViewports,
                  selectedViewport: tempViewports[0]
                });
              }
              this.setState({ viewPortFromConfigAdded: true }); // just do it once
            }
          }
        }
      }
    }
  }

  unload = () => {
    if (this.state.isTranslationWindowVisible) {
      this.handleTranslationButton();
    }
    window.removeEventListener('resize', this.resize, false);
    window.removeEventListener('message', this.receiveMessage, false);
    window.removeEventListener('beforeunload', this.handleLeavePage, false);
    window.removeEventListener('storage', this.lookForTextEdits, false);
    window.onstorage = null;
    this.refs.iframe.removeEventListener('load', this.onIframeLoaded, false);
  };

  checkOnDarkLightMode = () => {
    // WENN SCHON VORHANDEN
    let mangooluMode = JSON.parse(localStorage.getItem('mangoolu_darkmode'));
    if (mangooluMode) {
      // WENN LocalStorage
      this.setTheme(mangooluMode.darkMode);
    } else {
      if (this.state.darkMode) {
        // INITIAL
        this.setTheme(this.state.darkMode);
      }
    }
  };

  setTheme = (dark) => {
    let html = document.querySelector('html');
    let htmlHasClassDark = html.classList.contains('dark');
    if (dark) {
      if (!htmlHasClassDark) {
        document.querySelector('html').classList.add('dark');
      }
    } else {
      if (htmlHasClassDark) {
        document.querySelector('html').classList.remove('dark');
      }
    }
    this.setState({ darkMode: dark });
    localStorage.setItem(
      'mangoolu_darkmode',
      JSON.stringify({ darkMode: dark })
    );
  };

  toggleTheme = () => {
    let html = document.querySelector('html');
    let htmlHasClassDark = html.classList.contains('dark');
    if (htmlHasClassDark) {
      html.classList.remove('dark');
    } else {
      html.classList.add('dark');
    }
    this.updateDarkModeLocalStorage();
  };

  updateDarkModeLocalStorage = () => {
    let isDark = document.querySelector('html').classList.contains('dark');
    if (isDark !== this.state.darkmode) {
      this.setState({ darkMode: isDark });
    }
    let localStorageObject = { darkMode: isDark };
    localStorage.setItem(
      'mangoolu_darkmode',
      JSON.stringify(localStorageObject)
    );
  };

  lookForTextEdits = () => {
    const idsFromLocalStorage = JSON.parse(
      localStorage.getItem('openProject_' + this.id)
    );

    if (idsFromLocalStorage) {
      // eslint-disable-next-line no-prototype-builtins
      if (idsFromLocalStorage.hasOwnProperty('textUpdate')) {
        if (idsFromLocalStorage.textUpdate === '1') {
          this.updateTextInIframe(idsFromLocalStorage.newTexts);
          idsFromLocalStorage.textUpdate = 0;
          idsFromLocalStorage.newTexts = null;
          idsFromLocalStorage.scrollTo = {
            collection: '',
            textfield_id: ''
          };
          localStorage.setItem(
            'openProject_' + this.id,
            JSON.stringify(idsFromLocalStorage)
          );
          if (this.state.inlineTranslationTool) {
            this.setState({ inlineTranslationToolObject: idsFromLocalStorage });
          }
        }
      }
    }
  };

  checkOnHelpTexts = (route) => {
    let hasHelp = false;
    for (var k = 0; k < helpTexts.length; k++) {
      if (matchPath(route, { path: helpTexts[k].route })) {
        hasHelp = true;
        break;
      }
    }
    this.props.setHasHelp(hasHelp);
  };

  openHelp = () => {
    this.setState({
      helpOpened: true
    });
  };

  closeHelp = () => {
    this.setState({
      helpOpened: false
    });
  };

  isElementInViewport = (el) => {
    let rect = el.getBoundingClientRect();
    let structureWrapper = document.querySelector(
      '[ref-name="structure-wrapper"]'
    );
    let structureWrapperRect = structureWrapper.getBoundingClientRect();
    var isVisible =
      rect.top >= structureWrapperRect.top &&
      rect.bottom <= structureWrapperRect.height + structureWrapperRect.top;
    return isVisible;
  };

  resize = () => {
    const { viewports } = this.state;

    let tempViewports = [...viewports];
    let indexOfCustomViewPort = viewports.findIndex(
      (element) => element.name === 'your_current_viewport'
    );

    let customViewport = { ...tempViewports[indexOfCustomViewPort] };

    customViewport.width =
      this.refs.iframeWrapper.getBoundingClientRect().width;
    customViewport.height =
      this.refs.iframeWrapper.getBoundingClientRect().height;

    tempViewports[indexOfCustomViewPort] = customViewport;
    this.setState({
      viewports: tempViewports,
      selectedViewport: customViewport
    });
  };

  addToCallbackChain = (name, callback) => {
    let callbackChain = this.state.callbackChain;
    callbackChain[name] = callback;
    this.setState({ callbackChain: callbackChain });
  };

  dispatchFromCallbackChain = (name) => {
    if (this.state.packageConnected) {
      let callbackChain = this.state.callbackChain;

      if (typeof callbackChain[name] === 'function') {
        callbackChain[name]();
        callbackChain[name] = null;
        this.setState({ callbackChain: callbackChain });
      }
    }
  };

  onIframeLoaded = () => {
    this.setState({ isIframeLoaded: true });
    window.testiframeref = this.refs.iframe;
    this.initiateHandshakeIfReady();
  };

  initiateHandshakeIfReady = () => {
    const { project, fetchingProject, fetchingStructure, fetchingTexts } =
      this.props;

    let dispatchHandshake = !(
      project === null ||
      fetchingProject === ProcessStatus.STARTED ||
      fetchingStructure === ProcessStatus.STARTED ||
      fetchingTexts === ProcessStatus.STARTED
    );

    if (dispatchHandshake && this.state.isIframeLoaded) {
      let translation = project.translations.find(
        (translation) =>
          translation.id.toString() === this.props.match.params.translationId
      );

      if (!this.state.isHandshakeInitiated) {
        this.setState({ isHandshakeInitiated: true });
        this.postMessageToIframe({
          command: 'handshake',
          languagecode: translation
            ? translation.languagecode
            : project.master_languagecode,
          bookmark: 'undefined'
        });
      }
      setTimeout(() => {
        this.initiateHandshakeIfConnected();
      }, 1000);
    } else {
      setTimeout(() => {
        this.initiateHandshakeIfReady();
      }, 1000);
    }
  };

  initiateHandshakeIfConnected = () => {
    if (!this.state.packageConnected) {
      this.postMessageToIframe({
        command: 'handshake',
        languagecode: 'de',
        bookmark: 'undefined'
      });
      setTimeout(() => {
        this.initiateHandshakeIfConnected();
      }, 1000);
    }
  };

  postMessageToIframe = (msg) => {
    // console.log('postMessageToIframe', msg);
    let iframe = this.refs.iframe;
    iframe.contentWindow.postMessage(msg, baseApiUrl);
  };

  updateTextInIframe = (texts) => {
    // console.log('updateTextInIframe', texts, parseHTML(texts));
    this.postMessageToIframe({
      command: 'textsUpdated',
      status: 'success',
      texts: texts
    });
  };

  handleNodeSelect = (node) => {
    this.goToPage(node.external_id);
  };

  goToPage = (id) => {
    if (id && id.length > 0) {
      this.requestPage(id);
    }
  };

  requestPage = (id) => {
    clearTimeout(this.selectItemTimeout);
    clearInterval(this.updateInterval);
    this.updateInterval = setInterval(this.update, 1000);

    if (this.state.packageConnected) {
      this.postMessageToIframe({ command: 'goTo', value: id });
    }

    this.setState({
      currentPageId: id,
      currentRequestedId: id,
      isCourseBehind: true
    });
  };

  update = () => {
    let currentRequestedId = this.state.currentRequestedId;
    let currentCoursePageId = this.state.currentCoursePageId;
    let isCourseBehind = this.state.isCourseBehind;

    if (
      currentCoursePageId != null &&
      currentCoursePageId !== currentRequestedId &&
      isCourseBehind
    ) {
      this.requestPage(currentRequestedId);
    }
  };

  selectItemById = (newId) => {
    const { projectId, translationId } = this.props.match.params;
    let list = treeToList(this.props.structure, 'childs');
    let node = list.find((node) => node.external_id == newId);
    this.props.selectItem(projectId, translationId, node.id);
  };

  handleResizeSelectChange = (selectedOption) => {
    this.setState({ selectedViewport: selectedOption });
  };

  receiveMessage = (event) => {
    if (typeof event.data.command !== 'undefined') {
      // console.log('TranslationView event: ', event.data);
    }

    let currentRequestedId = this.state.currentRequestedId;
    let currentCoursePageId = '';
    let isCourseBehind = false;

    switch (event.data.command) {
      case 'handshake': // successful handshake received from WBT
        this.setState({ packageConnected: true });
        break;
      case 'getStructure': // getStructure used??
        break;
      case 'getText': // WBT requests a text from db
        break;
      case 'getAllTexts': // WBT requests all text from db
        this.dispatchFromCallbackChain('initialTexts');
        this.setState({ dispatched: true });
        break;
      case 'textsOnStage': // texts on stage coming from WBT - after that open translation popup!
        // console.log('!!! event.data.value:', event.data.value);
        this.setState(
          { textsOnStage: event.data.value },
          this.setLocalStorageForTranslationTool
        );
        break;
      case 'pageChanged': // page change event coming from WBT
        currentCoursePageId = event.data.value;
        isCourseBehind = currentRequestedId
          ? currentCoursePageId !== currentRequestedId
          : false;
        clearTimeout(this.selectItemTimeout);

        if (!isCourseBehind) {
          clearInterval(this.updateInterval);
          this.updateInterval = undefined;
          this.selectItemTimeout = setTimeout(() => {
            this.dispatchFromCallbackChain('hash');
            this.postMessageToIframe({ command: 'getTextsOnStage' });

            this.selectItemById(currentCoursePageId);
            this.setState({
              currentPageId: currentCoursePageId,
              currentRequestedId: null,
              currentCoursePageId: currentCoursePageId,
              isReadyForEditing: true
            });
          }, 50);
        }
        this.setState(
          {
            currentCoursePageId: currentCoursePageId,
            isCourseBehind: isCourseBehind,
            isReadyForEditing: false,
            textWasSelected: false
          }
          // this.setLocalStorageForTranslationTool
        );
        break;

      case 'textSelected': // when user has clicked on a text within the WBT
        this.setState(
          {
            scrollTo: {
              collection: event.data.collection,
              textfield_id: event.data.textfield_id
            },
            // isTranslationWindowVisible: true,
            isTranslationToolAway: false,
            textWasSelected: true
          },
          this.setLocalStorageForTranslationTool
        );
        setTimeout(() => {
          this.setState({ isTranslationWindowVisible: true }, () => {
            setTimeout(() => {
              this.setState({ isTranslationToolAway: false });
            }, 150);
          });
        }, 100);
        break;
      case 'textUpdated': // when user has clicked on a text within the WBT
        break;
      default:
        break;
    }
  };

  getLanguageTitle = (id) => {
    if (!this.props.project) {
      return;
    }
    let currentTranslation = null;
    this.props.project.translations.map((translation) => {
      if (id == translation.id) {
        currentTranslation = translation;
        return;
      }
    });
    let returnString =
      currentTranslation.languagecode + ' - ' + currentTranslation.name;
    return returnString;
  };

  getLanguageType = (id) => {
    const { project } = this.props;
    if (!project) {
      return;
    }

    const translation = project.translations.find(
      (translation) => Number(id) === translation.id
    );

    return translation.type;
  };

  getSourceTitle = () => {
    const { source } = this.props;

    if (source.size === 0) {
      return;
    }

    let returnString = source.get('languagecode') + ' - ' + source.get('name');
    return returnString;
  };

  toggleStructureView = () => {
    this.setState({
      showStructureViewDrawer: !this.state.showStructureViewDrawer
    });
  };

  toggleDirectLinkModal = (directLink = '') => {
    this.setState({
      showDirectLinkModal: !this.state.showDirectLinkModal,
      directLink: directLink
    });
  };

  makeExternalLink = (externalId) => {
    const {
      match: {
        params: { projectId, translationId, sourceLanguageId }
      },
      t
    } = this.props;

    const search = this.props.location.search;

    let sourcePart = sourceLanguageId ? `/${sourceLanguageId}` : '';
    let searchPart = search ? search : '';

    // WARNING: DO NOT BREAK!
    // eslint-disable-next-line max-len
    let link = `${location.protocol}//${location.host}/projects/translate/${projectId}/${translationId}${sourcePart}${searchPart}#${externalId}`;

    // this.toggleDirectLinkModal(link);
    this.copyStringToClipboard(link);

    this.props.addToast(t('link_copied_clipboard'));
  };

  copyStringToClipboard = (str) => {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  };

  handleSearchInput = (value) => {
    this.setState({ searchText: value });
    this.props.filterStructure(this.props.texts, value);
  };

  handleSearchReset = () => {
    this.setState({ searchText: '' });
    this.props.filterStructure(this.props.texts, '');
  };

  handleTranslationButton = () => {
    if (this.state.isTranslationWindowVisible) {
      if (this.state.inSafeClosingMode) {
        this.setState({ initiateSafeClosing: true });
      } else {
        this.setState({ isTranslationWindowVisible: false });
      }
    } else {
      this.setLocalStorageForTranslationTool();
      setTimeout(() => {
        this.setState({ isTranslationWindowVisible: true }, () => {
          setTimeout(() => {
            this.setState({ isTranslationToolAway: false });
          }, 150);
        });
      }, 100);
    }
  };

  toggleTranslationInline = () => {
    this.setState({ inlineTranslationTool: !this.state.inlineTranslationTool });
  };

  setLocalStorageForTranslationTool = () => {
    // console.log('setLocalStorageForTranslationTool', 'openProject_' + this.id);
    const { projectId, translationId, sourceLanguageId } =
      this.props.match.params;

    let languageTitleTranslation = this.getLanguageTitle(translationId);
    const translationType = this.getLanguageType(translationId);
    const sourceType = sourceLanguageId
      ? this.getLanguageType(sourceLanguageId)
      : translationType;

    let tos = [];
    if (this.state.textsOnStage) {
      for (let p = 0; p < this.state.textsOnStage.length; p++) {
        let item = this.state.textsOnStage[p];
        tos.push({
          c: item.collection,
          t: item.textfieldId
        });
      }
    }

    // console.log({tos});

    let obj = {
      id: this.id,
      startedFromProject: false,
      textsOnStage: tos,
      // textsOnStage: this.state.textsOnStage, // War zu groß für IE --> ersetzt mit tos
      // allCourseTexts: this.state.allCourseTexts,         // War zu groß für IE --> wird nicht mehr gebraucht
      currentPageId: this.state.currentPageId,
      projectId: parseInt(projectId),
      translationId: parseInt(translationId),
      sourceLanguageId: parseInt(
        sourceLanguageId ? sourceLanguageId : translationId
      ),
      scrollTo: this.state.scrollTo,
      translationLanguageCode: languageTitleTranslation,
      sourceLanguageCode: sourceLanguageId
        ? this.getSourceTitle()
        : languageTitleTranslation,
      sourceSnapshotId: -1,
      date: new Date(),
      textWasSelected: this.state.textWasSelected,
      translationType,
      sourceType
    };

    const values = queryString(this.props.location.search);
    if (values.sourcesnapshotId) {
      obj.sourceSnapshotId = parseInt(values.sourcesnapshotId);
    }

    checkMemorySizeOf(obj);
    localStorage.setItem('openProject_' + this.id, JSON.stringify(obj));
    this.setState({
      scrollTo: {
        collection: '',
        textfield_id: ''
      }
    });
    if (this.state.inlineTranslationTool) {
      this.setState({ inlineTranslationToolObject: obj });
    }
  };

  removeLocalStorageForTranslationTool = () => {
    localStorage.removeItem('openProject_' + this.id); // LocalStorage bereinigen
  };

  resetSafeClosingInitiation = () => {
    this.setState({ initiateSafeClosing: false });
  };

  handleWindowClosed = () => {
    this.setState({ isTranslationWindowVisible: false });
    this.removeLocalStorageForTranslationTool();
  };

  toggleProjectStatusDialog = () => {
    // console.log('toggleProjectStatusDialog: is this needed?'); // TODO: INSERTED IN REACT-TOOLBOX _ REACT-MD REFACTORING
  };

  dispatchTextUpdateFromInlineTranslationTool = (newTexts) => {
    this.updateTextInIframe(newTexts);
  };

  setOffTWS = () => {
    this.setState({ textWasSelected: false });
  };

  openAssets = (e) => {
    let { pageX, pageY } = e;
    if (e.changedTouches) {
      pageX = e.changedTouches[0].pageX;
      pageY = e.changedTouches[0].pageY;
    }
    this.setState({
      assetsPopup: true,
      assetsPopupPageX: pageX,
      assetsPopupPageY: pageY
    });
  };
  closeAssets = () => {
    this.setState({
      assetsPopup: false,
      assetsPopupPageX: 0,
      assetsPopupPageY: 0
    });
  };

  render() {
    const {
      screenHeight,
      project,
      structure,
      source,
      updateToken,
      fetchingProject,
      fetchingStructure,
      fetchingTexts,
      matchingIds,
      toggleLiveEdit,
      updateStorage,
      translation,
      t
    } = this.props;

    const { translationId, projectId, sourceLanguageId } =
      this.props.match.params;

    const {
      selectedViewport,
      currentPageId,
      currentCoursePageId,
      showStructureViewDrawer,
      searchText,
      isTranslationWindowVisible,
      showDirectLinkModal,
      isReadyForEditing,
      directLink,
      inSafeClosingMode,
      initiateSafeClosing,
      helpOpened,
      viewports,
      showModal,
      packageConnected,
      isTranslationToolAway,
      inlineTranslationTool,
      assetsPopup,
      assetsPopupPageX,
      assetsPopupPageY,
      projectIframeUrl
    } = this.state;

    let iframeWidth = selectedViewport ? selectedViewport.width : '100%';
    let iframeHeight = selectedViewport ? selectedViewport.height : '100%';

    return (
      <React.Fragment>
        <div className={styles.translationViewContainer}>
          {!showStructureViewDrawer && (
            <Draggable
              defaultPosition={{ x: 0, y: 0 }}
              bounds='parent'
              axis='y'
              cancel='.no-drag'
              defaultClassName={'imageDraggable'}
              handle='.handleImage'
            >
              <div>
                <img
                  title={t('click_or_drag_to_show_structure')}
                  src={require('../../images/icon-structureview.png')}
                  onClick={this.toggleStructureView}
                />
                <span className='handleImage'>
                  <FontIcon>control_camera</FontIcon>
                </span>
              </div>
            </Draggable>
          )}

          <StructureView
            screenHeight={screenHeight}
            toggleStructureView={this.toggleStructureView}
            project={project}
            translationId={translationId}
            iframeLoaded={this.iframeLoaded}
            sourceLangId={sourceLanguageId}
            source={source}
            structure={structure}
            searchTerm={searchText}
            handleSearchInput={this.handleSearchInput}
            handleSearchReset={this.handleSearchReset}
            makeExternalLink={this.makeExternalLink}
            selectedViewport={selectedViewport}
            viewports={viewports}
            handleResizeSelect={this.handleResizeSelectChange}
            updateToken={updateToken}
            onNodeSelect={this.handleNodeSelect}
            currentPageId={currentPageId}
            currentCoursePageId={currentCoursePageId}
            matchingIds={matchingIds}
            updateStorage={updateStorage}
            toggleTheme={this.toggleTheme}
            isDark={this.state.darkMode}
            helpOpened={helpOpened}
            openHelp={this.openHelp}
            closeHelp={this.closeHelp}
            isTranslationToolInline={this.state.inlineTranslationTool}
            toggleTranslationInline={this.toggleTranslationInline}
            invisible={!showStructureViewDrawer}
          />
          <div className={'iframeWrapper'} ref='iframeWrapper'>
            {translation.size > 0 && (
              <HoverPanel
                openAssets={this.openAssets}
                currentPageId={currentPageId}
                makeExternalLink={this.makeExternalLink}
                isTranslationWindowVisible={isTranslationWindowVisible}
                isReadyForEditing={isReadyForEditing}
                onTranslationButton={this.handleTranslationButton}
                toggleLiveEdit={toggleLiveEdit}
                goToPage={this.goToPage}
                prevPageId={getPrevPageId(structure, currentPageId)}
                nextPageId={getNextPageId(structure, currentPageId)}
                isTranslationToolInline={this.state.inlineTranslationTool}
                toggleTranslationInline={this.toggleTranslationInline}
              />
            )}
            <div
              className={
                inlineTranslationTool &&
                isTranslationWindowVisible &&
                !isTranslationToolAway
                  ? 'iframeBoxShadowWrapper ttopen'
                  : 'iframeBoxShadowWrapper'
              }
            >
              <iframe
                ref='iframe'
                src={''}
                className={'iframe'}
                style={{ width: iframeWidth, height: iframeHeight }}
              />
            </div>
          </div>

          {inlineTranslationTool && isTranslationWindowVisible && (
            <div
              className={
                isTranslationToolAway
                  ? 'translationToolWindow away'
                  : 'translationToolWindow'
              }
            >
              <div className={'translationToolWindowWrapper'}>
                <iframe
                  src={'/projects/translationtool/' + this.id}
                  className={'iframe'}
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <button
                className={'switchbtn'}
                title={
                  isTranslationToolAway
                    ? 'Switch to TranslationTool'
                    : 'Switch to Project'
                }
                onClick={() => {
                  this.setState({
                    isTranslationToolAway: !isTranslationToolAway
                  });
                }}
              >
                {isTranslationToolAway ? 'Switch to Editor' : 'Back to Project'}
              </button>
            </div>
          )}
        </div>

        <ProjectStatusDialog
          title={t('loading_status')}
          active={showModal}
          toggle={this.toggleProjectStatusDialog}
          translationId={translationId}
          projectId={projectId}
          sourceLanguageId={sourceLanguageId}
          processStatus={{
            fetchingProject: fetchingProject !== ProcessStatus.FINISHED,
            fetchingStructure: fetchingStructure !== ProcessStatus.FINISHED,
            fetchingTexts: fetchingTexts !== ProcessStatus.FINISHED,
            packageConnected: packageConnected
          }}
        />

        <DialogContainer
          // JH active={showDirectLinkModal}
          visible={showDirectLinkModal}
          // onEscKeyDown={() => this.toggleDirectLinkModal()}
          // onOverlayClick={() => this.toggleDirectLinkModal()}
          onHide={this.toggleDirectLinkModal} // MUSS DAS REIN?
          title={t('direct_link')}
          id='direktlink'
        >
          <TextField
            id='directlink'
            name='directlink'
            label={`${t('windows_os')}: ${t(
              'press_ctrl_c'
            )}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${t('mac_os')}: ${t(
              'press_cmd_c'
            )}:`}
            defaultValue={directLink}
          />
        </DialogContainer>

        <DialogContainer
          id='simple-full-page-dialog'
          visible={assetsPopup}
          pageX={assetsPopupPageX}
          pageY={assetsPopupPageY}
          fullPage
          onHide={null}
          aria-labelledby='simple-full-page-dialog-title'
        >
          <Button
            style={{ top: '0', right: '0', position: 'absolute' }}
            icon
            onClick={this.closeAssets}
          >
            close
          </Button>
          <AssetsView
            projectId={projectId}
            siteId={currentPageId}
            iframeLink={projectIframeUrl + '/'}
          />
        </DialogContainer>

        {!inlineTranslationTool && isTranslationWindowVisible && (
          <PortalWindow
            inSafeClosingMode={inSafeClosingMode}
            initiateSafeClosing={initiateSafeClosing}
            resetSafeClosingInitiation={this.resetSafeClosingInitiation}
            onWindowClosed={this.handleWindowClosed}
            translationViewId={this.id}
            isPdf={false}
          >
            <div></div>
          </PortalWindow>
        )}
        <GlobalSnackbar />
        <GlobalDialog />
        <ErrorDialog />
      </React.Fragment>
    );
  }
}

export default withRouter(TranslationView);
