import { I18nAction } from '../utils/ActionTypes';
import { Map, fromJS } from 'immutable';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  availableTranslations: Map(),
  fetchingAvailableTranslations: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case I18nAction.FETCH_AVAILABLE_TRANSLATIONS:
      return state.set('fetchingAvailableTranslations', ProcessStatus.STARTED);
    case I18nAction.FETCH_AVAILABLE_TRANSLATIONS_FULFILLED:
      return state
        .set('fetchingAvailableTranslations', ProcessStatus.FINISHED)
        .set('availableTranslations', Map(action.data));
    case I18nAction.FETCH_AVAILABLE_TRANSLATIONS_REJECTED:
      return state.set('fetchingAvailableTranslations', ProcessStatus.FINISHED);
    default:
      return state;
  }
};

export { initialState, reducer as i18n };
