import { TextScopesAction } from '../utils/ActionTypes';
import * as Rx from 'rxjs';

const fetchTextScopes = () => {
  return {
    type: TextScopesAction.FETCH_TEXT_SCOPES,
    data: {}
  };
};

const fetchTextScopesFulfilled = (data) => {
  return {
    type: TextScopesAction.FETCH_TEXT_SCOPES_FULFILLED,
    data: data
  };
};

const fetchTextScopesRejected = (error) => {
  return Rx.Observable.of({
    type: TextScopesAction.FETCH_TEXT_SCOPES_REJECTED,
    payload: error.xhr ? error.xhr.response : error
  });
};

export { fetchTextScopes, fetchTextScopesFulfilled, fetchTextScopesRejected };
