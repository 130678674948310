import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssets } from '../../actions/wbtViewer';
import LayoutContainer from '../layout/LayoutContainer';
import { ProcessStatus } from '../../utils/Constants';

export default function AssetsView(props) {
  const assets = useSelector((state) => state.getIn(['wbtViewer', 'assets']));
  const fetchingAssets = useSelector((state) =>
    state.getIn(['wbtViewer', 'fetchingAssets'])
  );
  const dispatch = useDispatch();

  // INITIAL LOADING
  useEffect(() => {
    dispatch(fetchAssets(props.projectId, props.siteId, props.iframeLink));
  }, []);

  if (fetchingAssets !== ProcessStatus.FINISHED) {
    return (
      <LayoutContainer width={'small'} style={{ height: '100vh' }}>
        <h1
          style={{
            padding: '20px 0',
            margin: '0'
          }}
        >
          Assets von &quot;{props.siteId}&quot;
        </h1>
        <div>loading ...</div>
      </LayoutContainer>
    );
  }
  if (assets === 'no dir found') {
    return (
      <LayoutContainer width={'small'} style={{ height: '100vh' }}>
        <h1
          style={{
            padding: '20px 0',
            margin: '0'
          }}
        >
          Assets von &quot;{props.siteId}&quot;
        </h1>
        <div>no dir found</div>
      </LayoutContainer>
    );
  }

  let images = [];
  for (var o = 0; o < assets.length; o++) {
    if (assets[o].type === 'dir' && assets[o].name === 'images') {
      images = assets[o].data;
    }
  }

  const renderImage = function (img) {
    return (
      <div style={{ padding: '0 0 20px 0' }}>
        <img
          style={{
            width: 'auto',
            maxWidth: '100%',
            height: 'auto',
            display: 'block',
            backgroundColor: '#cbcbcb',
            // eslint-disable-next-line max-len
            backgroundImage:
              'repeating-linear-gradient(45deg, #cecece, #cecece 35px, rgba(255, 255, 255, 0.66) 35px, rgba(255, 255, 255, 0.66) 70px)',
            boxShadow: '0 0 5px 0 grey'
          }}
          title={img.path}
          src={img.data}
          alt=''
        />
        <div>{img.name}</div>
      </div>
    );
  };

  const renderImages = function (images) {
    return images.map((value, key) => {
      return (
        <div key={key}>
          {value.type === 'dir' ? renderImages(value.data) : renderImage(value)}
        </div>
      );
    });
  };

  const renderVideo = function (video) {
    if (video.type !== 'video') {
      return;
    }
    return (
      <div style={{ padding: '0 0 20px 0' }}>
        <video
          controls
          style={{
            width: 'auto',
            maxWidth: '100%',
            height: 'auto',
            display: 'block',
            margin: '0 auto'
          }}
          src={props.iframeLink + video.path}
        />
        <div>{video.name}</div>
      </div>
    );
  };

  const renderVideos = function (videos) {
    return videos.map((value, key) => {
      return (
        <div key={key}>
          {value.type === 'dir' ? renderVideos(value.data) : renderVideo(value)}
        </div>
      );
    });
  };

  const renderSound = function (sound) {
    if (sound.type !== 'voiceover') {
      return;
    }
    let path = props.iframeLink + sound.path;
    return (
      <div style={{ padding: '0 0 20px 0' }}>
        <a href={path} target={'_blank'}>
          {sound.name}
        </a>
      </div>
    );
  };

  const renderSounds = function (sounds) {
    return sounds.map((value, key) => {
      return (
        <div key={key}>
          {value.type === 'dir' ? renderSounds(value.data) : renderSound(value)}
        </div>
      );
    });
  };

  let config = {};
  for (var oo = 0; oo < assets.length; oo++) {
    if (assets[oo].type === 'config') {
      config = assets[oo].data;
    }
  }

  let videos = [];
  for (var ooo = 0; ooo < assets.length; ooo++) {
    if (assets[ooo].type === 'dir' && assets[ooo].name === 'video') {
      videos = assets[ooo].data;
    }
  }

  let sounds = [];
  for (var u = 0; u < assets.length; u++) {
    if (assets[u].type === 'dir' && assets[u].name === 'sound') {
      sounds = assets[u].data;
    }
  }

  return (
    <LayoutContainer width={'small'} style={{ height: '100vh' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          height: '100vh'
        }}
      >
        <h1
          style={{
            flex: '0 0 auto',
            padding: '20px 0',
            margin: '0'
          }}
        >
          Assets von &quot;{props.siteId}&quot;
        </h1>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            flex: '1 1 auto',
            height: '90%'
          }}
        >
          <div
            style={{
              overflowX: 'hidden',
              overflowY: 'auto',
              height: '100%',
              flexGrow: '1',
              flexShrink: '1',
              flexBasis: '0',
              padding: '0 5px'
            }}
          >
            <h3>config.json</h3>
            <pre style={{ color: 'white', margin: '0', overflow: 'auto' }}>
              <code
                style={{
                  backgroundColor: '#282828',
                  padding: '10px',
                  color: 'white',
                  display: 'inline-block',
                  fontSize: '12px'
                }}
              >
                {JSON.stringify(config, null, 2)}
              </code>
            </pre>
          </div>
          {images.length > 0 && (
            <div
              style={{
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '100%',
                flexGrow: '1',
                flexShrink: '1',
                flexBasis: '0',
                padding: '0 5px'
              }}
            >
              <h3>Bilder</h3>
              <div style={{ width: '100%' }}>{renderImages(images)}</div>
            </div>
          )}
          {videos.length > 0 && (
            <div
              style={{
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '100%',
                flexGrow: '1',
                flexShrink: '1',
                flexBasis: '0',
                padding: '0 5px'
              }}
            >
              <h3>Videos</h3>
              <div style={{ width: '100%' }}>{renderVideos(videos)}</div>
            </div>
          )}
          {sounds.length > 0 && (
            <div
              style={{
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '100%',
                flexGrow: '1',
                flexShrink: '1',
                flexBasis: '0',
                padding: '0 5px'
              }}
            >
              <h3>VoiceOver</h3>
              <div style={{ width: '100%' }}>{renderSounds(sounds)}</div>
            </div>
          )}
        </div>
      </div>
    </LayoutContainer>
  );
}
