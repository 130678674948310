import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { renderTextField } from '../../general/BasicFormComponents';
import styles from '../../../styles/project_detail.scss';
import { withTranslation } from 'react-i18next';

@withTranslation(['general'], { wait: true })
class SnapshotPrime extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { editMode, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={'column'} editmode={editMode ? 'true' : 'false'}>
        <div className={styles.header}>
          <h2
            className={'headName'}
            style={{
              textTransform: 'uppercase'
            }}
          >
            {t(`${generalLNS}:snapshots`)}
          </h2>
        </div>
        <div className={'editableElements'}>
          <Field
            id='name'
            name='name'
            label={t(`${generalLNS}:name`)}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
          <Field
            id='description'
            name='description'
            label={t(`${generalLNS}:description`)}
            type='text'
            disabled={!editMode}
            className='md-cell md-cell--12'
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.getIn(['projects', 'selectedProject'])
  };
}

export default connect(mapStateToProps, {})(SnapshotPrime);
