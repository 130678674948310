import React, { Component } from 'react';

import { Button, TextField } from 'react-md';

import styles from '../../styles/search_box.scss';
import { withTranslation } from 'react-i18next';

@withTranslation('general', { wait: true })
class SearchBox extends Component {
  constructor(props) {
    super(props);
  }

  handleInputChange = (value) => {
    this.props.handleSearchInput(value);
  };

  handleResetPress = () => {
    this.props.handleSearchReset();
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      this.props.hitEnterToFocus();
    }
  };

  render() {
    const { searchTerm, customClass, t } = this.props;
    const appliedClass = customClass + ' ' + styles.searchBox;
    const placeholder = this.props.placeholder || '';

    return (
      <div className={appliedClass} title={t('search_box_hint_text')}>
        <span className={'searchLabel'}>{t('search')}</span>

        <Button
          icon
          disabled={searchTerm === ''}
          onClick={() => this.nameInput.focus()}
        >
          search
        </Button>

        <TextField
          id='searchbox'
          placeholder={placeholder}
          value={searchTerm}
          className='md-cell md-cell--bottom'
          onChange={this.handleInputChange}
          onKeyUp={this.handleKeyUp}
          ref={(input) => {
            this.nameInput = input;
          }}
        />

        <Button
          icon
          onClick={this.handleResetPress}
          disabled={searchTerm === ''}
        >
          clear
        </Button>
      </div>
    );
  }
}

export default SearchBox;
