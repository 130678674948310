import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { Autocomplete } from 'react-md';
import { withTranslation } from 'react-i18next';
import styles from '../../styles/project_detail.scss';

@withTranslation(['exporttypes', 'general'], { wait: true })
class ChipAllocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allItems: [],
      selectedItems: [],
      remainingItems: [],
      updating: false
    };
  }

  UNSAFE_componentWillMount() {
    this.updateData(this.props.items, this.props.input.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (nextProps.items.size >= 0 && nextProps.items !== this.props.items) ||
      nextProps.input.value !== this.props.input.value
    ) {
      this.updateData(nextProps.items, nextProps.input.value);
    }
  }

  updateData = (items, inputValue) => {
    let selectedItems = [];
    let remainingItems = [];

    // let sortedItems = items;
    let sortedItems = items.sort((a, b) => {
      let nameA = a.get('name');
      let nameB = b.get('name');

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    let allItems = sortedItems.map((item) => item.get('id'));

    allItems.map((id) => {
      let isUserAlreadySelected = false;
      if (inputValue) {
        inputValue.map((value) => {
          if (id == value.get('id')) {
            isUserAlreadySelected = true;
          }
        });
      }
      isUserAlreadySelected ? selectedItems.push(id) : remainingItems.push(id);
    });

    this.setState({
      allItems: allItems,
      selectedItems: selectedItems,
      remainingItems: remainingItems,
      updating: false
    });
  };

  setNextItem = (selectedItems, nextItem) => {
    let items = this.props.items.filter(
      (item) => selectedItems.indexOf(item.get('id')) >= 0
    );

    if (this.props.asynchron) {
      items = items.map((item) => {
        let it = item;
        if (it.get('id') == nextItem.get('id')) {
          it = it.set('unconfirmed', true);
        }
        return it;
      });
    }
    this.props.input.onChange(items);
  };

  addItem = (id) => {
    const selectedItems = [...this.state.selectedItems, id];
    let item = this.props.items.find((item) => item.get('id') == id);

    if (this.props.onAdd) {
      this.props.onAdd(item);
    }
    this.setNextItem(selectedItems, item);
  };

  removeItem = (item) => {
    let id = item.get('id');
    this.removeItemWithId(id);
  };

  removeItemWithId = (id) => {
    const selectedItems = this.state.selectedItems;

    let item = this.props.items.find((item) => item.get('id') == id);

    if (!this.props.asynchron) {
      selectedItems.splice(selectedItems.indexOf(id), 1);
    }

    if (this.props.onRemove) {
      this.props.onRemove(item);
    }

    this.setNextItem(selectedItems, item);
  };

  handleChipClick = (event) => {
    const { editable, locked } = this.props;

    if (editable && !locked) {
      let item = event.item;
      this.removeItem(item);
    }
  };

  handleChipUpdate = (data) => {
    let item = this.props.items.find(
      (item) => item.get('id') == data.get('id')
    );

    if (this.props.onUpdate) {
      this.props.onUpdate(data);
    }

    this.setNextItem(this.state.selectedItems, item);
  };

  handleCheckboxChange = (event) => {
    this.setState({ updating: true });
    const id = parseInt(event.target.value);
    if (event.target.checked) {
      this.addItem(id);
    } else {
      this.removeItemWithId(id);
    }
  };

  handleCheckboxClick = () => {
    setTimeout(() => {
      this.setState({ updating: true }, () => {
        setTimeout(() => {
          this.setState({ updating: true });
        }, 300);
      });
    }, 50);
  };

  render() {
    const { items, label, editable, locked, t } = this.props;
    const { selectedItems, remainingItems, allItems, updating } = this.state;
    const Chipp = this.props.chip;

    const Chipps = selectedItems.map((id) => {
      let item = items.find((item) => {
        return parseInt(item.get('id')) === id;
      });

      return (
        <Chipp
          key={id}
          item={item}
          editable={editable}
          removable={!locked}
          onClick={this.handleChipClick}
          onUpdate={this.handleChipUpdate}
        />
      );
    });

    const autocompleteData = remainingItems.map((id) => {
      let item = items.find((item) => item.get('id') === id);
      return item.toJSON();
    });
    const allItemData = allItems.map((id) => {
      let item = items.find((item) => item.get('id') === id);
      return item.toJSON();
    });

    return (
      <>
        <CSSTransition
          component='div'
          classNames='opacity'
          timeout={{ exit: 150, enter: 150 }}
        >
          <div>
            {editable && (
              <Autocomplete
                key='autocomplete'
                id='items-autocomplete'
                label={label}
                data={autocompleteData}
                dataLabel='name'
                dataValue='id'
                onAutocomplete={this.addItem}
                placeholder={this.props.placeholder}
                clearOnAutocomplete
                deleteKeys='id'
                disabled={this.props.locked}
                required={this.props.required}
                // style={{display: this.props.locked ? 'none' : ''}}
              />
            )}
            <div style={{ paddingTop: '20px', maxWidth: '450px' }}>
              {Chipps}
            </div>
          </div>
        </CSSTransition>
      </>
    );
  }

  static propTypes = {
    chip: PropTypes.func.isRequired
  };

  static defaultProps = {
    editable: true
  };
}

export default connect()(ChipAllocation);
