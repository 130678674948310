import { Map } from 'immutable';

import { WbtViewerAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = Map({
  courseTexts: {},
  textsGroupedByCollection: {},
  source: Map(),
  fetchingAllTexts: ProcessStatus.INITIAL,
  fetchingAllTextsGroupedByCollection: ProcessStatus.INITIAL,
  fetchingSource: ProcessStatus.INITIAL,
  activeTranslationId: -1,
  isLiveEditEnabled: false,
  fetchingAssets: ProcessStatus.INITIAL,
  assets: { id: 'hallo' }
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WbtViewerAction.FETCH_ALL_TEXTS:
      return state.set('fetchingAllTexts', ProcessStatus.STARTED);
    case WbtViewerAction.FETCH_ALL_TEXTS_FULFILLED:
      return state
        .set('fetchingAllTexts', ProcessStatus.FINISHED)
        .set('courseTexts', action.data.texts)
        .set('activeTranslationId', parseInt(action.data.translation_id));
    case WbtViewerAction.FETCH_ALL_TEXTS_REJECTED:
      return state.set('fetchingAllTexts', ProcessStatus.FAILED);
    case WbtViewerAction.FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION:
      return state.set(
        'fetchingAllTextsGroupedByCollection',
        ProcessStatus.STARTED
      );
    case WbtViewerAction.FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION_FULFILLED:
      return state
        .set('fetchingAllTextsGroupedByCollection', ProcessStatus.FINISHED)
        .set('textsGroupedByCollection', action.data.texts)
        .set('activeTranslationId', parseInt(action.data.translation_id));
    case WbtViewerAction.FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION_REJECTED:
      return state.set(
        'fetchingAllTextsGroupedByCollection',
        ProcessStatus.FAILED
      );
    case WbtViewerAction.FETCH_SOURCE: {
      return state.set('fetchingSource', ProcessStatus.STARTED);
    }
    case WbtViewerAction.FETCH_SOURCE_FULFILLED: {
      return state
        .set('fetchingSource', ProcessStatus.FINISHED)
        .set('source', Map(action.source));
    }
    case WbtViewerAction.FETCH_SOURCE_REJECTED: {
      return state
        .set('fetchingSource', ProcessStatus.FAILED)
        .set('error', action.payload);
    }
    case WbtViewerAction.TOGGLE_LIVE_EDIT:
      return state.set('isLiveEditEnabled', !state.get('isLiveEditEnabled'));
    case WbtViewerAction.FETCH_ASSETS: {
      return state.set('fetchingAssets', ProcessStatus.STARTED);
    }
    case WbtViewerAction.FETCH_ASSETS_FULFILLED: {
      console.log('FETCH_ASSETS_FULFILLED', action);
      return state
        .set('fetchingAssets', ProcessStatus.FINISHED)
        .set('assets', action.source.assets);
    }
    case WbtViewerAction.FETCH_ASSETS_REJECTED: {
      return state
        .set('fetchingAssets', ProcessStatus.FAILED)
        .set('error', action.payload);
    }
    default:
      return state;
  }
};

export { initialState, reducer as wbtViewer };
