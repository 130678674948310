import { ajax } from 'rxjs/observable/dom/ajax';
import { UploaderAction } from '../utils/ActionTypes';
import {
  discardUploadFulfilled,
  discardUploadRejected
} from '../actions/uploader';
import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';
import { deleteUserFulfilled } from '../actions/users';

const discardUploadEpic = (action$, store) => {
  return action$
    .ofType(UploaderAction.DISCARD_UPLOAD)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'fineUpload/' + action.uuid;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'DELETE', headers: headers })
        .map((res) => res.response)
        .map(() => discardUploadFulfilled(action.uuid))
        .catch(discardUploadRejected);
    });
};

export { discardUploadEpic };
