import { Map, List, fromJS } from 'immutable';
import { TypesAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  types: Map(),
  formattedTypes: List([]),
  timestamp: 0,
  selectedTypeId: null,
  fetchingTypes: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TypesAction.FETCH_TYPES:
      return state.set('fetchingTypes', ProcessStatus.STARTED);
    case TypesAction.FETCH_TYPES_FULFILLED:
      return state
        .set('fetchingTypes', ProcessStatus.FINISHED)
        .set('timestamp', Date.now())
        .set('types', Map(action.types))
        .set(
          'formattedTypes',
          Map(action.types).map((key, value) => {
            return { value: key, label: value };
          })
        );
    case TypesAction.FETCH_TYPES_REJECTED:
      return state
        .set('fetchingTypes', ProcessStatus.FAILED)
        .set('error', action.payload);
    default:
      return state;
  }
};

export { initialState, reducer as types };
