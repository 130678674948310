import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontIcon } from 'react-md';
import { withTranslation } from 'react-i18next';
import { fetchSnapshots } from '../../../actions/snapshots';
import { fetchSourceSnapshots } from '../../../actions/sourceSnapshots';
import { renderSelectField } from '../../general/BasicFormComponents';

import { Field } from 'react-final-form';

@withTranslation('project', { wait: true })
class ExportTranslationSnapshots extends Component {
  state = {
    sourceSnapshotId: -1,
    translationSnapshotId: -1,
    formattedSourceSnapshots: [],
    formattedTranslationSnapshots: []
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { sourceSnapshots, targetSnapshots, values } = this.props;

    const formattedSourceSnapshots = this.formatSnapshots(sourceSnapshots);
    const formattedTranslationSnapshots = this.formatSnapshots(targetSnapshots);

    values.sourceSnapshotId = formattedSourceSnapshots[0]
      ? formattedSourceSnapshots[0].value
      : -1;
    values.translationSnapshotId = formattedTranslationSnapshots[0]
      ? formattedTranslationSnapshots[0].value
      : -1;

    this.setState({
      formattedSourceSnapshots: formattedSourceSnapshots,
      formattedTranslationSnapshots: formattedTranslationSnapshots
    });
  }

  formatSnapshots = (snapshots) => {
    let snapshotsArray = snapshots.toArray();

    let formattedSnapshots = [];

    if (snapshotsArray.length > 0) {
      formattedSnapshots = this.formatSnapshotOptions(snapshotsArray).sort(
        (a, b) => this.sortDescending(a, b)
      );
    }
    return formattedSnapshots;
  };

  formatSnapshotOptions = (snapshots) => {
    return snapshots.map((snapshot) => {
      return {
        label:
          snapshot.created_at.substr(0, 10) +
          ' | ' +
          snapshot.id +
          ' - ' +
          snapshot.name,
        value: snapshot.id
      };
    });
  };

  sortDescending = (a, b) => {
    if (a.value > b.value) return -1;
    if (a.value < b.value) return 1;
    return 0;
  };

  render() {
    const {
      formattedSourceSnapshots,
      formattedTranslationSnapshots,
      sourceSnapshotId,
      translationSnapshotId
    } = this.state;

    const { t } = this.props;

    if (
      formattedSourceSnapshots.length === 0 ||
      formattedTranslationSnapshots.length === 0
    ) {
      return (
        <div>
          <p>{t('tmx_needs_snapshots')}</p>
          <p>
            {t('source_snapshots') + ': ' + formattedSourceSnapshots.length}
          </p>
          <p>
            {t('translation_snapshots') +
              ': ' +
              formattedTranslationSnapshots.length}
          </p>
        </div>
      );
    }

    return (
      <div className={'snapshotSelectWrapper'}>
        <Field
          id='sourceSnapshotId'
          name='sourceSnapshotId'
          type='text'
          helpText={t('select_source_snapshot')}
          className='md-cell md-cell--12'
          menuItems={formattedSourceSnapshots}
          value={sourceSnapshotId}
          dropdownIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
          component={renderSelectField}
          required
        />
        <Field
          id='translationSnapshotId'
          name='translationSnapshotId'
          type='text'
          helpText={t('select_translation_snapshot')}
          className='md-cell md-cell--12'
          menuItems={formattedTranslationSnapshots}
          value={translationSnapshotId}
          dropdownIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
          component={renderSelectField}
          required
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    targetSnapshots: state.getIn(['snapshots', 'snapshots']),
    fetchingTargetSnapshots: state.getIn(['snapshots', 'fetchingSnapshots']),

    sourceSnapshots: state.getIn(['sourceSnapshots', 'sourceSnapshots']),
    fetchingSourceSnapshots: state.getIn([
      'sourceSnapshots',
      'fetchingSourceSnapshots'
    ])
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchSnapshots,
    fetchSourceSnapshots
  })(ExportTranslationSnapshots)
);
