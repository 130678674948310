// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".languagecode ul[role=\"listbox\"] {\n  top: auto;\n  bottom: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"languagecode": "languagecode"
};
module.exports = exports;
