import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List as RMList, Subheader, ListItem, Divider } from 'react-md';
import { withTranslation } from 'react-i18next';
import LayoutContainer from '../layout/LayoutContainer';
import PropTypes from 'prop-types';

import { ProcessStatus, Routes } from '../../utils/Constants';
import styles from '../../styles/list.scss';

import { fetchPermissions } from '../../actions/permissions';
import {
  DeleteButton,
  EditButton,
  NewButton
} from '../general/BasicComponents';
import ContentDrawer from '../../utils/ContentDrawer';
import AddPermission from './addpermission/AddPermission';
import EditPermission from './editpermission/EditPermission';
import DeletePermissionWarning from './dialog/DeletePermissionWarning';
import { showWarning } from '../../actions/globalDialog';
import LoadingBar from '../general/DumbComponent';
import { aclFilter } from '../../utils/ACL';
import { aclPermission } from '../../utils/Permissions';
import { hasPermission } from '../../utils/AuthHelper';

@withTranslation(['permissions', 'general'], { wait: true })
class ListPermissions extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    if (this.props.permissions.size === 0) {
      this.props.fetchPermissions(true);
    }
  }

  closeDrawer = () => {
    this.props.history.push('/permissions');
  };

  handleClick(event, row, data) {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    if (data && data.name) {
      switch (data.name) {
        case 'edit':
          this.handleEdit(row.id);
          break;

        case 'delete':
          this.showDeleteWarning(row);
          break;

        default:
          break;
      }
    }
  }

  handleEdit = (id) => {
    this.props.history.push('permissions/' + id + '/edit');
  };

  showDeleteWarning = (permission) => {
    const { showWarning, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    let title = t(`${generalLNS}:warning`);
    let text =
      t('warning_delete_permission') + ' ' + permission.display_name + ' ?';

    let component = <DeletePermissionWarning permissionId={permission.id} />;

    showWarning({
      title: title,
      text: text,
      actions: [],
      component: component,
      isCancellable: false
    });
  };

  renderPermissionsList = (permissions) => {
    // eslint-disable-next-line
    permissions = permissions.map((permission) => {
      if (permission.category == null) {
        permission.category = 'Other';
      }
      return permission;
    });

    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    // eslint-disable-next-line
    permissions = permissions.sort((a, b) => {
      return a.category.localeCompare(b.category);
    });

    let currentCategory = null;
    let extra = null;
    let firstTime = true;

    return (
      <LayoutContainer>
        {aclFilter(
          <div className={styles.addButtonContainer}>
            <NewButton to={Routes.PERMISSIONS.ADD} className={'addButton'} />
          </div>
        )([], [aclPermission.create])}
        <RMList className='md-cell md-paper md-paper--1 md-cell--12'>
          {permissions.map((permission, index) => {
            if (permission.category != currentCategory) {
              currentCategory = permission.category;

              if (firstTime) {
                firstTime = false;
                extra = (
                  <Subheader
                    key={'subheader' + index}
                    primaryText={permission.category}
                  />
                );
              } else {
                extra = [
                  <Divider key={'divider' + index} inset />,
                  <Subheader
                    key={'subheader' + index}
                    primaryText={permission.category}
                  />
                ];
              }
            } else {
              extra = null;
            }

            return [
              extra,
              <ListItem
                key={index}
                primaryText={permission.display_name}
                secondaryText={permission.name}
                onClick={(event) => {
                  hasPermission(aclPermission.update) &&
                    this.handleClick(event, permission, { name: 'edit' });
                }}
              >
                {aclFilter(
                  <DeleteButton
                    key={index + '_deleteButton'}
                    tabIndex={'-1'}
                    title={t(`${generalLNS}:delete`)}
                    onClick={(event) => {
                      this.handleClick(event, permission, { name: 'delete' });
                    }}
                  />
                )([], [aclPermission.delete])}
                {aclFilter(
                  <EditButton
                    key={index + '_editButton'}
                    tabIndex={'-1'}
                    title={t(`${generalLNS}:edit`)}
                    onClick={(event) => {
                      this.handleClick(event, permission, { name: 'edit' });
                    }}
                  />
                )([], [aclPermission.update])}
              </ListItem>
            ];
          })}
        </RMList>
      </LayoutContainer>
    );
  };

  render() {
    const {
      permissions,
      fetchingPermissions,
      match: {
        params: { permissionId }
      }
    } = this.props;

    if (
      fetchingPermissions == ProcessStatus.INITIAL ||
      fetchingPermissions == ProcessStatus.STARTED
    ) {
      return (
        <LayoutContainer>
          <LoadingBar show={true} />
        </LayoutContainer>
      );
    }
    if (permissions == undefined) {
      return <div>No permissions found.</div>;
    }

    return (
      <LayoutContainer>
        <ContentDrawer
          key='1'
          children={<AddPermission closeDrawer={this.closeDrawer} />}
          visible={this.props.match.path === Routes.PERMISSIONS.ADD}
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key='2'
          children={<EditPermission permissionId={permissionId} />}
          visible={this.props.match.path === Routes.PERMISSIONS.EDIT}
          closeDrawer={this.closeDrawer}
        />
        {this.renderPermissionsList(permissions)}
      </LayoutContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.getIn(['permissions', 'permissions']),
    fetchingPermissions: state.getIn(['permissions', 'fetchingPermissions'])
  };
}

ListPermissions.propTypes = {
  itemClickFunc: PropTypes.func
};

export default connect(mapStateToProps, { fetchPermissions, showWarning })(
  ListPermissions
);
