import React from 'react';
import { Form } from 'react-final-form';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';
import WizardBase from '../../general/WizardBase';

@withTranslation('general', { wait: true })
class Wizard extends WizardBase {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, t } = this.props;
    const { page, values } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;

    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <div
              className='buttons'
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {page > 0 && !isLastPage && (
                <Button
                  flat
                  type='button'
                  iconChildren='keyboard_arrow_left'
                  onClick={this.previous}
                  children={t(`${generalLNS}:previous`)}
                />
              )}
              {!isLastPage && (
                <Button
                  flat
                  type='submit'
                  iconBefore={false}
                  iconChildren='keyboard_arrow_right'
                  children={t(`${generalLNS}:next`)}
                />
              )}
              {
                // No interaction in lastPage
              }
            </div>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      </Form>
    );
  }
}

export default Wizard;
