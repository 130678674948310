import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';

import EditClient from './EditClient';
import ClientUsers from './ClientUsers';
import ClientExportTypes from './ClientExportTypes';

import { fetchClient } from '../../../actions/clients';

import styles from '../../../styles/project_detail.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
import stylesTabs from '../../../styles/tabs.scss';

@withTranslation(['profile', 'general'], { wait: true })
class EditClientContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      tabCount: 2
    };
  }

  setTab = (tabIndex) => {
    this.setState({ tabIndex: tabIndex });
  };

  UNSAFE_componentWillMount() {
    const {
      client,
      match: {
        params: { clientId }
      }
    } = this.props;

    if (client == null || client.id != clientId) {
      this.props.fetchClient(clientId);
    }
  }

  /* UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      match: {
        params: {clientId}
      },
      updateToken
    } = nextProps;

    if (updateToken != this.props.updateToken) {
      // console.log("MUSS HIER Überhaupt gefetchted werden? WAHRSCHEINLCH NIUCHT; SCHAU NACH UPDATE");
      // this.props.fetchClient(clientId);
    }
  } */

  render() {
    const { client, t } = this.props;
    const { tabIndex, tabCount } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let transformValue = 100 * (tabIndex / tabCount) * -1;
    let tabContainerTransformValue =
      'translateX(' + transformValue + '%) translateZ(0)';
    let tabContainerWidth = 'calc(100% * ' + tabCount + ')';

    if (client == null) {
      return <LoadingBar show='true' message={t('loading_clients')} />;
    }

    return (
      <div className={stylesDrawer.drawer + ' editDrawer'}>
        <div className={'head'}>
          <div className={'headlines'}>
            <h2>{client.name}</h2>
            <h4>
              {client.id} | {client.version}
            </h4>
          </div>
        </div>

        <div className={stylesTabs.tabBtnRow}>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 0).toString()}
            onClick={() => {
              this.setTab(0);
            }}
          >
            {t(`${generalLNS}:properties`)}
          </button>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 1).toString()}
            onClick={() => {
              this.setTab(1);
            }}
          >
            {t(`${generalLNS}:relations`)}
          </button>
        </div>

        <div className={stylesTabs.tabContainerRow}>
          <div
            className='tabContainerWrapper'
            style={{
              transform: tabContainerTransformValue,
              width: tabContainerWidth
            }}
          >
            <div className={'tabContainer'}>
              <EditClient />
            </div>
            <div className={'tabContainer'}>
              <div className={styles.flexHorizontalWrapper}>
                <ClientUsers />
                <ClientExportTypes />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    client: state.getIn(['clients', 'selectedClient']),
    fetchingClient: state.getIn(['clients', 'fetchingClient']),
    updateToken: state.getIn(['clients', 'updateToken'])
  };
}

export default withRouter(
  connect(mapStateToProps, { fetchClient })(EditClientContainer)
);
