import { ajax } from 'rxjs/observable/dom/ajax';
import { StructureAction } from '../utils/ActionTypes';

import {
  fetchStructureFulfilled,
  fetchStructureRejected,
  selectItemFulfilled,
  selectItemRejected,
  setItemStatusFulfilled,
  setItemStatusRejected
} from '../actions/structure';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchStructureEpic = (action$, store) => {
  return action$
    .ofType(StructureAction.FETCH_STRUCTURE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/structure/' +
        action.translationId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .get(url, headers)
        .map((ajaxResponse) => ajaxResponse.response.data)
        .map(fetchStructureFulfilled)
        .catch(fetchStructureRejected);
    });
};

const selectItemEpic = (action$, store) => {
  return action$
    .ofType(StructureAction.SELECT_ITEM)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/structure/items/' +
        action.itemId +
        '/' +
        action.translationId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers, body: action.data })
        .map((res) => res.response.data)
        .map(selectItemFulfilled)
        .catch(selectItemRejected);
    });
};

const setItemStatusEpic = (action$, store) => {
  return action$
    .ofType(StructureAction.SET_ITEM_STATUS)
    .debounceTime(500)
    .mergeMap((action) => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.translationId +
        '/items/' +
        action.itemId +
        '/status';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.status })
        .map((res) => res.response)
        .map(setItemStatusFulfilled)
        .catch(setItemStatusRejected);
    });
};

export { fetchStructureEpic, selectItemEpic, setItemStatusEpic };
