import { combineReducers } from 'redux-immutable';

import { routing } from '../reducers/routing';

import { createResponsiveStateReducer } from 'redux-responsive';

const browser = createResponsiveStateReducer(null, {
  extraFields: () => ({
    width: window.innerWidth,
    height: window.innerHeight
  })
});

import { notifications } from '../reducers/notifications';
import { errorDialog } from '../reducers/errorDialog';
import { globalDialog } from '../reducers/globalDialog';
import { globalSnackbar } from '../reducers/globalSnackbar';
import { auth } from '../reducers/auth';

import { clients } from '../reducers/clients';
import { users } from '../reducers/users';
import { languages } from '../reducers/languages';
import { enumDefaults } from '../reducers/enumDefaults';
import { types } from '../reducers/types';
import { engineTypes } from '../reducers/engineTypes';
import { exportTypes } from '../reducers/exportTypes';
import { textScopes } from '../reducers/textScopes';
import { status } from '../reducers/status';
import { snapshots } from '../reducers/snapshots';
import { sourceSnapshots } from '../reducers/sourceSnapshots';
import { projects } from '../reducers/projects';
import { additionalContents } from '../reducers/additionalContents';
import { uploader } from '../reducers/uploader';
import { permissions } from '../reducers/permissions';
import { roles } from '../reducers/roles';
import { contacts } from '../reducers/contacts';
import { profile } from '../reducers/profile';
import { i18n } from '../reducers/i18n';
import { wbtViewer } from '../reducers/wbtViewer';
import { viewports } from '../reducers/viewports';
import { help } from '../reducers/help';
import { image } from '../reducers/image';

import projectsFilter from '../reducers/filter/projects';
import translationsFilter from '../reducers/filter/translations';
import { translationTool } from '../reducers/translationTool';
import { fieldLevel } from '../reducers/fieldLevel';
import { structure } from '../reducers/structure';
import { AuthAction } from './ActionTypes';

import { Map } from 'immutable';

const combinedReducers = (state, action) => {
  if (
    action.type === AuthAction.LOGIN_AS_USER_FULFILLED ||
    action.type === AuthAction.LOGOUT_AS_USER_FULFILLED
  ) {
    state = state.map((item, key) => {
      if (item instanceof Map) {
        if (key == 'auth') {
          return item;
        }
        return undefined;
      }
      return item;
    });
  }
  if (action.type === AuthAction.LOGOUT) {
    return userDependentReducers(undefined, action);
  }
  return userDependentReducers(state, action);
};

const userDependentReducers = combineReducers({
  routing,
  browser,
  notifications,
  errorDialog,
  globalDialog,
  globalSnackbar,
  auth,
  clients,
  users,
  languages,
  enumDefaults,
  types,
  engineTypes,
  exportTypes,
  textScopes,
  status,
  snapshots,
  sourceSnapshots,
  projects,
  additionalContents,
  uploader,
  projectsFilter,
  translationsFilter,
  permissions,
  roles,
  contacts,
  profile,
  i18n,
  wbtViewer,
  viewports,
  help,
  translationTool,
  fieldLevel,
  structure,
  image
});

export { combinedReducers };
