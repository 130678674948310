import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';

import {
  renderTextField,
  renderSelectField
} from '../../general/BasicFormComponents';

const staticViewports = [
  { id: 1, label: 'benutzerdefiniert', value: '' },
  { id: 2, label: 'Standard WBT', value: '1024,733' },
  { id: 3, label: 'Standard CLT', value: '1024,688' }
];

@withTranslation(['project', 'general'], { wait: true })
class ImportProjectMetadata extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { values, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    let hasUpload = values && values.uploadedFile ? true : false;

    return (
      <div className={'md-grid'}>
        <Field
          id='identifier'
          name='identifier'
          label={t(`${generalLNS}:identifier`)}
          type='text'
          className='md-cell md-cell--12'
          required
          errorText={t('please_enter_identifier')}
          disabled={hasUpload}
          component={renderTextField}
        />
        <Field
          id='version'
          name='version'
          label={t(`${generalLNS}:version`)}
          type='text'
          className='md-cell md-cell--12'
          required
          errorText={t('please_enter_version')}
          component={renderTextField}
        />
        <Field
          id='masterLanguage'
          name='masterLanguage'
          label={t(`${generalLNS}:master_language`)}
          type='text'
          className='md-cell md-cell--12'
          component={renderTextField}
        />
        <Field
          id='viewport'
          name='viewport'
          label={t(`${generalLNS}:viewport`)}
          type='text'
          helpText={t(`${generalLNS}:do_something`)}
          className='md-cell md-cell--12'
          menuItems={staticViewports}
          component={renderSelectField}
        />
      </div>
    );
  }
}

export default connect(null, { renderTextField, renderSelectField })(
  ImportProjectMetadata
);
