import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import styles from '../../../styles/export-translation.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
import AddSnapshotPrime from './AddSnapshotPrime';
import { addSnapshot } from '../../../actions/snapshots';
import { Button } from 'react-md';
import { ProcessStatus } from '../../../utils/Constants';

@withTranslation(['project', 'general'], { wait: true })
class AddSnapshot extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { projectId, translationId, sourceLanguageId }
      },
      addingSnapshot
    } = nextProps;

    if (
      addingSnapshot == ProcessStatus.FINISHED &&
      addingSnapshot != this.props.addingSnapshot
    ) {
      let sourceLanguagePart = sourceLanguageId ? '/' + sourceLanguageId : '';

      this.props.history.push(
        '/projects/' +
          projectId +
          '/translations/' +
          translationId +
          sourceLanguagePart
      );
    }
  }

  handleSubmit = (data) => {
    const {
      match: {
        params: { projectId, translationId }
      },
      snapshotLength
    } = this.props;

    this.props.addSnapshot(
      projectId,
      translationId,
      snapshotLength + 1,
      data.name,
      data.description
    );
  };

  render() {
    const { translationName, snapshotLength, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const version = snapshotLength + 1;

    return (
      <div className={stylesDrawer.drawer + 'editDrawer'}>
        <Form
          onSubmit={this.handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={'body paddingLeftRight marginTop'}>
                <FormSpy
                  subscription={{ values: true }}
                  no
                  translationName={translationName}
                  version={version}
                  component={AddSnapshotPrime}
                />
                <div className={styles.buttonsRightWrapper}>
                  <Button
                    children={t(`${generalLNS}:submit`)}
                    type='submit'
                    flat
                    primary
                    swapTheming
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    addingSnapshot: state.getIn(['snapshots', 'addingSnapshot'])
  };
}

export default withRouter(
  connect(mapStateToProps, {
    addSnapshot
  })(AddSnapshot)
);
