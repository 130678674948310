import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Avatar, Button, FontIcon } from 'react-md';
import { aclFilter, matchesRoles } from '../../utils/ACL';
import { aclTranslationTool } from '../../utils/Permissions';
import { Scope } from '../../utils/constants/ACL';
import classNames from 'classnames';
import styles from '../../styles/translationTool/translationTool.scss';
import { connect } from 'react-redux';
import {
  setTextCommentDone,
  setTextCommentUndone,
  updateTextComment
} from '../../actions/translationTool';
import { ProcessStatus } from '../../utils/Constants';

@withTranslation('translation_navigation', { wait: true })
class Comment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: false,
      waitingDone: false,
      waitingUndone: false,
      waitingText: false,
      deleting: false,
      deleteMenu: false,
      done: false,
      editMode: false,
      text: '',
      updatedAt: ''
    };

    this.commentTextarea = React.createRef();
  }

  componentDidMount() {
    const { done, text, updatedAt } = this.props;
    this.setState({
      done: done === 1,
      text: text,
      updatedAt: updatedAt
    });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      id,
      asDoneMarkedCommentId,
      asUnDoneMarkedCommentId,
      updatedComment,
      settingCommentDone,
      settingCommentUndone,
      updatingComment
    } = this.props;
    const { waitingDone, waitingUndone, waitingText } = prevState;

    if (
      settingCommentDone === ProcessStatus.FINISHED &&
      asDoneMarkedCommentId === id &&
      waitingDone
    ) {
      this.setState({ done: true, waiting: false, waitingDone: false }, () => {
        this.forceUpdate();
      });
    }

    if (
      settingCommentUndone === ProcessStatus.FINISHED &&
      asUnDoneMarkedCommentId === id &&
      waitingUndone
    ) {
      this.setState({ done: false, waitingUndone: false }, () => {
        this.forceUpdate();
      });
    }

    if (
      updatingComment === ProcessStatus.FINISHED &&
      updatedComment.id === id &&
      waitingText
    ) {
      this.setState(
        {
          text: updatedComment.text,
          updatedAt: updatedComment.updated_at,
          waitingText: false
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  /*
   * Methoden für State-Changes
   * */

  showDeletemenu = () => {
    this.setState({ deleteMenu: true });
  };

  hideDeletemenu = () => {
    this.setState({ deleteMenu: false });
  };

  toggleEditMode = () => {
    const { editMode, text } = this.state;
    this.setState(
      {
        editMode: !editMode
      },
      () => {
        if (this.state.editMode) {
          this.commentTextarea.current.value = text;
        }
      }
    );
  };

  /*
   * Methoden mit Ziel API
   * */

  deleteComment = () => {
    const { deleteTextComment, id } = this.props;
    this.setState(
      {
        deleting: true,
        deleteMenu: false
      },
      () => {
        setTimeout(() => {
          deleteTextComment(id); // an parent übergeben
        }, 1000);
      }
    );
  };

  markAsDone = () => {
    const { setTextCommentDone, id } = this.props;
    this.setState(
      {
        waitingDone: true
      },
      () => {
        setTimeout(() => {
          setTextCommentDone(id); // direkt an api übergeben
        }, 100);
      }
    );
  };

  markAsUndone = () => {
    const { setTextCommentUndone, id } = this.props;
    this.setState(
      {
        waitingUndone: true
      },
      () => {
        setTimeout(() => {
          setTextCommentUndone(id); // direkt an api übergeben
        }, 100);
      }
    );
  };

  updateComment = () => {
    const { updateTextComment, id } = this.props;

    const sendObj = {
      id: id,
      text: this.commentTextarea.current.value
    };

    this.setState(
      {
        editMode: false,
        waitingText: true
      },
      () => {
        updateTextComment(sendObj); // direkt an api übergeben
        this.commentTextarea.current.value = '';
      }
    );
  };

  /*
   * Methoden zum Rendern
   * */

  renderDateTime = (dateString) => {
    const date = new Date(dateString);
    let d =
      date.getDate() +
      '.' +
      (date.getMonth() + 1) +
      '.' +
      date.getFullYear() +
      ', ' +
      date.getHours() +
      ':' +
      date.getMinutes();

    return <span>{d}</span>;
  };

  render() {
    const { id, name, createdAt, commentUserId, currentUserId } = this.props;

    const {
      waitingDone,
      waitingUndone,
      waitingText,
      deleting,
      deleteMenu,
      done,
      editMode,
      text,
      updatedAt
    } = this.state;

    let nameArray = name.split(' ');
    let initials;
    if (nameArray.length === 1) {
      initials = name.substr(0, 1);
    } else {
      initials = nameArray[0].substr(0, 1) + nameArray[1].substr(0, 1);
    }

    const isSameUserOrIsAdmin =
      commentUserId === currentUserId || matchesRoles(['admin']);

    return (
      <div
        className={classNames('comment', {
          waiting: waitingText || waitingUndone || waitingDone,
          deleting, // shortform for  '"deleting": deleting'
          done
        })}
      >
        {/* {done &&
          <FontIcon className={'doneIcon'}>check</FontIcon>
        } */}
        <div className='commentHead'>
          {aclFilter(
            <div className='adminData'>
              <span>
                ADMIN:: ID: {id}, CommentUserId: {commentUserId}, currentUserId:{' '}
                {currentUserId}
              </span>
            </div>
          )(['admin'])}

          <div className='left'>
            <Avatar>{initials}</Avatar>
            <span>{name}</span>
            <span className='separator'>&#183;</span>
            {this.renderDateTime(createdAt)}
            {createdAt !== updatedAt && (
              <>
                <span className='separator'>&#183;</span>
                <FontIcon>edit</FontIcon>
                {this.renderDateTime(updatedAt)}
              </>
            )}
          </div>
          <div className='right'>
            {commentUserId === currentUserId && !done && (
              <Button
                icon
                className={classNames({ active: editMode })}
                title={'edit your own comment'}
                disabled={done}
                onClick={this.toggleEditMode}
              >
                {editMode ? 'edit_off' : 'edit'}
              </Button>
            )}

            {aclFilter(
              <>
                {done && (
                  <Button onClick={this.markAsUndone} className='undoneButton'>
                    revert done
                  </Button>
                )}
              </>
            )([], [aclTranslationTool.comments_undone], Scope.TRANSLATION)}

            {!done && isSameUserOrIsAdmin && (
              <Button
                icon
                title={'mark as done'}
                className='doneButton'
                disabled={editMode}
                onClick={this.markAsDone}
              >
                done
              </Button>
            )}

            {aclFilter(
              <>
                {!done && isSameUserOrIsAdmin && (
                  <div className='deleteButtonWrapper'>
                    <Button
                      icon
                      title='Close Comments'
                      onClick={this.showDeletemenu}
                      disabled={editMode}
                    >
                      delete_outline
                    </Button>
                    <div
                      className={classNames('deleteMenu', { show: deleteMenu })}
                    >
                      <Button
                        icon
                        title='delete comment'
                        onClick={this.deleteComment}
                      >
                        delete
                      </Button>
                      <Button icon title='close' onClick={this.hideDeletemenu}>
                        close
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )([], [aclTranslationTool.comments_delete], Scope.TRANSLATION)}
          </div>
        </div>
        <div className='text-wrapper'>
          <div
            className={'editComment'}
            style={{ display: editMode ? 'flex' : 'none' }}
          >
            <textarea rows={3} ref={this.commentTextarea} />
            <div className={styles.buttonsColumn}>
              <Button
                icon
                title='send'
                className='sendCommentButton'
                onClick={this.updateComment}
              >
                send
              </Button>
            </div>
          </div>
          <div
            className='text'
            style={{ display: !editMode ? 'block' : 'none' }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settingCommentDone: state.getIn(['translationTool', 'settingCommentDone']),
    asDoneMarkedCommentId: state.getIn([
      'translationTool',
      'asDoneMarkedCommentId'
    ]),
    settingCommentUndone: state.getIn([
      'translationTool',
      'settingCommentUndone'
    ]),
    asUnDoneMarkedCommentId: state.getIn([
      'translationTool',
      'asUnDoneMarkedCommentId'
    ]),
    updatingComment: state.getIn(['translationTool', 'updatingComment']),
    updatedComment: state.getIn(['translationTool', 'updatedComment'])
  };
}

export default connect(mapStateToProps, {
  setTextCommentDone,
  setTextCommentUndone,
  updateTextComment
})(Comment);
