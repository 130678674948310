import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

@withTranslation('project', { wait: true })
class ImportTranslationMetadata extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {}

  render() {
    const { t } = this.props;
    return (
      <div className={'md-grid'}>
        {t('placeholder_snapshot_suggestion_conflict')}
      </div>
    );
  }
}

export default ImportTranslationMetadata;
