import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { parseCode, markSearchTerm } from '../../utils/RTEParser';

import styles from '../../styles/translationTool/translationTool.scss';

@withTranslation(['translation', 'general'], { wait: true })
class StandardWithoutContentTools extends PureComponent {
  createMarkup = (htmlString) => {
    let regexBreak = /\[br\]/g;
    let newHtmlString = htmlString.replace(regexBreak, '\n&#8203;'); // Einrückungen korrekt anzeigen
    let processedString = parseCode(newHtmlString); // this.processRawContent(htmlString);
    return { __html: processedString };
  };

  /*
  processRawContent = (content) => {
    //const content = this.state.rawContent;

    var searchRegExp = /(<\/p>)((.|\n)*?)(<p>)/g;
    var replacementString = '$2<br />';

    var replacedString = content.replace(searchRegExp, replacementString);
    replacedString = replacedString.replace(/(<p>|<\/p>)/g, '');

    return replacedString;
  };
*/
  render() {
    const {
      searchTerm,
      content,
      saved,
      translationType,
      t,
      highlightedContent,
      showHighlightedContent
    } = this.props;

    let placeholder =
      translationType === 'adaption' ? 'changes' : 'translation';

    let classes = styles.contentTools + ' rightContent rteEditorStyles';
    if (saved) {
      classes += ' greenBorder';
    }
    if (showHighlightedContent) {
      classes += ' nonEditableContent styled-tags';
    }
    let stringifiedContent = (content + '').replace(/<p>(.*)<\/p>/, '$1'); // Int crasht!
    stringifiedContent =
      searchTerm !== ''
        ? markSearchTerm(stringifiedContent, searchTerm)
        : stringifiedContent;
    return (
      <div className={classes} onClick={this.props.showRTE}>
        {showHighlightedContent && (
          <div data-target-compare='true'>{highlightedContent}</div>
        )}
        {!showHighlightedContent && (
          <>
            {stringifiedContent.length === 0 ? (
              <div className={'to noEditor'}>
                <span style={{ opacity: 0.3, cursor: 'pointer' }}>
                  {t(placeholder)}
                </span>
              </div>
            ) : (
              <div
                className={'to noEditor'}
                dangerouslySetInnerHTML={this.createMarkup(stringifiedContent)}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

StandardWithoutContentTools.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default StandardWithoutContentTools;
