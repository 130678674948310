import React, { Component } from 'react';
import Tooltip from '../../components/Tooltip';
import Portal from '../../components/Portal';
import styles from '../../style/Tooltip.scss';
import { Button, Card } from 'react-md';

class TooltipEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltipAt: null
    };
  }

  openTooltip = (e) => {
    const trigger = e.target;
    if (trigger instanceof Element) {
      let rect = trigger.getBoundingClientRect();
      this.setState({ showTooltipAt: rect });
    }
  };

  closeTooltip = () => {
    this.setState({ showTooltipAt: null });
  };

  render() {
    const { entityKey, contentState, children, onEdit, onRemove, label, type } =
      this.props;
    const { showTooltipAt } = this.state;
    const { url } = contentState.getEntity(entityKey).getData();

    // Contrary to what JSX A11Y says, this should be a button but it shouldn't be focusable.
    /* eslint-disable @thibaudcolas/cookbook/jsx-a11y/interactive-supports-focus, @thibaudcolas/cookbook/jsx-a11y/anchor-is-valid */
    return (
      <a
        role='button'
        onMouseUp={this.openTooltip}
        className='TooltipEntity'
        title={type + ' to: ' + url}
      >
        <span className='TooltipEntity__text'>{children}</span>
        {showTooltipAt && (
          <Portal
            onClose={this.closeTooltip}
            closeOnClick
            closeOnType
            closeOnResize
          >
            <Tooltip
              target={showTooltipAt}
              direction='top'
              className={styles.Tooltip}
            >
              <Card className={' md-background--card'}>
                <div style={{ color: '#ff5900' }}>{type + ' to: '}</div>
                <a
                  href={url}
                  title={url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='Tooltip__link'
                >
                  {label}
                </a>

                <Button
                  icon
                  title={'edit link'}
                  onClick={onEdit.bind(null, entityKey)}
                  primary
                >
                  edit
                </Button>
                <Button
                  icon
                  title={'remove link'}
                  onClick={onRemove.bind(null, entityKey)}
                  primary
                >
                  link_off
                </Button>
              </Card>
            </Tooltip>
          </Portal>
        )}
      </a>
    );
  }
}

export default TooltipEntity;
