import React from 'react';
import { RoleAttributes } from './Constants';
import { Avatar } from 'react-md';

import chipStyles from '../styles/chip.scss';

const formatRoleOptions = (roles) => {
  const formattedRoles = roles.map((role) => {
    // https://github.com/mlaursen/react-md/issues/262
    const menuItem = {
      label: role.display_name,
      value: role.id,
      primaryText: 'label',
      leftAvatar: createAvatar(role.id)
    };
    return menuItem;
  });
  return formattedRoles;
};

const createAvatar = (roleId) => {
  let roleAttribute = RoleAttributes.find(
    (roleAttribute) => roleAttribute.id === parseInt(roleId)
  );
  if (roleAttribute == null) {
    roleAttribute = RoleAttributes.find(
      (roleAttribute) => roleAttribute.className === 'viewer'
    );
  }

  const className = [chipStyles.role, chipStyles[roleAttribute.className]].join(
    ' '
  );

  return <Avatar className={className}>{roleAttribute.short}</Avatar>;
};

const sortAlphabetically = (a, b) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};

export { formatRoleOptions, createAvatar, sortAlphabetically };
