import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';

import {
  renderTextField,
  renderSelectField
} from '../../general/BasicFormComponents';

import ObjectAutocomplete from '../../general/ObjectAutocomplete';
import Owner from '../../general/Owner';

import { truequired } from '../../../utils/ValidationRules';

import styles from '../../../styles/project_detail.scss';

@withTranslation(['project', 'general', 'types'], { wait: true })
class TranslationPrime extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.update(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.update(nextProps);
  }

  update = (props) => {
    const { project, languageOptions, countryOptions, values } = props;

    if (project.translations) {
      const tlcs = project.translations.map(
        (translation) => translation.languagecode
      );

      const translation_languagecode = values.languagecode;

      const input_lc = values.language.label.split(' - ')[0];
      let input_cc;
      let languagecode;

      // values.country.value != 1 -> if it is not an international version
      if (values.country && values.country.label && values.country.value != 1) {
        input_cc = values.country.label.split(' - ')[0];
      }

      languageOptions.map((languageOption) => {
        let option_lc = languageOption.label.split(' - ')[0];

        if (input_cc) {
          languagecode = option_lc + '-' + input_cc;
        } else {
          languagecode = option_lc;
        }

        // the languagecode of the current translation is still viable
        if (languagecode != translation_languagecode) {
          languageOption.disabled = tlcs.indexOf(languagecode) >= 0;
        }
      });

      countryOptions.map((countryOption) => {
        let option_cc = countryOption.label.split(' - ')[0];

        if (option_cc == "Intl'") {
          languagecode = input_lc;
        } else {
          languagecode = input_lc + '-' + option_cc;
        }

        // the languagecode of the current translation is still viable
        if (languagecode != translation_languagecode) {
          countryOption.disabled = tlcs.indexOf(languagecode) >= 0;
        }
      });
    }
  };

  render() {
    const {
      editMode,
      languageOptions,
      countryOptions,
      parentLanguageOptions,
      types,
      values,
      t
    } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const typesLNS = 'types'; // typesLanguageNamespaceSource

    let localeTypes = types.map((value) => ({
      label: t(`${typesLNS}:${value}`),
      value: value
    }));

    return (
      <div className={'column'} editmode={editMode ? 'true' : 'false'}>
        <div className={styles.header}>
          <h2 style={{ textTransform: 'uppercase' }} className={'headName'}>
            {t(`${generalLNS}:information`)}
          </h2>
        </div>
        <div className={'editableElements'}>
          <Field
            id='type'
            name='type'
            label={t(`${generalLNS}:type`)}
            type='text'
            helpText={t('select_type')}
            menuItems={localeTypes}
            component={renderSelectField}
            validate={truequired}
            disabled={!editMode}
            className={styles.formField}
            required
            errorText={t('no_type_selected')}
          />
          <Field
            id='language.value'
            name='language.label'
            label={t(`${generalLNS}:language`)}
            helpText={t('select_language')}
            errorText={t('select_language_error')}
            errorTextForNotFound={t('select_language_error_not_found')}
            errorTextForDisabled={t('select_language_error_disabled')}
            value={values && values.language ? values.language.label : null}
            changeValues={this.props.form.mutators.changeValues}
            options={languageOptions}
            validate={truequired}
            disabled={!editMode}
            className={styles.formField}
            required
            component={ObjectAutocomplete}
          />
          <Field
            id='country.value'
            name='country.label'
            label={t(`${generalLNS}:country`)}
            placeholder={t('country_placeholder')}
            helpText={t('country_help')}
            errorTextForNotFound={t('country_error_not_found')}
            errorTextForDisabled={t('country_error_disabled')}
            value={values && values.country ? values.country.label : null}
            changeValues={this.props.form.mutators.changeValues}
            options={countryOptions}
            validate={truequired}
            disabled={!editMode}
            className={styles.formField}
            required
            component={ObjectAutocomplete}
          />
          {values.type &&
            values.type != 'adaption' &&
            values.parent_id != null && (
              <Field
                id='parent_id'
                name='parent_id'
                label={t('translate_from')}
                type='text'
                helpText={t('parent_id_help')}
                menuItems={parentLanguageOptions}
                disabled={!editMode}
                className={styles.formField}
                component={renderSelectField}
              />
            )}
          <Field
            id='translator'
            name='translator'
            label={t('translator')}
            type='text'
            helpText={t('translator_help')}
            disabled={!editMode}
            className={styles.formField}
            component={renderTextField}
          />
        </div>
        <div className={'notEditableElements'}>
          <Field
            id='owner'
            name='owner'
            label={t(`${generalLNS}:owner`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={Owner}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.getIn(['projects', 'selectedProject'])
  };
}

export default connect(mapStateToProps, {})(TranslationPrime);
