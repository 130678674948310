import React from 'react';
import { Form } from 'react-final-form';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';
import WizardBase from './WizardBase';
import { ProcessStatus } from '../../utils/Constants';
import ActionResponseButton from './ActionResponseButton';

@withTranslation('general', { wait: true })
class Wizard extends WizardBase {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      children,
      mutators,
      submitLabel,
      submitStatus,
      t,
      readyToSubmit,
      submitError
    } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;

    let error = '';
    if (submitError) {
      if (submitError) {
        error = 'Error: ' + submitError.status;
      }
      if (
        submitError.notify &&
        submitError.notify.error &&
        submitError.notify.error.length > 0
      ) {
        error += ', ' + submitError.notify.error.join(',');
      } else {
        error += ', no message';
      }
    }

    return (
      <Form
        mutators={mutators}
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <div
              className='buttons'
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px'
              }}
            >
              {page > 0 && (
                <Button
                  flat
                  type='button'
                  iconChildren='keyboard_arrow_left'
                  onClick={this.previous}
                  disabled={submitStatus == ProcessStatus.STARTED}
                  children={t('previous')}
                />
              )}
              {!isLastPage && readyToSubmit && (
                <Button
                  flat
                  type='submit'
                  iconBefore={false}
                  iconChildren='keyboard_arrow_right'
                  children={t('next')}
                />
              )}
              {isLastPage && readyToSubmit && (
                <ActionResponseButton
                  title={submitLabel}
                  defaulticon='keyboard_arrow_right'
                  type='submit'
                  loading={submitStatus === ProcessStatus.STARTED}
                  error={submitStatus === ProcessStatus.FAILED}
                  finished={submitStatus === ProcessStatus.FINISHED}
                  text={submitLabel}
                  deactivated={submitStatus === ProcessStatus.STARTED}
                  onClick={null}
                />
              )}
            </div>
            {isLastPage &&
              readyToSubmit &&
              submitStatus === ProcessStatus.FAILED &&
              submitError !== null && (
                <div
                  style={{
                    textAlign: 'right',
                    color: '#ff5900',
                    fontSize: '12px'
                  }}
                >
                  {error}
                </div>
              )}
          </form>
        )}
      </Form>
    );
  }
}

export default Wizard;
