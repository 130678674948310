import React, { Component } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { withTranslation } from 'react-i18next';
import { FontIcon } from 'react-md';
import ContentState from 'draft-js/lib/ContentState';
import SelectionState from 'draft-js/lib/SelectionState';

@withTranslation(['translation_navigation'], { wait: true })
class IndentListButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  blockTypeIsActive = () => {
    const { getEditorState } = this.props;
    const editorState = getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === 'unordered-list-item';
  };

  adjustBlockDepthForContentState = (
    contentState,
    selectionState,
    adjustment,
    maxDepth
  ) => {
    let startKey = selectionState.getStartKey();
    let endKey = selectionState.getEndKey();
    let blockMap = contentState.getBlockMap();
    let blocks = blockMap
      .toSeq()
      .skipUntil((_, k) => k === startKey)
      .takeUntil((_, k) => k === endKey)
      .concat([[endKey, blockMap.get(endKey)]])
      .map((block) => {
        let depth = block.getDepth() + adjustment;
        depth = Math.max(0, Math.min(depth, maxDepth));
        return block.set('depth', depth);
      });

    blockMap = blockMap.merge(blocks);

    return contentState.merge({
      blockMap,
      selectionBefore: selectionState,
      selectionAfter: selectionState
    });
  };

  onClick = (e) => {
    e.preventDefault();
    const { getEditorState, setEditorState } = this.props;
    const editorState = getEditorState();
    const selection = editorState.getSelection();

    let key = selection.getAnchorKey();
    if (key !== selection.getFocusKey()) {
      return editorState;
    }

    let content = editorState.getCurrentContent();
    let block = content.getBlockForKey(key);
    let type = block.getType();
    if (type !== 'unordered-list-item' && type !== 'ordered-list-item') {
      return editorState;
    }

    event.preventDefault();

    // Only allow indenting one level beyond the block above, and only if
    // the block above is a list item as well.
    let blockAbove = content.getBlockBefore(key);
    if (!blockAbove) {
      return editorState;
    }

    let typeAbove = blockAbove.getType();
    if (
      typeAbove !== 'unordered-list-item' &&
      typeAbove !== 'ordered-list-item'
    ) {
      return editorState;
    }

    let withAdjustment = this.adjustBlockDepthForContentState(
      content,
      selection,
      1, // event.shiftKey ? -1 : 1,
      1000
    );

    setEditorState(
      EditorState.push(editorState, withAdjustment, 'adjust-depth')
    );
  };

  render() {
    let disabled = !this.blockTypeIsActive();
    return (
      <span
        className='button list-additional'
        onMouseDown={this.onClick}
        title={this.props.description}
        data-disabled={disabled.toString()}
      >
        <FontIcon>format_indent_increase</FontIcon>
      </span>
    );
  }
}

export default IndentListButton;
