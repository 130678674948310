import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, TextField } from 'react-md';
import { withTranslation } from 'react-i18next';
import styles from '../../styles/project_detail.scss';
import stylesDrawer from '../../styles/drawer_inner.scss';
// import {renderTextField} from '../general/BasicFormComponents';
import { logoutAsUser } from '../../actions/auth';
// import mapStateToProps from 'react-redux/es/connect/mapStateToProps';

@withTranslation(['profile', 'general'], { wait: true })
class Impersonation extends Component {
  constructor(props) {
    super(props);
  }

  logoutAsUser = () => {
    this.props.logoutAsUser(this.props.user.loggedin_as);
    this.props.closeDrawer();
  };

  render() {
    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div>
        <div
          className={
            stylesDrawer.drawer + ' drawerInner marginTop' + ' editDrawer'
          }
          style={{ marginTop: '0' }}
        >
          <div className={styles.autoHeight}>
            <div className={'formContentWrapper body paddingLeftRight'}>
              <TextField
                id='username'
                name='username'
                label={t(`${generalLNS}:username`)}
                value={this.props.impersonatedUser.username}
                className='md-cell md-cell--12'
                disabled
              />
              <TextField
                id='name'
                name='name'
                label={t(`${generalLNS}:name`)}
                value={this.props.impersonatedUser.name}
                className='md-cell md-cell--12'
                disabled
              />
              <TextField
                id='email'
                name='email'
                label={t('associated_email')}
                value={this.props.impersonatedUser.email}
                className='md-cell md-cell--12'
                disabled
              />
              <TextField
                id='role'
                name='role'
                label={t(`${generalLNS}:role`)}
                value={this.props.impersonatedUser.roles[0].display_name}
                className='md-cell md-cell--12'
                disabled
              />
            </div>
          </div>
          <div
            className={styles.buttonsRightWrapper}
            style={{ margin: '8px 28px' }}
          >
            <Button
              children={t('logout_as') + ' ' + this.props.impersonatedUser.name}
              onClick={this.logoutAsUser}
              flat
              secondary
              swapTheming
              iconChildren='supervised_user_circle'
              iconBefore={false}
              type='submit'
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.getIn(['auth', 'user']),
    impersonatedUser: state.getIn(['auth', 'impersonatedUser'])
  };
}

export default connect(mapStateToProps, { logoutAsUser })(Impersonation);
