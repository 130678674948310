import { ajax } from 'rxjs/observable/dom/ajax';
import { ExportTypesAction } from '../utils/ActionTypes';
import {
  fetchExportTypesFulfilled,
  fetchExportTypesRejected
} from '../actions/exportTypes';
import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchExportTypesEpic = (action$, store) => {
  return action$
    .ofType(ExportTypesAction.FETCH_EXPORT_TYPES)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'exporttypes';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map((res) => res.response.data)
        .map(fetchExportTypesFulfilled)
        .catch(fetchExportTypesRejected);
    });
};

export { fetchExportTypesEpic };
