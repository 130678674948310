import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import {
  renderPasswordTextField,
  renderTextField
} from '../general/BasicFormComponents';
import { showNotification } from '../../actions/notifications';
import { resetPassword } from '../../actions/auth';

import { ProcessStatus } from '../../utils/Constants';
import { FontIcon } from 'react-md';
import stylesLogin from '../../styles/login.scss';
import { withTranslation } from 'react-i18next';

@withTranslation(['change_password', 'login'], { wait: true })
class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,

      nPWFocus: false,
      nPWTouched: false,
      nPWStrength: 0,
      nPWLowercase: false,
      nPWUppercase: false,
      nPWNumbers: false,
      nPWSpecial: false,
      nPWLength: false,
      nPWLengthNumber: 0,

      nConfirmPWFocus: false,
      nConfirmPWTouched: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { resettingPassword } = nextProps;

    if (
      resettingPassword === ProcessStatus.FINISHED &&
      resettingPassword !== this.props.resettingPassword
    ) {
      this.setState({ success: true });
      this.props.showBackButton();
    }
    // if (resettingPassword === ProcessStatus.FAILED) {
    //   this.setState({success: true});
    // }
  }

  /* resetPassword = formData => {
    this.props.resettingPassword == ProcessStatus.STARTED;
    console.log('resetPassword', formData);
    const mergedMap = formData.set('token', this.props.token);
    console.log('mergedMap', mergedMap);
    this.props.resetPassword(mergedMap);
  }; */

  checkPasswordStrength = (nPw) => {
    let nPWStrength = 0;
    let {
      nPWLowercase,
      nPWUppercase,
      nPWNumbers,
      nPWSpecial,
      nPWLength,
      newPasswordFilled
    } = false;
    if (nPw) {
      if (nPw.length > 9) {
        nPWLength = true;
        nPWStrength += 2;
      }
      if (nPw.match(/[A-Z]|[Ä-ß]/g)) {
        nPWUppercase = true;
        nPWStrength++;
      }
      if (nPw.match(/[a-z]|[ä-ü]/g)) {
        nPWLowercase = true;
        nPWStrength++;
      }
      if (nPw.match(/\d/g)) {
        nPWNumbers = true;
        nPWStrength++;
      }
      if (nPw.match(/!+|@+|#+|\$+|%+|\^+|&+|\*+|\(+|\)+/g)) {
        nPWSpecial = true;
        nPWStrength++;
      }
      if (nPWStrength >= 5) {
        newPasswordFilled = true;
      }

      this.setState({
        nPWStrength,
        nPWLowercase,
        nPWUppercase,
        nPWNumbers,
        nPWSpecial,
        nPWLength,
        nPWLengthNumber: nPw.length
      });

      this.props.values.new_password = nPw;
      if (this.props.values.confirm_new_password) {
        if (this.props.values.new_password) {
          const newPasswordConfirmed =
            nPw === this.props.values.confirm_new_password;
          this.props.setFormFields({ newPasswordConfirmed });
        } else {
          this.props.setFormFields({ newPasswordConfirmed: false });
        }
      }
      this.props.setFormFields({ newPasswordFilled });
    } else {
      this.setState({
        nPWStrength: 0,
        nPWLowercase: false,
        nPWUppercase: false,
        nPWNumbers: false,
        nPWSpecial: false,
        nPWLength: false,
        nPWLengthNumber: 0
      });
      this.props.setFormFields({ newPasswordFilled: false });
    }
  };

  checkPassIdentical = (cPw) => {
    if (this.props.values.new_password) {
      const newPasswordConfirmed = this.props.values.new_password === cPw;
      this.props.setFormFields({ newPasswordConfirmed });
    } else {
      this.props.setFormFields({ newPasswordConfirmed: false });
    }
  };

  onFocusNewPassword = () => {
    this.setState({
      nPWFocus: true,
      nPWTouched: true
    });
  };

  onBlurNewPassword = () => {
    this.setState({
      nPWFocus: false
    });
  };

  onFocusConfirmNewPassword = () => {
    this.setState({
      nConfirmPWFocus: true,
      nConfirmPWTouched: true
    });
  };

  onBlurConfirmNewPassword = () => {
    this.setState({
      nConfirmPWFocus: false
    });
  };

  validationInformation = () => {
    const { t } = this.props;
    const {
      nPWLowercase,
      nPWUppercase,
      nPWNumbers,
      nPWSpecial,
      nPWLength,
      nPWLengthNumber
    } = this.state;

    return (
      <div
        className={'validationInformation ' + this.state.nPWFocus ? 'show' : ''}
      >
        <div className={stylesLogin.validationInformationItem}>
          <FontIcon>{nPWLowercase ? 'done' : 'clear'}</FontIcon>
          <div className={stylesLogin.validateText}>
            {t('validate_nPWLowercase')}
          </div>
        </div>
        <div className={stylesLogin.validationInformationItem}>
          <FontIcon>{nPWUppercase ? 'done' : 'clear'}</FontIcon>
          <div className={stylesLogin.validateText}>
            {t('validate_nPWUppercase')}
          </div>
        </div>
        <div className={stylesLogin.validationInformationItem}>
          <FontIcon>{nPWNumbers ? 'done' : 'clear'}</FontIcon>
          <div className={stylesLogin.validateText}>
            {t('validate_nPWNumbers')}
          </div>
        </div>
        <div className={stylesLogin.validationInformationItem}>
          <FontIcon>{nPWSpecial ? 'done' : 'clear'}</FontIcon>
          <div className={stylesLogin.validateText}>
            {t('validate_nPWSpecial')}
          </div>
        </div>
        <div className={stylesLogin.validationInformationItem}>
          <FontIcon>{nPWLength ? 'done' : 'clear'}</FontIcon>
          <div className={stylesLogin.validateText}>
            {nPWLengthNumber} /{t('validate_nPWLength')}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const { newPasswordFilled, newPasswordConfirmed } = this.props.formFields;
    const { nPWFocus, nPWStrength, nConfirmPWFocus } = this.state;
    // const showNewPassword = oldPasswordFilled ? 'show' : '';
    // const showConfirmPassword = newPasswordFilled ? 'show' : '';
    const strengthNPw = 'strength' + nPWStrength * 20;
    const isConfirmPasswordIdentical = newPasswordConfirmed
      ? 'strength100'
      : 'strength0';
    const showValidationInformation =
      nPWFocus && !newPasswordFilled ? 'show' : '';
    const showNPwProgressbar = nPWFocus || newPasswordFilled ? 'show' : '';
    const showConfirmPasswordProgressBar =
      nConfirmPWFocus || newPasswordConfirmed ? 'show' : '';

    if (this.state.success) {
      return (
        <div className={stylesLogin.successMessage}>
          <FontIcon>done_outline</FontIcon>
          <div className='text'>
            <p>{t(`${'login'}:reset_success`)}</p>
            <p>{t(`${'login'}:after_reset_success`)}</p>
          </div>
        </div>
      );
    }

    return (
      <div className={stylesLogin.changePasswordWrapperLogin}>
        <div className={stylesLogin.inputFieldContainer}>
          <div className={stylesLogin.redLine + ' ' + showNPwProgressbar}>
            <div className={stylesLogin.greenLine + ' ' + strengthNPw}></div>
          </div>
          <Field
            tabIndex='0'
            id='new_password'
            name='new_password'
            label={t('type_new_password')}
            type='password'
            className={
              stylesLogin.newPasswordContainer + ' ' + stylesLogin.show
            }
            required
            component={renderPasswordTextField}
            validate={this.checkPasswordStrength}
            onFocus={this.onFocusNewPassword}
            onBlur={this.onBlurNewPassword}
          />
          {newPasswordFilled && <FontIcon>done</FontIcon>}
        </div>

        <div
          className={
            stylesLogin.validationInformationContainer +
            ' ' +
            showValidationInformation
          }
        >
          {this.validationInformation()}
        </div>

        <div className={stylesLogin.inputFieldContainer + ' newPassword'}>
          <div
            className={
              stylesLogin.redLine + ' ' + showConfirmPasswordProgressBar
            }
          >
            <div
              className={
                stylesLogin.greenLine + ' ' + isConfirmPasswordIdentical
              }
            ></div>
          </div>
          <Field
            tabIndex='0'
            id='confirm_new_password'
            name='confirm_new_password'
            label={t('confirm_new_password')}
            type='password'
            className={
              stylesLogin.confirmNewPasswordContainer + ' ' + stylesLogin.show
            }
            required
            component={renderPasswordTextField}
            validate={this.checkPassIdentical}
            onFocus={this.onFocusConfirmNewPassword}
            onBlur={this.onBlurConfirmNewPassword}
          />
          {newPasswordConfirmed && <FontIcon>done</FontIcon>}
        </div>
      </div>
    );
  }
}

// function mapStateToProps (state) {
//   return {
//     // resettingPassword: state.get(['auth', 'resettingPassword'])
//   };
// }

export default connect()(ResetPasswordScreen);
