import React, { Component } from 'react';
import { Button, TextField } from 'react-md';
import { withTranslation } from 'react-i18next';
import { debounce } from 'lodash';

@withTranslation('general', { wait: true })
class SearchField extends Component {
  tweenTimeout = null;

  constructor(props) {
    super(props);

    this.state = {
      value: ''
    };

    this.debouncedSearchHandleChange = debounce(
      this.debouncedSearchHandleChange.bind(this),
      500
    );
  }

  componentDidMount() {
    const { searchTerm } = this.props;

    this.setState({
      value: searchTerm
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isVisible && !this.props.isVisible) {
      this.nameInput.focus();
    }
  }

  debouncedSearchHandleChange = (event) => {
    this.setState({ value: event }, () => {
      this.search();
    });
  };

  handleInputChange = (event) => {
    this.debouncedSearchHandleChange(event.target.value);

    const { target } = event;
    setTimeout(() => {
      if (
        document.activeElement &&
        document.activeElement.getAttribute('id') !== 'searchbox'
      ) {
        target.focus();
      }
    }, 1000);
  };

  search = () => {
    this.props.handleSearchInput(this.state.value);
  };

  handleResetPress = () => {
    this.props.handleSearchReset();
    setTimeout(() => {
      this.setState({
        isSearchFieldVisible: false
      });
    }, 200);
  };

  render() {
    const { isVisible, value } = this.props;
    const placeholder = this.props.placeholder || '';

    return (
      <div className={`searchBoxWrapper ${isVisible ? '' : ' hidden'}`}>
        <TextField
          id='searchbox'
          placeholder={placeholder}
          value={value}
          ref={(input) => {
            this.nameInput = input;
          }}
          onInput={this.handleInputChange}
        />
        <Button icon onClick={this.handleResetPress}>
          clear
        </Button>
      </div>
    );
  }
}

export default SearchField;
