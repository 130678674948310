import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';

import { renderTextField } from '../../general/BasicFormComponents';

import styles from '../../../styles/project_detail.scss';

@withTranslation(['project', 'general'], { wait: true })
class TranslationTimestamps extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { editMode, t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={'column'} editmode={editMode ? 'true' : 'false'}>
        <div className={styles.header}>
          <h2 style={{ textTransform: 'uppercase' }} className={'headName'}>
            {t(`${generalLNS}:timestamps`)}
          </h2>
        </div>
        <div className={'editableElements'} />
        <div className={'notEditableElements'}>
          <Field
            id='created_at'
            name='created_at'
            label={t(`${generalLNS}:created_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            component={renderTextField}
            required
            disabled
          />
          <Field
            id='imported_at'
            name='imported_at'
            label={t(`${generalLNS}:imported_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            component={renderTextField}
            required
            disabled
          />
          <Field
            id='updated_at'
            name='updated_at'
            label={t(`${generalLNS}:updated_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            component={renderTextField}
            required
            disabled
          />
          <Field
            id='exported_at'
            name='exported_at'
            label={t(`${generalLNS}:exported_at`)}
            type='text'
            helpText={t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            component={renderTextField}
            required
            disabled
          />
        </div>
      </div>
    );
  }
}

export default connect()(TranslationTimestamps);
