import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { Map } from 'immutable';
import { withTranslation } from 'react-i18next';
import {
  renderTextField,
  renderSwitch,
  renderSelectField,
  renderStarSwitch
} from '../../general/BasicFormComponents';
import Switch from '../../general/SwitchComponent';
import LoadingBar from '../../general/DumbComponent';
import ChipAllocationOld from '../../general/ChipAllocationOld';
import RoleAvatarChip from '../../general/chip/RoleAvatarChip';
import { fetchAvailableTranslations } from '../../../actions/i18n';
import { fetchRoles } from '../../../actions/roles';
import styles from '../../../styles/translation_detail.scss';
import {
  formatRoleOptions,
  sortAlphabetically
} from '../../../utils/ChipHelperFunctions';
import { aclFilter } from '../../../utils/ACL';
import { aclUser } from '../../../utils/Permissions';

@withTranslation(['users', 'general'], { wait: true })
class UserPrime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameError: false,
      usernameErrorText: null,
      emailError: false,
      emailErrorText: null,
      languageCodeError: false,
      languageCodeErrorText: null,
      clientOptions: null,
      roleOptions: null,
      originalRolesCount: null,
      availableTranslations: null,
      isSuperIsAvailable: false
    };
  }

  UNSAFE_componentWillMount() {
    const { availableTranslations, roles } = this.props;
    this.safeUpdate(this.props);
    if (availableTranslations.size === 0) {
      this.props.fetchAvailableTranslations();
    }
    if (roles.size === 0) {
      this.props.fetchRoles();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { values: detailed_clients } = nextProps;

    let isSuperIsAvailable = false;
    if (detailed_clients.detailed_clients) {
      isSuperIsAvailable = detailed_clients.detailed_clients.some(
        (client) => client.toObject().id === 1
      );
      if (isSuperIsAvailable !== this.state.isSuperIsAvailable) {
        this.setState({
          isSuperIsAvailable: isSuperIsAvailable
        });
      }
    }

    this.safeUpdate(nextProps);
  }

  safeUpdate = (props) => {
    const { clients, roles, availableTranslations, user } = props;
    const { originalRolesCount } = this.state;

    if (clients.size > 0 && roles.size > 0 && availableTranslations.size > 0) {
      let clientOptions = this.formatClientOptions(clients)
        .toArray()
        .sort((a, b) => sortAlphabetically(a, b));
      let roleOptions = formatRoleOptions(roles)
        .toArray()
        .sort((a, b) => sortAlphabetically(a, b));

      let availableTranslationOptions = this.formatAvailableTranslations(
        availableTranslations.toObject()
      );

      // user.roles[0].id === 1 -> check if users role is admin
      if (originalRolesCount === null && user.roles[0].id !== 1) {
        let tempRoleOptions = roleOptions.filter((obj) => {
          return obj.value !== 1; // 1 is the ID for Admin
        });

        this.setState({
          clientOptions: clientOptions,
          roleOptions: tempRoleOptions,
          availableTranslations: availableTranslationOptions,
          originalRolesCount: tempRoleOptions.size
        });
      } else if (user.roles[0].id === 1) {
        this.setState({
          clientOptions: clientOptions,
          roleOptions: roleOptions,
          availableTranslations: availableTranslationOptions
        });
      }
    }
  };

  formatClientOptions = (clients) => {
    return clients.map((client) => {
      return Map({ id: client.id, name: client.name + ' ' }); // extra SPACE for convenient search for all clients
    });
  };

  formatAvailableTranslations = (availableTranslations) => {
    return Object.entries(availableTranslations).map(([key, value]) => {
      return { label: key + ' | ' + value, value: key };
    });
  };

  checkIfAttributeExist = (attribute, value) => {
    const { users } = this.props;
    return users.find((user) => user[attribute] == value);
  };

  validateUsername = (username) => {
    let returnValue = '';
    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (this.checkIfAttributeExist('username', username)) {
      this.setState({
        usernameError: true,
        usernameErrorText: t('username_already_taken')
      });
      returnValue = t(`${generalLNS}:error`);
    } else {
      this.setState({ usernameError: false, usernameErrorText: null });
      returnValue = undefined;
    }
    return returnValue;
  };

  validateEmail = (email) => {
    let returnValue = '';
    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (this.checkIfAttributeExist('email', email)) {
      this.setState({
        emailError: true,
        emailErrorText: t('email_already_taken')
      });
      returnValue = t(`${generalLNS}:error`);
    } else {
      this.setState({ emailError: false, emailErrorText: null });
      returnValue = undefined;
    }
    return returnValue;
  };

  render() {
    const { values, t, inviteToMe, userWithoutMail } = this.props;

    const {
      clientOptions,
      roleOptions,
      usernameError,
      usernameErrorText,
      emailError,
      emailErrorText
    } = this.state;

    const { isSuperIsAvailable } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (clientOptions == null) {
      return (
        <div className={styles.translationPrime}>
          <LoadingBar
            show='true'
            message={t(`${generalLNS}:loading_translations`)}
          />
        </div>
      );
    }

    const className = 'md-grid ' + styles.translationPrime;

    let languageCodes = this.state.availableTranslations;

    return (
      <div className={className}>
        <div className={'translationFormHead'}>
          <div className={'translationHeadHeadlines'}>
            <h2>{t('add_user')}</h2>
          </div>
        </div>
        {aclFilter(
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'flex-end',
              paddingTop: '10px',
              width: '100%'
            }}
          >
            <Switch
              // readonly
              id={'userWithoutMail'}
              name={'userWithoutMail'}
              label={t('user_without_mail')}
              labelBefore={true}
              checked={userWithoutMail}
              value={userWithoutMail}
              onChange={this.props.handleUserWithoutMail}
            />
          </div>
        )([], [aclUser.create_user_without_email])}
        <div className={'formContentWrapper'}>
          <Field
            id='username'
            name='username'
            maxlength='32'
            label={t(`${generalLNS}:username`)}
            type='text'
            helpText={t('select_username')}
            className='md-full-width'
            required
            component={renderTextField}
            validate={this.validateUsername}
            error={usernameError}
            errorText={usernameErrorText}
          />
          <Field
            id='name'
            name='name'
            maxlength='32'
            label={t(`${generalLNS}:name`)}
            type='text'
            helpText={t('select_name')}
            className='md-full-width'
            required
            component={renderTextField}
          />
          {!userWithoutMail && (
            <Field
              id='email'
              name='email'
              label={t('associated_email')}
              type='text'
              className='md-full-width'
              required
              component={renderTextField}
              validate={this.validateEmail}
              error={emailError}
              errorText={emailErrorText}
            />
          )}
          <Field
            id='detailed_clients'
            name='detailed_clients'
            label={t('select_clients')}
            placeholder={t('press_space_for_complete_list')}
            type='text'
            helpText={t('select_clients_help')}
            className='md-full-width'
            required={values['detailed_clients'] ? false : true}
            items={clientOptions}
            chip={RoleAvatarChip}
            component={ChipAllocationOld}
            errorText={t('select_clients_error')}
          />
          <div
            className='superRole md-full-width'
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center'
            }}
          >
            <Field
              id='role_id'
              name='role_id'
              label={t('global_role')}
              type='text'
              style={{
                flexGrow: '1',
                flexShrink: '1',
                flexBasis: 'auto'
              }}
              menuItems={roleOptions}
              required
              component={renderSelectField}
              errorText={t('select_role_error')}
            />
            {isSuperIsAvailable && (
              <Field
                id='is_super'
                name='is_super'
                label='is super'
                style={{
                  flexGrow: '0',
                  flexShrink: '0',
                  flexBasis: 'auto'
                }}
                component={renderStarSwitch}
              />
            )}
          </div>
          <Field
            id='languagecode'
            name='languagecode'
            label={t(`${generalLNS}:language_code`)}
            type='text'
            className='md-full-width'
            menuItems={languageCodes}
            required
            component={renderSelectField}
            errorText={t('select_language_code_error')}
          />
          <Field
            id='default_permissions'
            name='default_permissions'
            label={t('default_permissions')}
            type='text'
            helpText={t('grant_default_permissions')}
            className='md-full-width'
            component={renderTextField}
          />
          <Field
            id='active'
            name='active'
            label={t(`${generalLNS}:active`)}
            type='switch'
            component={renderSwitch}
          />
          {!userWithoutMail && (
            <div>
              {aclFilter(
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-end',
                    paddingTop: '10px',
                    width: '100%'
                  }}
                >
                  <Switch
                    id={'inviteToMe'}
                    name={'inviteToMe'}
                    label={t('invite_to_me')}
                    labelBefore={true}
                    disabled={userWithoutMail}
                    checked={inviteToMe && !userWithoutMail}
                    value={inviteToMe && !userWithoutMail}
                    onChange={this.props.handleInviteToMe}
                  />
                </div>
              )([], [aclUser.invite_to_me])}

              {!inviteToMe && (
                <Field
                  id='invite'
                  name='invite'
                  label={t('invite_user')}
                  type='switch'
                  component={renderSwitch}
                />
              )}
              {inviteToMe && (
                <div
                  style={{
                    lineHeight: '40px',
                    paddingTop: '10px',
                    textAlign: 'right',
                    fontSize: '13px'
                  }}
                >
                  {t('invite_not_user')}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clients: state.getIn(['clients', 'clients']),
    roles: state.getIn(['roles', 'roles']),
    users: state.getIn(['users', 'users']),
    user: state.getIn(['auth', 'user']),
    availableTranslations: state.getIn(['i18n', 'availableTranslations'])
  };
}

export default connect(mapStateToProps, {
  fetchAvailableTranslations,
  fetchRoles,
  formatRoleOptions
})(UserPrime);
