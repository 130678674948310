import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, FormSpy } from 'react-final-form';
import { withTranslation } from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import { renderSelectionGroup } from '../../general/BasicFormComponents';

import Wizard from './Wizard';
import WizardBase from '../../general/WizardBase';
import FinalizeExport from './FinalizeExport';
import SelectPackageType from './SelectPackageType';

import { fetchAvailableExports } from '../../../actions/projects';
// import {fetchAdditionalContents} from '../../../actions/additionalContents';

import styles from '../../../styles/export-translation.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
// import {Map} from 'immutable';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const onSubmit = async (values) => {
  await sleep(300);
  window.alert(JSON.stringify(values, 0, 2));
};

@withTranslation(['project', 'general', 'export'], { wait: true })
class ExportTranslationWizard extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    // console.log('this.props.isPDFExporter: ', this.props.isPDFExporter);
    // if (!this.props.isPDFExporter) {
    this.props.fetchAvailableExports(this.props.projectId);
    // }
  }

  render() {
    const {
      availableExports,
      exportingTranslation,
      fetchingAvailableExports,
      t
    } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const exportLNS = 'export'; // exportLanguageNamespaceSource
    let initialValues = {};
    let additionalContentId = null;

    if (this.props.isPDFExporter && this.props.additionalContents) {
      // console.log('RENDER: this.props.additionalContents', this.props.additionalContents);
      // console.log('RENDER: this.props.collectionId', this.props.collectionId);
      let filteredAdditionalContent = this.props.additionalContents.filter(
        (ac) => {
          return ac.type === 'pdf';
        }
      );
      additionalContentId = filteredAdditionalContent[0].id;
    }

    let exports = [];
    let exportTypes = [];

    if (availableExports.size > 0 && !this.props.isPDFExporter) {
      availableExports.map((availableExport) => {
        let type = availableExport.type.toString();

        if (exportTypes.includes(type)) {
          // already used
        } else {
          exportTypes.push(type);
          exports.push({
            label: t(`${exportLNS}:${type}`),
            value: type
          });
        }
      });
      if (exports.length > 0) {
        initialValues = {
          exportType: exports[0].value,
          project_package_type: '3',
          language_package_type: '6',
          language_for_translation_package_type: '7',
          snapshot: 'yes'
        };
      }
    } else if (this.props.isPDFExporter) {
      /*
        exportTypes.push('language_for_translation');
        exports.push({
          label: t(`${exportLNS}:${'language_for_translation'}`),
          value: 'language_for_translation'
        });
      */
      exportTypes.push('language');
      exports.push({
        label: t(`${exportLNS}:${'language'}`),
        value: 'language'
      });
      if (exports.length > 0) {
        initialValues = {
          exportType: exports[0].value,
          project_package_type: '3',
          language_package_type: '8',
          language_for_translation_package_type: '7',
          snapshot: 'yes'
        };
      }
      // console.log('exportTypes: ', exportTypes);
      // console.log('exports: ', exports);
    }

    if (!this.props.isPDFExporter) {
      if (
        availableExports.size === 0 &&
        (fetchingAvailableExports === 1 || fetchingAvailableExports === 2)
      ) {
        return (
          <section>
            <div
              className={
                styles.form + ' ' + stylesDrawer.drawer + ' editDrawer'
              }
            >
              <div className={'head'}>
                <div className={styles.formHeader + ' headlines no-margin'}>
                  <h2>{t('export_translation')}</h2>
                </div>
              </div>
              <div
                className={styles.formContentWrapper + ' body paddingLeftRight'}
              >
                <LoadingBar
                  show='true'
                  wrapperClassname={styles.loadingBarWrapper}
                  message={t(`${generalLNS}:preparing`)}
                />
              </div>
            </div>
          </section>
        );
      } else if (
        availableExports.size === 0 &&
        fetchingAvailableExports === 3
      ) {
        return (
          <section>
            <div
              className={
                styles.form + ' ' + stylesDrawer.drawer + ' editDrawer'
              }
            >
              <div className={'head'}>
                <div className={styles.formHeader + ' headlines no-margin'}>
                  <h2>{t('export_translation')}</h2>
                </div>
              </div>
              <div
                className={styles.formContentWrapper + ' body paddingLeftRight'}
              >
                {t('no_export_types_set')}
              </div>
            </div>
          </section>
        );
      } else if (availableExports.size >= 0 && fetchingAvailableExports === 3) {
        let passedProps = [];
        availableExports.map((availableExport) => {
          passedProps.push(availableExport);
        });

        return (
          <section>
            <div
              className={
                styles.form + ' ' + stylesDrawer.drawer + ' editDrawer'
              }
            >
              <div className={'head'}>
                <div className={styles.formHeader + ' headlines no-margin'}>
                  <h2>{t('export_translation')}</h2>
                </div>
              </div>
              <div
                className={styles.formContentWrapper + ' body paddingLeftRight'}
              >
                <Wizard
                  initialValues={initialValues}
                  submitLabel={t(`${generalLNS}:export`)}
                  onSubmit={onSubmit}
                  submitStatus={exportingTranslation}
                >
                  <WizardBase.Page>
                    <div>
                      <p>{t('select_export_type')}</p>
                      <Field
                        name='exportType'
                        type='radio'
                        component={renderSelectionGroup}
                        controls={exports}
                      />
                    </div>
                  </WizardBase.Page>
                  <WizardBase.Page>
                    <FormSpy
                      subscription={{ values: true }}
                      passedProps={passedProps}
                      component={SelectPackageType}
                    />
                  </WizardBase.Page>
                  <WizardBase.Page>
                    <FormSpy
                      subscription={{ values: true }}
                      projectId={this.props.projectId}
                      translationId={this.props.translationId}
                      additionalContentId={additionalContentId}
                      component={FinalizeExport}
                    />
                  </WizardBase.Page>
                </Wizard>
              </div>
            </div>
          </section>
        );
      }
    } else if (this.props.isPDFExporter) {
      let passedProps = [];
      availableExports.map((availableExport) => {
        passedProps.push(availableExport);
      });
      passedProps = passedProps.filter((item) => {
        return item.name === 'xliff_by_collections';
      });
      let collectionId = this.props.collectionId ? this.props.collectionId : '';
      console.log('COLLECTION ID EXISTS: ', collectionId);

      return (
        <section>
          <div
            className={styles.form + ' ' + stylesDrawer.drawer + ' editDrawer'}
          >
            <div className={'head'}>
              <div className={styles.formHeader + ' headlines no-margin'}>
                <h2 style={{ textTransform: 'uppercase' }}>
                  {t('export_pdf_translation')}
                </h2>
                <h3>{collectionId}</h3>
              </div>
            </div>
            <div
              className={styles.formContentWrapper + ' body paddingLeftRight'}
            >
              <Wizard
                initialValues={initialValues}
                submitLabel={t(`${generalLNS}:export`)}
                onSubmit={onSubmit}
                submitStatus={exportingTranslation}
              >
                {/* <WizardBase.Page>
                  <div>
                    <p>{t('select_export_type')}</p>
                    <Field
                      name="exportType"
                      type="radio"
                      component={renderSelectionGroup}
                      controls={exports}
                    />
                  </div>
                </WizardBase.Page> */}
                <WizardBase.Page>
                  <FormSpy
                    subscription={{ values: true }}
                    passedProps={passedProps}
                    component={SelectPackageType}
                  />
                </WizardBase.Page>
                <WizardBase.Page>
                  <FormSpy
                    subscription={{ values: true }}
                    projectId={this.props.projectId}
                    translationId={this.props.translationId}
                    isPDFExporter={this.props.isPDFExporter}
                    collectionId={collectionId}
                    additionalContentId={additionalContentId}
                    component={FinalizeExport}
                  />
                </WizardBase.Page>
              </Wizard>
            </div>
          </div>
        </section>
      );
    }
    return (
      <section>
        <div
          className={styles.form + ' ' + stylesDrawer.drawer + ' editDrawer'}
        >
          <div className={'head'}>
            <div className={styles.formHeader + ' headlines no-margin'}>
              <h2>
                {this.props.isPDFExporter
                  ? t('export_pdf_translation')
                  : t('export_translation')}
              </h2>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    exportingTranslation: state.getIn(['projects', 'exportingTranslation']),
    fetchingAvailableExports: state.getIn([
      'projects',
      'fetchingAvailableExports'
    ]),
    availableExports: state.getIn(['projects', 'availableExports']),
    additionalContents: state.getIn([
      'projects',
      'translation',
      'additional_contents'
    ])
  };
}

export default connect(mapStateToProps, { fetchAvailableExports })(
  ExportTranslationWizard
);
